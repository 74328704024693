<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Products'],
        ]"
      />
    </template>
    <template #header_page_title> Customer Products </template>
    <Menu></Menu>
    <div :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <portal v-if="editDefaultsForProduct" to="portal_popup">
        <modal-window
          :modal_open="editDefaultsForProduct"
          title="Product Defaults"
          @close="editDefaultsForProduct = undefined"
        >
          <manage-product-defaults
            :customer-id="customer.customerId"
            :dataset-id="customer.datasetIds[0]"
            :product="editDefaultsForProduct"
            @close="editDefaultsForProduct = undefined"
          ></manage-product-defaults>
        </modal-window>
      </portal>
      <loading :loading="loading" class="min-h-full">
        <div class="flex flex-col xs:flex-row gap-2 items-center justify-between m-3 md:m-10 p-1 mb-0">
          <span class="flex-grow">
            Select which products are available for this customer, these will then be available on
            their sales and survey interface and website designer if applicable.
          </span>
          <button class="btn w-full xs:w-auto" @click="selectAll()">Select All</button>
        </div>
        <div class="flex flex-wrap m-3 md:m-10 mt-0">
          <div
            v-for="(product, index) in availableProducts"
            :key="index"
            class="p-1 w-full xs:w-1/3 md:w-1/4"
          >
            <div
              class="flex flex-col items-center p-3 xs:p-5 border border-gray-300 rounded-lg bg-white"
            >
              <api-img :src="'/api/image/product/' + product.productId" alt="" class="h-32" />
              <span class="mt-1">{{ product.productName }}</span>
              <div class="flex flex-col my-2">
                <label class="mb-1">
                  <input v-model="product.enabled" type="checkbox" :value="product.productId" />
                  Available to Order
                </label>
                <label :class="{ 'text-gray-500 cursor-not-allowed': !product.enabled }">
                  <input
                    v-model="product.enabledRetail"
                    :disabled="!product.enabled"
                    type="checkbox"
                    :value="product.productId"
                  />
                  Available in Branded Mode
                </label>
              </div>
              <button
                v-if="productHasDefaults(product)"
                class="mt-3 btn btn-action"
                @click="editDefaultsFor(product)"
              >
                Customer Defaults
              </button>
              <button v-else class="mt-3 btn cursor-not-allowed" disabled>Customer Defaults</button>
            </div>
          </div>
        </div>
      </loading>
    </div>
    <div v-if="!loading" class="text-right w-full p-3 bg-white border-t">
      <button class="btn-action btn-lg" @click="saveSettings">Save Changes</button>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import ManageProductDefaults from '@/components/business/customer/ManageProductDefaults.vue';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'manage-product-defaults': ManageProductDefaults,
    Menu,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
      availableProducts: [],
      editDefaultsForProduct: undefined,
      productsWithDefaults: [],
    };
  },
  watch: {
    availableProducts: {
      handler(products) {
        products.forEach((product) => {
          if (!product.enabled) {
            // eslint-disable-next-line no-param-reassign
            product.enabledRetail = false;
          }
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.$store
      .dispatch('touch/customerGetProductsWithDefaults', this.customer.datasetIds[0])
      .then((result) => {
        this.productsWithDefaults = result.data;
      });
    this.availableProducts = await this.$store.dispatch(
      'touch/loadProductsForCustomer',
      this.customer.customerId,
    );
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    productHasDefaults(product) {
      return this.productsWithDefaults.some(
        (defaultProduct) => defaultProduct.id === product.productId,
      );
    },
    editDefaultsFor(product) {
      this.editDefaultsForProduct = {
        product,
        default: this.productsWithDefaults.find(
          (defaultProduct) => defaultProduct.id === product.productId,
        ),
      };
    },
    selectAll() {
      this.availableProducts.forEach((product) => {
        // eslint-disable-next-line no-param-reassign
        product.enabled = true;
        // eslint-disable-next-line no-param-reassign
        product.enabledRetail = true;
      });
    },
    async saveSettings() {
      this.loading = true;

      if (!this.availableProducts.some(x => x.enabled)) {
        const errors = {
          Discounts: 'Must have one product enabled.',
        };
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(errors).join('</li><li>')}</ul>`,
        });
        this.loading = false
        return
      }
      
      await this.$store
        .dispatch('touch/updateProductsForCustomer', {
          customerId: this.customer.customerId,
          products: this.availableProducts,
        });

      this.loading = false;
    },
  },
};
</script>
