<script>
import BasicLine from '@/components/shared/priceLines/List/Lines/BasicLine.vue';

export default {
  mixins: [BasicLine],
  methods: {
    setupLayout() {
      this.subtitle = 'Percentage';
      this.icon = '%';
      this.position = 'suffix';
    },
  },
};
</script>
