<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Option Lead Times'],
        ]"
      />
    </template>
    <template #header_page_title> Option Lead Times </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search option headings..." />
    </portal>
    <Menu></Menu>
    <div class="flex flex-col flex-grow">
      <div class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full" style="flex: 1 1 0">
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white p-5 border border-gray overflow-y-auto">
            <span class="text-lg">Option Headings</span>
          </div>
          <loading v-if="isLoadingOptionHeadings" :loading="true" class="bg-white p-5 border border-gray h-full" style="flex: 1 1 0"></loading>
          <div v-else class="bg-white p-5 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
            <div class="flex flex-col gap-2">
              <div
                v-for="heading in filteredHeadings"
                :key="heading.uniqueValue"
                class="bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
                :class="{ 'bg-green-600 text-white': heading.uniqueValue === headingId }"
                @click="validateOptionItems(heading.uniqueValue)"
              >
                <span>{{ heading.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white p-5 border border-gray overflow-y-auto">
            <div class="flex items-center gap-2 px-2">
              <span class="text-lg col-span-2">Option Items</span>
              <span class="ml-auto">Lead Time (Days)</span>
            </div>
          </div>
          <loading
            v-if="isLoadingOptionHeadings || isLoadingOptionItems"
            :loading="true"
            class="bg-white p-5 border border-gray h-full"
            style="flex: 1 1 0"
          ></loading>
          <div v-else class="bg-white p-5 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
            <div class="flex flex-col gap-2">
              <div
                v-for="item in items"
                :key="item.id"
                class="bg-gray-200 rounded lg p-2 flex gap-2 items-center"
              >
                <span class="col-span-2">{{ item.name }}</span>
                <div class="ml-auto">
                  <input
                    v-model.number="item.leadTime"
                    type="number"
                    min="0"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 bg-white text-right p-3 border-t">
        <button
          class="btn-action btn-lg"
          :class="{
            'opacity-50 pointer-events-none bg-gray-500 border-gray-500':
              JSON.stringify(items) === JSON.stringify(originalItems),
          }"
          @click="UpdateOptionItems()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    SearchBox,
  },
  data() {
    return {
      headings: [],
      headingId: undefined,
      items: [],
      originalItems: [],
      itemId: undefined,
      loading: true,
      search: '',
      isLoadingOptionHeadings: false,
      isLoadingOptionItems: false
    };
  },
  computed: {
    filteredHeadings() {
      const search = this.$route.query.search || '';
      return this.headings.filter((x) => x.name.toLowerCase().startsWith(search.toLowerCase()));
    },
  },
  async created() {
    await this.getOptionHeadings();
    await this.getOptionItems(this.headings[0].uniqueValue);
    this.loading = false;
  },
  methods: {
    async getOptionHeadings() {
      this.isLoadingOptionHeadings = true
      this.headings = await window.touch.staffGetOptionHeadings();
      this.headings.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.isLoadingOptionHeadings = false
    },
    async validateOptionItems(id) {
      if (JSON.stringify(this.items) !== JSON.stringify(this.originalItems)) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes in this Option Heading.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.getOptionItems(id);
            }
          });
      } else {
        await this.getOptionItems(id);
      }
    },
    async getOptionItems(id) {
      try {
        this.headingId = id;
        this.isLoadingOptionItems = true
        this.items = await window.touch.staffGetOptionItems(id);
        this.items.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
        );
        this.originalItems = JSON.parse(JSON.stringify(this.items));
      } catch (error) {
        this.alertBox().fire({
          title: error,
          icon: 'error',
        });
        this.headingId = undefined;
        this.items = [];
      }
      this.isLoadingOptionItems = false
    },
    async UpdateOptionItems() {
      const changesItems = [];
      let html = '';
      for (let i = 0; i < this.items.length; i += 1) {
        if (
          !this.originalItems.some(
            (x) => x.id === this.items[i].id && x.leadTime === this.items[i].leadTime,
          )
        ) {
          html += `<div class="border border-t-0 border-black">${this.items[i].name}</div><div class="border-b border-r border-black">${this.items[i].leadTime}${this.items[i].leadTime === 1 ? ' Day' : ' Days'}</div>`;
          changesItems.push({
            OptionItemId: this.items[i].id,
            LeadTime: this.items[i].leadTime,
          });
        }
      }
      this.alertBox()
        .fire({
          title: 'Save Changes',
          html: `<div class="grid grid-cols-2 border-t border-black">${html}</div>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoadingOptionItems = true
            const datasetId = this.$store.state.user.customer.datasetIds[0];
            await window.touch.staffUpdateOptionItems(datasetId, changesItems);
            this.originalItems = JSON.parse(JSON.stringify(this.items));
            this.isLoadingOptionItems = false
          }
        });
    },
    isNotChanged(uniqueId, leadTime) {
      return this.originalItems.some((x) => x.uniqueId === uniqueId && x.leadTime === leadTime);
    },
  },
};
</script>
