export default {
  namespaced: true,
  state: {},
  actions: {
    async connectToInstagram(context, params) {
      return window.touch.connectToInstagram(params.fabricatorName);
    },
    async getSocialFeedsInstagram(context, params) {
      return window.touch.getInstagramFeed(params.fabricatorName);
    },
    async checkInstagramAuth(context, params) {
      return window.touch.checkInstagramAuth(params.fabricatorName);
    },
  },
};
