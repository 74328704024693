import Touch from './touch';

Touch.prototype.commonGetCurrencies = async function commonGetCurrencies() {
  return this.parseResponse(await this.authenticatedPost('common/GetCurrencies'), () => {
    throw new Error('Unable to download Currencies');
  });
};

Touch.prototype.commonGetDatasets = async function commonGetDatasets() {
  return this.parseResponse(await this.authenticatedPost('setup/GetDatasets'), () => {
    throw new Error('Unable to download Datasets');
  });
};

Touch.prototype.commonOrganisationDetails = async function commonOrganisationDetails() {
  const { data } = await this.post('common/OrganisationDetails');

  return data;
};

Touch.prototype.getTermsAndConditionsDocument = async function getTermsAndConditionsDocument() {
  const response = await this.authenticatedPost('common/ListDocuments', {
    Type: Number(1),
    MyDocuments: true,
  });
  return response.data.documents[0];
};

Touch.prototype.getPrivacyPolicyDocument = async function getPrivacyPolicyDocument() {
  const response = await this.authenticatedPost('common/ListDocuments', {
    Type: Number(2),
    MyDocuments: true,
  });
  return response.data.documents[0];
};

Touch.prototype.commonGetProductSelectionGroups = async function commonGetProductSelectionGroups() {
  return this.authenticatedPost('common/GetProductSelectionGroups');
};

Touch.prototype.commonGetProductSelectionTypes = async function commonGetProductSelectionTypes() {
  return this.authenticatedPost('common/GetProductSelectionTypes');
};

Touch.prototype.commonGetCustomerStatuses = async function commonGetCustomerStatuses() {
  const { data } = await this.authenticatedPost('common/GetCustomerStatuses');
  return data;
};

Touch.prototype.sendEnquiryToTouchStore = async function sendEnquiryToTouchStore(
  companyName,
  contactFirstname,
  contactSurname,
  contactPhone,
  contactEmail,
) {
  this.axios.post(`${process.env.VUE_APP_STORE_PREFIX}api/submit-enquiry`, {
    company_name: companyName,
    contact_firstname: contactFirstname,
    contact_surname: contactSurname,
    contact_phone: contactPhone,
    contact_email: contactEmail,
    customer_need: 'dealer_network',
  });
};
