var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:py-2"},[_c('div',{staticClass:"sm:hidden"},[_c('div',{staticClass:"border-solid border-t border-gray-300"}),_c('div',{staticClass:"p-2"},[_c('MenuButton',{attrs:{"icon":"fa-home","label":"Home","url":"/","active":'home'}})],1),_c('div',{staticClass:"border-solid border-t border-gray-300"})]),_c('div',{staticClass:"p-2"},[_c('MenuButton',{attrs:{"icon":"fa-users","label":"Customers","url":"/customer","active":'customer'}}),(_vm.is_admin_user)?_c('MenuButton',{attrs:{"icon":"fa-sharp fa-light fa-user-helmet-safety","label":"Staff","url":"/manage-touch-account/staff","active":'staff'}}):_vm._e(),(_vm.is_admin_user)?_c('MenuButton',{attrs:{"icon":"fa-sharp fa-light fa-sitemap","label":"Product Setup","url":"/product-setup","active":'product-setup'}}):_vm._e(),(_vm.is_admin_user && _vm.isBranchManagerAvailable)?_c('MenuButton',{key:"branch",attrs:{"icon":"fa-light fa-code-branch","data-index":5,"label":"Branch Management","url":"/branch","active":'branch'}}):_vm._e()],1),_c('div',{staticClass:"border-solid border-t border-gray-300 my-2"}),_c('div',{staticClass:"p-2"},[(!_vm.isBranchManager && _vm.isDealerNetworkModeAvailable)?_c('router-link',{attrs:{"to":"/dealer-network"}},[_c('div',{staticClass:"flex items-center hover:bg-gray-300",class:{ 'bg-gray-300': _vm.$route.meta.active === 'dealer-network' }},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"rounded-full h-5 w-5 border-solid border-2 border-yellow-500 mr-4"})]),_c('span',[_vm._v("View Enquiries")])])]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{attrs:{"to":{ name: 'All Orders', query: { ..._vm.$route.query, type: 'order', status: '1' } }}},[_c('div',{staticClass:"flex items-center hover:bg-gray-300",class:{
          'bg-gray-300':
            _vm.$route.meta.active === 'all-orders' &&
            _vm.$route.query.type === 'order' && _vm.$route.query.status === '1',
        }},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"rounded-full h-5 w-5 border-solid border-2 border-green-500 mr-4"})]),_c('span',[_vm._v("Pending Orders")])])]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{attrs:{"to":{ name: 'All Orders', query: { ..._vm.$route.query, type: 'order', status: '2' } }}},[_c('div',{staticClass:"flex items-center hover:bg-gray-300",class:{
          'bg-gray-300':
            _vm.$route.meta.active === 'all-orders' &&
            _vm.$route.query.type === 'order' && _vm.$route.query.status === '2',
        }},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"rounded-full h-5 w-5 border-solid border-2 border-orange-500 mr-4"})]),_c('span',[_vm._v("Placed Orders")])])]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{attrs:{"to":{ name: 'All Orders', query: { ..._vm.$route.query, type: 'order', status: '3' } }}},[_c('div',{staticClass:"flex items-center hover:bg-gray-300",class:{
          'bg-gray-300':
            _vm.$route.meta.active === 'all-orders' &&
            _vm.$route.query.type === 'order' && _vm.$route.query.status === '3',
        }},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"rounded-full h-5 w-5 border-solid border-2 border-red-500 mr-4"})]),_c('span',[_vm._v("Cancelled Orders")])])]):_vm._e()],1),_c('div',{staticClass:"border-solid border-t border-gray-300 my-2"}),_c('div',{staticClass:"p-2"},[(_vm.isRackManagerEnabled && !_vm.isBranchManager)?_c('MenuButton',{attrs:{"icon":"fa-regular fa-boxes-stacked","label":"Rack Manager","url":"/rack-manager/map","active":'rack-manager'}}):_vm._e(),(_vm.is_admin_user && _vm.isDealerNetworkModeAvailable && !_vm.isBranchManager && !_vm.isBnQ)?_c('MenuButton',{attrs:{"icon":"fa-light fa-chart-network","label":"Dealer Network","url":"/dealer-network-setup","active":'dealer-network-setup'}}):_vm._e(),_c('MenuButton',{attrs:{"icon":"fa-book-open","label":"Resource Centre","url":_vm.isLinkedToBranch ? '/marketing' : '/manage-marketing',"active":'marketing'}}),_c('MenuButton',{attrs:{"icon":"fa-megaphone","label":"News","url":_vm.isLinkedToBranch ? '/news' : '/manage-news',"active":'manage-news'}}),(!_vm.isLeadGen && !_vm.isBranchManager)?_c('MenuButton',{attrs:{"icon":"fa-chart-line","label":"KPI","url":"/kpi","active":'kpi'}}):_vm._e()],1),_c('div',{staticClass:"border-solid border-t border-gray-300 my-2"}),_c('div',{staticClass:"p-2"},[_c('MenuButton',{attrs:{"icon":"fa-square-question","label":"Knowledge Base","external_link":true,"url":"https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252273","active":''}})],1),(_vm.is_admin_user)?_c('router-link',{staticClass:"sm:hidden",attrs:{"to":"/manage-touch-account/company-details"}},[_c('div',{staticClass:"text-center p-3"},[_c('div',{staticClass:"btn-action"},[_vm._v("My Account")])])]):_vm._e(),_c('SupportPopUp',{attrs:{"company-name":_vm.$store.state.auth.companyName}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }