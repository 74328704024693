<template>
  <div>
    <div v-for="(spec, i) in specGroupDuplicateNames" :key="i" class="xl:flex xl:items-center mb-2">
      <div class="xl:w-1/3">
        <question-label
          v-if="spec.name"
          ref="label"
          :show-eyeball="!isOpen"
          @set-advanced-mode="$emit('input', $event)"
          @toggle-show-selection="$store.commit('designer/toggleShowSelectionForHeadingId', spec.optionHeadingId)"
        >
          {{ spec.name }}
        </question-label>
      </div>

      <div
        class="bg-gray-200 flex flex-col rounded w-full h-12 xl:w-2/3"
        @click="$emit('open', i)"
      >
        <div
          class="cursor-pointer hover-bg-brand-primary_light flex h-12 relative"
          :class="{ 'cursor-wait': loading }"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <div
            class="cursor-pointer p-2 flex flex-1 justify-around"
            :class="{ 'cursor-wait': loading }"
          >
            <span class="inline-block flex-1 flex flex-col justify-around text-xs">
              <div class="flex justify-between">
                {{ selectedOption(spec)?.description }}
                <span
                  v-if="showImageLegends"
                  class="rounded-full w-4 h-4 bg-black"
                  :style="imageLegendBackgroundColourStyle(i)"
                ></span>
              </div>
            </span>
          </div>

          <div class="flex flex-col justify-around p-2 mr-2">
            <i class="fa fa-caret-down" />
          </div>
        </div>
      </div>

      <div
        v-for="parameter in availableParams(spec)"
        :key="`${parameter.index}:${parameter.memberId}:${parameter.componentId}`"
      >
        <div class="xl:flex xl:items-center xl:mb-2">
          <div class="flex xl:w-1/3">
            <question-label :show-eyeball="false">
              <span>{{ parameter.text }}</span>
            </question-label>
          </div>
          <question-trigger
            class="xl:w-2/3"
            :loading="loading"
            :has-text="true"
            :text="parameter.value"
            :text-placeholder="parameter.text"
            @set-text="setParameterText(spec, parameter, $event)"
          >
          </question-trigger>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionTrigger from '@/components/shared/designer/SpecificationGroup/QuestionTrigger.vue';
import QuestionLabel from '@/components/shared/designer/SpecificationGroup/QuestionLabel.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    'question-label': QuestionLabel,
    'question-trigger': QuestionTrigger,
  },
  props: ['specs', 'loading', 'value', 'isOpen'],
  data() {
    return {
      selected: this.value,
      hover: false,
    };
  },
  computed: {
    ...mapGetters('designer', {
      somethingIsSelected: 'somethingIsSelected',
      interactiveHighlightState: 'interactiveHighlightState',
    }),
    showImageLegends() {
      return this.$store.state.designer.showSelectionForHeadingId === this.specs[0].optionHeadingId;
    },
    description() {
      return this.components_or_members
        .map((item) => `${item.description}: ${this.selectedComponentOption(item.id).description}`)
        .join(', ');
    },
    components_or_members() {
      return this.specs
        .map((spec) => spec.components.concat(spec.members))
        .flat(1)
        .sort((a, b) => a.description.localeCompare(b.description));
    },
    specGroupDuplicateNames () {
      let isSameName = true
      for (let i = 0; i < this.specs.length; i++) {
        if (this.specs[0].name !== this.specs[i].name) {
          isSameName = false
        }
      }
      return isSameName ? this.specs.map((x, index) => ({
        ...x,
        name: index === 0 ? x.name : undefined
      })) : this.specs
    }
  },
  watch: {
    value(newProp) {
      this.selected = newProp;
    },
    selected(newChoices) {
      this.$emit('input', newChoices);
    },
    specs(newSpecs) {
      if (newSpecs.length === 1) {
        this.$emit('end-advanced-mode');
      }
    },
  },
  methods: {
    setParameterText(spec, parameter, text) {
      this.$emit('update-parameter', {
        heading: spec.optionHeadingId,
        option: this.selectedOption(spec).id,
        parameter,
        value: text,
        spec,
      });
    },
    availableParams(spec) {
      if (this.somethingIsSelected) {
        return spec.parameters.filter((parameter) => {
          if (this.interactiveHighlightState.currentSelectionType === 'member') {
            return parameter.memberId === this.interactiveHighlightState.clickedMemberId;
          }

          return true;
        });
      }

      return spec.parameters;
    },
    imageLegendBackgroundColourStyle(i) {
      return {
        backgroundColor: this.$store.state.designer.backgroundColourList[i],
      };
    },
    selectedOption(spec) {
      return spec.options.find((option) => option.isCurrentOption);
    },
    selectedComponentOption(componentOrMemberId) {
      return this._.find(
        this._.head(
          this.specs.filter(
            (spec) =>
              this._.find(spec.components, (component) => component.id === componentOrMemberId) ||
              this._.find(spec.members, (member) => member.id === componentOrMemberId),
          ),
        ).options,
        (option) => option.isCurrentOption,
      );
    },
    imageHighlight(setOn, id) {
      if (setOn) {
        this.$emit('component-highlight', id);
      } else {
        this.$emit('component-highlight', null);
      }
    },
  },
};
</script>
