<template>
  <div class="h-screen sm:h-auto bg-gray-200 sm:bg-transparent overflow-hidden">
    <div class="text-brand-primary hidden sm:block">
      <div class="absolute top-0 right-0 -z-1 left-0">
        <img :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
      </div>
    </div>
    <header class="bg-white w-full sm:bg-transparent print:hidden">
      <div class="flex flex-grow">
        <logo
          class="border-0 bg-transparent"
          :src="style.fabricator_logo"
          :href="$t(store_url)"
        ></logo>
        <div class="flex flex-grow flex-col"></div>
        <div class="flex ml-auto">
          <div class="flex flex-col justify-center">
            <a
              :href="style.back_to_site_link"
              class="bg-white rounded-full hover:bg-gray-200 py-2 pr-6 pl-5 m-5 font-medium"
            >
              <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
            </a>
          </div>
        </div>
      </div>
    </header>

    <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
      <div class="mx-auto w-full flex flex-col sm:flex-row justify-center items-center" style="max-width: 1600px">
        <div class="hidden sm:flex flex-col justify-around text-center m-10 mr-0">
          <div class="flex flex-col justify-between font-medium">
            <div v-html="$t('Login-Main Title')" />
            <div class="text-lg font-normal mx-auto my-6" style="max-width: 500px">
              {{
                $t(
                  'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
                )
              }}
            </div>
            <div class="mx-auto">
              <img
                :src="$t('/images/login-image.png')"
                class="m-auto w-full"
                style="margin-left: -30px"
              />
            </div>
          </div>
        </div>
        <div class="login-form-wrapper">
          <transition name="fade-in" appear mode="out-in">
            <div key="login" class="h-full">
              <div class="text-center mb-4 bg-white py-4 sm:hidden">
                <div class="sm:text-xl">Continue to your dashboard</div>
              </div>

              <form class="login-form" @submit.prevent="login">
                <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                  <div class="text-4xl mb-2 font-medium">Welcome back!</div>
                  <div class="text-xl">Continue to your dashboard.</div>
                </div>

                <error v-if="login_error" :message="login_error" />

                <div class="mb-4">
                  <label for="username" class="block w-full">username</label>
                  <input
                    id="email"
                    v-model="email"
                    type="text"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="off"
                    autofocus
                  />
                </div>

                <div class="mb-4">
                  <label for="password" class="block w-full">Password</label>
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    autocomplete="off"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>

                <div class="mb-4">
                  <label for="not_important_please_do_not_autofill_google" class="block w-full">Customer Email</label>
                  <input
                    id="not_important_please_do_not_autofill_google"
                    v-model="customerEmail"
                    type="email"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>

                <button type="submit" class="btn-action btn-lg w-full">Login</button>

                <div class="text-center mt-10">
                  <a
                    :href="$t(store_url)"
                    target="_blank"
                    class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                  >
                    <div class="p-1">
                      <img src="../../assets/images/touch-logo.svg" width="75px" />
                    </div>
                    <div class="">
                      <template v-if="touch_portal_installation">
                        <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
                      </template>
                    </div>
                    <div class="p-1 text-xs">By BM Group</div>
                  </a>
                </div>
              </form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Logo from '@/components/shared/nav/Logo.vue';

export default {
  components: {
    Logo,
  },
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('reset', [
        'basket',
        'touch.isDealerNetworkPartnerOnly',
        'style',
        'feature',
        'designer.isQuickTip',
      ]); // eslint-disable-line
    });
  },
  data() {
    return {
      email: '',
      password: '',
      customerEmail: '',
      endpoint: process.env.VUE_APP_ENDPOINT_URL,
      imageBaseURL: process.env.VUE_APP_IMAGE_BASE_URL,
      login_error: false,
    };
  },
  watch: {
    devUser({ username, password }) {
      this.email = username;
      this.password = password;
    },
    devTouchServer({ endpointUrl, imageUrl }) {
      this.endpoint = endpointUrl;
      this.imageBaseURL = imageUrl;
    },
  },
  methods: {
    ...mapActions({
      setEndpoint: 'auth/setEndpoint',
      setImageBaseURL: 'auth/setImageBaseURL',
      attemptLogin: 'auth/attemptSuperUserLogin',
    }),
    login() {
      if (this.email !== '' && this.password !== '') {
        this.attemptLogin({
          username: this.email,
          password: this.password,
          email: this.customerEmail,
        }).then(async (loggedIn) => {
          if (loggedIn) {
            this.login_error = false;
            this.setSentryUser();
            this.routerPush(this.$router.currentRoute.query.redirect ?? '/');
            return;
          }
          this.login_error = 'Unable to login, Please check your username / password and try again';
        });
      } else {
        this.login_error = 'Email address and password required';
      }
    },
  },
};
</script>
