<template>
  <touch-layout page_override_class="bg-gray-200" :style="{ width: '100vw' }">
    <main class="bg-gray-200 w-full overflow-y-scroll">
      <Summary :is-bn-q="isBnQ" />
      <div class="md:flex gap-5 p-5 flex-no-wrap whitespace-nowrap">
        <div :style="{ flex: '2 1' }" class="flex flex-col">
          <Welcome
            :first-name="user.firstName"
            :company-name="user.companyName"
            :is-bn-q="isBnQ"
            @addCustomerPopUp="add_customer_box_open = true"
          />
          <Enquiries />
        </div>
        <div v-if="!isBnQ" :style="{ flex: '1 1' }" class="xs:flex mt-5 md:mt-0 md:flex-col gap-5 md:max-w-lg">
          <Support />
          <Hero />
        </div>
        <div v-else :style="{ flex: '1 1' }" class="xs:flex mt-5 md:mt-0 md:flex-col gap-5 md:max-w-lg">
          <News :company-name="user.companyName" :news-items="newsItems" class="w-full" />
        </div>
      </div>
      <portal v-if="add_customer_box_open" to="portal_popup">
        <modal-window
          :modal_open="add_customer_box_open"
          title="Add Customer"
          @close="add_customer_box_open = false"
        >
          <customer-create @close="add_customer_box_open = false"></customer-create>
        </modal-window>
      </portal>
    </main>
  </touch-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Summary from '@/components/business/home/Summary.vue';
import Welcome from '@/components/business/home/Welcome.vue';
import Enquiries from '@/components/business/home/Enquiries.vue';
import Support from '@/components/business/home/Support.vue';
import Hero from '@/components/business/home/Hero.vue';
import CustomerCreate from '@/components/shared/CustomerCreate.vue';
import News from '@/components/portal/home/News.vue';

export default {
  name: 'Home',
  components: {
    Summary,
    Welcome,
    CustomerCreate,
    Enquiries,
    Support,
    Hero,
    News
  },
  data() {
    return {
      active_news_slide: 'news',
      twitter_handle: '',
      quotes_and_enquiries_tab: 'enquiries',
      news_widget_hovered: false,
      news_interval: null,
      add_customer_box_open: false,
      add_staff_box_open: false,
      instagram_connected: false,
      feeds_available: ['news'],
      newsItems: []
    };
  },
  computed: {
    ...mapState('basket', {
      basket: 'basket',
    }),
    ...mapState('auth', {
      isDealerNetworkModeAvailable: 'isDealerNetworkModeAvailable',
      isBranchManager: 'isBranchManager',
    }),
    ...mapGetters({
      user: 'auth/user',
      fabricator_name: 'style/fabricator_name',
      basketQty: 'basket/basketQty',
      customer: 'user/getCustomer',
    }),
    show_news_link() {
      return this.feeds_available.includes('news');
    },
    isBnQ() {
      return this.$store.state.style.fabricator.name === 'B & Q'
    },
  },
  watch: {
    news_widget_hovered(hovered) {
      if (hovered) {
        clearInterval(this.news_interval);
      } else if (this.feeds_available.length > 1) {
        this.slideNews();
      }
    },
  },
  async mounted() {
    if (this.isBnQ) {
      const { news_items } = await this.latestNews({ limit: 3, offset: 0 });
      this.newsItems = news_items
    }
    
    this.user_img_url = window.touch.imageBaseURL + this.user.image;
    this.$store.state.infoPopup.activePopup = 'dashboard:intro_popup';

    if (window.location.hostname.split('.')[0] === 'demo') {
      await this.checkInstagramAuth({
        fabricatorName: this.$store.getters['style/fabricator_name'],
      })
        .then(() => {
          this.instagram_connected = true;
          this.feeds_available.push('instagram');
        })
        .catch(() => {
          this.instagram_connected = false;
        });
    }

    // eslint-disable-next-line prefer-destructuring
    this.active_news_slide = this.feeds_available[0];

    if (this.feeds_available.length > 1) {
      this.slideNews();
    }
  },
  methods: {
    ...mapActions({
      checkInstagramAuth: 'socialFeeds/checkInstagramAuth',
      getOrganisationWebSetting: 'auth/getOrganisationWebSetting',
      latestNews: 'news/latestNews',
    }),
    newStaffMemberCreated() {
      this.add_staff_box_open = false;
      this.alertBox().fire('New Staff User Created');
    },
    slideNews() {
      const vueThis = this;
      this.news_interval = setInterval(() => {
        const currentNewsIndex = vueThis.feeds_available.indexOf(vueThis.active_news_slide);
        if (currentNewsIndex + 1 === vueThis.feeds_available.length) {
          // eslint-disable-next-line prefer-destructuring
          vueThis.active_news_slide = vueThis.feeds_available[0];
        } else {
          vueThis.active_news_slide = vueThis.feeds_available[currentNewsIndex + 1];
        }
      }, 5000);
    },
  },
};
</script>
