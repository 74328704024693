export default {
  computed: {
    canUseProductMeasurements() {
      return (
        this.unit_of_measure !== window.enum.unitOfMeasure.VOLUME &&
        this.unit_of_measure !== window.enum.unitOfMeasure.LENGTH &&
        this.unit_of_measure !== window.enum.unitOfMeasure.FITTING_DAYS &&
        this.unit_of_measure !== window.enum.unitOfMeasure.UNIT &&
        this.unit_of_measure !== null
      );
    },
    canUseFittingCharge() {
      return this.unit_of_measure !== null;
    },
  },
};
