<template>
  <label class="flex items-center justify-between my-2">
    <span :class="{ 'font-bold': isHighlighted }" v-text="dimension.description"></span>
    <div
      v-if="pleaseWait"
      class="cursor-wait w-1/2 border border-solid border-gray-400 rounded p-2 text-xs appearance-none disabled pointer-events-none opacity-50"
    >
      Processing...
    </div>
    <input
      v-else
      ref="dimension"
      :placeholder="placeholder"
      class="w-1/2 border border-solid border-gray-400 rounded p-2 text-xs appearance-none"
      type="text"
      :value="initialValue"
      :title="title"
      @change="change(Number($event.target.value), $event.target.value)"
      @focus="focus"
      @blur="blur"
    />
  </label>
</template>

<script>
export default {
  props: ['dimension', 'dimensionHighlight', 'pleaseWait'],
  computed: {
    isHighlighted() {
      return this.dimensionHighlight === this.dimension.id;
    },
    placeholder() {
      let minMax = '';

      if (this.dimension.min > 0 && this.dimension.max > 0) {
        minMax = ` (${this.dimension.min}mm - ${this.dimension.max}mm)`;
      } else if (this.dimension.min > 0) {
        minMax = ` (at least ${this.dimension.min}mm)`;
      } else if (this.dimension.max > 0) {
        minMax = ` (up to ${this.dimension.max}mm)`;
      }

      return this.dimension.requiresInput
        ? `Please Specify${minMax}`
        : `Optionally override ${this.dimension.value} default, ${minMax}`;
    },

    initialValue() {
      if (!this.dimension.hasBeenInput) {
        return '';
      }

      return this.dimension.value;
    },
    title() {
      if (this.dimension.min > 0 && this.dimension.max > 0) {
        return `${this.dimension.description} (${this.dimension.min}mm - ${this.dimension.max}mm)`;
      }

      if (this.dimension.min > 0) {
        return `${this.dimension.description} (at least ${this.dimension.min}mm)`;
      }

      if (this.dimension.max > 0) {
        return `${this.dimension.description} (up to ${this.dimension.max}mm)`;
      }

      return this.dimension.description;
    },
  },
  methods: {
    focus($event) {
      this.$emit('dimension-highlight', this.dimension.id);
      $event.target.select();
    },
    blur() {
      this.$emit('dimension-highlight', null);
    },
    change(value, input) {
      if (Number.isNaN(value)) {
        this.alertBox()
          .fire({
            title: `${input} not recognised as a number`,
            icon: 'error',
            html: 'Please try again.',
          })
          .then(() => {
            this.$refs.dimension.value = this.dimension.value;
            this.$refs.dimension.select();
          });
        return;
      }

      if (this.dimension.min > 0) {
        if (value < this.dimension.min) {
          this.alertBox()
            .fire({
              title: `${this.dimension.description} minimum is ${this.dimension.min}mm`,
              icon: 'error',
              html: `${value}mm is ${this.dimension.min - value}mm below the minimum`,
            })
            .then(() => {
              this.$refs.dimension.value = this.dimension.value;
              this.$refs.dimension.select();
            });
          return;
        }
      }

      if (this.dimension.max > 0) {
        if (value > this.dimension.max) {
          this.alertBox()
            .fire({
              title: `${this.dimension.description} maximum is ${this.dimension.max}mm`,
              icon: 'error',
              html: `${value}mm is ${value - this.dimension.max}mm above the maximum`,
            })
            .then(() => {
              this.$refs.dimension.value = this.dimension.value;
              this.$refs.dimension.select();
            });
          return;
        }
      }

      this.$emit('change-dimension', value);
    },
  },
};
</script>
