<template>
  <div>
    <div class="overflow-x-auto bg-white xs:hidden -mb-3 pb-3">
      <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
        <div
          v-if="showGroupLink"
          :class="selectedGroup ? 'text-gray-600' : 'font-medium'"
          class="px-1 xs:px-3 py-3 flex items-center"
          @click="$emit('choose-group')"
        >
          <span v-text="selectedGroup ? selectedGroup : 'Choose Group'"></span>
        </div>
        <div
          v-if="showTypeLink && selectedGroup"
          :class="selectedType ? 'text-gray-600' : 'font-medium'"
          class="px-1 xs:px-3 py-3 flex items-center"
          @click="$emit('choose-type')"
        >
          <i v-if="showGroupLink" class="fal fa-chevron-right mr-1 xs:mr-3"></i>
          <span v-text="selectedType ? selectedType : 'Choose Type'"></span>
        </div>
        <div
          v-if="selectedType"
          :class="selectedProduct ? 'text-gray-600' : 'font-medium'"
          class="px-1 xs:px-3 py-3 flex items-center"
          @click="$emit('choose-product')"
        >
          <i v-if="showTypeLink || showGroupLink" class="fal fa-chevron-right mr-1 xs:mr-3"></i>
          <span v-text="selectedProduct ? selectedProduct : 'Choose Product'"></span>
        </div>
        <div v-if="selectedProduct" class="font-medium px-1 xs:px-3 py-3 flex items-center">
          <i class="fal fa-chevron-right mr-1 xs:mr-3"></i>
          <span>Choose Style</span>
        </div>
      </div>
    </div>
    <div class="hidden xs:flex justify-center w-full bg-gray-200 lg:p-3">
      <div
        v-if="showGroupLink"
        :class="!selectedGroup ? 'border-green-600' : 'border-gray-400 cursor-pointer'"
        class="flex border-b-2 text-center my-2 mx-4 pb-2"
        @click="$emit('choose-group')"
      >
        <div class="flex flex-col justify-around">
          <div
            class="border-2 border-green-600 rounded-full w-6 h-6 lg:w-8 lg:h-8 flex flex-col justify-around flex-shrink-0"
          >
            1
          </div>
        </div>
        <div
          class="lg:py-2 pl-6 pr-8 flex flex-col justify-around text-center text-2xs sm:text-xs lg:text-base flex-grow tracking-widest uppercase"
          v-text="selectedGroup ? selectedGroup : 'Choose Group'"
        ></div>
      </div>

      <template v-if="selectedGroup">
        <div
          v-if="showTypeLink"
          :class="!selectedType ? 'border-green-600' : 'border-gray-400 cursor-pointer'"
          class="flex border-b-2 text-center my-2 mx-4 pb-2"
          @click="$emit('choose-type')"
        >
          <div class="flex flex-col justify-around">
            <div
              class="border-2 border-green-600 rounded-full w-6 h-6 lg:w-8 lg:h-8 flex flex-col justify-around flex-shrink-0"
            >
              {{ typeStepNumber }}
            </div>
          </div>
          <div
            class="lg:py-2 pl-6 pr-8 flex flex-col justify-around text-center text-2xs sm:text-xs lg:text-base flex-grow tracking-widest uppercase"
            v-text="selectedType ? selectedType : 'Choose Type'"
          ></div>
        </div>

        <div
          v-if="selectedType"
          :class="!selectedProduct ? 'border-green-600' : 'border-gray-400 cursor-pointer'"
          class="flex border-b-2 text-center my-2 mx-4 pb-2"
          @click="$emit('choose-product')"
        >
          <div class="flex flex-col justify-around">
            <div
              class="border-2 border-green-600 rounded-full w-6 h-6 lg:w-8 lg:h-8 flex flex-col justify-around flex-shrink-0"
            >
              {{ productStepNumber }}
            </div>
          </div>
          <div
            class="lg:py-2 pl-6 pr-8 flex flex-col justify-around text-center text-2xs sm:text-xs lg:text-base flex-grow tracking-widest uppercase"
            v-text="selectedProduct ? selectedProduct : 'Choose Product'"
          ></div>
        </div>

        <div
          v-if="selectedProduct"
          class="border-green-600 flex border-b-2 text-center my-2 mx-4 pb-2"
        >
          <div class="flex flex-col justify-around">
            <div
              class="border-2 border-green-600 rounded-full w-6 h-6 lg:w-8 lg:h-8 flex flex-col justify-around flex-shrink-0"
            >
              {{ styleStepNumber }}
            </div>
          </div>
          <div
            class="lg:py-2 pl-6 pr-8 flex flex-col justify-around text-center text-2xs sm:text-xs lg:text-base flex-grow tracking-widest uppercase"
          >
            Choose Style
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectedGroup', 'selectedType', 'selectedProduct', 'groupCount', 'typeCount'],
  computed: {
    showGroupLink() {
      return this.groupCount > 1;
    },
    showTypeLink() {
      return this.typeCount > 1;
    },
    typeStepNumber() {
      return this.showGroupLink ? 2 : 1;
    },
    productStepNumber() {
      let step = 1;

      if (this.showTypeLink) {
        step += 1;
      }
      if (this.showGroupLink) {
        step += 1;
      }

      return step;
    },
    styleStepNumber() {
      return this.productStepNumber + 1;
    },
  },
};
</script>
