// eslint-disable-next-line camelcase
window.svg_Dimension_Input_Input = function svg_Dimension_Input_Input(e) {
  const clickEvent = new CustomEvent('input-input', {
    bubbles: true,
    detail: {
      dimensionId: e.target.getAttribute('dimensionid'),
      element: e.target,
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_Dimension_Input_Click = function svg_Dimension_Input_Click(e) {
  const clickEvent = new CustomEvent('input-click', {
    bubbles: true,
    detail: {
      dimensionId: e.target.getAttribute('dimensionid'),
      element: e.target,
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_Dimension_Input_KeyPress = function svg_Dimension_Input_KeyPress(e) {
  const clickEvent = new CustomEvent('input-key-press', {
    bubbles: true,
    detail: {
      dimensionId: e.target.getAttribute('dimensionid'),
      element: e.target,
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_Dimension_Input_Focus = function svg_Dimension_Input_Focus(e) {
  const clickEvent = new CustomEvent('input-focus', {
    bubbles: true,
    detail: {
      dimensionId: e.target.getAttribute('dimensionid'),
      element: e.target,
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_Dimension_Input_Blur = function svg_Dimension_Input_Blur(e) {
  const clickEvent = new CustomEvent('input-blur', {
    bubbles: true,
    detail: {
      dimensionId: e.target.getAttribute('dimensionid'),
      element: e.target,
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_interaction_onclick = function svg_interaction_onclick(e) {
  const clickEvent = new CustomEvent('diagram-clicked', {
    bubbles: true,
    detail: {
      active: String(e.target.getAttribute('interactionactive')).toUpperCase() === 'Y',
      type: e.target.getAttribute('interactiontype'),
      componentId: e.target.getAttribute('componentid'),
      memberId: e.target.getAttribute('memberid'),
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// // eslint-disable-next-line camelcase
// window.svg_onclick = function svg_onclick() {
// };

// eslint-disable-next-line camelcase
window.svg_Design_Selector_Click = function svg_Design_Selector_Click(e) {
  const clickEvent = new CustomEvent('design-selector-clicked', {
    bubbles: true,
    detail: {
      componentId: e.target.getAttribute('componentid'),
    },
  });

  e.target.dispatchEvent(clickEvent);
};

// eslint-disable-next-line camelcase
window.svg_Selector_Click = function svg_Selector_Click(e) {
  const clickEvent = new CustomEvent('selector-clicked', {
    bubbles: true,
    detail: {
      componentId: e.target.getAttribute('componentid'),
      memberId: e.target.getAttribute('memberid'),
    },
  });

  e.target.dispatchEvent(clickEvent);
};
