<template>
  <transition-group
    appear
    name="staggered-slide"
    tag="div"
    start-delay="startDelay"
    :css="false"
    @before-enter="staggeredSlideBeforeEnter"
    @enter="staggeredSlideEnter"
    @leave="staggeredSlideLeave"
  >
    <slot />
  </transition-group>
</template>

<script>
import animationHelper from '@/mixins/animationHelper';

export default {
  mixins: [animationHelper],
  props: {
    startDelay: {
      default: 0,
      type: Number,
    },
  },
};
</script>
