<template>
  <div class="flex flex-wrap relative">
    <div v-if="consumer" class="xs:flex flex-grow">
      <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
        <div class="font-bold">Customer Name</div>
        {{ name }}
      </div>
      <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
        <div class="font-bold">Email</div>
        <span v-if="dataIsRedacted">&lt;Accept to view&gt;</span>
        <a v-else :href="'mailto:' + consumer.email">{{ consumer.email }}</a>
      </div>
      <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
        <div class="font-bold">Tel</div>
        <span v-if="dataIsRedacted">&lt;Accept to view&gt;</span>
        <a
          v-if="!dataIsRedacted && consumer.mobile"
          :href="`tel:${consumer.mobile}`"
          class="pb-3 xs:pb-0"
        >{{ consumer.mobile }}</a>
      </div>
    </div>
    <div v-if="!consumer" class="flex-grow">
      <span class="font-bold"> No Consumer </span>
      <span v-if="!readOnly" class="cursor-pointer" @click="$emit('edit')">
        - Click to add details
      </span>
    </div>
    <div>
      <info-popup
        :id="'enquiry:customer_details'"
        :info_message="'This shows the customers details that they supplied when making their enquiry via the website designer.'"
        :next_id="'enquiry:price'"
      ></info-popup>
      <div
        v-if="!demo_mode && !readOnly"
        class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600 inline-block"
        @click="$emit('edit')"
      >
        <i class="fa fa-pencil"></i>
      </div>
    </div>
    <div v-if="consumer" class="w-full mt-3">
      <div class="font-bold">Address</div>
      <div v-html="address"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['consumer', 'readOnly', 'redacted'],
  computed: {
    dataIsRedacted() {
      return this.redacted === true;
    },
    name() {
      return [this.consumer.firstName, this.consumer.lastName].join(' ');
    },
    address() {
      const address = [
        this._.escape(this.consumer.addressLine1),
        this._.escape(this.consumer.addressLine2),
        this._.escape(this.consumer.addressLine3),
        this._.escape(this.consumer.addressTown),
        this._.escape(this.consumer.addressCounty),
        this._.escape(this.consumer.addressCode),
        this._.escape(this.consumer.addressIso),
      ]
        .filter(Boolean)
        .join(', ');

      if (!this.consumer.addressCode) {
        return address;
      }

      return `<a target="_blank" title="click to find directions" href="https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}">${address} <i class="fal fa-map-marker"></i> </a>`;
    },
  },
};
</script>
