<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [orderStatusLabel + ' Orders', '/all-orders?type=' + $route.query.type + '&status=' + $route.query.status],
        ]"
      />
    </template>
    <template #header_page_title> {{ orderStatusLabel }} Orders </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for reference..." />
    </portal>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center gap-3">
        <div v-if="branches.length > 0">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="branches"
            :default-required="true"
            background-colour="bg-blue-400"
          />
        </div>
        <div v-else-if="isBranchManagerAvailable">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="allBranches"
            background-colour="bg-blue-400"
          />
        </div>
      </div>
    </template>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <sales-dashboard-table :key="$route.query.type + $route.query.status" :type="$route.query.type" :status="$route.query.status">
        <template #show-imports>
          <th class="text-red-700">Imported</th>
        </template>
        <template #show-contract-imported="{ contract_data }">
          <td class="flex items-center">
            <div class="td-title w-24 text-red-700">Imported</div>
            <i v-if="isContractImported(contract_data)" class="fa fa-check block text-red-700"></i>
            <i v-else class="fa fa-light fa-times block text-black"></i>
          </td>
        </template>
      </sales-dashboard-table>
    </div>
  </touch-layout>
</template>

<script>
import SalesDashboardTable from '@/components/shared/SalesDashboardTable.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  components: {
    SalesDashboardTable,
    Breadcrumbs,
    SearchBox,
    FilterDropdown
  },
  data() {
    return {
      tab: undefined,
      allBranches: [],
      options: {
        limit: 20,
        offset: 0,
      },
    };
  },
  computed: {
    orderStatusLabel() {
      return this.enums.orderStatus.PENDING == this.$route.query.status
        ? 'Pending'
        : this.enums.orderStatus.PLACED == this.$route.query.status
          ? 'Placed'
          : 'Cancelled'
    },
    branches() {
      return this.$store.state.auth.branches.map(x => ({
        id: x.branchCompanyId,
        name: x.name,
        isPrimary: x.isPrimary
      }))
    },
    ...mapState('auth', {
      isBranchManagerAvailable: 'isBranchManagerAvailable',
    }),
  },
  async mounted() {
    if (!this.$route.query.type || (!this.$route.query.type && !this.$route.query.status)) {
      this.$router.replace({ query: { ...this.$route.query, type: 'order', status: this.enums.orderStatus.PENDING } }).catch(() => { })
    }

    const response = await window.touch.customerListBranches(this.options.limit, this.options.offset)
    this.allBranches = response.branches.map(x => ({
      id: x.id,
      name: x.name
    }))
  },
  methods: {
    isContractImported(contract) {
      return contract?.jobs[0]?.jobImportState === this.enums.ContractImportStateEnum.IMPORTED;
    },
  },
};
</script>
