<template>
  <div class="">
    <div class="2xs:flex relative">
      <vue-dropzone
        id="dropzone"
        ref="fileUploadDropzone"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        class="p-0 bg-gray-200 flex flex-col justify-center mb-3 2xs:mb-0 dz-dont-show-progress"
        @vdropzone-file-added="fileAddedToDropZone"
        @vdropzone-removed-file="fileRemovedFromDropZone"
      >
        <div
          class="text-center block border border-gray-200 p-3 flex flex-col justify-center h-full text-black bg-gray-200 cursor-pointer"
        >
          <div class="mb-1">Drag and drop image file</div>
          <div class="mb-1">or</div>
          <div>
            <a class="btn-action w-32"> Select Image </a>
          </div>
        </div>
      </vue-dropzone>
      <div class="flex-grow 2xs:ml-3 2xs:pl-3 2xs:border-l 2xs:border-gray-200">
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="xs:w-32" for="name"> Title </label>
          <input id="name" v-model="title" type="text" class="form-control" />
        </div>
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="w-32" for="description"> Description </label>
          <textarea id="description" v-model="description" rows="5" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button class="btn-action btn-lg" @click.prevent.stop="saveOption()">Add Group</button>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import imageHelper from '@/mixins/imageHelper';
import vue2Dropzone from 'vue2-dropzone';
import currencyHelper from '@/mixins/currencyHelper';
import extrasHelper from '@/mixins/extrasHelper';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [imageHelper, currencyHelper, extrasHelper],
  props: ['categories'],
  data() {
    return {
      title: '',
      description: null,
      image_data: null,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
    };
  },
  methods: {
    inputIsValid() {
      return validate(
        {
          title: this.title,
          description: this.description,
        },
        {
          title: {
            presence: { allowEmpty: false },
          },
          description: {
            presence: { allowEmpty: false },
          },
        },
      );
    },
    saveOption() {
      const validationErrors = this.inputIsValid();

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      this.$emit('new-product-group', {
        title: this.title,
        description: this.description,
        ImageBlob: this.image_data,
      });

      this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
      this.image_data = null;
      this.title = '';
      this.description = null;
    },
    async fileAddedToDropZone(uploadedFile) {
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      this.image_data = await this.imageToBase64WithPrefix(uploadedFile);
    },
    fileRemovedFromDropZone() {
      this.image_data = null;
    },
  },
};
</script>

<style scoped></style>
