<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Add Category" @close="$emit('close')">
      <div class="font-medium">Category Name</div>
      <div>
        <input v-model="new_category.name" class="form-control" />
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg w-48" @click.prevent.stop="save()">Save Category</button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      new_category: { name: '' },
    };
  },
  methods: {
    ...mapActions({
      addDocumentCategory: 'marketing/addDocumentCategory',
    }),
    async save() {
      await this.addDocumentCategory(this.new_category.name);
      this.new_category = { name: '' };
      this.$emit('close');
      this.$emit('category-added');
    },
  },
};
</script>
