import Touch from './touch';

Touch.prototype.listCarriers = async function listCarriers() {
  return (await this.authenticatedPost('carrier/List')).data;
};

Touch.prototype.createCarrier = async function createCarrier(name, web, email, phone) {
  await this.authenticatedPost('carrier/Create', {
    Name: name,
    Web: web,
    Email: email,
    Phone: phone,
  });
};

Touch.prototype.updateCarrier = async function updateCarrier(id, name, web, email, phone) {
  await this.authenticatedPost('carrier/Update', {
    Id: id,
    Name: name,
    Web: web,
    Email: email,
    Phone: phone,
  });
};
