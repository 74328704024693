<template>
  <div class="flex justify-between my-3 xl:flex-col">
    <div class="flex items-center">
      <div
        v-if="showEyeball"
        class="mr-3 cursor-pointer"
        @click.prevent="$emit('toggle-show-selection')"
      >
        <i class="fal fa-eye"></i>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['showEyeball'],
};
</script>
