<template>
  <div class="bg-gray-200 rounded-lg mb-3">
    <h1 class="p-3 flex justify-between" @click="$emit('open-price-block')">
      <slot name="title"></slot>
      <div v-if="open">
        <i class="fa fa-caret-up"></i>
      </div>
      <div v-if="!open">
        <i class="fa fa-caret-down"></i>
      </div>
    </h1>
    <div v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['open', 'filter'],
  watch: {
    open: {
      handler(isOpen) {
        if (isOpen) {
          this.$emit('set-filter', this.filter);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
