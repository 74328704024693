<template>
  <li
    class="cursor-pointer p-1"
    :class="{ selected: isSelected }"
    @click="$emit('set-filter', filter)"
  >
    <span class="border-b border-purple-700">
      <slot></slot>
    </span>
  </li>
</template>

<script>
export default {
  props: ['filter'],
  computed: {
    isSelected() {
      const search = this.$route.query.search || '';
      return this.filter === search;
    },
  },
};
</script>

<style scoped>
li.selected::before {
  overflow: visible;
  content: "";
  width: 0;
  height: 0;
  left: -1.1em;
  top: -0.375em;
  position: absolute;
  border-top: 1.2rem solid transparent;
  border-bottom: 1.2rem solid transparent;
  border-right: 1.2rem solid #6f4a97;
}

li.selected {
  overflow: visible;
  margin-left: -1.2rem;
  list-style-position: inside;
  position: relative;
  background-color: #6f4a97;
}

li.selected span {
  color: white;
}
li.selected::marker {
  color: white;
}
</style>
