<template>
  <portal v-if="modal_open" to="portal_popup">
    <modal-window
      :modal_open="true"
      :title="'Edit ' + price.groupText + ' // ' + price.description"
      @close="$emit('close')"
    >
      <table class="table-pricing mb-6">
        <price-line
          v-for="override in overrides.summaries"
          :key="override.customPriceData.id + override.customerId + Math.random()"
          :customer-id="override.customerId"
          :dataset-id="datasetId"
          :default-currency="defaultCurrency"
          :price="override.customPriceData"
          :hide-customer-override-button="true"
          :list-value="override.customPriceData.listValue"
          :is-price-selectable="false"
          @price-updated="priceUpdated(override.customPriceData, $event)"
        ></price-line>
      </table>
      <div class="text-right w-full p-3 bg-white border-t">
        <button class="btn-action btn-lg" @click="$emit('save-changes', modifiedPrices)">
          Save Changes
        </button>
      </div>
    </modal-window>
  </portal>
</template>
<script>
import PriceLine from '@/components/shared/PriceLine.vue';

export default {
  components: {
    PriceLine,
  },
  props: {
    price: {
      type: Object,
      required: true,
    },
    datasetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      overrides: [],
      defaultCurrency: undefined,
      modifiedPrices: [],
      modal_open: false,
    };
  },
  async mounted() {
    this.defaultCurrency = (await this.$store.dispatch('touch/commonGetCurrencies')).filter(
      (currency) => currency.isDefault,
    )[0].code;
    this.overrides = await window.touch.staffGetFinancialOverrides(
      this.datasetId,
      this.price.id,
      9999,
      0,
    );
    this.overrides.summaries.forEach((x) => {
      x.customPriceData = JSON.parse(JSON.stringify(this.price));
      x.customPriceData.description = x.name;
      x.customPriceData.overrideValue = x.financialItems[0].value;
      x.customPriceData.valueIsNett = x.financialItems[0].valueIsNett;
    });
    this.modal_open = true;
  },
  methods: {
    priceUpdated(data, object) {
      const existingEntry = this.modifiedPrices.find((x) => x.customerName === data.description);
      if (typeof existingEntry !== 'undefined') {
        existingEntry.newValue = object.overrideValue;
        existingEntry.valueIsNett = object.valueIsNett;
      } else {
        this.modifiedPrices.push({
          customerId: this.overrides.summaries.find((x) => data.description === x.name).customerId,
          customerName: data.description,
          newValue: object.overrideValue,
          valueIsNett: object.valueIsNett,
          id: data.id,
        });
      }
      const index = this.overrides.summaries.findIndex((x) => data.description === x.name)
      this.$set(this.overrides.summaries, index, {
        ...this.overrides.summaries[index],
        customPriceData: {
          ...this.overrides.summaries[index].customPriceData,
          valueIsNett: object.valueIsNett,
          overrideValue: object.overrideValue
        }
      })
    },
  },
};
</script>
