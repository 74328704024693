var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"flex bg-white px-5 border-t-4 border-white whitespace-no-wrap"},[_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.$route.query.active_section === 'images-and-text',
        'border-white': _vm.$route.query.active_section !== 'images-and-text',
      },attrs:{"to":{ query: { active_section: 'images-and-text' } }}},[_vm._v(" Images & Text ")]),_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.$route.query.active_section === 'emails',
        'border-white': _vm.$route.query.active_section !== 'emails',
      },attrs:{"to":{ query: { active_section: 'emails' } }}},[_vm._v(" Emails ")]),_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.$route.query.active_section === 'paperwork',
        'border-white': _vm.$route.query.active_section !== 'paperwork',
      },attrs:{"to":{ query: { active_section: 'paperwork' } }}},[_vm._v(" Paperwork ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }