import TouchJob from '../classes/TouchJob';

export default {
  methods: {
    jobPriceSubtotal(job) {
      return job.priceBeforeTax;
    },
    jobPriceTotal(job) {
      return job.priceIncludingTax;
    },
    asTouchJob(job) {
      return new TouchJob(job);
    },
  },
  computed: {
    touchJob() {
      return this.asTouchJob(this.job);
    },
  },
};
