import Touch from './touch';

Touch.prototype.visualisationListImages = async function visualisationListImages(contactId) {
  return this.authenticatedPost('visualisation/ListImages', {
    OnlyForThisContactId: contactId,
  });
};

Touch.prototype.visualisationUpdateImage = async function visualisationUpdateImage(
  id,
  reference,
  view,
) {
  return this.authenticatedPost('visualisation/UpdateImage', {
    Id: id,
    Reference: reference,
    InternalView: view,
  });
};

Touch.prototype.visualisationDeleteImage = async function visualisationDeleteImage(id) {
  return this.authenticatedPost('visualisation/DeleteImage', {
    Id: id,
  });
};
