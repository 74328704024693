<template>
  <div class="flex flex-col xs:flex-row overflow-hidden flex-shrink-0 flex-grow h-px">
    <aside
      class="bg-white shadow-2xl w-full xs:w-1/2 sm:w-1/3 flex flex-col relative xs:p-4 h-full"
    >
      <loading
        :loading="loading"
        class="overflow-y-auto xs:flex-shrink-0 xs:flex-grow xs:h-px p-6 pt-3 scrolling-touch"
      >
        <animation-staggered-fade class="flex flex-wrap flex-col text-sm">
          <div
            key="choose_product_start"
            class="hidden xs:flex justify-between flex-wrap p-3"
            data-index="0"
          >
            <div class="hidden sm:flex flex-col justify-around text-base pb-3 xl:p-0">
              Choose a product
            </div>
          </div>
          <template v-if="filteredProducts.length > 0">
            <button
              v-for="(product, index) in filteredProducts"
              :key="product.productId"
              class="hidden xs:block bg-gray-200 rounded-lg p-5 m-2 flex-1 cursor-pointer hover:shadow-hover hover:-translate-x-px hover-bg-brand-primary_light transition duration-500 ease-in-out"
              style="min-width: 100px"
              :data-index="index"
              @click="selected_product = product"
            >
              <div class="flex h-6">
                <span v-text="product.name"></span>
                <div
                  v-if="selected_product.productId == product.productId"
                  class="flex flex-col justify-around rounded-full bg-brand-primary w-6 text-center text-white ml-auto"
                >
                  <i class="fal fa-check"></i>
                </div>
              </div>
            </button>
          </template>
          <div v-else key="none-found">
            <div v-if="product_search" class="flex justify-between p-3">
              <div class="flex flex-col justify-around">
                No Products match "{{ product_search }}"
              </div>
              <div>
                <a class="btn-action" @click.stop="product_search = ''"> Clear Search </a>
              </div>
            </div>
            <div v-else>No Products to show</div>
          </div>
        </animation-staggered-fade>

        <!--this is the mobile version of the menu - would prefer to find a way to merge them-->
        <div key="choose_product_wrapper" class="flex flex-col text-sm xs:hidden" data-index="1">
          <div class="px-5 m-2 w-full">Choose a Product</div>
          <div
            class="bg-gray-200 rounded-lg p-5 m-2 w-full flex justify-between"
            @click.prevent="toggleChooseProductDropdownOpen()"
          >
            <div v-if="selected_product" class="flex flex-col justify-around">
              {{ selected_product.name }}
            </div>
            <div class="flex flex-col justify-around">
              <i class="fa fa-caret-down"></i>
            </div>
          </div>
          <div v-show="choose_product_dropdown_open" class="sm:hidden">
            <button
              v-for="(product, index) in filteredProducts"
              :key="product.productId"
              class="w-full bg-gray-200 rounded-lg p-5 m-2 flex-1 cursor-pointer hover:shadow-hover hover:-translate-x-px hover-bg-brand-primary_light transition duration-500 ease-in-out"
              style="min-width: 100px"
              :data-index="index"
              @click="(selected_product = product), (choose_product_dropdown_open = false)"
            >
              <div class="flex h-6">
                <span v-text="product.name"></span>
                <div
                  v-if="selected_product.productId == product.productId"
                  class="flex flex-col justify-around rounded-full bg-brand-primary w-6 text-center text-white ml-auto"
                >
                  <i class="fal fa-check"></i>
                </div>
              </div>
            </button>
          </div>
        </div>
      </loading>

      <div class="mt-4 hidden xs:block">
        <div class="text-center">
          <a
            :href="$t(store_url)"
            target="_blank"
            class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
          >
            <div class="p-1">
              <img src="/images/touch-logo.svg" width="50px" />
            </div>
            <div class="">
              <img src="/images/touch-portal-icon.png" style="max-height: 22px" />
            </div>
            <div class="p-1 text-2xs">By BM Group</div>
          </a>
        </div>
      </div>
    </aside>
    <loading
      :loading="loading || !selected_product"
      class="bg-gray-200 w-full xs:w-1/2 sm:w-2/3 overflow-y-scroll scrolling-touch p-6"
    >
      <div class="flex justify-between p-3">
        <div v-if="selected_product" class="hidden sm:flex flex-col justify-around text-base">
          Select a {{ selected_product.name }} design
        </div>
        <div class="flex">
          <div class="flex flex-col justify-around">
            <div class="bg-white rounded-full text-xs flex border">
              <div
                class="bg-brand-primary rounded-full p-2 border-white"
                style="width: 32px; height: 32px"
              >
                <i class="fal fa-exchange text-white fa-fw"></i>
              </div>
              <div class="flex flex-col justify-around uppercase relative">
                <select
                  v-model="qty_of_squares_filter"
                  class="bg-transparent outline-none pl-2 pr-6 h-full appearance-none"
                  style="width: 100px"
                >
                  <option
                    value=""
                    selected
                    v-text="qty_of_squares_filter ? 'Clear Filter' : 'Filter'"
                  ></option>
                  <option
                    v-for="(squaresQty, index) in quantityOfSquaresFilterList"
                    :key="index"
                    :value="squaresQty"
                  >
                    {{ squaresQty }} Square<template v-if="squaresQty > 1">s</template>
                  </option>
                </select>
                <div
                  class="p-3 h-full absolute top-0 right-0 flex flex-col justify-around pointer-events-none"
                >
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <animation-staggered-fade v-if="filtered_product_designs.length > 0" class="flex flex-wrap">
        <button
          v-for="(product_design, index) in filtered_product_designs"
          :key="product_design.designId"
          class="p-5 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 hover-zoom cursor-pointer"
          :data-index="index"
          @click.prevent="selectDesign(product_design.designId)"
        >
          <div class="flex flex-col bg-white rounded-lg p-5 shadow-lg">
            <img v-if="product_design.imageUrl" class="flex-1 h-64 mb-6" :src="product_design.imageUrl" />
            <span v-else class="flex-1 h-64 mb-6" v-html="product_design.imageSvg"></span>
            <span class="text-center" v-text="product_design.name"></span>
            <span class="text-sm text-center">
              <span v-if="product_design.quantityOfFacets !== 0">
                {{ product_design.quantityOfFacets }} facets,
              </span>
              {{ product_design.quantityOfSquares }} squares
            </span>
          </div>
        </button>
      </animation-staggered-fade>
      <div v-else key="none-found">
        <div v-if="product_design_search" class="flex justify-between p-3">
          <div class="flex flex-col justify-around">
            No {{ selected_product.name }} Designs match "{{ product_design_search }}"
          </div>
          <div>
            <a class="btn-action" @click.stop="product_design_search = ''"> Clear Search </a>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product_search: '',
      product_design_search: '',
      qty_of_squares_filter: '',
      selected_product: undefined,
      products: [],
      product_designs: [],
      choose_product_dropdown_open: false,
      loading: true,
      width: this.$route.query.width,
      height: this.$route.query.height,
    };
  },
  computed: {
    quantityOfSquaresFilterList() {
      return [...new Set(this.product_designs.map((design) => design.quantityOfSquares))].sort(
        (a, b) => a - b,
      );
    },
    filteredProducts() {
      if (this.product_search === '') {
        return this.products;
      }
      return this.products.filter((product) => {
        if (product.name.toLowerCase().includes(this.product_search.toLowerCase())) {
          return product;
        }
        return false;
      });
    },
    filtered_product_designs() {
      if (this.qty_of_squares_filter === '' && this.product_design_search === '') {
        return this.product_designs.slice().sort((a, b) => a.listIndex - b.listIndex);
      }

      let filteredProductDesigns = this.product_designs;

      if (this.qty_of_squares_filter !== '') {
        filteredProductDesigns = filteredProductDesigns.filter((productDesign) => {
          if (productDesign.quantityOfSquares === this.qty_of_squares_filter) {
            return productDesign;
          }
          return false;
        });
      }

      if (this.product_design_search !== '') {
        filteredProductDesigns = filteredProductDesigns.filter((productDesign) => {
          if (productDesign.name.toLowerCase().includes(this.product_design_search.toLowerCase())) {
            return productDesign;
          }
          return false;
        });
      }

      return filteredProductDesigns.sort((a, b) => a.listIndex - b.listIndex);
    },
  },
  watch: {
    selected_product(selectedProduct) {
      this.product_designs = [];
      this.qty_of_squares_filter = '';
      this.product_design_search = '';
      const productType = this.$store.state.basket.currentItem.productType.toLowerCase()
      window.touch
        .productStoredDesigns(
          this.$store.state.basket.customerId,
          this.$store.state.auth.processingLevel,
          selectedProduct.productId,
          this.width,
          this.height,
          productType.includes('bay') || productType.includes('bow'),
          undefined,
          undefined,
          undefined,
          productType.includes('coupled'),
        )
        .then((response) => {
          if (selectedProduct.productId === this.selected_product.productId) {
            this.product_designs = response.filteredList;
          }
        });
    },
    product_design_search(newQuery) {
      if (newQuery.length > 0) {
        this.qty_of_squares_filter = '';
      }
    },
    qty_of_squares_filter(numberOfSquares) {
      if (numberOfSquares >= 1) {
        this.product_design_search = '';
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getProducts();
    this.loading = false;
  },
  methods: {
    async getProducts() {
      this.products = await this.$store.dispatch('basket/getChildProducts', {
        componentId: this.$route.params.id,
        width: this.width,
        height: this.height,
        customerId: this.$route.params.customerId,
      });

      // eslint-disable-next-line prefer-destructuring
      this.selected_product = this.products[0];
    },
    async selectDesign(designId) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await this.$store
        .dispatch('basket/insertDesignIntoBay', {
          product: this.selected_product.productId,
          designId,
          itemId: this.$route.params.id,
          componentId: this.$route.params.component,
        })
        .then(() => {
          this.$emit('goToURL');
        });
    },
    toggleChooseProductDropdownOpen() {
      this.choose_product_dropdown_open = !this.choose_product_dropdown_open;
    },
  },
};
</script>
