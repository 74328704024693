import store from '../../store';

export default async (to, from, next) => {
  if (to.path === '/') {
    next({ path: '/portal' });
  } else if (to.matched.some((record) => record.meta.portalRoute === true)) {
    const isLoginValid = await store.dispatch('auth/validateLogin', 'customer');
    if (isLoginValid) {
      if (from.name === null) {
        await store.dispatch('auth/setLogin', 'customer');
        await store.dispatch('user/setCustomer', undefined);
      }
      store.state.user.freshdeskWidgetEnabled = true;
      next();
    } else {
      store.state.user.freshdeskWidgetEnabled = false;
      next({
        path: store.state.auth.login_url,
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next('/');
  }
};
