<template>
  <div class="bg-white p-4 mt-10">
    <div class="">
      <div class="mb-3">
        <strong class="text-base"> Upload your GDPR Documents </strong>
      </div>
      <div class="flex flex-col justify-around mb-10">
        These documents are required to comply with GDPR regulations as you will be collecting
        customer data via {{ setup }} Mode.
      </div>
      <loading v-if="saving" :loading="saving" :label="'Saving'" style="flex: 1 1 0"></loading>
      <div v-else class="flex flex-col md:flex-row -mx-5">
        <div class="md:w-1/2 p-5">
          <table class="table w-full">
            <thead>
              <tr class="hidden xs:table-row">
                <th class="text-left font-normal p-3 px-6 text-base font-bold">
                  Name
                  <info-popup
                    :id="'vendor_settings_website_details:terms'"
                    class="_pop-out-right"
                    :info_message="'Here you will need to upload a copy of your standard business Terms and Conditions, these will then be available for your customers to view when they are using your Web Designer.'"
                    :next_id="'vendor_settings_website_details:privacy'"
                  ></info-popup>
                </th>
                <th class="text-left font-normal p-3 px-6 text-base font-bold">&nbsp;</th>
                <th class="text-left font-normal p-3 px-6 text-base xs:text-center font-bold w-40">
                  Upload / Edit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="flex flex-col xs:table-row relative bg-gray-200 border-white border-b-4">
                <td class="p-3">
                  <span class="xs:hidden"> Name: </span>
                  Terms and Conditions
                </td>
                <td v-if="termsAndConditionsDocument !== undefined" class="p-3">
                  <a
                    v-if="termsIsLink"
                    target="_blank"
                    :href="termsAndConditionsDocument.url"
                    class="cursor-pointer break-all"
                    v-text="termsAndConditionsDocument.url"
                  />
                  <a
                    v-else
                    target="_blank"
                    :href="termsAndConditionsDocument.url"
                    class="cursor-pointer break-all"
                    v-text="termsAndConditionsDocument.name"
                  />
                </td>
                <td v-else class="text-red-600 p-3">Not Yet Uploaded</td>
                <td
                  class="p-3 xs:text-center cursor-pointer"
                  @click.prevent="editing_terms_file = !demo_mode"
                >
                  <span class="xs:hidden"> Upload / Edit: </span>
                  <i class="fal fa-file-upload"></i>
                </td>
              </tr>
              <tr v-if="editing_terms_file">
                <td colspan="4">
                  <div class="bg-white p-5">
                    <div class="mb-2">Enter a new link for your terms and conditions here:</div>
                    <input v-model="terms_link" type="text" class="form-control" />
                    <label class="block mt-2 text-green-600 hover:text-green-800 cursor-pointer">
                      ... or click here to upload a new file.
                      <input
                        id="terms_file"
                        type="file"
                        accept="application/pdf"
                        class="form-control hidden"
                        @change="newTermsUpload"
                      />
                    </label>
                    <div class="text-right">
                      <div
                        class="btn mr-2"
                        @click="(editing_terms_file = false), (terms_link = null)"
                      >
                        Cancel
                      </div>
                      <div v-if="terms_link" class="btn-action" @click="saveNewTermsLink">Save</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="md:w-1/2 p-5">
          <table class="table w-full">
            <thead>
              <tr class="hidden xs:table-row">
                <th class="text-left font-normal p-3 px-6 text-base font-bold">
                  Name
                  <info-popup
                    :id="'vendor_settings_website_details:privacy'"
                    class=""
                    :info_message="'Under General Data Protection Regulations you are required to display a Privacy Policy to your customers to agree to before they submit their personal information to you. This document needs to detail clearly how you plan to use and store their information, as well as information regarding their rights. The ICO provide further information'"
                    :next_id="'vendor_settings_website_details:deploy'"
                  ></info-popup>
                </th>
                <th class="text-left font-normal p-3 px-6 text-base font-bold">&nbsp;</th>
                <th class="text-left font-normal p-3 px-6 text-base xs:text-center font-bold w-40">
                  Upload / Edit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="flex flex-col xs:table-row relative bg-gray-200 border-white border-b-4">
                <td class="p-3">
                  <span class="xs:hidden"> Name: </span>
                  Privacy Policy
                </td>
                <td v-if="privacyPolicyDocument !== undefined" class="p-3">
                  <a
                    v-if="privacyIsLink"
                    target="_blank"
                    :href="privacyPolicyDocument.url"
                    class="cursor-pointer break-all"
                    v-text="privacyPolicyDocument.url"
                  />
                  <a
                    v-else
                    target="_blank"
                    :href="privacyPolicyDocument.url"
                    class="cursor-pointer break-all"
                  >View Document</a>
                </td>
                <td v-else class="text-red-600 p-3">Not Yet Uploaded</td>
                <td
                  class="p-3 xs:text-center cursor-pointer"
                  @click.prevent="editing_privacy_file = !demo_mode"
                >
                  <span class="xs:hidden"> Upload / Edit: </span>
                  <i class="fal fa-file-upload"></i>
                </td>
              </tr>
              <tr v-if="editing_privacy_file">
                <td colspan="4">
                  <div class="bg-white p-5">
                    <div class="mb-2">Enter a new link for your privacy policy here:</div>
                    <input v-model="privacy_policy_link" type="text" class="form-control" />
                    <label class="block mt-2 text-green-600 hover:text-green-800 cursor-pointer">
                      ... or click here to upload a new file.
                      <input
                        id="privacy_policy_file"
                        type="file"
                        accept="application/pdf"
                        class="form-control hidden"
                        @change="newPrivacyPolicyUpload"
                      />
                    </label>
                    <div class="text-right">
                      <div
                        class="btn mr-2"
                        @click="(editing_privacy_file = false), (privacy_policy_link = null)"
                      >
                        Cancel
                      </div>
                      <div
                        v-if="privacy_policy_link"
                        class="btn-action"
                        @click="saveNewPrivacyPolicyLink"
                      >
                        Save
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['customerId', 'setup'],
  data() {
    return {
      privacyPolicyDocument: false,
      termsAndConditionsDocument: false,
      saving: false,
      editing_terms_file: false,
      editing_privacy_file: false,
      terms_link: null,
      privacy_policy_link: null,
      copy_url_button_clicked: false,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'user/getCustomer',
    }),
    termsIsLink() {
      if (this.termsAndConditionsDocument) {
        return !this.termsAndConditionsDocument.url.startsWith(process.env.VUE_APP_IMAGE_BASE_URL);
      }
      return false;
    },
    privacyIsLink() {
      if (this.privacyPolicyDocument) {
        return !this.privacyPolicyDocument.url.startsWith(process.env.VUE_APP_IMAGE_BASE_URL);
      }
      return false;
    },
  },
  async mounted() {
    await Promise.all([this.getPrivacyPolicyDocument(), this.getTermsAndConditionsDocument()]).then(
      ([privacyPolicyDocument, termsAndConditionsDocument]) => {
        this.privacyPolicyDocument = privacyPolicyDocument;
        this.termsAndConditionsDocument = termsAndConditionsDocument;
        this.priceDisplayMode = this.customer.eCommerceIntegration.priceDisplayMode;
        this.saving = false;
      },
    );
    if (this.termsIsLink) {
      this.terms_link = this.termsAndConditionsDocument.url;
    }
    if (this.privacyIsLink) {
      this.privacy_policy_link = this.privacyPolicyDocument.url;
    }
  },
  methods: {
    ...mapActions({
      getTermsAndConditionsDocument: 'user/getTermsAndConditionsDocument',
      getPrivacyPolicyDocument: 'user/getPrivacyPolicyDocument',
      uploadDocumentForm: 'marketing/uploadDocumentForm',
      customerUploadDocumentWithUrl: 'user/customerUploadDocumentWithUrl',
      customerUpdateCommerceIntegrationSettings: 'user/customerUpdateCommerceIntegrationSettings',
    }),
    async newPrivacyPolicyUpload(event) {
      this.saving = true;
      if (event.target.files[0].name.split('.').pop().toLowerCase() !== 'pdf') {
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          html: 'Please choose a PDF',
        });
        document.getElementById('privacy_policy_file').value = '';
        this.saving = false;
        return;
      }
      const file = document.getElementById('privacy_policy_file').files[0]; // eslint-disable-line prefer-destructuring
      const formData = new FormData();
      formData.append('Type', '2');
      formData.append('Name', 'privacy_policy.pdf');
      formData.append('File', file);
      formData.append('OverwriteCurrentSingleDocument', JSON.stringify(true));
      await this.uploadDocumentForm(formData);
      this.editing_privacy_file = false;
      this.saving = false;
      this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
      this.privacy_policy_link = null;
      this.alertBox().fire('Privacy Policy file saved');
    },
    async newTermsUpload(event) {
      this.saving = true;
      if (event.target.files[0].name.split('.').pop().toLowerCase() !== 'pdf') {
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          html: 'Please choose a PDF',
        });
        document.getElementById('terms_file').value = '';
        this.saving = false;
        return;
      }
      const file = document.getElementById('terms_file').files[0]; // eslint-disable-line prefer-destructuring
      const formData = new FormData();
      formData.append('Type', '1');
      formData.append('Name', 'terms_and_conditions.pdf');
      formData.append('File', file);
      formData.append('OverwriteCurrentSingleDocument', JSON.stringify(true));
      await this.uploadDocumentForm(formData);
      this.saving = false;
      this.editing_terms_file = false;
      this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
      this.terms_link = null;
      this.alertBox().fire('Terms and Conditions document saved');
    },
    async saveNewTermsLink() {
      this.saving = true;

      if (!this.terms_link.includes('//')) {
        this.terms_link = `http://${this.terms_link}`;
      }

      const document = {
        name: 'Terms and Conditions',
        type: 1,
        url: this.terms_link,
      };
      await this.customerUploadDocumentWithUrl(document);
      this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
      this.editing_terms_file = false;
      this.saving = false;
      this.terms_link = null;
      this.alertBox().fire('Terms and Conditions link saved');
    },
    async saveNewPrivacyPolicyLink() {
      this.saving = true;

      if (!this.privacy_policy_link.includes('//')) {
        this.privacy_policy_link = `http://${this.privacy_policy_link}`;
      }

      const document = {
        name: 'Privacy Policy',
        type: 2,
        url: this.privacy_policy_link,
      };
      await this.customerUploadDocumentWithUrl(document);
      this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
      this.editing_privacy_file = false;
      this.saving = false;
      this.privacy_policy_link = null;
      this.alertBox().fire('Privacy Policy link saved');
    },
  },
};
</script>
