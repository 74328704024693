<template>
  <div class="fixed top-0 z-50 text-white text-center w-full pointer-events-none">
    <div
      v-if="devMode && showDevPanel"
      class="bg-green-400 cursor-pointer text-white z-50 rounded-b-lg mx-auto pt-0.5 pb-5 px-5 pointer-events-auto flex flex-col items-center text-xs sm:w-4/12 w-full"
      @click="showDevPanel = false"
    >
      <div class="text-base text-xl uppercase">
        {{ nodeEnv }}<span v-if="demo_mode"> - DEMO MODE</span>
      </div>
      <div class="w-full flex justify-between">
        <div>Screen:</div>
        <div class="3xs:hidden">&lt;3XS</div>
        <div class="hidden 3xs:block 2xs:hidden">3XS</div>
        <div class="hidden 2xs:block xs:hidden">2XS</div>
        <div class="hidden xs:block sm:hidden">XS</div>
        <div class="hidden sm:block md:hidden">SM</div>
        <div class="hidden md:block lg:hidden">MD</div>
        <div class="hidden lg:block xl:hidden">LG</div>
        <div class="hidden xl:block">XL</div>
      </div>
      <div class="w-full flex justify-between">
        <div>Installation Type:</div>
        <div v-if="touch_portal_installation">Portal</div>
        <div v-else>Business</div>
      </div>
      <div class="w-full flex justify-between">
        <div>Token Type:</div>
        <div v-if="guest_mode_user">Unauthenticated</div>
        <div v-else-if="touch_portal_user && !branded_mode_user">Portal</div>
        <div v-else-if="touch_business_user">Business</div>
        <div v-else-if="branded_mode_user">Branded Mode</div>
      </div>
      <div class="w-full flex justify-between">
        <div>Server URL:</div>
        <div>{{ server_url }}</div>
      </div>
    </div>
    <div
      v-else
      class="px-5 py-0.5 inline-block rounded-b-lg cursor-pointer pointer-events-none"
      :class="{
        'bg-green-400': vueEnv === 'Local',
        'bg-blue-400': vueEnv === 'dev',
        'bg-orange-400': vueEnv === 'test',
        'bg-red-400': vueEnv === 'staging',
        'pointer-events-auto': vueEnv === 'Local',
      }"
      @click="showDevPanel = true"
    >
      <span class="text-xl uppercase">{{ vueEnv }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebugBanner',
  props: ['vueEnv'],
  data() {
    return {
      showDevPanel: false,
      nodeEnv: process.env.NODE_ENV,
      devMode: process.env.NODE_ENV !== 'production',
    };
  },
  computed: {
    server_url() {
      return window.touch.endpoint;
    },
  },
};
</script>

<style scoped></style>
