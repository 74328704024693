<template>
  <aside
    tabindex="0"
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch p-3 2xs:p-5 xl:p-10 h-full absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
    @focusout="close()"
  >
    <div class="m-2 md:p-5 lg:p-10 m-auto">
      <div class="cursor-pointer hover:text-gray-800" @click="close()">
        <i class="fal fa-times" /> <span class="pl-4">Close</span>
      </div>
      <div class="mt-10">
        <div class="text-lg font-medium mb-10">{{ item.description }} Summary</div>
        <div v-if="showSizes" class="item-summary-group">
          <header class="group-title">
            <h3>Measurements</h3>
          </header>
          <div v-for="spec in item.dimensions" :key="spec.id" class="group-attributes">
            <div class="attribute-label">
              <strong>{{ spec.description }}</strong>
            </div>
            <div class="attribute-value">{{ spec.value }}mm</div>
          </div>
        </div>
        <div
          v-for="(specGroup, title) in specificationGroups"
          :key="title"
          class="item-summary-group"
        >
          <header class="group-title">
            <h3>{{ title }}</h3>
          </header>
          <div v-for="(spec, key) in specGroup" :key="key" class="group-attributes">
            <div class="attribute-label">
              <strong>{{ spec.name }}</strong>
            </div>
            <template v-for="(option, option_key) in spec.options">
              <div v-if="option.isCurrentOption" :key="option_key" class="attribute-value">
                <template v-if="option.textEntry && spec.text">
                  {{ spec.text }}
                </template>
                <template v-else>
                  {{ option.description }}
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
    },
    showSizes: {
      default: true,
    },
  },
  computed: {
    specificationGroups() {
      const spec = this.item.specification.filter((specItem) => specItem.omitFromSummary === false);
      const groups = spec.reduce((obj, specItem) => {
        const groupName = specItem.optionGroupName;

        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(groupName)) {
          // eslint-disable-next-line no-param-reassign
          obj[groupName] = [];
        }

        obj[groupName].push(specItem);

        return obj;
      }, {});

      const toRename = [];

      Object.entries(groups).forEach(([key, value]) => {
        // groups[key] = 1;
        value.forEach((item, index) => {
          if (groups[key].filter((group) => group.name === item.name).length > 1) {
            if (item.components && item.components.length > 0) {
              toRename.push({
                key,
                index,
                newName: `${groups[key][index].name} (${item.components[0].description})`,
              });
            }
          }
        });
      });

      toRename.forEach((entry) => {
        groups[entry.key][entry.index].name = entry.newName;
      });

      return groups;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
