export default {
  methods: {
    validate (attributes) {
      let errors = []
      attributes = attributes.filter(x => x.length > 0)
      for (let i = 0; i < attributes.length; i++) {
        const property = attributes[i][0]
        const data = attributes[i][1]
        const maxLength = attributes[i][2]
        const type = attributes[i][3]
        let error

        if (!data) {
          error = '<strong>' +  property + '</strong> is required'
        } else if (type) {
          error = this[type + 'Validate'](data, property)
        } else if (data && data?.length > maxLength) {
          error = '<strong>' + property + '</strong> is to long (maximum is ' + maxLength + ' characters)'
        }

        if (error) {
          errors.push(error)
        }
      }

      if (errors.length > 0)  {
        this.alertBox().fire({
          title: 'Additional information required',
          icon: 'error',
          html: `<ul>${Object.values(errors).join('</li><li>')}</ul>`,
        });
        return false
      } else {
        return true
      }
    },
    emailValidate (email, property) {
      if (/^\S+@\S+\.\S+$/.test(email)) {
        return false
      } else {
        return '<strong>' + property + '</strong> is an invalid Email'
      }
    },
    websiteValidate (website, property) {
      try {
        new URL(website);
        return false
      } catch (err) {
        return '<strong>'  + property + '</strong> must be valid and include http:// or https://';
      }
    },
    numberValidate (number, property) {
      if (!isNaN(number)) {
        return false
      } else {
        return '<strong>' + property + '</strong> is not a number'
      }
    },
  },
};
