<template>
  <div>
    <div class="flex flex-col xs:flex-row flex-wrap">
      <div class="w-full">
        <div v-show="addresses && addresses.length > 0" class="">
          <div class="mb-2">
            <strong> Select address </strong>
          </div>
          <select
            :disabled="!is_admin_user"
            type="text"
            :value="selectedAddress"
            class="form-control"
            @change="selectAddress"
          >
            <option :value="0">Select address ...</option>
            <option v-for="address in addresses" :key="address.id" :value="address.id">
              {{ formattedAddress(address) }}
            </option>
          </select>
          <hr class="mt-6 mb-6" />
          <div class="mb-2">
            <strong> ... or create a new one </strong>
          </div>
        </div>
        <AddressForm @address-updated="addressUpdated" />
      </div>
    </div>
    <div class="text-right">
      <button class="btn mr-2" @click.prevent="close">Cancel</button>
      <button class="btn-action" @click.prevent="saveAddress">Save new address</button>
    </div>
  </div>
</template>

<script>
import BaseAddress from '@/components/shared/address/BaseAddress.vue';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  mixins: [BaseAddress],
  data() {
    return {
      newAddress: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        code: '',
        isSelectable: true,
        isPrimary: false,
        iso: 'GB',
        latitude: 0,
        longitude: 0,
      },
    };
  },
  methods: {
    addressUpdated(newAddress) {
      this.newAddress.line1 = newAddress.addressLine1;
      this.newAddress.line2 = newAddress.addressLine2;
      this.newAddress.line3 = newAddress.addressLine3;
      this.newAddress.town = newAddress.addressTown;
      this.newAddress.county = newAddress.addressCounty;
      this.newAddress.code = newAddress.addressCode;
      this.newAddress.iso = newAddress.addressIso;
      this.newAddress.isPrimary = newAddress.isPrimary;
    },
  },
};
</script>

<style scoped></style>
