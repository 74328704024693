export default {
  methods: {
    ytRegex(url) {
      return url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/);
    },
    vimeoRegex(url) {
      return url.match(/\/([0-9]+)$/);
    },
    async getYoutubeThumbnail(url) {
      const id = this.ytRegex(url)[1];

      return `https://img.youtube.com/vi/${id}/sddefault.jpg`;
    },
    async getVimeoThumbnail(url) {
      const id = this.vimeoRegex(url)[1];

      return `https://vumbnail.com/${id}_medium.jpg`;
    },
    async getYoutubeEmbedUrl(videoUrl) {
      const urlBreakdown = this.ytRegex(videoUrl);
      if (urlBreakdown.length <= 1) {
        return undefined;
      }
      return `https://www.youtube.com/embed/${this.ytRegex(videoUrl)[1]}`;
    },
    getVideoType(url) {
      if (this.ytRegex(url)) {
        return this.enums.documentVideoType.YOUTUBE;
      }

      if (this.vimeoRegex(url)) {
        return this.enums.documentVideoType.VIMEO;
      }

      return this.enums.documentVideoType.NONE;
    },
  },
};
