<template>
  <portal to="portal_popup">
    <modal-window
      v-if="modal_open"
      ref="modal"
      :modal_open="true"
      title="Add Address"
      @close="$emit('close')"
    >
      <AddressForm
        class="mt-5"
        :is-primary-by-default="isPrimaryByDefault"
        :is-lat-lng-required="true"
        :is-save-button="true"
        @address-updated="addressUpdated"
        @saveAddress="saveAddress"
      />
    </modal-window>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  props: ['customerId'],
  data() {
    return {
      newAddress: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        latitude: '',
        longitude: '',
        code: '',
        isSelectable: true,
        isPrimary: false,
        iso: 'GB',
      },
      customer: null,
      manualAddressEntry: false,
      address_picked: false,
      modal_open: false,
    };
  },
  computed: {
    formatted_address() {
      return [
        this.newAddress.line1,
        this.newAddress.line2,
        this.newAddress.line3,
        this.newAddress.town,
        this.newAddress.county,
        this.newAddress.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
    isPrimaryByDefault() {
      return this.customer?.addresses?.length === 0 ?? false;
    },
  },
  watch: {
    newAddress: {
      handler(val) {
        if (val !== 'GB') {
          this.manualAddressEntry = true;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadUser();
    this.modal_open = true;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
      customerUpdateAddresses: 'user/customerUpdateAddresses',
    }),
    async loadUser() {
      this.customer = await this.customerGet(this.customerId);
    },
    addressUpdated(newAddress) {
      this.$refs.modal.isEdited = true;
      this.newAddress.line1 = newAddress.addressLine1;
      this.newAddress.line2 = newAddress.addressLine2;
      this.newAddress.line3 = newAddress.addressLine3;
      this.newAddress.town = newAddress.addressTown;
      this.newAddress.county = newAddress.addressCounty;
      this.newAddress.code = newAddress.addressCode;
      this.newAddress.iso = newAddress.addressIso;
      this.newAddress.longitude = newAddress.longitude;
      this.newAddress.latitude = newAddress.latitude;
      this.newAddress.isPrimary = newAddress.isPrimary;
    },
    async saveAddress() {
      this.loading = true;
      this.customer.addresses[this.customer.addresses.length] = this.newAddress;
      await this.customerUpdateAddresses({ customer: this.customer });
      this.loading = false;
      this.$emit('created');
      this.$emit('close');
    },
    pickedAddress(address) {
      this.newAddress.line1 = address.address1;
      this.newAddress.line2 = address.address2;
      this.newAddress.line3 = address.address3;
      this.newAddress.town = address.town;
      this.newAddress.county = address.county;
      this.newAddress.code = address.postcode;
      this.newAddress.latitude = address.latitude;
      this.newAddress.longitude = address.longitude;
      this.address_picked = true;
    },
    clearAddress() {
      this.newAddress.line1 = '';
      this.newAddress.line2 = '';
      this.newAddress.line3 = '';
      this.newAddress.town = '';
      this.newAddress.county = '';
      this.newAddress.code = '';
      this.newAddress.isPrimary = false;
      this.newAddress.latitude = undefined;
      this.newAddress.longitude = undefined;
      this.address_picked = false;
    },
  },
};
</script>
