var render = function render(){var _vm=this,_c=_vm._self._c;return _c('touch-layout',{scopedSlots:_vm._u([{key:"header_page_breadcrumb",fn:function(){return [_c('Breadcrumbs',{attrs:{"routes":[
        ['Dashboard', '/'],
        ['Orders', '/orders'],
      ]}})]},proxy:true},{key:"header_page_title",fn:function(){return [_vm._v(" Search Orders ")]},proxy:true},{key:"header_page_actions",fn:function(){return [_c('div',{staticClass:"pr-3 md:px-10 flex items-center"},[_c('router-link',{staticClass:"btn-action",attrs:{"to":"/new-order"}},[_vm._v(" New Order ")]),_c('info-popup',{staticClass:"_pop-out-left",attrs:{"id":'quotes:new_quote',"info_message":'Click here to create a new quote at any time.',"next_id":'quotes:view_quote'}})],1)]},proxy:true}])},[_c('portal',{attrs:{"to":"portal_search"}},[_c('SearchBox',{staticClass:"flex",attrs:{"placeholder":"Search for reference..."}})],1),_c('loading',{staticClass:"bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"flex flex-wrap-reverse 2xs:flex-no-wrap"},_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.tabType,staticClass:"py-2 px-4 border border-b-0 cursor-pointer",class:{
          'bg-white': _vm.selectedTab === tab.tabType,
          'border-gray-400 bg-gray-300 text-gray-600': _vm.selectedTab !== tab.tabType,
        },on:{"click":function($event){_vm.selectedTab = tab.tabType}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0),(_vm.orders.length > 0)?_c('div',{key:"orders_list",staticClass:"bg-white p-3 md:p-10 pb-6 border",attrs:{"data-index":"1"}},[_c('table',{staticClass:"table-lined xs:table-unstacked _with-hover w-full"},[_c('thead',[_c('tr',[_c('th',{staticClass:"w-10"},[_vm._v("#")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Order Reference")]),_c('th',[_vm._v("Net Cost")]),_c('th',{staticClass:"cursor-pointer relative"},[_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v("Status")])]),_c('th',[_vm._v("Visible to Admin")]),_c('th',{staticClass:"w-16"},[_c('button',{staticClass:"btn-action",on:{"click":function($event){$event.stopPropagation();return _vm.loadFeed.apply(null, arguments)}}},[_vm._v("Reload")])])])]),_c('tbody',_vm._l((_vm.orders),function(order){return _c('router-link',{key:order.id,staticClass:"cursor-pointer pointer-events-none",class:{
              'pointer-events-auto': [
                _vm.enums.orderStatusSaveStates.NOT_APPLICABLE,
                _vm.enums.orderStatusSaveStates.COMPLETED,
              ].includes(order.saveState),
              'fadeInAndOut': order.saveState === _vm.enums.orderStatusSaveStates.STARTED
            },attrs:{"tag":"tr","to":order.url}},[_c('td',[_c('div',{staticClass:"td-title w-24"},[_vm._v("Number:")]),_c('div',{staticClass:"td-content"},[_vm._v(_vm._s(order.customerCounter))])]),_c('td',[_c('div',{staticClass:"td-title w-24"},[_vm._v("Created:")]),_c('div',{staticClass:"td-content"},[_vm._v(_vm._s(_vm.formatDate(order.date)))])]),_c('td',[_c('div',{staticClass:"td-title w-24"},[_vm._v("Reference:")]),_c('div',{staticClass:"td-content truncate w-full"},[_vm._v(_vm._s(order.reference))])]),(order.jobs.length > 0)?_c('td',[_c('div',{staticClass:"td-title w-24"},[_vm._v("Net Cost:")]),_c('div',{staticClass:"td-content",domProps:{"innerHTML":_vm._s(
                  _vm.formatCurrency(
                    order.customerCurrencyCode,
                    order.jobs[0].priceBeforeTax,
                  )
                )}})]):_c('td',[_c('strong',{staticClass:"xs:hidden"},[_vm._v(" Net Cost: ")]),_vm._v(" -")]),_c('td',[_c('div',{staticClass:"td-title w-24"},[_vm._v("Status:")]),_c('span',{class:{
                  'font-bold text-red-500':
                    order.saveState === _vm.enums.orderStatusSaveStates.FAILED,
                },domProps:{"textContent":_vm._s(_vm.statusText(order))}})]),_c('td',[(
                  _vm.showReleaseToAdminButton(order) &&
                    order.saveState !== _vm.enums.orderStatusSaveStates.FAILED
                )?[_c('div',{staticClass:"td-title w-24 my-auto"},[_vm._v("Visible To Admin?")]),(!order.visibleToAdmin)?_c('button',{staticClass:"btn-action",on:{"click":function($event){$event.stopPropagation();return _vm.toggleAdminView(order)}}},[_vm._v(" Show To Admin ")]):_c('i',{staticClass:"fa fa-check text-green-600"})]:_vm._e()],2),_c('td',{staticClass:"td-action-buttons"},[_c('div',{staticClass:"table-edit-btn"},[_c('i',{staticClass:"fa fa-pencil"})])])])}),1)])]):_c('div',{key:"none-found",staticClass:"bg-white p-3 md:p-10 pb-6 border"},[(_vm.$route.query.search)?_c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("No Orders match \""+_vm._s(_vm.$route.query.search)+"\"")]),_c('div',[_c('a',{staticClass:"btn-action",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ query: undefined })}}},[_vm._v(" Clear Search ")])])]):_c('div',[_vm._v("No Orders to show")])]),(_vm.orders.length > 0)?_c('div',{key:"pagination",staticClass:"mt-3"},[_c('pagination',{attrs:{"total_items":_vm.total_items,"limit":_vm.limit},on:{"load-feed":_vm.loadFeed}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }