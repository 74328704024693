<template>
  <div
    :class="{
      'fixed flex flex-col p-3 p-8-lg z-50 bottom-0 left-0 bg-white w-full xs:w-1/2 sm:w-1/3 custom-offset':
        isOpen,
    }"
  >
    <div class="xl:flex xl:items-center xl:mb-2">
      <div class="flex xl:w-1/3">
        <button v-if="isOpen" class="mr-2" @click="isOpen = false">
          <i class="fal fa-2x fa-arrow-circle-left mt-1"></i>
        </button>
        <question-label
          ref="label"
          :show-eyeball="false"
          @set-advanced-mode="$emit('input', $event)"
        >
          <span :class="{ 'text-red-600 font-bold': isAForcedQuestion }">{{ spec.name }}</span>
        </question-label>
      </div>

      <question-trigger
        v-if="option && (!isAForcedQuestion || !isOpen)"
        class="xl:w-2/3"
        :class="{ 'text-red-600': isAForcedQuestion }"
        :loading="loading"
        :is-open="isOpen"
        :has-text="option.textEntry"
        :text="spec.text"
        :text-validation="spec.validation"
        :text-placeholder="option.description"
        :image-url="!isAForcedQuestion && spec.displayType === 1 ? option.imageUrl : ''"
        @open="isOpen = true"
        @close="isOpen = false"
        @set-text="setText($event)"
      >
        {{ isAForcedQuestion ? "Please Select..." : option.description }}
      </question-trigger>
      <question-trigger
        v-if="!option && (!isAForcedQuestion || !isOpen)"
        class="xl:w-2/3"
        :loading="loading"
        :is-open="isOpen"
        :has-text="false"
        :text="spec.text"
        :text-validation="spec.validation"
        text-placeholder=""
        image-url=""
        @open="isOpen = true"
        @close="isOpen = false"
        @set-text="setText($event)"
      >
        {{ isAForcedQuestion ? "Please Select..." : "No Option Selected" }}
      </question-trigger>
    </div>

    <template v-if="!isOpen">
      <div
        v-for="parameter in availableParams"
        :key="`${parameter.index}:${parameter.memberId}:${parameter.componentId}`"
      >
        <div class="xl:flex xl:items-center xl:mb-2">
          <div class="flex xl:w-1/3">
            <question-label :show-eyeball="false">
              <span>{{ parameter.text }}</span>
            </question-label>
          </div>
          <question-trigger
            v-if="option && !isOpen"
            class="xl:w-2/3"
            :loading="loading"
            :has-text="true"
            :text="parameter.value"
            :text-placeholder="parameter.text"
            @set-text="setParameterText(parameter, $event)"
          >
          </question-trigger>
        </div>
      </div>
    </template>

    <div
      v-if="isOpen"
      :class="{ flex: spec.displayType === 1 }"
      class="bg-gray-200 mt-2 rounded flex-col p-2 overflow-y-auto flex-grow pb-24"
    >
      <search-bar
        v-if="spec.options.length > 10"
        v-model="searchQuery"
        :filters="spec.filters"
        :filter-id="filterId"
        @setFilterId="filterId = $event"
      />

      <div class="flex flex-wrap">
        <div
          v-for="option in filteredOptions"
          :key="option.id"
          class="w-full p-2"
          :class="{ 'xs:w-1/2 lg:w-1/3 xl:w-1/4': spec.displayType === 1 }"
        >
          <component
            :is="spec.displayType === 1 ? 'image-option' : 'dropdown-option'"
            :loading="loading"
            :option="option"
            :spec="spec"
            @change-value="change($event.heading, $event.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ImageOption from '@/components/shared/designer/SpecificationGroup/ImageOption.vue';
import DropdownOption from '@/components/shared/designer/SpecificationGroup/DropdownOption.vue';
import QuestionTrigger from '@/components/shared/designer/SpecificationGroup/QuestionTrigger.vue';
import SearchBar from '@/components/shared/designer/SpecificationGroup/SearchBar.vue';
import QuestionLabel from '@/components/shared/designer/SpecificationGroup/QuestionLabel.vue';

export default {
  components: {
    'image-option': ImageOption,
    'dropdown-option': DropdownOption,
    'question-trigger': QuestionTrigger,
    'question-label': QuestionLabel,
    'search-bar': SearchBar,
  },
  props: ['spec', 'loading', 'value', 'showAdvancedModeToggle'],
  data() {
    return {
      open_option_group: null,
      open_search: null,
      searchQuery: '',
      filterId: undefined
    };
  },
  computed: {
    ...mapGetters('designer', {
      somethingIsSelected: 'somethingIsSelected',
      interactiveHighlightState: 'interactiveHighlightState',
    }),
    availableParams() {
      if (this.somethingIsSelected) {
        return this.spec.parameters.filter((parameter) => {
          if (this.interactiveHighlightState.currentSelectionType === 'member') {
            return parameter.memberId === this.interactiveHighlightState.clickedMemberId;
          }

          return true;
        });
      }

      return this.spec.parameters;
    },
    isAForcedQuestion() {
      return this.$store.getters['designer/listOfUnansweredForcedQuestions']
        .map((spec) => spec.optionHeadingId)
        .includes(this.spec.optionHeadingId);
    },
    productDescription() {
      return this.$store.getters['basket/currentItem'].description;
    },
    filteredOptions() {
      let options = this.spec.options
      if (this.searchQuery) {
        options = options.filter((option) =>
          option.description.toLowerCase().includes(this.searchQuery.toLowerCase()),
        );
      }
      if (this.filterId) {
        options = options.filter((option) =>
          option.filterId === this.filterId,
        );
      }
      return options
    },
    option() {
      return this._.head(this.spec.options.filter((option) => option.isCurrentOption));
    },
    isOpen: {
      get() {
        return (
          this.$store.getters['designer/optionHeadingForOpenQuestion'] === this.spec.optionHeadingId
        );
      },
      set(setOpen) {
        if (setOpen) {
          this.$store.commit('designer/setOpenQuestion', this.spec.optionHeadingId);
          this.$store.dispatch('basket/updateImageTypeForHeading', this.spec.optionHeadingId);
          return;
        }

        if (
          this.$store.getters['designer/optionHeadingForOpenQuestion'] === this.spec.optionHeadingId
        ) {
          this.$store.commit('designer/clearOpenQuestion');
        }
      },
    },
  },
  watch: {
    isOpen(opening) {
      if (!opening) {
        // Blank search when we close
        this.searchQuery = '';
        this.filterId = undefined
        // this.$emit('scroll-to', this.$refs.label.$el);
      }
    },
  },
  methods: {
    setParameterText(parameter, text) {
      this.$emit('update-parameter', {
        heading: this.spec.optionHeadingId,
        option: this.option.id,
        parameter,
        value: text,
      });
    },
    setText(text) {
      this.$emit('set-text', {
        heading: this.spec.optionHeadingId,
        option: this.option.id,
        text,
      });
    },
    change(heading, value) {
      this.$emit('change', { heading, value });
      this.isOpen = false;
    },
  },
};
</script>

<style>
.custom-offset {
  top: 122px;
}
</style>
