import { render, staticRenderFns } from "./UpdateExtra.vue?vue&type=template&id=066b6997&scoped=true"
import script from "./UpdateExtra.vue?vue&type=script&lang=js"
export * from "./UpdateExtra.vue?vue&type=script&lang=js"
import style0 from "./UpdateExtra.vue?vue&type=style&index=0&id=066b6997&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066b6997",
  null
  
)

export default component.exports