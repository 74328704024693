<template>
  <div>
    <portal v-if="filteredDesigns" to="branded_choose_product_filters">
      <div class="flex">
        <div class="flex flex-col justify-around">
          <div class="bg-white rounded-full text-xs flex border">
            <div
              class="bg-brand-primary rounded-full p-2 border-white"
              style="width: 32px; height: 32px"
            >
              <i class="fal fa-exchange text-white fa-fw"></i>
            </div>
            <div class="flex flex-col justify-around uppercase relative">
              <select
                v-model="squaresFilter"
                class="bg-transparent outline-none pl-2 pr-6 h-full appearance-none"
                style="width: 100px"
              >
                <option
                  value=""
                  selected
                  v-text="squaresFilter ? 'Clear Filter' : 'Filter'"
                ></option>
                <option
                  v-for="(squaresQty, index) in quantityOfSquaresFilterList"
                  :key="index"
                  :value="squaresQty"
                >
                  {{ squaresQty }} Square<template v-if="squaresQty > 1">s</template>
                </option>
              </select>
              <div
                class="p-3 h-full absolute top-0 right-0 flex flex-col justify-around pointer-events-none"
              >
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </portal>
    <div class="flex flex-wrap w-full justify-center">
      <div
        v-for="(design, i) in filteredDesigns"
        :key="i"
        class="flex flex-col w-full xs:w-1/2 sm:w-1/4 lg:w-1/5 p-3 lg:p-5"
      >
        <div
          class="flex flex-col h-full bg-gray-200 rounded-lg cursor-pointer"
          @click="$emit('selected', design.designId)"
        >
          <div>
            <div class="flex flex-col justify-around p-3 pb-0 md:pb-3">
              <strong v-if="!showDesignNames" class="text-center">{{ design.name }}</strong>
              <div v-if="design.imageUrl" class="mx-auto">
                <img
                  class="flex flex-col justify-around p-3"
                  style="max-height: 150px"
                  data-svgImage
                  :src="design.imageUrl"
                />
              </div>
              <span
                v-else
                class="flex flex-col justify-around p-3"
                style="max-height: 150px"
                data-svgImage
                v-html="design.imageSvg"
              ></span>
            </div>
          </div>
          <div class="mt-auto p-3 pt-0">
            <button v-if="showDesignNames" class="btn-action w-full">{{ design.name }}</button>
            <button v-else class="btn-action w-full">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['productId', 'showDesignNames'],
  data() {
    return {
      designs: [],
      squaresFilter: '',
    };
  },
  computed: {
    quantityOfSquaresFilterList() {
      return [...new Set(this.designs.map((design) => design.quantityOfSquares))].sort(
        (a, b) => a - b,
      );
    },
    filteredDesigns() {
      let filteredDesigns = this.designs;

      if (this.squaresFilter !== '') {
        filteredDesigns = filteredDesigns.filter(
          (productDesign) => productDesign.quantityOfSquares === this.squaresFilter,
        );
      }

      return filteredDesigns.sort((a, b) => a.listIndex - b.listIndex);
    },
  },
  async mounted() {
    this.designs = (
      await window.touch.productStoredDesigns(
        this.$store.state.basket.customerId,
        this.$store.state.auth.processingLevel,
        this.productId,
      )
    ).filteredList;

    if (this.designs.length === 0) {
      this.alertBox().fire('No designs available');
    }

    if (this.designs.length === 1) {
      this.$emit('selected', this.designs[0].designId);
    }

    this.addHeightToSVGs();
  },
  methods: {
    addHeightToSVGs() {
      this.$nextTick(() => {
        const svgImages = document.querySelectorAll('[data-svgImage] svg');
        svgImages.forEach((image) => {
          image.setAttribute('height', '140px');
        });
      });
    },
  },
};
</script>
