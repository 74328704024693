<template>
  <table class="w-full table-pricing">
    <thead>
      <th class="font-medium text-left font-normal px-3 py-2 border-b">Part No.</th>
      <th v-if="hasColour" class="font-medium text-left font-normal px-3 py-2 border-b">Colour</th>
      <th class="font-medium text-left font-normal px-3 py-2 w-8 border-b">Order Qty.</th>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="align-top text-left font-normal px-3 py-2 bg-gray-300">
          {{ item.code }}
        </td>
        <td v-if="hasColour" class="align-top text-left font-normal px-3 py-2 bg-gray-300">
          {{ item.colour }}
        </td>
        <td class="text-right font-normal px-3 py-2 bg-gray-300">
          <input
            :value="getQtyFor(item)"
            type="text"
            class="w-16 border rounded border-gray-300 px-3 py-1"
            @change="updatedQtyFor(item, $event.target.value)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['items'],
  computed: {
    ...mapGetters({
      cartItems: 'stockParts/cartItems',
    }),
    hasColour() {
      return this.items.some((item) => item.colour);
    },
  },
  methods: {
    getQtyFor(item) {
      const cartEntry = this.cartItems.find((cartItem) => cartItem.stockItemId === item.id);

      if (cartEntry) {
        return cartEntry.quantity;
      }
      return null;
    },
    updatedQtyFor(item, quantity) {
      const formattedQuantity = Number(quantity);
      this.$store.dispatch('stockParts/updateQtyForItem', {
        itemId: item.id,
        quantity: Number.isNaN(formattedQuantity) ? 0 : formattedQuantity,
      });
    },
  },
};
</script>

<style scoped></style>
