<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/my-account'],
          ['Addresses & Delivery Days'],
        ]"
      />
    </template>
    <template #header_page_title> Addresses </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a
          class="btn-action"
          @click.stop.prevent="$refs.AddressesAndDeliveryDepots.add_address_popup_open = true"
        >
          Add Address
        </a>
      </div>
    </template>
    <Menu></Menu>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden">
      <AddressesAndDeliveryDepots ref="AddressesAndDeliveryDepots"></AddressesAndDeliveryDepots>
    </div>
  </touch-layout>
</template>

<script>
import AddressesAndDeliveryDepots from '@/components/shared/AddressesAndDeliveryDepots.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';

export default {
  components: {
    AddressesAndDeliveryDepots,
    Breadcrumbs,
    Menu,
  },
};
</script>
