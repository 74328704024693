import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.CreatePaymentRequest = async function CreatePaymentRequest(contractId, JobKey) {
  const response = this.parseResponse(
    await this.authenticatedPost('payment/CreatePaymentRequest', {
      contractId,
      JobKey,
    }),
    (errorResponse) => {
      throw new Error(errorResponse.data.message);
    },
  );

  if (!response) {
    throw new Error('No response');
  }

  return response;
};

Touch.prototype.GetContractTransactions = async function GetContractTransactions(contractId) {
  return this.parseResponseWith400(
    await this.authenticatedPost('payment/GetContractTransactions', {
      contractId,
    }),
  );
};

Touch.prototype.UpdatePaymentStatus = async function UpdatePaymentStatus(
  ContractId,
  PaymentStatus,
) {
  return this.parseResponse(
    await this.authenticatedPost('contract/UpdatePaymentStatus', {
      ContractId,
      PaymentStatus,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.getAccount = async function getAccount() {
  return this.parseResponseWith400(await this.authenticatedPost('stripe/getaccount'));
};

Touch.prototype.createAccount = async function createAccount() {
  return this.parseResponse(await this.authenticatedPost('stripe/createaccount'), errorCallback)
};

Touch.prototype.createAccountLink = async function createAccountLink() {
  return this.parseResponse(await this.authenticatedPost('stripe/createaccountlink'), errorCallback)
};
