<template>
  <div
    v-if="grids"
    class="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-around p-3 2xs:p-6 xs:p-10 z-50 bg-black_80"
    @click.prevent="closeIfSaved(false)"
  >
    <div
      class="bg-white rounded-lg m-auto overflow-auto xs:my-10 relative w-full flex flex-col"
      style="max-width: 600px"
      @click.prevent.stop
    >
      <div class="overflow-auto p-6 xs:p-10">
        <a class="absolute top-0 right-0 p-3 xs:p-6 cursor-pointer" @click.prevent="closeIfSaved">
          <i class="fal fa-times"></i>
        </a>
        <div class="flex flex-col mb-3 xs:mb-6">
          <div class="flex">
            <span
              v-if="selectedGridId || isWidthByHeight"
              class="text-lg flex flex-col justify-around"
            >Edit {{ price.description }} {{ selectedGrid.description || "List" }}</span>
            <span
              v-else
              class="text-lg flex flex-col justify-around"
            >Select {{ price.description }} Configuration</span>
          </div>
        </div>
        <edit-grid
          v-if="selectedGridId || isWidthByHeight"
          :default-currency="defaultCurrency"
          :unsaved="unsaved"
          :grid="selectedGrid"
          :is-width-by-height="isWidthByHeight"
          :financial-rate-type="price.financialRateType"
          @new-prices="newPrices($event)"
        >
        </edit-grid>
        <div v-else>
          <table class="table-pricing">
            <tr v-for="grid in grids.configurations" :key="grid.financialGridConfigurationId">
              <td class="py-1 px-3">
                <span>{{ grid.description }}</span>
              </td>
              <td class="text-right p-1">
                <button class="btn-action" @click="selectGrid(grid)">Edit List</button>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="price.description === 'Colour Group Uplifts'" class="mt-3">
          <div class="btn-info" @click.prevent="pricing_info_popup_open = true">
            View Colour Groups <i class="fa fa-info-circle"></i>
          </div>
          <portal v-if="pricing_info_popup_open" to="portal_pricing_info_popup">
            <modal-window
              :modal_open="pricing_info_popup_open"
              :max-width="1550"
              title=""
              @close="pricing_info_popup_open = false"
            >
              <pricing-info-popup
                :start_on_section="'Colours'"
                @close="pricing_info_popup_open = false"
              ></pricing-info-popup>
            </modal-window>
          </portal>
        </div>
      </div>
      <div class="flex justify-between flex-shrink-0 p-6 xs:p-10">
        <div>
          <span v-if="unsaved" class="text-red-600">You have unsaved changes</span>
        </div>
        <div class="flex">
          <button class="btn mr-2" @click.prevent="closeIfSaved">Close</button>
          <button
            v-if="selectedGridId && grids.configurations.length !== 1"
            class="btn mr-2"
            @click.prevent="backToSelect"
          >
            Back
          </button>
          <button v-if="unsaved" class="btn-action" @click="save()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditGrid from '@/components/shared/priceLines/List/EditGrid.vue';
import PricingInfo from '@/components/shared/PricingInfo.vue';

export default {
  components: {
    'edit-grid': EditGrid,
    'pricing-info-popup': PricingInfo,
  },
  props: ['price', 'datasetId', 'customerId', 'defaultCurrency', 'isNewBasePrice'],
  data() {
    return {
      grids: null,
      selectedGridId: null,
      updatedPrices: null,
      pricing_info_popup_open: false,
      isWidthByHeight: false,
    };
  },
  computed: {
    unsaved() {
      return this.changedPrices.length > 0;
    },
    originalPrices() {
      if (this.selectedGrid.overrideCells) {
        return this.selectedGrid.overrideCells;
      }

      return this.selectedGrid.cells;
    },
    changedPrices() {
      if (!this.updatedPrices || (!this.selectedGridId && !this.isWidthByHeight)) {
        return [];
      }

      if (this.isWidthByHeight) {
        return JSON.stringify(this.updatedPrices) !== JSON.stringify(this.originalPrices)
          ? this.updatedPrices
          : [];
      }

      return this.updatedPrices
        .filter(
          (price) =>
            Number(this.originalPrice('id', price.id).value) !== Number(price.value) ||
            this.originalFOC(price.id) !== price.foc,
        )
        .map((price) => ({
          id: price.id,
          value: price.value,
          foc: price.foc,
        }));
    },
    selectedGrid() {
      return this.grids.configurations
        ? this.grids.configurations.find(
            (grid) => grid.financialGridConfigurationId === this.selectedGridId,
          )
        : this.grids;
    },
  },
  async mounted() {
    this.loadGrids();
  },
  methods: {
    closeIfSaved(prompt = true) {
      if (!this.unsaved) {
        this.$emit('close');
        return;
      }

      if (prompt) {
        this.alertBox()
          .fire({
            title: 'You will lose unsaved changes, are you sure?',
            text: 'Your edits on this list will be lost',
            showCancelButton: true,
          })
          .then(async (response) => {
            if (response.isConfirmed) {
              this.$emit('close');
            }
          });
      }
    },
    async loadGrids() {
      let datasetId;
      let customerId = null;
      if (this.touch_business_user) {
        // eslint-disable-next-line prefer-destructuring
        datasetId = this.datasetId;
        // eslint-disable-next-line prefer-destructuring
        customerId = this.customerId;
      } else {
        // eslint-disable-next-line prefer-destructuring
        datasetId = this.$store.state.user.customer.datasetIds[0];
      }

      if (this.isNewBasePrice) {
        this.grids = await window.touch.staffGetBasePriceList(datasetId, this.price.id);
        if (this.grids.cells && this.grids.cells.some((x) => (typeof x.minimumHeight !== 'undefined'))) {
          this.isWidthByHeight = true;
          this.grids.cells = this.grids.cells.map((x) => ({ ...x, foc: x.foc ? x.foc : false }));
        }
      } else {
        this.grids = await this.$store.dispatch('touch/staffGetThisListAnomaly', {
          id: this.price.id,
          customerId,
          datasetId,
        });
      }

      if (this.grids.configurations) {
        this.grids.configurations.sort((a, b) =>
          a.description.localeCompare(b.description, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        );

        this.grids.configurations.map((configuration) => ({
          ...configuration,
          cells: configuration.cells.sort((a, b) =>
            a.description.localeCompare(b.description, undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
          ),
          overrideCells: configuration.overrideCells
            ? configuration.overrideCells.sort((a, b) =>
                a.description.localeCompare(b.description, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                }),
              )
            : null,
        }));

        if (this.grids.configurations.length === 1) {
          this.selectedGridId = this.grids.configurations[0].financialGridConfigurationId;
        }
      }
    },
    backToSelect() {
      if (!this.unsaved) {
        this.$emit('close');
        return;
      }

      this.alertBox()
        .fire({
          title: 'You will lose unsaved changes, are you sure?',
          text: 'Your edits on this list will be lost',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            this.selectedGridId = null;
          }
        });
    },
    originalPrice(property, id) {
      return this.originalPrices.find((price) => price[property] === id);
    },
    originalFOC(id) {
      const { foc } = this.originalPrices.find((price) => price.id === id);
      return foc || false;
    },
    newPrices(prices) {
      this.updatedPrices = prices.map((x) => ({ ...x, foc: x.foc ? x.foc : false }));
    },
    selectGrid(grid) {
      this.selectedGridId = grid.financialGridConfigurationId;
    },
    async save() {
      if (this.isWidthByHeight) {
        await window.touch.UpdateBaseValueWidthByHeight(
          this.datasetId,
          this.price.id,
          this.updatedPrices,
        );
      } else if (this.isNewBasePrice) {
        await window.touch.staffUpdateBasePriceList(
          this.datasetId,
          this.price.id,
          this.selectedGridId,
          this.changedPrices.map((price) => ({
            Id: price.id,
            Value: price.value,
            foc: price.foc,
          })),
        );
      } else {
        await this.$store.dispatch('touch/staffUpdateThisListAnomaly', {
          OverrideCells: this.changedPrices,
          Id: this.price.id,
          FinancialGridConfigurationId: this.selectedGridId,
          DatasetId: this.touch_business_user ? this.datasetId : undefined,
          customerId: this.customerId,
        });
      }
      this.loadGrids();
      this.alertBox().fire({
        title: 'Grid Saved',
        icon: 'success',
      });
    },
  },
};
</script>

<style scoped></style>
