<template>
  <div
    v-if="showPoint"
    class="help-point cursor-pointer justify-center items-center rounded-full inline-flex h-6 w-6"
    @click.prevent="helpPointClickHandler"
  >
    <div class="border border-white rounded-full w-4 h-4 flex justify-center items-center">
      <i class="fa fa-question text-white text-2xs"></i>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HelpPoint',
  props: {
    freshdeskArticleId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      freshdeskEnabled: 'user/getFreshdeskWidgetEnabled',
    }),
    showPoint() {
      return this.freshdeskEnabled && this.freshdeskArticleId !== 'undefined';
    },
  },
  methods: {
    helpPointClickHandler() {
      window.openFreshdeskWidget(this.freshdeskArticleId);
    },
  },
};
</script>
<style scoped>
.help-point {
  background-color: #3679f6;
}
</style>
