<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Rack Manager', '/rack-manager'], [$route.name]]"
      />
    </template>
    <template #header_page_title> Rack Manager </template>
    <div class="flex h-screen flex-col">
      <loading
        :loading="loading"
        class="bg-gray-200 w-full"
      >
        <div class="flex bg-white px-5 border-t-4 border-white gap-2 overflow-x-auto ">
          <router-link
            to="/rack-manager/map"
            class="cursor-pointer flex-shrink-0 py-2 mx-5 border-b-4 hover:border-black"
            :class="[$route.name === 'Rack Map' ? 'border-black' : 'border-white' ]"
          >
            Map
          </router-link>
          <router-link
            to="/rack-manager/vehicles"
            class="cursor-pointer flex-shrink-0 py-2 mx-5 border-b-4 hover:border-black"
            :class="[$route.name === 'Vehicle Management' ? 'border-black' : 'border-white' ]"
          >
            Vehicles
          </router-link>
          <router-link
            to="/rack-manager/rack-types"
            class="cursor-pointer flex-shrink-0 py-2 mx-5 border-b-4 hover:border-black"
            :class="[$route.name === 'Rack Management' ? 'border-black' : 'border-white' ]"
          >
            Rack Types
          </router-link>
          <router-link
            to="/rack-manager/carriers"
            class="cursor-pointer flex-shrink-0 py-2 mx-5 border-b-4 hover:border-black"
            :class="[$route.name === 'Carrier Management' ? 'border-black' : 'border-white' ]"
          >
            Carriers
          </router-link>
          <div class="pr-5"></div>
        </div>
      </loading>
      <router-view class="flex-1 bg-gray-200"></router-view>
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
