<template>
  <div class="h-screen bg-gray-200 sm:bg-transparent overflow-hidden">
    <div class="text-brand-primary hidden sm:block">
      <div class="absolute top-0 right-0 -z-1 left-0">
        <img
          v-if="isFabricatorForMondayDemo()"
          src="/images/stddemofab.png"
          class="ml-auto w-1/2"
        />
        <img
          v-else-if="hasCustomSecondaryImage"
          :src="customSecondaryImage"
          class="ml-auto w-1/2"
        />
        <img v-else :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
      </div>
    </div>
    <header class="bg-white w-full sm:bg-transparent print:hidden">
      <div class="flex flex-grow">
        <logo
          class="border-0 bg-transparent"
          :src="style.fabricator_logo"
          :href="$t(store_url)"
        ></logo>
        <div class="flex flex-grow flex-col"></div>
        <div class="flex ml-auto">
          <div class="flex flex-col justify-center">
            <a
              :href="
                isFabricatorForMondayDemo()
                  ? 'https://wallside.bm-touch.com/'
                  : style.back_to_site_link
              "
              class="bg-white rounded-full hover:bg-gray-200 py-2 pr-6 pl-5 m-5 font-medium"
            >
              <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
            </a>
          </div>
        </div>
      </div>
    </header>
    <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
      <div class="mx-auto w-full flex justify-around" style="max-width: 1600px">
        <div class="hidden sm:flex flex-col justify-around text-center m-10 mr-0">
          <div class="flex flex-col justify-between font-medium">
            <div v-if="isFabricatorForMondayDemo()" class="text-3xl xs:text-5xl leading-tight mb-6">
              Welcome To
              <div>Wallside Windows</div>
            </div>
            <div v-else v-html="$t('Login-Main Title')"></div>
            <div
              v-if="!isFabricatorForMondayDemo()"
              class="text-lg font-normal mx-auto my-6"
              style="max-width: 400px"
            >
              {{
                $t('Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.')
              }}
            </div>
            <div class="mx-auto">
              <img
                v-if="isFabricatorForMondayDemo()"
                src="/images/stddemofab-2.png"
                class="m-auto w-full"
                style="margin-left: -30px"
              />
              <img
                v-else-if="hasCustomImage"
                :src="customImage"
                class="m-auto w-full"
                style="margin-left: -30px"
              />
              <img
                v-else
                :src="$t('/images/login-image.png')"
                class="m-auto w-full"
                style="margin-left: -30px"
              />
            </div>
          </div>
        </div>
        <div class="login-form-wrapper">
          <transition name="fade-in" appear mode="out-in">
            <div v-if="!forgot_password_open" key="login" class="my-auto">
              <div class="text-center mb-4 bg-white py-4 sm:hidden font-medium">
                <div class="flex flex-col justify-between font-medium">
                  <div v-html="$t('Login-Main Title')"></div>
                  <div
                    class="text-base xs:text-lg font-normal mx-auto my-6"
                    style="max-width: 400px"
                  >
                    {{
                      $t(
                        'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
                      )
                    }}
                  </div>

                  <div class="mx-auto">
                    <img
                      v-if="hasCustomImage"
                      :src="customImage"
                      class="m-auto w-full"
                      style="max-height: 130px"
                    />
                    <img
                      v-else
                      :src="$t('/images/login-image.png')"
                      class="m-auto w-full"
                      style="max-height: 130px"
                    />
                  </div>
                </div>
              </div>
              <form class="login-form" @submit.prevent="login">
                <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                  <div class="text-4xl mb-2 font-medium">Welcome back!</div>
                  <div class="text-xl">Continue to your dashboard.</div>
                </div>

                <error v-if="login_error" :message="login_error"></error>

                <div class="mb-4">
                  <label for="email" class="block w-full">Email address</label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="email"
                    autofocus
                  />
                </div>

                <div class="mb-4">
                  <label for="password" class="block w-full">Password</label>
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    autocomplete="current-password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>

                <div class="mb-4 text-right">
                  <div
                    class="text-gray-600 text-xs hover:text-gray-800 cursor-pointer"
                    @click.prevent="forgot_password_open = true"
                  >
                    Forgot your password? Click here!
                  </div>
                </div>

                <button type="submit" class="btn-action btn-lg w-full">Login</button>

                <div class="text-center mt-10">
                  <a
                    :href="$t(store_url)"
                    target="_blank"
                    class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                  >
                    <div class="p-1">
                      <img src="../../assets/images/touch-logo.svg" width="75px" />
                    </div>
                    <div class="p-1 text-xs">By BM Group</div>
                  </a>
                </div>
              </form>
            </div>
            <div v-if="forgot_password_open" key="forgot">
              <forgot-password-form @close="forgot_password_open = false"></forgot-password-form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Logo from '@/components/shared/nav/Logo.vue';
import ForgotPasswordForm from './ForgotPassword.vue';

export default {
  components: {
    'forgot-password-form': ForgotPasswordForm,
    Logo,
  },
  data() {
    return {
      email: '',
      password: '',
      endpoint: process.env.VUE_APP_ENDPOINT_URL,
      imageBaseURL: process.env.VUE_APP_IMAGE_BASE_URL,
      processingLevel: 2,
      login_error: false,
      forgot_password_open: false,
    };
  },
  computed: {
    ...mapState('style', ['fabricator']),
    hasCustomImage() {
      return this.customImage && this.customImage.length;
    },
    customImage() {
      return this.fabricator?.styling?.portalPageStyling?.loginImageUri;
    },
    hasCustomSecondaryImage() {
      return this.customSecondaryImage && this.customSecondaryImage.length;
    },
    customSecondaryImage() {
      return this.fabricator?.styling?.portalPageStyling?.loginSecondaryImageUri;
    },
  },
  methods: {
    ...mapActions({
      setEndpoint: 'auth/setEndpoint',
      setImageBaseURL: 'auth/setImageBaseURL',
      attemptLogin: 'auth/attemptBusinessLogin',
    }),
    ...mapMutations({
      setProcessingLevel: 'auth/setProcessingLevel',
    }),
    login() {
      if (this.email !== '' && this.password !== '') {
        this.attemptLogin({
          username: this.email,
          password: this.password,
        })
          .then((loggedIn) => {
            if (loggedIn) {
              this.login_error = false;
              this.setSentryUser();
              this.routerPush(this.$router.currentRoute.query.redirect ?? '/');
              return;
            }
            this.login_error = 'Unable to login, Please check your username / password and try again';
          })
          .catch((error) => {
            this.login_error = error.response.data.message;
          });
      } else {
        this.login_error = 'Email address and password required';
      }
    },
    isFabricatorForMondayDemo() {
      return window.location.hostname === 'stddemofab.admin.bm-touch.co.uk';
    },
  },
};
</script>
