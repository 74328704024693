<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['First Data'],
        ]"
      />
    </template>
    <template #header_page_title> First Data Payment Gateway </template>
    <Menu></Menu>
    <div class="p-8 bg-gray-200 h-screen">
      <div class="bg-white p-6">
        <span>Your Payment Gateway is managed by First Data. No futher action required.</span>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';

export default {
  components: {
    Breadcrumbs,
    Menu
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.style.fabricator.name === 'Virtuoso') {
        next();
      } else {
        next('/manage-touch-account/payment-gateway/stripe');
      }
    });
  },
};
</script>
