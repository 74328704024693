<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Manage News', '/manage-news'], [news_post.title]]"
      />
    </template>
    <template #header_page_title>
      {{ news_post.title }}
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/manage-news/category" class="btn-action mr-1">
          Manage Categories
        </router-link>
      </div>
    </template>
    <transition appear name="fade-in" mode="out-in">
      <loading v-if="loading || saving_post" :key="1" :loading="loading || saving_post" :label="saving_post ? 'Saving' : 'Loading'" style="flex: 1 1 0"></loading>
      <main v-else :key="2" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10">
        <div class="bg-white p-6 xs:p-10 rounded-lg xs:mb-10 relative">
          <div class="2xs:flex mb-5">
            <div class="flex-1 flex 2xs:mr-5 mb-5 2xs:mb-0">
              <div class="flex flex-col justify-around whitespace-no-wrap mr-3 w-16 2xs:w-auto">
                Title
              </div>
              <div class="flex-grow">
                <input v-model="news_post.title" type="text" class="form-control" />
              </div>
            </div>
            <div class="flex-1 flex 2xs:ml-5">
              <div class="flex flex-col justify-around whitespace-no-wrap mr-3 w-16 2xs:w-auto">
                Category
              </div>
              <div class="flex-grow">
                <select v-model="news_post.newsCategoryId" class="form-control">
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                    :selected="news_post.newsCategoryId === category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-2">Summary</div>
            <wysiwyg v-model="news_post.summary"></wysiwyg>
          </div>
          <div class="mt-6">
            <div class="mb-2">Content</div>
            <wysiwyg v-model="news_post.paragraph"></wysiwyg>
          </div>
          <div v-if="!loading" class="mt-5" style="max-width: 415px">
            <api-img :src="news_post.imageUrl" class="w-full mb-2" />
          </div>
          <div class="mt-5">
            <vue-dropzone
              id="dropzone"
              ref="fileUploadDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              class="p-0 hover:bg-white"
              @vdropzone-file-added="fileAddedToDropZone"
              @vdropzone-removed-file="fileRemovedFromDropZone"
            >
              <div
                class="text-center block border border-black p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer"
              >
                <div class="mb-2">Drag and drop an image file</div>
                <div class="mb-2">or</div>
                <div>
                  <a class="btn-action w-32"> Select Image </a>
                </div>
              </div>
            </vue-dropzone>
          </div>
          <div class="mt-5 text-right">
            <a class="btn-action btn-lg" @click.prevent="saveNewsItem()"> Save News Post </a>
          </div>
          <transition name="fade-in">
            <div v-if="errors.length > 0" class="flex flex-col justify-around mt-3">
              <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-red-600"
                :class="{ 'mt-1': index > 0 }"
              >
                {{ error }}
              </div>
            </div>
          </transition>
        </div>
      </main>
    </transition>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import dateHelper from '@/mixins/dateHelper';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [dateHelper],
  data() {
    return {
      loading: true,
      news_post: {},
      errors: [],
      categories: [],
      saving_post: false,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      new_news_image: null,
    };
  },
  mounted() {
    this.loadCategories();
    this.loadFeed(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      getNewsCategories: 'news/getNewsCategories',
      getNewsItem: 'news/getNewsItem',
      updateNewsItem: 'news/updateNewsItem',
    }),
    async loadCategories() {
      this.categories = await this.getNewsCategories();
    },
    async loadFeed(id) {
      this.news_post = await this.getNewsItem(id);
      this.loading = false;
    },
    async saveNewsItem() {
      this.saving_post = true;

      if (this.new_news_image !== null) {
        this.news_post.image = this.new_news_image;
      }

      await this.updateNewsItem(this.news_post).then(() => {
        this.routerPush('/manage-news');
      });
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.new_news_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async fileAddedToDropZone(uploadedFile) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'bmp', 'tiff', 'tif'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png, svg, gif, bmp, tif',
        });
        return;
      }
      this.createImage(uploadedFile);
    },
    fileRemovedFromDropZone() {
      this.new_news_image = null;
    },
  },
};
</script>
