<template>
  <div class="p-2 h-10 w-full border flex flex-wrap items-center bg-white">
    <div class="pr-2">
      <span>Quote Stages:</span>
    </div>
    <select
      v-model="staffId"
      class="flex-auto font-bold text-right"
      required
      @change="AssignCustomQuoteStage()"
      @click="isActive = !isActive"
    >
      <option :value="0">{{ placeholder }}</option>
      <option v-for="stage in stages" :key="stage.id" class="font-bold" :value="stage.id">
        {{ stage.description }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    contractId: String,
    placeholder: String,
    currentStaffId: Number,
  },
  data() {
    return {
      staffId: 0,
      stages: [],
      isActive: false,
    };
  },
  async mounted() {
    if (this.currentStaffId) {
      this.staffId = this.currentStaffId;
    }
    await this.getQuoteStages();
    if (!this.stages.some((x) => x.id === this.staffId)) {
      this.staffId = 0;
    }
  },
  methods: {
    async AssignCustomQuoteStage() {
      if (this.contractId) {
        await window.touch.AssignCustomQuoteStage(
          this.contractId,
          this.$store.state.user.customer.customerId,
          this.staffId,
        );
      } else {
        this.$emit('setCustomQuoteStageId', this.staffId || undefined);
      }
    },
    async getQuoteStages() {
      this.stages = await window.touch.GetCustomQuoteStatuses(
        this.$store.state.user.customer.companyId,
      );
    },
  },
};
</script>
