export default {
  namespaced: true,
  state: {
    selectedSandbox: null,
  },
  mutations: {
    SET_SANDBOX(state, sandbox) {
      state.selectedSandbox = sandbox;
    },
  },
  actions: {
    loadFromStorage({ commit, state }) {
      if (state.selectedSandbox) {
        commit('SET_SANDBOX', state.selectedSandbox);
      }
    },
    processingUrl({ getters, state }, url) {
      if (getters.sandboxIsActive) {
        return `v1/sandbox/${state.selectedSandbox.id}/${url}`;
      }

      return url;
    },
    async addNoteOnSandbox(context, { sandboxId, contractId, note }) {
      return window.touch.testAddNote(contractId, sandboxId, note);
    },
  },
  getters: {
    sandboxIsActive(state) {
      return window.VUE_APP_INSTALLATION_TYPE === 'business' && state.selectedSandbox !== null;
    },
    name(state) {
      return state.selectedSandbox.name;
    },
  },
};
