import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.getAnomalies = async function getAnomalies(datasetId) {
  return (
    await this.authenticatedPost('vendor/GetAnomalies', {
      DatasetId: datasetId,
      Limit: 500,
      Offset: 0,
    })
  ).data;
};

Touch.prototype.updateAnomalies = async function updateAnomalies(datasetId, anomalies) {
  const Anomalies = anomalies.map((anom) => ({
    Id: anom.id,
    Value: Number(anom.overrideValue),
  }));

  return this.parseResponse (
    await this.authenticatedPost('vendor/UpdateAnomalies', { DatasetId: datasetId, Anomalies }),
    errorCallback,
    () => successCallback('Save')
  )
};

Touch.prototype.getCompanyStyling = async function getCompanyStyling(companyId) {
  return (await this.authenticatedPost(`styling/GetCompanyStyling?companyId=${companyId}`)).data;
};

// the intention should be create a separate endpoint to send this data to.
Touch.prototype.deleteCompanyStyling = async function deleteCompanyStyling(
  companyId,
  enquiryWelcomeImage,
  enquiryCompletionImage,
  virtualHomeBanner,
  virtualHomeBackground,
  portalPageLoginImage,
  portalPageLoginSecondaryImage,
  enquiryPDFFirstImage,
  enquiryPDFLastImage,
  quotePDFFirstImage,
  quotePDFLastImage,
  emailEquiryImage,
) {
  const data = {
    CompanyId: companyId,
    EnquiryPageStyling: {
      WelcomeImage: undefined,
      CompletionImage: undefined,
    },
    VirtualHomePageStyling: {
      BannerImage: undefined,
      BackgroundImage: undefined,
    },
    PortalPageStyling: {
      LoginImage: undefined,
      LoginSecondaryImage: undefined,
    },
    EnquiryReportStyling: {
      FirstPageImage: undefined,
      LastPageImage: undefined,
    },
    QuoteReportStyling: {
      FirstPageImage: undefined,
      LastPageImage: undefined,
    },
    EnquiryEmailStyling: {
      HeaderImage: undefined,
    },
  };
  if (enquiryWelcomeImage) {
    data.EnquiryPageStyling.WelcomeImage = {};
    data.EnquiryPageStyling.WelcomeImage.Data = ''
    data.EnquiryPageStyling.WelcomeImage.Delete = enquiryWelcomeImage;
  }
  if (enquiryCompletionImage) {
    data.EnquiryPageStyling.CompletionImage = {};
    data.EnquiryPageStyling.CompletionImage.Data = ''
    data.EnquiryPageStyling.CompletionImage.Delete = enquiryCompletionImage;
  }
  if (virtualHomeBanner) {
    data.VirtualHomePageStyling.BannerImage = {};
    data.VirtualHomePageStyling.BannerImage.Data = ''
    data.VirtualHomePageStyling.BannerImage.Delete = virtualHomeBanner;
  }
  if (virtualHomeBackground) {
    data.VirtualHomePageStyling.BackgroundImage = {};
    data.VirtualHomePageStyling.BackgroundImage.Data = ''
    data.VirtualHomePageStyling.BackgroundImage.Delete = virtualHomeBackground;
  }
  if (portalPageLoginImage) {
    data.PortalPageStyling.LoginImage = {};
    data.PortalPageStyling.LoginImage.Data = ''
    data.PortalPageStyling.LoginImage.Delete = portalPageLoginImage;
  }
  if (portalPageLoginSecondaryImage) {
    data.PortalPageStyling.LoginSecondaryImage = {};
    data.PortalPageStyling.LoginSecondaryImage.Data = ''
    data.PortalPageStyling.LoginSecondaryImage.Delete = portalPageLoginSecondaryImage;
  }
  if (enquiryPDFFirstImage) {
    data.EnquiryReportStyling.FirstPageImage = {};
    data.EnquiryReportStyling.FirstPageImage.Data = ''
    data.EnquiryReportStyling.FirstPageImage.Delete = enquiryPDFFirstImage;
  }
  if (enquiryPDFLastImage) {
    data.EnquiryReportStyling.LastPageImage = {};
    data.EnquiryReportStyling.LastPageImage.Data = ''
    data.EnquiryReportStyling.LastPageImage.Delete = enquiryPDFLastImage;
  }
  if (quotePDFFirstImage) {
    data.QuoteReportStyling.FirstPageImage = {};
    data.QuoteReportStyling.FirstPageImage.Data = ''
    data.QuoteReportStyling.FirstPageImage.Delete = quotePDFFirstImage;
  }
  if (quotePDFLastImage) {
    data.QuoteReportStyling.LastPageImage = {};
    data.QuoteReportStyling.LastPageImage.Data = ''
    data.QuoteReportStyling.LastPageImage.Delete = quotePDFLastImage;
  }
  if (emailEquiryImage) {
    data.EnquiryEmailStyling.HeaderImage = {};
    data.EnquiryEmailStyling.HeaderImage.Data = ''
    data.EnquiryEmailStyling.HeaderImage.Delete = emailEquiryImage;
  }
  return this.parseResponse(
    await this.authenticatedPost('styling/UpdateCompanyStyling', data),
    errorCallback,
  );
};

Touch.prototype.setCompanyStyling = async function setCompanyStyling(
  companyId,
  enquiryWelcomeImage = undefined,
  enquiryCompletionImage = undefined,
  virtualHomeBanner = undefined,
  virtualHomeBackground = undefined,
  portalPageLoginImage = undefined,
  portalPageLoginSecondaryImage = undefined,
  portalPageLoginHeader = undefined,
  portalPageSubHeader = undefined,
  portalPageLoginWelcomeText = undefined,
  enquiryHeader = undefined,
  enquirySubHeader = undefined,
  enquiryWelcome = undefined,
  enquiryPDFFirstImage = undefined,
  enquiryPDFLastImage = undefined,
  enquiryPdfFooterHeader = undefined,
  enquiryPdfFooterText = undefined,
  quotePDFFirstImage = undefined,
  quotePDFLastImage = undefined,
  emailEquiryImage = undefined,
  enquiryEmailInstallerSubject = undefined,
  enquiryEmailInstallerBodyText = undefined,
  enquiryPageDesignNames = undefined,
  enquiryEmailDealerConfirmationText = undefined,
  enquiryEmailBrandedConfirmationText = undefined,
  siteTitle = undefined,
  thankYouText = undefined,
  thankYouHeadingText = undefined,
  thankYouSubHeadingText = undefined,
) {
  const data = {
    CompanyId: companyId,
    EnquiryPageStyling: {
      WelcomeImage: undefined,
      CompletionImage: undefined,
      WelcomeHeadingText: undefined,
      WelcomeSubHeadingText: undefined,
      WelcomeText: undefined,
      thankYouText: undefined,
      thankYouHeadingText: undefined,
      thankYouSubHeadingText: undefined,
    },
    VirtualHomePageStyling: {
      BannerImage: undefined,
      BackgroundImage: undefined,
    },
    PortalPageStyling: {
      LoginImage: undefined,
      LoginSecondaryImage: undefined,
      LoginHeader: undefined,
      LoginSubHeader: undefined,
      LoginWelcomeText: undefined,
    },
    EnquiryReportStyling: {
      FirstPageImage: undefined,
      LastPageImage: undefined,
      footerHeader: undefined,
      FooterText: undefined,
    },
    QuoteReportStyling: {
      FirstPageImage: undefined,
      LastPageImage: undefined,
    },
    EnquiryEmailStyling: {
      HeaderImage: undefined,
    },
    siteTitle: undefined,
  };
  if (enquiryWelcomeImage) {
    data.EnquiryPageStyling.WelcomeImage = {};
    data.EnquiryPageStyling.WelcomeImage.Data = enquiryWelcomeImage;
  }
  if (enquiryCompletionImage) {
    data.EnquiryPageStyling.CompletionImage = {};
    data.EnquiryPageStyling.CompletionImage.Data = enquiryCompletionImage;
  }
  if (virtualHomeBanner) {
    data.VirtualHomePageStyling.BannerImage = {};
    data.VirtualHomePageStyling.BannerImage.Data = virtualHomeBanner;
  }
  if (virtualHomeBackground) {
    data.VirtualHomePageStyling.BackgroundImage = {};
    data.VirtualHomePageStyling.BackgroundImage.Data = virtualHomeBackground;
  }
  if (portalPageLoginImage) {
    data.PortalPageStyling.LoginImage = {};
    data.PortalPageStyling.LoginImage.Data = portalPageLoginImage;
  }
  if (portalPageLoginSecondaryImage) {
    data.PortalPageStyling.LoginSecondaryImage = {};
    data.PortalPageStyling.LoginSecondaryImage.Data = portalPageLoginSecondaryImage;
  }
  if (portalPageLoginHeader|| portalPageLoginHeader === '') {
    data.PortalPageStyling.LoginHeader = portalPageLoginHeader;
  }
  if (portalPageSubHeader|| portalPageSubHeader === '') {
    data.PortalPageStyling.LoginSubHeader = portalPageSubHeader;
  }
  if (portalPageLoginWelcomeText|| portalPageLoginWelcomeText === '') {
    data.PortalPageStyling.LoginWelcomeText = portalPageLoginWelcomeText;
  }
  if (enquiryHeader || enquiryHeader === '') {
    data.EnquiryPageStyling.WelcomeHeadingText = enquiryHeader;
  }
  if (enquirySubHeader || enquirySubHeader === '') {
    data.EnquiryPageStyling.WelcomeSubHeadingText = enquirySubHeader;
  }
  if (enquiryWelcome || enquiryWelcome === '') {
    data.EnquiryPageStyling.WelcomeText = enquiryWelcome;
  }
  if (thankYouSubHeadingText || thankYouSubHeadingText === '') {
    data.EnquiryPageStyling.thankYouSubHeadingText = thankYouSubHeadingText;
  }
  if (thankYouHeadingText || thankYouHeadingText === '') {
    data.EnquiryPageStyling.thankYouHeadingText = thankYouHeadingText;
  }
  if (thankYouText || thankYouText === '') {
    data.EnquiryPageStyling.thankYouText = thankYouText;
  }
  if (enquiryPDFFirstImage) {
    data.EnquiryReportStyling.FirstPageImage = {};
    data.EnquiryReportStyling.FirstPageImage.Data = enquiryPDFFirstImage;
  }
  if (enquiryPDFLastImage) {
    data.EnquiryReportStyling.LastPageImage = {};
    data.EnquiryReportStyling.LastPageImage.Data = enquiryPDFLastImage;
  }
  if (enquiryPdfFooterHeader || enquiryPdfFooterHeader === '') {
    data.EnquiryReportStyling.footerHeading = enquiryPdfFooterHeader;
  }
  if (enquiryPdfFooterText || enquiryPdfFooterText === '') {
    data.EnquiryReportStyling.footerText = enquiryPdfFooterText;
  }
  if (quotePDFFirstImage) {
    data.QuoteReportStyling.FirstPageImage = {};
    data.QuoteReportStyling.FirstPageImage.Data = quotePDFFirstImage;
  }
  if (quotePDFLastImage) {
    data.QuoteReportStyling.LastPageImage = {};
    data.QuoteReportStyling.LastPageImage.Data = quotePDFLastImage;
  }
  if (emailEquiryImage) {
    data.EnquiryEmailStyling.HeaderImage = {};
    data.EnquiryEmailStyling.HeaderImage.Data = emailEquiryImage;
  }
  if (enquiryEmailInstallerSubject || enquiryEmailInstallerSubject === '') {
    data.EnquiryEmailStyling.InstallerSubject = enquiryEmailInstallerSubject;
  }
  if (enquiryEmailInstallerBodyText || enquiryEmailInstallerBodyText === '') {
    data.EnquiryEmailStyling.InstallerBodyText = enquiryEmailInstallerBodyText;
  }
  if (enquiryPageDesignNames !== undefined) {
    data.EnquiryPageStyling.ShowDesignNames = enquiryPageDesignNames;
  }
  if (enquiryEmailDealerConfirmationText || enquiryEmailDealerConfirmationText === '') {
    data.EnquiryEmailStyling.DealerConfirmationText = enquiryEmailDealerConfirmationText;
  }
  if (enquiryEmailBrandedConfirmationText || enquiryEmailBrandedConfirmationText === '') {
    data.EnquiryEmailStyling.BrandedConfirmationText = enquiryEmailBrandedConfirmationText;
  }

  if (siteTitle) {
    data.siteTitle = siteTitle;
  }

  return this.parseResponse(
    await this.authenticatedPost('styling/UpdateCompanyStyling', data),
    errorCallback,
    () => successCallback('Save')
  );
};
