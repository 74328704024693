<template>
  <router-link :to="jobUrl" class="line-item">
    <div class="line-item-header">
      <div class="_header-attributes-group">
        <div class="_header-attribute">
          <div class="_label">#</div>
          <div class="_description">{{ jobNumber }}</div>
        </div>
        <div class="_header-attribute">
          <div class="_label">Reference</div>
          <div class="_description">{{ job.reference }}</div>
        </div>
      </div>
      <div class="_header-attributes-group"></div>
      <div class="_header-attributes-group">
        <div v-if="showPrice" class="_header-attribute _item-price">
          <div class="_label">Total</div>
          <div
            class="_description"
            v-html="formatCurrency(touchJob.currency(), touchJob.itemCost())"
          ></div>
        </div>
        <div class="_header-attribute _item-no">
          <div class="_label">Items</div>
          <div class="_description">{{ job.itemCount }}</div>
        </div>
        <div class="_header-attribute">
          <a class="btn md:w-48 mr-1" @click.prevent.stop="$emit('pdf-survey-request')">
            Download Survey Sheet
          </a>
        </div>
        <div v-if="!isLeadGen" class="_header-attribute">
          <a class="btn-action md:w-48" @click.prevent.stop="$emit('order-job')">
            Convert to Quote
          </a>
        </div>
        <div class="_show-detail-control">
          <i class="fa fa-caret-right"></i>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import currencyHelper from '@/mixins/currencyHelper';
import jobHelper from '@/mixins/jobHelper';

export default {
  mixins: [currencyHelper, jobHelper],
  props: ['jobNumber', 'totalItems', 'job', 'showPrice'],
  data() {
    return {
      other_actions_dropdown_open: false,
    };
  },
  computed: {
    jobUrl() {
      return `${this.$route.params.id}/${this.job.jobKey}`;
    },
    isLeadGen() {
      return (
        this.$store.state.style.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};
</script>
