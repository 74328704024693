<template>
  <touch-layout>
    <template v-if="quote && $refs.quoteComponent.job" #header_page_breadcrumb>
      <Breadcrumbs
        v-if="quote"
        :routes="[
          ['Dashboard', '/'],
          ['Quotes', '/quotes'],
          [
            '#' +
              quote.customerCounter +
              (quote.jobs.length > 1 ? '- ' + $refs.quoteComponent.job.jobKey : ''),
          ],
        ]"
      />
    </template>
    <template v-if="quote && $refs.quoteComponent.job" #header_page_title>
      #{{ quote.customerCounter
      }}<span v-show="quote.jobs.length > 1"> - {{ $refs.quoteComponent.job.jobKey }}</span>
    </template>
    <template v-if="quote && $refs.quoteComponent.job" #header_page_actions>
      <div class="flex md:px-10 items-center gap-5">
        <div class="hidden xs:block">
          <span class="font-medium mr-2">Reference</span>
          <span class="text-brand-primary whitespace-no-wrap">
            {{ quote.reference }}
            <span v-show="quote.jobs.length > 1">({{ $refs.quoteComponent.job.reference }})</span>
          </span>
        </div>
        <div v-if="quote.childContractIds && quote.childContractIds.length > 0">
          <router-link :to="`/order/${quote.childContractIds[0]}`" class="btn-action">
            View Order
          </router-link>
        </div>
      </div>
    </template>
    <QuoteComponent
      ref="quoteComponent"
      breakdown="Cost"
      :can-skip-sop="canSkipSop"
      :customer="$store.state.user.customer"
      :is-costs-hidden="isCostsHidden"
      @setVisibility="setVisibility"
      @redirectToBasket="redirectToBasket"
      @redirectToCheckout="redirectToCheckout"
      @setQuote="setQuote"
    >
      <template #locked_by_user> This order is locked by you </template>
      <template #consumer_details>
        <div key="consumer-details" data-index="0" class="bg-white p-3 border">
          <consumer-details
            :read-only="$refs.quoteComponent.readOnly"
            :consumer="$refs.quoteComponent.quote.consumer"
            @edit="$refs.quoteComponent.edit_consumer_open = true"
          ></consumer-details>
        </div>
      </template>
      <template #portal_ctas>
        <div
          v-if="quote && $refs.quoteComponent.job"
          key="action-btns"
          class="flex flex-col xs:flex-row gap-3 flex-wrap mt-3"
        >
          <div v-if="!quote.finalised" class="flex-1">
            <a
              class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click="$refs.quoteComponent.showSurveyPdf()"
            >
              Download Survey Sheet
              <i class="fa fa-file-download text-sm"></i>
              <info-popup
                :id="'job:download_survey_sheet'"
                class="-m-3 absolute right-0 top-0"
                :info_message="'If you wish to carry out a survey, you can download and print a copy of the quote to add technical details. These can then be added to the quote via the edit button.'"
                :next_id="'job:generate_customer_quote'"
              >
              </info-popup>
            </a>
          </div>
          <div class="flex-1">
            <a
              class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click.prevent="
                other_actions_dropdown_open = false;
                $refs.quoteComponent.downloadRequoteReport();
              "
            >
              <span>Download Customer Quote</span>
              <i class="fa fa-file-download text-sm"></i>
              <info-popup
                :id="'job:generate_customer_quote'"
                class="-m-3 absolute right-0 top-0"
                :info_message="'This allows you to add a price uplift to your quote, this can be added to the overall quote or to each product line item. You can also add a fitting cost. You can then download the quote with these prices to send to your customer.'"
                :next_id="'job:download_price_request'"
              >
              </info-popup>
            </a>
          </div>
          <div v-if="quote.consumer && !$store.state.auth.accountSettings.emailOff" class="flex-1">
            <a
              class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click.prevent="
                $refs.quoteComponent.other_actions_dropdown_open = false;
                $refs.quoteComponent.send_customer_quote_modal_open = true;
              "
            >
              <span>Send Customer Quote</span>
              <i class="fa fa-file-download text-sm"></i>
            </a>
          </div>
          <div v-else-if="!$store.state.auth.accountSettings.emailOff" class="flex-1">
            <a
              class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click.prevent="$refs.quoteComponent.open_swal_warning_for_no_consumer"
            >
              <span>Send Customer Quote</span>
              <i class="fa fa-file-download text-sm"></i>
            </a>
          </div>
          <div v-if="!quote.visibleToAdmin && quote.visibleToAdmin !== undefined" class="flex-1 p-3">
            <a
              class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
              @click.prevent="$refs.quoteComponent.toggleVisibility()"
            >
              <span>Show To Admin</span>
              <i class="fa fa-eye text-sm"></i>
            </a>
          </div>
        </div>
      </template>
      <template #quoted_at_price>
        <quoted-at-price
          v-if="quote"
          :job="$refs.quoteComponent.job"
          @edit-requote="$refs.quoteComponent.requoter_open = true"
        ></quoted-at-price>
      </template>
      <template #cost_toggle>
        <div class="flex items-center cursor-pointer" @click="isCostsHidden = !isCostsHidden">
          <div class="relative">
            <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
            <div class="switch-toggle" :class="{'_active': !isCostsHidden }"></div>
          </div>
          <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
            <span v-text="isCostsHidden ? 'Costs Off' : 'Costs On'"></span>
          </div>
        </div>
      </template>
      <template #customer_notes="slotProps">
        <div class="flex flex-col">
          <div class="bg-white border p-3 h-full">
            <show-note
              :read-only="slotProps.isReadOnlyNote"
              :contract-id="slotProps.contractId"
              :job-id="slotProps.jobId"
              :note-type="slotProps.noteType"
              :note="slotProps.note"
              @loadNotes="$refs.quoteComponent.loadNotes"
            >
              Customer Notes
            </show-note>
          </div>
        </div>
      </template>
      <template #internal_notes="slotProps">
        <div class="flex flex-col">
          <div class="bg-white border p-3 h-full">
            <show-note
              :read-only="slotProps.isReadOnlyNote"
              :contract-id="slotProps.contractId"
              :job-id="slotProps.jobId"
              :note-type="slotProps.noteType"
              :note="slotProps.note"
              @loadNotes="$refs.quoteComponent.loadNotes"
            >
              Internal Notes
            </show-note>
          </div>
        </div>
      </template>
    </QuoteComponent>
  </touch-layout>
</template>

<script>
import QuoteComponent from '@/components/shared/Quote.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ConsumerDetails from '@/components/shared/job/ConsumerDetails.vue';
import QuotedAtPrice from '@/components/shared/job/QuotedAtPrice.vue';
import ShowNote from '@/components/shared/ShowNote.vue';

export default {
  components: {
    QuoteComponent,
    Breadcrumbs,
    ConsumerDetails,
    QuotedAtPrice,
    ShowNote,
  },
  data() {
    return {
      quote: undefined,
      isCostsHidden: true,
    };
  },
  computed: {
    canSkipSop() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
  },
  methods: {
    setQuote(quote) {
      this.quote = quote;
    },
    async setVisibility() {
      const res = await window.touch.contractUpdateVisibility(this.order.id, {
        visibleToAdmin: !this.order.visibleToAdmin,
      });
      if (res.status === 200) {
        this.order.visibleToAdmin = !this.order.visibleToAdmin;
        window.alertBox.fire('Order is now shown to admin');
      }
    },
    redirectToBasket() {
      this.routerPush('/basket');
    },
    redirectToCheckout() {
      this.routerPush('/basket');
    },
  },
};
</script>
