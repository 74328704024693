import ChooseProduct from '@/views/Branded/ChooseProduct.vue';
import Designer from '@/views/Branded/Designer.vue';
import Index from '@/views/Branded/Basket.vue';
import ThankYou from '@/views/Branded/ThankYou.vue';
import VisualiserUpload from '@/views/Shared/Visualiser.vue';
import VisualiserPlace from '@/views/Shared/Visualiser/Place.vue';

const routes = [
  {
    path: '/:slug/choose-product',
    name: 'Retail Choose Product',
    component: ChooseProduct,
  },
  {
    path: '/:slug/design/:id',
    name: 'Retail Designer',
    component: Designer,
  },
  {
    path: '/:slug/basket',
    name: 'Retail Basket',
    component: Index,
  },
  {
    path: '/:slug/thanks',
    name: 'Thank You',
    component: ThankYou,
  },
  {
    path: '/:slug/visualiser',
    name: 'visualiser',
    meta: { showSidebar: false, vendorLayout: true },
    component: VisualiserUpload,
  },
  {
    path: '/:slug/visualiser/place',
    name: 'visualiser-place',
    meta: { showSidebar: false, vendorLayout: true },
    component: VisualiserPlace,
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, brandedModeRoute: true };
  return { ...route, meta };
});
