<template>
  <aside
    class="bg-white shadow-xl sm:w-1/3 scrolling-touch sm:p-5 xl:p-10 sm:flex-grow sm:relative z-10"
  >
    <div class="xs:p-5 md:p-10 m-auto">
      <div class="hidden sm:block">
        <div class="font-medium">{{ breakdown }} Summary</div>

        <div class="mb-4">
          Basket showing {{ basketQty }} item<span v-if="basketQty > 1">s</span>
        </div>
        
        <div v-if="delivery && !$store.state.basket?.job?.overrideCalculatedPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Delivery Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, delivery)"></div>
        </div>

        <div v-if="fittingPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Fitting Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, fittingPrice)"></div>
        </div>

        <div v-if="surveyPrice" class="flex border-t border-gray-300 p-6 mt-6">
          <div>Survey Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, surveyPrice)"></div>
        </div>

        <div v-if="$store.state.basket?.job?.overrideCalculatedPrice">
          <div v-if="$store.state.basket?.job?.discountApplied">
            <div class="border-t border-gray-300 p-6">
              <div class="flex">
                <div>Subtotal</div>
                <div class="ml-auto" v-html="formatCurrency(basketCurrency, $store.state.basket?.job?.calculatedPriceTaxable)"></div>
              </div>
            </div>
            <div class="border-t border-gray-300 p-6">
              <div class="flex">
                <div>Discount ({{ $store.state.basket.job.discountCode }})</div>
                <span class="ml-auto">-{{ formatCurrency(basketCurrency, discount) }}</span>
              </div>
            </div> 
          </div>
          <div class="border-t border-gray-300 p-6">
            <div class="flex">
              <div>Subtotal <span v-if="$store.state.basket?.job?.discountApplied">(Discounted)</span></div>
              <div class="ml-auto" v-html="formatCurrency(basketCurrency, $store.state.basket?.job?.overridePriceTaxable)"></div>
            </div>
          </div>
          <div class="flex border-t border-grat-600 p-6">
            <div>Delivery Charge</div>
            <div class="ml-auto" v-html="formatCurrency(basketCurrency, delivery)"></div>
          </div>
        </div>
        <div v-else-if="salesSectorType !== enums.salesSectorType.RETAIL" class="border-t border-gray-300 p-6">
          <div class="flex">
            <div>Subtotal</div>
            <div class="ml-auto" v-html="formatCurrency(basketCurrency, subtotal)"></div>
          </div>
        </div>

        <div class="flex border-t border-gray-300 p-6">
          <div>VAT</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, vat)"></div>
        </div>

        <div class="flex border-t border-b border-gray-300 p-6 font-medium">
          <div>Total</div>
          <div class="ml-auto" v-html="formatCurrency(basketCurrency, total)"></div>
        </div>
      </div>
      <div class="m-3 sm:m-0 sm:mb-6">
        <div class="mt-6 mb-2">Reference</div>
        <input
          v-model="reference"
          type="text"
          class="form-control"
          :class="{ 'border-red-600': referenceMissing }"
        />
        <div v-if="referenceMissing" class="text-red-600 mt-3">Please enter a reference</div>
        <slot name="voucher"></slot>
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a v-if="!isOrder" class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          Log Order With {{ style.fabricator_name }}
        </a>
        <a v-else class="btn-action btn-lg w-full" @click.prevent="placeOrder()">
          <span
            v-if="$store.state.basket.isPaymentGateway &&
              [enums.PaymentStatus.AWAITING, enums.PaymentStatus.FAILED].includes(contractData?.paymentStatus)"
          >
            <span v-if="touch_portal_installation">Make Payment</span>
            <span v-else>Place Order as Pending Payment</span>
          </span>
          <span v-else> Charge to Account With {{ style.fabricator_name }} </span>
        </a>
      </div>
      <div class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col">
        <a class="btn btn-lg w-full" @click.prevent="cancelOrder()"> Cancel </a>
      </div>

      <!--<img src="/images/card-images.png" class="w-64 mt-3 mx-auto">-->
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [currencyHelper],
  props: {
    breakdown: String,
    customer: Object
  },
  data() {
    return {
      referenceMissing: false,
    };
  },
  computed: {
    reference: {
      get() {
        return this.$store.state.basket.reference;
      },
      set(value) {
        this.$store.commit('basket/setReference', value);
      },
    },
    ...mapState('basket', {
      basketItems: 'basket',
      isOrder: 'isOrder',
      contractData: 'contractData',
      salesSectorType: 'salesSectorType',
    }),
    ...mapGetters({
      total: 'basket/total',
      vat: 'basket/vat',
      subtotal: 'basket/subtotal',
      basketCurrency: 'basket/getCurrency',
      basketQty: 'basket/basketQty',
      fittingPrice: 'basket/fittingPrice',
      surveyPrice: 'basket/surveyPrice',
      delivery: 'basket/delivery',
    }),
    discount() {
      const total = this.$store.state.basket?.job?.calculatedPriceTaxable + this.delivery + this.surveyPrice + this.fittingPrice
      const discount = total - this.subtotal
      return discount
    }
  },
  methods: {
    cancelOrder() {
      this.$emit('cancel-order');
    },
    placeOrder() {
      if (this.reference.trim().length < 1) {
        this.referenceMissing = true;
        return;
      }
      this.referenceMissing = false;

      if (this.customer?.financial?.paymentWithOrder && !this.touch_portal_installation) {
        this.alertBox()
          .fire({
            title: "Order will be marked as 'Placed (Pending Payment)'",
            text: 'Customer will need to login and pay for the order.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('place-order');
            }
          });
      } else {
        this.$emit('place-order');
      }
    },
  },
};
</script>
