<template>
  <div>
    <transition name="fade-in" mode="out-in">
      <loading
        v-if="placing_order"
        :key="1"
        class="flex flex-col h-screen"
        :loading="true"
        :label="loading_label"
        style="flex: 1 1 0"
      ></loading>
      <div v-else-if="basketItems" class="flex flex-col sm:flex-row h-screen overflow-hidden relative">
        <main
          class="bg-gray-200 w-full flex-grow sm:w-2/3 pb-20 sm:pb-10 overflow-y-auto overflow-x-hidden scrolling-touch"
        >
          <animation-staggered-slide appear class="flex flex-col xs:p-10 xs:pb-40 relative">
            <div
              key="mobile_price_summary"
              class="bg-white shadow-lg rounded-lg p-10 mb-6 xs:mb-10 sm:hidden"
              data-index="0"
            >
              <div class="font-medium">{{ breakdown }} Summary</div>

              <div class="mb-4">
                Basket showing {{ basketQty }} item<span v-if="basketQty > 1">s</span>
              </div>

              <div class="flex border-t border-gray-300 p-6 mt-6">
                <div>Subtotal</div>
                <div class="ml-auto" v-html="formatCurrency(basketCurrency, subtotal)"></div>
              </div>
              <div class="flex border-t border-gray-300 p-6">
                <div>VAT</div>
                <div class="ml-auto" v-html="formatCurrency(basketCurrency, vat)"></div>
              </div>
              <div class="flex border-t border-b border-gray-300 p-6 font-medium">
                <div>Total</div>
                <div class="ml-auto" v-html="formatCurrency(basketCurrency, total)"></div>
              </div>
            </div>
            <div key="0" class="bg-white shadow-lg rounded-lg p-6 xs:p-10" data-index="0">
              <div class="border-b border-gray-200 pb-3 mb-3 text-lg">
                <i class="fal fa-check text-green-600 mr-3 hidden 2xs:inline"></i>
                1. Delivery Address
              </div>
              <div class="flex flex-col gap-5 p-5 max-w-4xl">
                <div class="gap-3 flex items-center justify-between">
                  <div>Delivery Address</div>
                  <div>
                    <a
                      v-if="!editing_delivery_address"
                      class="ml-4 btn"
                      @click="editing_delivery_address = true"
                    >
                      Add new address <i class="fal fa-pencil ml-3"></i>
                    </a>
                    <a v-else class="ml-4 btn" @click="editing_delivery_address = false">Cancel</a>
                  </div>
                </div>
                <edit-address v-if="editing_delivery_address" @dismiss="editing_delivery_address = false"></edit-address>
                <select-address v-else></select-address>
              </div>
            </div>

            <div
              v-if="!editing_delivery_address"
              key="1"
              class="bg-white shadow-lg rounded-lg p-6 xs:p-10 mt-6 xs:mt-10 z-10"
              data-index="1"
            >
              <div class="flex items-center gap-3 border-b border-gray-200 pb-3 mb-3 text-lg">
                <i class="fal fa-check text-green-600 hidden 2xs:inline"></i> 
                2. Requested Delivery Date and Notes
              </div>
              <div class="grid xs:grid-cols-2 gap-5 xs:gap-10 p-5 max-w-4xl">
                <div class="flex flex-col gap-2">
                  <div>Requested Delivery Date <span class="text-red-600">*</span></div>
                  <div>
                    <datepicker
                      v-model="delivery_date"
                      input-class="form-control"
                      style="width: 100% !important"
                      :disabled-date="disabled_delivery_dates"
                      :disabled-calendar-changer="disabled_delivery_dates2"
                      :default-value="nextAvailableDate()"
                    ></datepicker>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <div>Delivery Notes</div>
                  <input v-model="delivery_notes" type="text" class="form-control" />
                </div>
                <div class="flex flex-col gap-2">
                  <div>Supplier Notes</div>
                  <input v-model="supplierNotes" type="text" class="form-control" />
                </div>
                <div class="flex flex-col gap-2">
                  <div>PO Number</div>
                  <input v-model="po_number" class="form-control" />
                </div>
              </div>
            </div>
            <slot name="order-review"></slot>
          </animation-staggered-slide>
        </main>

        <transition appear name="fade-in">
          <checkout-pricing
            :breakdown="breakdown"
            :customer="customer"
            @cancel-order="$emit('cancelOrder')"
            @place-order="placeOrder()"
          >
            <template #voucher>
              <slot name="voucher" type="order" :override="$store.state.basket?.job?.overrideCalculatedPrice"></slot>
            </template>
          </checkout-pricing>
        </transition>
      </div>
    </transition>
    <form ref="theForm" method="post" class="opacity-0" :action="paymentRequest.paymentUrl">
      <input
        v-for="(input, index) in paymentRequest.fields"
        :key="input.id"
        type="hidden"
        :name="index"
        :value="input"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
// import Datepicker from 'vuejs-datepicker';
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import CheckoutPricing from '@/components/shared/checkout/CheckoutPricing.vue';
import EditAddress from '@/components/shared/checkout/EditAddress.vue';
import SelectAddress from '@/components/shared/checkout/SelectAddress.vue';
import currencyHelper from '@/mixins/currencyHelper';
import validate from 'validate.js';

export default {
  components: {
    Datepicker,
    'checkout-pricing': CheckoutPricing,
    'edit-address': EditAddress,
    'select-address': SelectAddress,
  },
  mixins: [currencyHelper],
  props: {
    breakdown: String,
    customer: Object,
    warnings_checked: Boolean
  },
  data() {
    return {
      terms_checked: false,
      show_errors: false,
      placing_order: false,
      showSummary: false,
      showImage: false,
      itemIndex: undefined,
      loadedItem: undefined,
      editing_delivery_address: false,
      termsDocumentUrl: '',
      privacyPolicyDocumentUrl: '',
      loading: true,
      paymentRequest: {},
    };
  },
  computed: {
    ...mapState('basket', {
      basketItems: 'basket',
      isOrder: 'isOrder',
      contractData: 'contractData',
    }),
    ...mapGetters({
      total: 'basket/total',
      vat: 'basket/vat',
      subtotal: 'basket/subtotal',
      availableDeliveryDates: 'basket/availableDeliveryDates',
      basketCurrency: 'basket/getCurrency',
      basketQty: 'basket/basketQty',
      addressId: 'basket/selected_address_id',
    }),
    delivery_address: {
      get() {
        return this.$store.state.basket.deliveryAddress;
      },
    },
    po_number: {
      get() {
        return this.$store.state.basket.poNumber;
      },
      set(value) {
        this.$store.commit('basket/setPONumber', value);
      },
    },
    delivery_notes: {
      get() {
        return this.$store.state.basket.deliveryNotes;
      },
      set(value) {
        this.$store.commit('basket/setDeliveryNotes', value);
      },
    },
    supplierNotes: {
      get() {
        return this.$store.state.basket.supplierNotes;
      },
      set(value) {
        this.$store.commit('basket/setSupplierNotes', value);
      },
    },
    delivery_date: {
      get() {
        return this.$store.state.basket.deliveryDate;
      },
      set(value) {
        this.$store.commit('basket/setDeliveryDate', value);
      },
    },
    loading_label() {
      if (this.$store.state.basket.isPaymentGateway && [this.enums.PaymentStatus.AWAITING, this.enums.PaymentStatus.FAILED].includes(this.contractData.paymentStatus)) {
        if (this.touch_portal_installation) {
          return 'Redirecting to payment gateway'
        } else {
          return 'Placing order as pending payment'
        }
      } else {
        return 'Placing your order'
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('basket/setPONumber', '');
    this.$store.commit('basket/setDeliveryNotes', '');
    this.$store.commit('basket/setSupplierNotes', '');
    this.$store.commit('basket/setDeliveryDate', '');
  },
  async mounted() {
    this.$store.commit('basket/setDeliveryDate', '');
    const documents = await this.getDocsToAccept();
    const terms = documents.filter((document) => document.type === 1)[0];
    const privacyPolicy = documents.filter((document) => document.type === 2)[0];

    if (terms) {
      this.termsDocumentUrl = terms.url;
      if (!this.termsDocumentUrl.includes('//')) {
        this.termsDocumentUrl = `http://${this.termsDocumentUrl}`;
      }
    }

    if (privacyPolicy) {
      this.privacyPolicyDocumentUrl = privacyPolicy.url;
      if (!this.privacyPolicyDocumentUrl.includes('//')) {
        this.privacyPolicyDocumentUrl = `http://${this.privacyPolicyDocumentUrl}`;
      }
    }
    const contract = await this.$store.dispatch('basket/getContract');
    await window.touch.processingUpdateJobDelivery(
      this.$store.state.basket.customerId, contract.contractId, contract.jobKey, { AddressId: parseInt(this.addressId, 10) }
    );
    await this.$store.dispatch('basket/refresh', contract);
    await this.$store.dispatch('basket/refreshDelivery', contract);

    if (contract.existsInBusinessStore) {
      const supplierNote = await this.$store.dispatch('touch/loadNote', {
        contractId: contract.contractId,
        jobId: contract.jobKey,
        noteType: this.enums.noteType.SUPPLIER,
      });

      if (supplierNote) {
        this.supplierNotes = supplierNote.text;
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getDocsToAccept: 'touch/getDocsToAccept',
    }),
    disabled_delivery_dates(date) {
      return !this.availableDeliveryDates.includes(moment(date).format('DD/MM/YYYY'));
    },
    disabled_delivery_dates2(date, why) {
      const dates = this.availableDeliveryDates.map((dateString) =>
        moment(dateString, 'DD/MM/YYYY'),
      );
      let maxDate = moment(Math.max(...dates)).endOf('month');
      let minDate = moment(Math.min(...dates)).startOf('month');

      if (why === 'year') {
        maxDate = maxDate.endOf('year');
        minDate = minDate.startOf('year');
      }

      return date < minDate || date > maxDate;
    },
    nextAvailableDate() {
      let date = new Date();
      if (this.availableDeliveryDates.length > 0) {
        const dateMomentObject = moment(this.availableDeliveryDates[0], 'DD/MM/YYYY');
        date = dateMomentObject.toDate();
      }
      return date;
    },
    async placeOrder() {
      const validationErrors = this.inputIsValid();
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.terms_checked && this.warnings_checked) {
        if (this.$store.state.basket.isPaymentGateway && this.touch_portal_installation) {
          await this.processPlaceOrder(false);
          this.redirectToPayment();
        } else if (this.isOrder) {
          await this.processPlaceOrder();
        } else {
          this.alertBox()
            .fire({
              title: 'Order on hold',
              text: 'To formally place the order use the Confirm Customer Order button in the order page.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                await this.processPlaceOrder();
              }
            });
        }
      } else {
        this.show_errors = true;
        document.getElementById('show_terms').scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    async processPlaceOrder(isLazySaving = true) {
      this.placing_order = true;
      return this.$store
        .dispatch('basket/saveOrder', isLazySaving)
        .then(() => {
          this.$store.commit('basket/setReference', null);
          this.$store.commit('basket/setDeliveryDate', null);
          this.$store.commit('basket/setPONumber', '');
          this.$store.commit('basket/setDeliveryNotes', null);
          this.$store.commit('basket/setSupplierNotes', '');
          if (
            !this.$store.state.basket.isPaymentGateway ||
            (this.$store.state.basket.isPaymentGateway && this.touch_business_installation)
          ) {
            this.$emit('redirectProcessPlaceOrder', { isSuccess: true });
          }
        })
        .catch((error) => {
          this.alertBox()
            .fire({
              title: 'Error',
              text: error,
            })
            .then(() => {
              this.placing_order = false;
              this.$emit('redirectProcessPlaceOrder', { isSuccess: false });
            });
        });
    },
    async redirectToPayment() {
      await window.touch
        .CreatePaymentRequest(this.contractData.id, 1)
        .then((response) => {
          this.paymentRequest = response;
          if (this.paymentRequest.url) {
            window.location.href = this.paymentRequest.url;
          } else {
            this.$nextTick(() => {
              this.$refs.theForm.submit();
            });
          }
        })
        .catch((error) => {
          this.alertBox().fire({
            title: error,
            icon: 'error',
          });
          this.$router.push(`/order/${this.contractData.id}`);
        });
    },
    inputIsValid() {
      return validate(
        {
          requested_delivery_date: this.delivery_date,
        },
        {
          requested_delivery_date: {
            presence: { allowEmpty: false },
          },
        },
      );
    },
    async showSummaryPanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = index;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: index,
        });
      }
      this.showSummary = true;
      this.showImage = false;

      this.$nextTick(() => {
        this.$refs['basket-summary'].$el.focus();
      });
    },
    hideSummaryPanel() {
      this.showSummary = false;
    },
    async showImagePanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = index;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: index,
        });
      }
      this.showImage = true;
      this.showImage = true;
      this.showSummary = false;

      // this.$nextTick(() => {
      //   this.$refs['basket-image'].$el.focus();
      // });
    },
    hideImagePanel() {
      this.showImage = false;
    },
  },
};
</script>
