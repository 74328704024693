<template>
  <touch-layout :page_override_class="{ 'trade-designer-choose-product': true }">
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Choose Product'], [selectedProductName]]" />
    </template>
    <template #site_header_actions_wrapper>
      <div v-if="selectedProductName" class="flex border-b flex-1 h-full">
        <div
          v-if="$refs.chooseProduct"
          class="flex flex-row flex-wrap bg-white md:px-5 border-t-4 border-white h-full"
        >
          <div
            class="cursor-pointer border-b-4 py-2 px-1 mx-3 md:mx-5 hover:border-black mt-auto"
            :class="{ 'border-black': !stock_parts_mode, 'border-white': stock_parts_mode }"
            @click="$refs.chooseProduct.setMode('products')"
          >
            Products
          </div>
          <div
            v-if="license.status"
            class="cursor-pointer border-b-4 py-2 px-1 mx-3 md:mx-5 hover:border-black mt-auto"
            :class="{ 'border-black': stock_parts_mode, 'border-white': !stock_parts_mode }"
            @click="$refs.chooseProduct.setMode('stock-parts')"
          >
            Stock Parts
          </div>
        </div>
      </div>
    </template>
    <ChooseProduct
      ref="chooseProduct"
      :url="url"
      @selectedProductName="selectedProductName = $event"
      @setStockParts="stock_parts_mode = $event"
    />
  </touch-layout>
</template>

<script>
import ChooseProduct from '@/components/shared/chooseProduct/ChooseProduct.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapState } from 'vuex';

export default {
  components: {
    ChooseProduct,
    Breadcrumbs,
  },
  data() {
    return {
      selectedProductName: undefined,
      stock_parts_mode: false,
      url: '',
    };
  },
  computed: {
    ...mapState('stockParts', {
      license: 'license',
    }),
  },
  async created() {
    const OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
    if ((typeof this.license.lastChecked === 'undefined') || new Date(this.license.lastChecked) < OneDay) {
      const STOCK_PARTS_LICENSE = 30
      const { data } = await window.touch.isProductAvailable(STOCK_PARTS_LICENSE);
      this.license.status = data.available
      this.license.lastChecked = new Date()
    }
    
    if (this.$route.query.search) {
      this.$router.replace({ ...this.$route.query, search: undefined }).catch(() => {});
    }
  },
};
</script>
