<template>
  <div>
    <slot name="custom-installer-email"></slot>
    <slot name="text-area-confirmation-text"></slot>
  </div>
</template>

<script>
export default {
  props: {
    setup: String,
  },
};
</script>

<style scoped></style>
