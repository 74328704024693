<template>
  <button class="visualiser-place-image-tile" @click="selectImage()">
    <div class="truncate pb-2" style="width: 150px" v-text="image.reference" />
    <div class="bg-gray-200 p-3 flex-grow flex flex-col justify-center" style="width: 150px">
      <api-img :src="image.url" class="mx-auto" />
    </div>
  </button>
</template>

<script>
export default {
  props: ['image'],
  methods: {
    selectImage() {
      this.$store.commit('visualiser/selectImage', this.image.id);
    },
  },
};
</script>
