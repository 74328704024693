<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Stripe'],
        ]"
      />
    </template>
    <template #header_page_title> Stripe Payment Gateway </template>
    <Menu></Menu>
    <template #header_page_action>
      <div v-if="account" class="flex items-center">
        <div v-if="account.payoutsEnabled">
          <strong class="p-2 bg-green-400 text-white uppercase">SETUP COMPLETE</strong>
        </div>
        <div v-else>
          <strong class="p-2 bg-red-400 text-white uppercase">SETUP INCOMPLETE</strong>
        </div>
      </div>
    </template>
    <div v-if="account" class="p-8 bg-gray-200 h-screen">
      <div v-if="isRedirected">
        <div class="bg-white p-6 flex items-center mb-4 flex items-center border-2 border-red-400">
          <i class="fa fa-exclamation-circle mr-2 text-red-400"></i>
          <span>Refresh page after making any changes.</span>
        </div>
      </div>
      <div v-if="account.payoutsEnabled">
        <div class="bg-white p-6 flex flex-col gap-5 xs:flex-row items-center">
          <span>Follow the link to manage your Stripe account.</span>
          <span class="btn-action xs:ml-auto" @click="redirectToStripe()">Manage Stripe Account</span>
        </div>
      </div>
      <div v-else>
        <div class="bg-white p-6 flex items-center">
          <span>Follow the link to finish Stripe setup.</span>
          <span class="btn-action ml-auto" @click="redirectToStripe()">Finish Stripe Setup</span>
        </div>
      </div>
      <div class="grid mt-4 xs:grid-cols-2 gap-3">
        <div v-if="account.id" class="bg-white p-6">
          <div>
            <span>Account Identifier</span>
          </div>
          <div>
            <span class="text-xl">{{ account.id }}</span>
          </div>
        </div>
        <div v-if="account.type" class="bg-white p-6">
          <div>
            <span>Account Type</span>
          </div>
          <div>
            <span class="text-xl">{{ account.type }}</span>
          </div>
        </div>
        <div v-if="account.businessProfile.name" class="bg-white p-6">
          <div>
            <span>Business Profile</span>
          </div>
          <div>
            <span class="text-xl">{{ account.businessProfile.name }}</span>
          </div>
        </div>
        <div v-if="account.email" class="bg-white p-6">
          <div>
            <span>Email</span>
          </div>
          <div>
            <span class="text-xl">{{ account.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';

export default {
  components: {
    Breadcrumbs,
    Menu
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.style.fabricator.name === 'Virtuoso') {
        next('/manage-touch-account/payment-gateway/firstdata');
      } else {
        try {
          const account = await window.touch.getAccount();
          if (account.message) {
            throw new Error(account.message);
          }
          vm.account = account; // eslint-disable-line
        } catch (error) {
          next('/manage-touch-account/payment-gateway/stripe/create');
        }
      }
    });
  },
  data() {
    return {
      contractId: 0,
      account: undefined,
      isRedirected: false,
    };
  },
  methods: {
    async redirectToStripe() {
      this.isRedirected = true;
      const { url } = await window.touch.createAccountLink();
      window.open(url, 'blank');
    },
  },
};
</script>
