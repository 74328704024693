<template>
  <touch-layout page_override_class="bg-gray-200" :style="{ width: '100vw' }">
    <main class="bg-gray-200 w-full overflow-y-scroll">
      <div class="md:flex gap-5 p-5 flex-no-wrap whitespace-nowrap">
        <div :style="{ flex: '2 1' }" class="flex flex-col">
          <Welcome :first-name="user.firstName" :company-name="user.companyName" />
          <Enquiries />
          <div class="xs:flex md:flex-col gap-5 md:gap-0 mt-5 md:mt-0 md:hidden">
            <News :company-name="$store.state.style.fabricator.name" :news-items="newsItems" class="w-full" />
            <Hero />
          </div>
          <KnowledgeBase :style="{ flex: 'auto' }" />
        </div>
        <div
          :style="{ flex: '1 1' }"
          class="hidden md:flex md:flex-col gap-5 md:gap-0 mt-5 md:mt-0 max-w-lg"
        >
          <News :company-name="$store.state.style.fabricator.name" :news-items="newsItems" class="w-full" />
          <Hero />
        </div>
      </div>
    </main>
  </touch-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Welcome from '@/components/portal/home/Welcome.vue';
import Enquiries from '@/components/portal/home/Enquiries.vue';
import KnowledgeBase from '@/components/portal/home/KnowledgeBase.vue';
import Hero from '@/components/portal/home/Hero.vue';
import News from '@/components/portal/home/News.vue';

export default {
  name: 'Home',
  components: {
    Welcome,
    Enquiries,
    KnowledgeBase,
    News,
    Hero,
  },
  data() {
    return {
      active_news_slide: 'news',
      user_img_url: '',
      twitter_handle: '',
      seatCounts: 0,
      news_widget_hovered: false,
      news_interval: null,
      branded_banner_popup_open: false,
      instagram_connected: false,
      feeds_available: ['news'],
      vendorModalOpen: false,
      dontShowVendorModalAgain: false,
      newsItems: []
    };
  },
  computed: {
    ...mapState('basket', {
      basket: 'basket',
    }),
    ...mapGetters({
      user: 'auth/user',
      fabricator_name: 'style/fabricator_name',
      basketQty: 'basket/basketQty',
      isViewOrdersEnabled: 'feature/getIsViewOrdersEnabled',
      isViewQuotesEnabled: 'feature/getIsViewQuotesEnabled',
    }),
    show_news_link() {
      return this.feeds_available.includes('news');
    },
  },
  async mounted() {
    const { news_items } = await this.latestNews({ limit: 1, offset: 0 });
    this.newsItems = news_items
    this.setInitialQuoteEnquiriesWidgetTab();

    this.user_img_url = window.touch.imageBaseURL + this.user.image;
    this.$store.state.infoPopup.activePopup = 'dashboard:intro_popup';
    try {
      // Elon disabled twitter?
      // this.twitter_handle = (await this.getOrganisationWebSetting('twitter')).value;
      // this.feeds_available.push('twitter');
    } catch (error) {
      // no twitter handle
    }

    if (window.location.hostname.split('.')[0] === 'demo') {
      await this.checkInstagramAuth({
        fabricatorName: this.$store.getters['style/fabricator_name'],
      })
        .then(() => {
          this.instagram_connected = true;
          this.feeds_available.push('instagram');
        })
        .catch(() => {
          this.instagram_connected = false;
        });
    }

    // eslint-disable-next-line prefer-destructuring
    this.active_news_slide = this.feeds_available[0];
  },
  methods: {
    ...mapActions({
      checkInstagramAuth: 'socialFeeds/checkInstagramAuth',
      getOrganisationWebSetting: 'auth/getOrganisationWebSetting',
      latestNews: 'news/latestNews',
    }),
    openWidget() {
      window.openFreshdeskWidget('101000478837');
    },
    userImgFallback() {
      this.user_img_url = '/images/blank-user.png';
    },
    async loadSeatsAvailable() {
      this.seatCounts = await this.$store.dispatch('touch/checkSeats');
    },
    setInitialQuoteEnquiriesWidgetTab() {
      if (this.branded_mode_enabled) {
        this.quotes_and_enquiries_tab = 'enquiries';
      } else {
        this.quotes_and_enquiries_tab = 'networkenquiries';
      }
    },
  },
};
</script>
<style scoped>
.selecta-styling {
  max-width: 1800px;
}
</style>
