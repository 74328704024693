<template>
  <div>
    <div class="font-medium">Contact Roles <span class="text-green-600">*</span></div>
    <div class="border border-black p-2">
      <div
        v-for="contactRole in contactRoles"
        :key="contactRole.id"
        class="flex gap-4 cursor-pointer"
        @click="setContactRoles(contactRole[1])"
      >
        <input
          type="checkbox"
          :checked="
            bitsInRoles.includes(contactRole[1]) ||
              (contactRole[1] === 0 && bitsInRoles.length === 0)
          "
        />
        <div>
          {{ contactRole[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roles: Number,
  },
  computed: {
    contactRoles() {
      return Object.keys(this.enums.ContactRoles).map((key) => [key, this.enums.ContactRoles[key]]);
    },
    bitsInRoles() {
      let b = 1;
      const res = [];
      while (b <= this.roles) {
        if (b & this.roles) res.push(b);
        b <<= 1;
      }
      return res;
    },
  },
  methods: {
    setContactRoles(value) {
      if (value === 0) {
        this.$emit('setContactRoles', 0);
      } else if (this.bitsInRoles.includes(value)) {
        this.$emit('setContactRoles', -value);
      } else {
        this.$emit('setContactRoles', value);
      }
    },
  },
};
</script>
