function formatMoney(currencyCode, amount) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currencyCode || 'GBP',
  }).format(amount);
}

export default {
  methods: {
    currencySymbol(currencyCode) {
      return new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: currencyCode || 'GBP',
      })
        .formatToParts(0)
        .find((part) => part.type === 'currency').value;
    },
    formatCurrency(currencyCode, value, fallback = '-') {
      const price = Number(value);

      return price === 0 ? fallback : formatMoney(currencyCode, price);
    },
    formatCurrencyWith0(currencyCode, value) {
      const price = Number(value);

      return formatMoney(currencyCode, price);
    },
  },
  computed: {
    currencyLookup() {
      return {
        GBP: 'Pound Sterling',
        EUR: 'Euro',
        USD: 'US Dollar',
        CAD: 'Canadian Dollar',
        AUD: 'Australian Dollar',
      };
      // return {
      //   AFN: 'Afghani',
      //   EUR: 'Euro',
      //   ALL: 'Lek',
      //   DZD: 'Algerian Dinar',
      //   USD: 'US Dollar',
      //   AOA: 'Kwanza',
      //   XCD: 'East Caribbean Dollar',
      //   ARS: 'Argentine Peso',
      //   AMD: 'Armenian Dram',
      //   AWG: 'Aruban Florin',
      //   AUD: 'Australian Dollar',
      //   AZN: 'Azerbaijan Manat',
      //   BSD: 'Bahamian Dollar',
      //   BHD: 'Bahraini Dinar',
      //   BDT: 'Taka',
      //   BBD: 'Barbados Dollar',
      //   BYN: 'Belarusian Ruble',
      //   BZD: 'Belize Dollar',
      //   XOF: 'CFA Franc BCEAO',
      //   BMD: 'Bermudian Dollar',
      //   INR: 'Indian Rupee',
      //   BTN: 'Ngultrum',
      //   BOB: 'Boliviano',
      //   BOV: 'Mvdol',
      //   BAM: 'Convertible Mark',
      //   BWP: 'Pula',
      //   NOK: 'Norwegian Krone',
      //   BRL: 'Brazilian Real',
      //   BND: 'Brunei Dollar',
      //   BGN: 'Bulgarian Lev',
      //   BIF: 'Burundi Franc',
      //   CVE: 'Cabo Verde Escudo',
      //   KHR: 'Riel',
      //   XAF: 'CFA Franc BEAC',
      //   CAD: 'Canadian Dollar',
      //   KYD: 'Cayman Islands Dollar',
      //   CLP: 'Chilean Peso',
      //   CLF: 'Unidad de Fomento',
      //   CNY: 'Yuan Renminbi',
      //   COP: 'Colombian Peso',
      //   COU: 'Unidad de Valor Real',
      //   KMF: 'Comorian Franc',
      //   CDF: 'Congolese Franc',
      //   NZD: 'New Zealand Dollar',
      //   CRC: 'Costa Rican Colon',
      //   HRK: 'Kuna',
      //   CUP: 'Cuban Peso',
      //   CUC: 'Peso Convertible',
      //   ANG: 'Netherlands Antillean Guilder',
      //   CZK: 'Czech Koruna',
      //   DKK: 'Danish Krone',
      //   DJF: 'Djibouti Franc',
      //   DOP: 'Dominican Peso',
      //   EGP: 'Egyptian Pound',
      //   SVC: 'El Salvador Colon',
      //   ERN: 'Nakfa',
      //   ETB: 'Ethiopian Birr',
      //   FKP: 'Falkland Islands Pound',
      //   FJD: 'Fiji Dollar',
      //   XPF: 'CFP Franc',
      //   GMD: 'Dalasi',
      //   GEL: 'Lari',
      //   GHS: 'Ghana Cedi',
      //   GIP: 'Gibraltar Pound',
      //   GTQ: 'Quetzal',
      //   GBP: 'Pound Sterling',
      //   GNF: 'Guinean Franc',
      //   GYD: 'Guyana Dollar',
      //   HTG: 'Gourde',
      //   HNL: 'Lempira',
      //   HKD: 'Hong Kong Dollar',
      //   HUF: 'Forint',
      //   ISK: 'Iceland Krona',
      //   IDR: 'Rupiah',
      //   XDR: 'SDR (Special Drawing Right)',
      //   IRR: 'Iranian Rial',
      //   IQD: 'Iraqi Dinar',
      //   ILS: 'New Israeli Sheqel',
      //   JMD: 'Jamaican Dollar',
      //   JPY: 'Yen',
      //   JOD: 'Jordanian Dinar',
      //   KZT: 'Tenge',
      //   KES: 'Kenyan Shilling',
      //   KPW: 'North Korean Won',
      //   KRW: 'Won',
      //   KWD: 'Kuwaiti Dinar',
      //   KGS: 'Som',
      //   LAK: 'Lao Kip',
      //   LBP: 'Lebanese Pound',
      //   LSL: 'Loti',
      //   ZAR: 'Rand',
      //   LRD: 'Liberian Dollar',
      //   LYD: 'Libyan Dinar',
      //   CHF: 'Swiss Franc',
      //   MOP: 'Pataca',
      //   MKD: 'Denar',
      //   MGA: 'Malagasy Ariary',
      //   MWK: 'Malawi Kwacha',
      //   MYR: 'Malaysian Ringgit',
      //   MVR: 'Rufiyaa',
      //   MRU: 'Ouguiya',
      //   MUR: 'Mauritius Rupee',
      //   XUA: 'ADB Unit of Account',
      //   MXN: 'Mexican Peso',
      //   MXV: 'Mexican Unidad de Inversion (UDI)',
      //   MDL: 'Moldovan Leu',
      //   MNT: 'Tugrik',
      //   MAD: 'Moroccan Dirham',
      //   MZN: 'Mozambique Metical',
      //   MMK: 'Kyat',
      //   NAD: 'Namibia Dollar',
      //   NPR: 'Nepalese Rupee',
      //   NIO: 'Cordoba Oro',
      //   NGN: 'Naira',
      //   OMR: 'Rial Omani',
      //   PKR: 'Pakistan Rupee',
      //   PAB: 'Balboa',
      //   PGK: 'Kina',
      //   PYG: 'Guarani',
      //   PEN: 'Sol',
      //   PHP: 'Philippine Peso',
      //   PLN: 'Zloty',
      //   QAR: 'Qatari Rial',
      //   RON: 'Romanian Leu',
      //   RUB: 'Russian Ruble',
      //   RWF: 'Rwanda Franc',
      //   SHP: 'Saint Helena Pound',
      //   WST: 'Tala',
      //   STN: 'Dobra',
      //   SAR: 'Saudi Riyal',
      //   RSD: 'Serbian Dinar',
      //   SCR: 'Seychelles Rupee',
      //   SLL: 'Leone',
      //   SGD: 'Singapore Dollar',
      //   XSU: 'Sucre',
      //   SBD: 'Solomon Islands Dollar',
      //   SOS: 'Somali Shilling',
      //   SSP: 'South Sudanese Pound',
      //   LKR: 'Sri Lanka Rupee',
      //   SDG: 'Sudanese Pound',
      //   SRD: 'Surinam Dollar',
      //   SZL: 'Lilangeni',
      //   SEK: 'Swedish Krona',
      //   CHE: 'WIR Euro',
      //   CHW: 'WIR Franc',
      //   SYP: 'Syrian Pound',
      //   TWD: 'New Taiwan Dollar',
      //   TJS: 'Somoni',
      //   TZS: 'Tanzanian Shilling',
      //   THB: 'Baht',
      //   TOP: 'Pa\'anga',
      //   TTD: 'Trinidad and Tobago Dollar',
      //   TND: 'Tunisian Dinar',
      //   TRY: 'Turkish Lira',
      //   TMT: 'Turkmenistan New Manat',
      //   UGX: 'Uganda Shilling',
      //   UAH: 'Hryvnia',
      //   AED: 'UAE Dirham',
      //   USN: 'US Dollar (Next day)',
      //   UYU: 'Peso Uruguayo',
      //   UYI: 'Uruguay Peso en Unidades Indexadas (UI)',
      //   UYW: 'Unidad Previsional',
      //   UZS: 'Uzbekistan Sum',
      //   VUV: 'Vatu',
      //   VES: 'Bol&iacute;var Soberano',
      //   VND: 'Dong',
      //   YER: 'Yemeni Rial',
      //   ZMW: 'Zambian Kwacha',
      //   ZWL: 'Zimbabwe Dollar',
      //   XBA: 'Bond Markets Unit European Composite Unit (EURCO)',
      //   XBB: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
      //   XBC: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
      //   XBD: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
      //   XTS: 'Codes specifically reserved for testing purposes',
      //   XXX: 'The codes assigned for transactions where no currency is involved',
      //   XAU: 'Gold',
      //   XPD: 'Palladium',
      //   XPT: 'Platinum',
      //   XAG: 'Silver',
      // };
    },
    currencyList() {
      return {
        GBP: `(GBP) Pound Sterling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' }).format(1234.56)})`,
        EUR: `(EUR) Euro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol' }).format(1234.56)})`,
        USD: `(USD) US Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }).format(1234.56)})`,
        AUD: `(AUD) Australian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AUD', currencyDisplay: 'symbol' }).format(1234.56)})`,
        CAD: `(CAD) Canadian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' }).format(1234.56)})`,
      };
      // return {
      //   common: {
      //     GBP: `(GBP) Pound Sterling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     EUR: `(EUR) Euro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     USD: `(USD) US Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //   },
      //   other: {
      //     AFN: `(AFN) Afghani (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AFN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     EUR: `(EUR) Euro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ALL: `(ALL) Lek (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ALL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     DZD: `(DZD) Algerian Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'DZD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     USD: `(USD) US Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AOA: `(AOA) Kwanza (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AOA', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XCD: `(XCD) East Caribbean Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XCD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ARS: `(ARS) Argentine Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ARS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AMD: `(AMD) Armenian Dram (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AMD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AWG: `(AWG) Aruban Florin (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AWG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AUD: `(AUD) Australian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AUD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AZN: `(AZN) Azerbaijan Manat (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AZN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BSD: `(BSD) Bahamian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BSD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BHD: `(BHD) Bahraini Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BHD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BDT: `(BDT) Taka (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BDT', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BBD: `(BBD) Barbados Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BBD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BYN: `(BYN) Belarusian Ruble (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BYN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BZD: `(BZD) Belize Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BZD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XOF: `(XOF) CFA Franc BCEAO (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XOF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BMD: `(BMD) Bermudian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BMD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     INR: `(INR) Indian Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'INR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BTN: `(BTN) Ngultrum (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BTN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BOB: `(BOB) Boliviano (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BOB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BOV: `(BOV) Mvdol (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BOV', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BAM: `(BAM) Convertible Mark (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BAM', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BWP: `(BWP) Pula (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BWP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NOK: `(NOK) Norwegian Krone (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NOK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BRL: `(BRL) Brazilian Real (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BRL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BND: `(BND) Brunei Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BND', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BGN: `(BGN) Bulgarian Lev (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BGN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     BIF: `(BIF) Burundi Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'BIF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CVE: `(CVE) Cabo Verde Escudo (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CVE', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KHR: `(KHR) Riel (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KHR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XAF: `(XAF) CFA Franc BEAC (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XAF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CAD: `(CAD) Canadian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KYD: `(KYD) Cayman Islands Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KYD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CLP: `(CLP) Chilean Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CLP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CLF: `(CLF) Unidad de Fomento (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CLF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CNY: `(CNY) Yuan Renminbi (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CNY', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     COP: `(COP) Colombian Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'COP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     COU: `(COU) Unidad de Valor Real (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'COU', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KMF: `(KMF) Comorian Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KMF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CDF: `(CDF) Congolese Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CDF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NZD: `(NZD) New Zealand Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NZD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CRC: `(CRC) Costa Rican Colon (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CRC', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     HRK: `(HRK) Kuna (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'HRK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CUP: `(CUP) Cuban Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CUP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CUC: `(CUC) Peso Convertible (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CUC', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ANG: `(ANG) Netherlands Antillean Guilder (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ANG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CZK: `(CZK) Czech Koruna (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CZK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     DKK: `(DKK) Danish Krone (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'DKK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     DJF: `(DJF) Djibouti Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'DJF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     DOP: `(DOP) Dominican Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'DOP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     EGP: `(EGP) Egyptian Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'EGP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SVC: `(SVC) El Salvador Colon (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SVC', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ERN: `(ERN) Nakfa (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ERN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ETB: `(ETB) Ethiopian Birr (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ETB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     FKP: `(FKP) Falkland Islands Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'FKP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     FJD: `(FJD) Fiji Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'FJD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XPF: `(XPF) CFP Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XPF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GMD: `(GMD) Dalasi (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GMD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GEL: `(GEL) Lari (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GEL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GHS: `(GHS) Ghana Cedi (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GHS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GIP: `(GIP) Gibraltar Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GIP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GTQ: `(GTQ) Quetzal (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GTQ', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GBP: `(GBP) Pound Sterling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GNF: `(GNF) Guinean Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GNF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     GYD: `(GYD) Guyana Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'GYD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     HTG: `(HTG) Gourde (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'HTG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     HNL: `(HNL) Lempira (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'HNL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     HKD: `(HKD) Hong Kong Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'HKD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     HUF: `(HUF) Forint (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'HUF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ISK: `(ISK) Iceland Krona (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ISK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     IDR: `(IDR) Rupiah (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'IDR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XDR: `(XDR) SDR (Special Drawing Right) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XDR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     IRR: `(IRR) Iranian Rial (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'IRR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     IQD: `(IQD) Iraqi Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'IQD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ILS: `(ILS) New Israeli Sheqel (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ILS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     JMD: `(JMD) Jamaican Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'JMD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     JPY: `(JPY) Yen (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'JPY', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     JOD: `(JOD) Jordanian Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'JOD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KZT: `(KZT) Tenge (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KZT', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KES: `(KES) Kenyan Shilling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KES', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KPW: `(KPW) North Korean Won (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KPW', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KRW: `(KRW) Won (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KRW', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KWD: `(KWD) Kuwaiti Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KWD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     KGS: `(KGS) Som (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'KGS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LAK: `(LAK) Lao Kip (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LAK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LBP: `(LBP) Lebanese Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LBP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LSL: `(LSL) Loti (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LSL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ZAR: `(ZAR) Rand (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ZAR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LRD: `(LRD) Liberian Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LRD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LYD: `(LYD) Libyan Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LYD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CHF: `(CHF) Swiss Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CHF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MOP: `(MOP) Pataca (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MOP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MKD: `(MKD) Denar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MKD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MGA: `(MGA) Malagasy Ariary (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MGA', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MWK: `(MWK) Malawi Kwacha (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MWK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MYR: `(MYR) Malaysian Ringgit (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MYR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MVR: `(MVR) Rufiyaa (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MVR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MRU: `(MRU) Ouguiya (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MRU', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MUR: `(MUR) Mauritius Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MUR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XUA: `(XUA) ADB Unit of Account (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XUA', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MXN: `(MXN) Mexican Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MXN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MXV: `(MXV) Mexican Unidad de Inversion (UDI) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MXV', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MDL: `(MDL) Moldovan Leu (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MDL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MNT: `(MNT) Tugrik (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MNT', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MAD: `(MAD) Moroccan Dirham (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MAD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MZN: `(MZN) Mozambique Metical (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MZN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     MMK: `(MMK) Kyat (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'MMK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NAD: `(NAD) Namibia Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NAD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NPR: `(NPR) Nepalese Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NPR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NIO: `(NIO) Cordoba Oro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NIO', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     NGN: `(NGN) Naira (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'NGN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     OMR: `(OMR) Rial Omani (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'OMR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PKR: `(PKR) Pakistan Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PKR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PAB: `(PAB) Balboa (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PAB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PGK: `(PGK) Kina (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PGK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PYG: `(PYG) Guarani (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PYG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PEN: `(PEN) Sol (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PEN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PHP: `(PHP) Philippine Peso (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PHP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     PLN: `(PLN) Zloty (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'PLN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     QAR: `(QAR) Qatari Rial (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'QAR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     RON: `(RON) Romanian Leu (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'RON', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     RUB: `(RUB) Russian Ruble (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     RWF: `(RWF) Rwanda Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'RWF', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SHP: `(SHP) Saint Helena Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SHP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     WST: `(WST) Tala (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'WST', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     STN: `(STN) Dobra (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'STN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SAR: `(SAR) Saudi Riyal (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SAR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     RSD: `(RSD) Serbian Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'RSD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SCR: `(SCR) Seychelles Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SCR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SLL: `(SLL) Leone (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SLL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SGD: `(SGD) Singapore Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SGD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XSU: `(XSU) Sucre (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XSU', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SBD: `(SBD) Solomon Islands Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SBD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SOS: `(SOS) Somali Shilling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SOS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SSP: `(SSP) South Sudanese Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SSP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     LKR: `(LKR) Sri Lanka Rupee (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'LKR', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SDG: `(SDG) Sudanese Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SDG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SRD: `(SRD) Surinam Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SRD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SZL: `(SZL) Lilangeni (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SZL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SEK: `(SEK) Swedish Krona (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SEK', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CHE: `(CHE) WIR Euro (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CHE', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     CHW: `(CHW) WIR Franc (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'CHW', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     SYP: `(SYP) Syrian Pound (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'SYP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TWD: `(TWD) New Taiwan Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TWD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TJS: `(TJS) Somoni (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TJS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TZS: `(TZS) Tanzanian Shilling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TZS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     THB: `(THB) Baht (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'THB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TOP: `(TOP) Pa'anga (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TOP', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TTD: `(TTD) Trinidad and Tobago Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TTD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TND: `(TND) Tunisian Dinar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TND', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TRY: `(TRY) Turkish Lira (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     TMT: `(TMT) Turkmenistan New Manat (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'TMT', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UGX: `(UGX) Uganda Shilling (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UGX', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UAH: `(UAH) Hryvnia (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UAH', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     AED: `(AED) UAE Dirham (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'AED', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     USN: `(USN) US Dollar (Next day) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'USN', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UYU: `(UYU) Peso Uruguayo (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UYU', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UYI: `(UYI) Uruguay Peso en Unidades Indexadas (UI) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UYI', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UYW: `(UYW) Unidad Previsional (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UYW', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     UZS: `(UZS) Uzbekistan Sum (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'UZS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     VUV: `(VUV) Vatu (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'VUV', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     VES: `(VES) Bol&iacute;var Soberano (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'VES', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     VND: `(VND) Dong (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'VND', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     YER: `(YER) Yemeni Rial (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'YER', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ZMW: `(ZMW) Zambian Kwacha (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ZMW', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     ZWL: `(ZWL) Zimbabwe Dollar (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'ZWL', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XBA: `(XBA) Bond Markets Unit European Composite Unit (EURCO) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XBA', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XBB: `(XBB) Bond Markets Unit European Monetary Unit (E.M.U.-6) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XBB', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XBC: `(XBC) Bond Markets Unit European Unit of Account 9 (E.U.A.-9) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XBC', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XBD: `(XBD) Bond Markets Unit European Unit of Account 17 (E.U.A.-17) (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XBD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XTS: `(XTS) Codes specifically reserved for testing purposes (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XTS', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XXX: `(XXX) The codes assigned for transactions where no currency is involved (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XXX', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XAU: `(XAU) Gold (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XAU', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XPD: `(XPD) Palladium (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XPD', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XPT: `(XPT) Platinum (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XPT', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //     XAG: `(XAG) Silver (${new Intl.NumberFormat(Navigator.language, { style: 'currency', currency: 'XAG', currencyDisplay: 'symbol' }).format(12345678.90)})`,
      //   },
      // };
    },
  },
};
