import store from '../../store';

export default async (to, from, next) => {
  if (to.path === '/') {
    next({ path: '/business' });
  } else if (
    to.matched.some((record) => record.meta.businessRoute === true || record.meta.shared)
  ) {
    const isLoginValid = await store.dispatch('auth/validateLogin', 'staff');
    if (isLoginValid) {
      store.state.user.freshdeskWidgetEnabled = true;
      next();
    } else {
      store.state.user.freshdeskWidgetEnabled = false;
      next({
        path: store.state.auth.login_url,
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next('/');
  }
};
