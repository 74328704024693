<template>
  <div class="border bg-white p-6">
    <info-tooltip tip-name="howToPrice">
      <div class="absolute top-0 left-0 text-left m-4 p-1">Pricing Help.</div>
      <div class="mt-4">Select a product from this list to filter down your prices.</div>
    </info-tooltip>
    <div class="text-xl mb-6">
      How to set up your pricing.
      <info-popup
        :id="'vendor_settings_pricing:how_to_setup_pricing'"
        class=""
        :info_message="'Selecting the dropdown will highlight the necessary items in the table on the left. Click the drop down to show how each product is calculated.'"
        :next_id="'vendor_settings_pricing:pricing_search'"
      ></info-popup>
    </div>
    <!-- Window / Tilt Turn / Single / Double Door -->
    <how-to-setup-price-block
      filter="&quot;frame grid&quot;"
      :open="open_block === 'windows-and-doors'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('windows-and-doors')"
    >
      <template #title>Window / Tilt Turn / Single / Double Door</template>
      <div class="p-3">
        <ul>
          <li>
            <span class="font-medium">Your frame is calculated using:</span>
            <ol class="pl-8 list-decimal my-3 mb-6">
              <pricing-filter-link filter="&quot;frame grid&quot;" @set-filter="$emit('set-filter', $event)">
                Outerframe Grid
              </pricing-filter-link>
              <pricing-filter-link filter="&quot;sash grid&quot;" @set-filter="$emit('set-filter', $event)">
                Sash Grid
              </pricing-filter-link>
              <pricing-filter-link
                filter="transom mullion midrail"
                @set-filter="$emit('set-filter', $event)"
              >
                Transom / Mullion / Midrail
              </pricing-filter-link>
            </ol>
          </li>
          <li>
            <span class="font-medium">Then add your extra pricing:</span>
            <ol start="4" class="pl-8 list-decimal my-3 mb-0">
              <pricing-filter-link
                filter="&quot;colour group&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Colour group uplift
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:glass &quot;group:Integral Blinds&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Glass
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;group:Ancillary Profiles&quot; group:Ventilators"
                @set-filter="$emit('set-filter', $event)"
              >
                Ancillary Profiles
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:Hardware group:Handles group:locks group:hinge group:letterplate group:chains group:spyholes !composite"
                @set-filter="$emit('set-filter', $event)"
              >
                Hardware
              </pricing-filter-link>
            </ol>
          </li>
        </ul>
      </div>
      <div class="p-3">
        <span class="font-medium">Important Information:</span>
        <p class="my-3">Retail enquiries use the following default options:</p>

        <span class="font-medium">Windows</span>
        <ul class="list-disc pl-8 my-3">
          <pricing-filter-link filter="small" @set-filter="$emit('set-filter', $event)">
            Small profiles
          </pricing-filter-link>
        </ul>

        <span class="font-medium">Tilt/Turn and Doors</span>
        <ul class="list-disc pl-8 my-3">
          <pricing-filter-link filter="large" @set-filter="$emit('set-filter', $event)">
            Large Outerframe
          </pricing-filter-link>
          <pricing-filter-link filter="medium" @set-filter="$emit('set-filter', $event)">
            Medium Transom/Mullion
          </pricing-filter-link>
        </ul>
      </div>
    </how-to-setup-price-block>

    <!-- Bifold door -->
    <how-to-setup-price-block
      filter="&quot;BiFold Frame Grid&quot;"
      :open="open_block === 'bifold'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('bifold')"
    >
      <template #title>Bifold door</template>

      <div class="p-3">
        <ul>
          <li>
            <span class="font-medium">Your frame is calculated using:</span>
            <ol class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;BiFold Frame Grid&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Bifold Frame Grid
              </pricing-filter-link>
              <pricing-filter-link filter="&quot;bifold sash&quot;" @set-filter="$emit('set-filter', $event)">
                Per Bifold Sash
              </pricing-filter-link>
            </ol>
          </li>
          <li>
            <span class="font-medium">Then add your extra pricing:</span>
            <ol start="3" class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Colour Group&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Colour group uplift
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:glass &quot;group:Integral Blinds&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Glass
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;group:Ancillary Profiles&quot; group:Ventilators"
                @set-filter="$emit('set-filter', $event)"
              >
                Ancillary Profiles
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:Hardware group:Handles group:locks group:hinge group:letterplate group:chains group:spyholes !composite"
                @set-filter="$emit('set-filter', $event)"
              >
                Hardware
              </pricing-filter-link>
            </ol>
          </li>
        </ul>
      </div>
    </how-to-setup-price-block>

    <!-- Patio door -->
    <how-to-setup-price-block
      filter="&quot;patio door Frame&quot;"
      :open="open_block === 'patio'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('patio')"
    >
      <template #title>Patio door</template>
      <div class="p-3">
        <ul>
          <li>
            <span class="font-medium">Your frame is calculated using:</span>
            <ol class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;patio door Frame&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Patio Door Frame
              </pricing-filter-link>
              <pricing-filter-link filter="&quot;patio sash&quot;" @set-filter="$emit('set-filter', $event)">
                Per Patio Sash
              </pricing-filter-link>
            </ol>
          </li>
          <li>
            <span class="font-medium">Then add your extra pricing:</span>
            <ol start="3" class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Colour Group&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Colour group uplift
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:glass &quot;group:Integral Blinds&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Glass
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;group:Ancillary Profiles&quot; group:Ventilators"
                @set-filter="$emit('set-filter', $event)"
              >
                Ancillary Profiles
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:Hardware group:Handles group:locks group:hinge group:letterplate group:chains group:spyholes  !composite"
                @set-filter="$emit('set-filter', $event)"
              >
                Hardware
              </pricing-filter-link>
            </ol>
          </li>
        </ul>
      </div>
    </how-to-setup-price-block>

    <!-- Vertical Slider -->
    <how-to-setup-price-block
      filter="&quot;Vertical Slider Grid&quot;"
      :open="open_block === 'vertical-slider'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('vertical-slider')"
    >
      <template #title>Vertical Slider</template>
      <div class="p-3">
        <ul>
          <li>
            <span class="font-medium">Your frame is calculated using:</span>
            <ol class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Vertical Slider Grid&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Vertical Slider Grid
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;VS Top Sash&quot; &quot;VS Bottom Sash&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Vertical Slider Sash
              </pricing-filter-link>
            </ol>
          </li>
          <li>
            <span class="font-medium">Then add your extra pricing:</span>
            <ol start="3" class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Colour Group&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Colour group uplift
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:glass &quot;group:Integral Blinds&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Glass
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;group:Ancillary Profiles&quot; group:Ventilators"
                @set-filter="$emit('set-filter', $event)"
              >
                Ancillary Profiles
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:Hardware group:Handles group:locks group:hinge group:letterplate group:chains group:spyholes !composite"
                @set-filter="$emit('set-filter', $event)"
              >
                Hardware
              </pricing-filter-link>
            </ol>
          </li>
        </ul>
      </div>

      <div class="p-3">
        <span class="font-medium">Important Information:</span>
        <p class="my-3">Retail enquiries use the following default options:</p>
        <ul class="pl-8 list-disc mb-3">
          <pricing-filter-link filter="&quot;Small VS&quot;" @set-filter="$emit('set-filter', $event)">
            Small Sashes
          </pricing-filter-link>
        </ul>
      </div>
    </how-to-setup-price-block>

    <!-- Bay and Bow -->
    <how-to-setup-price-block
      filter="bay"
      :open="open_block === 'bay-and-bow'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('bay-and-bow')"
    >
      <template #title>Bay and Bow</template>
      <div class="p-3">
        You can set an additional price per product on the bay/bow.

        <pricing-filter-link filter="bay" @set-filter="$emit('set-filter', $event)">
          Makeup to allow for poles/welds etc...
        </pricing-filter-link>
      </div>
    </how-to-setup-price-block>

    <!-- Composite Doors -->
    <how-to-setup-price-block
      filter="&quot;Composite door frame&quot;"
      :open="open_block === 'composite-doors'"
      @set-filter="$emit('set-filter', $event)"
      @open-price-block="openPriceBlock('composite-doors')"
    >
      <template #title>Composite Doors</template>
      <div class="p-3">
        <ul>
          <li>
            <span class="font-medium">Your frame is calculated using:</span>
            <ol class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Composite door frame&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Frame / Sidepanel
              </pricing-filter-link>
              <pricing-filter-link
                filter="transom midrail"
                @set-filter="$emit('set-filter', $event)"
              >
                Transom / Midrail
              </pricing-filter-link>
            </ol>
          </li>
          <li>
            <span class="font-medium">Then add your extra pricing:</span>
            <ol start="3" class="pl-8 mb-4 list-decimal mt-2">
              <pricing-filter-link
                filter="&quot;Traditional Composite&quot; &quot;Contemporary Composite&quot; &quot;Sidelight Composite&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Door Style / Glass
              </pricing-filter-link>
              <pricing-filter-link filter="&quot;Door Colour&quot;" @set-filter="$emit('set-filter', $event)">
                Door Colour
              </pricing-filter-link>
              <pricing-filter-link
                filter="&quot;group:Ancillary Profiles&quot; group:Ventilators"
                @set-filter="$emit('set-filter', $event)"
              >
                Ancillary Profiles
              </pricing-filter-link>
              <pricing-filter-link
                filter="group:Handles &quot;group:Door locks&quot; group:hinge group:letterplate group:chains group:spyholes &quot;!Black Door Handle&quot; &quot;!Chrome Door Handle&quot; &quot;!Gold Door Handle&quot; &quot;!Satin Door Handle&quot; &quot;!White Door Handle&quot; &quot;!Black Door Hinges&quot; &quot;!Chrome Door Hinges&quot; &quot;!Gold Door Hinges&quot; &quot;!Satin Door Hinges&quot; &quot;!White Door Hinges&quot; &quot;!Standard Door Lock&quot; &quot;!High Security Door Lock&quot;"
                @set-filter="$emit('set-filter', $event)"
              >
                Hardware
              </pricing-filter-link>
            </ol>
          </li>
        </ul>

        <h1 class="mb-2 font-medium">Important Information:</h1>
        <p class="mb-2">Retail enquiries use the following default options:</p>
        <ul class="pl-8 list-disc mb-3">
          <pricing-filter-link filter="&quot;Medium Transom&quot;" @set-filter="$emit('set-filter', $event)">
            Medium Transom
          </pricing-filter-link>
        </ul>

        <!--      composite door frame is outer frame only
        side panels are priced and designed separately using the same calculation as outer frame. you also design a side panel the same way in which a door is designed.-->
        <!--      Composite Doors defaults to large frame and medium transoms-->
        <!--      then add your uplift within composite door section for premium or standard door design and standard, bevel or premium glass.-->
        <!--      your door design price is for a standard colour which includes white, xxxxx?-->
        <!--      you can add a colour upgrade using premium colour uplift which includes xxxxxx?-->
        <!--      then add your extras: hardware, add ons and cills within ancillary profiles-->
      </div>
    </how-to-setup-price-block>
  </div>
</template>

<script>
import HowToSetupPriceBlock from '@/components/portal/setup/pricing/HowToSetupPriceBlock.vue';
import PricingFilterLink from '@/components/portal/setup/pricing/PricingFilterLink.vue';
import InfoTooltip from '@/components/portal/setup/pricing/InfoTooltip.vue';

export default {
  components: {
    InfoTooltip,
    'how-to-setup-price-block': HowToSetupPriceBlock,
    'pricing-filter-link': PricingFilterLink,
  },
  data() {
    return {
      open_block: null,
    };
  },
  methods: {
    openPriceBlock(id) {
      if (this.open_block === id) {
        this.open_block = null;
      } else {
        this.open_block = id;
      }
    },
  },
};
</script>

<style scoped></style>
