var render = function render(){var _vm=this,_c=_vm._self._c;return _c('touch-layout',{scopedSlots:_vm._u([{key:"header_page_breadcrumb",fn:function(){return [_c('Breadcrumbs',{attrs:{"routes":[
        ['Dashboard', '/'],
        ['Customer Management', '/customer'],
        [`Customer (${_vm.customer.company.name})`, `/customer/${_vm.customer.customerId}`],
        ['Discounts'],
      ]}})]},proxy:true},{key:"header_page_title",fn:function(){return [_vm._v(" Discounts ")]},proxy:true}])},[_c('Menu'),_c('div',{staticClass:"bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"},[(_vm.discounts.length > 0)?[_c('div',{staticClass:"flex flex-wrap bg-white border m-3 md:m-10 relative pr-16"},[_vm._l((_vm.discounts),function(discount,index){return _c('div',{key:index,staticClass:"p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"},[_c('div',{staticClass:"font-bold"},[_vm._v(_vm._s(discount.description))]),_c('div',{staticClass:"h-10 flex flex-col justify-around"},[_vm._v(_vm._s(discount.value)+"%")])])}),_c('div',{staticClass:"absolute bottom-0 right-0 p-5"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin_user),expression:"is_admin_user"}],staticClass:"px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600",on:{"click":function($event){_vm.editing = true}}},[_c('i',{staticClass:"fa fa-pencil"})])])],2)]:[_c('div',{staticClass:"bg-white border m-3 xs:m-10 p-3 xs:p-10"},[_vm._v("No Discounts to Show")])],_c('portal',{attrs:{"to":"portal_popup"}},[_c('modal-window',{attrs:{"modal_open":_vm.editing,"title":"Edit Customer Discounts"},on:{"close":function($event){_vm.editing = false}}},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.discounts),function(discount,index){return _c('div',{key:index,staticClass:"w-full mt-4 xs:w-1/2",class:{
              'xs:pr-5': index % 2 === 0,
              'xs:pl-5': index % 2 !== 0,
              'xs:mt-8': index > 1,
            }},[_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(discount.description)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(discount.value),expression:"discount.value"}],staticClass:"form-control",domProps:{"value":(discount.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(discount, "value", $event.target.value)}}})])}),0),_c('div',{staticClass:"text-right mt-4 xs:mt-8 flex flex-col 2xs:block"},[_c('button',{staticClass:"btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0",on:{"click":function($event){$event.preventDefault();_vm.editing = false}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn-action btn-lg",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.saveSettings.apply(null, arguments)}}},[_vm._v(" Save Changes ")])])])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }