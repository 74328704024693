<template>
  <touch-layout>
    <transition name="fade-in" mode="out-in">
      <loading v-if="loading" :key="1" :loading="true" style="flex: 1 1 0"></loading>
      <div v-else :key="2" class="sm:flex sm:flex-row w-full sm:overflow-hidden sm:flex-shrink-0 sm:flex-grow sm:h-px">
        <div
          class="bg-gray-200 flex flex-col sm:w-2/3 md:w-3/5 lg:w-2/3 p-6 md:p-10 sm:overflow-y-auto scrolling-touch visualiser-background-start"
          :style="{ backgroundImage: bgImage }"
        >
          <div class="pb-6 md:pb-10" :class="$t('visualiser-upload-title-class')">
            <strong class="block text-lg mb-3">Upload Your Home</strong>
            <p>You can upload multiple images, i.e front, back, sides.</p>
          </div>
          <div class="visualiser-start-page-box-wrapper">
            <div class="mx-auto w-full">
              <div class="text-xl sm:text-2xl md:text-5xl leading-tight">
                <strong>Explore Endless Interior and Exterior Design Possibilities.</strong>
              </div>
              <div class="hidden md:block mt-6">
                <div class="mb-2">1. Import multiple photos from your library</div>
                <div class="mb-2">2. Choose internal or external view</div>
                <div class="mb-2">3. Visualise every facade of your home</div>
                <div class="mb-2">4. Resize and rotate your products</div>
                <div class="">5. Save to your quote or send to a friend</div>
              </div>
            </div>
            <div class="text-lg my-6">
              <strong>Upload tips <i class="fal fa-lightbulb-exclamation" /></strong>
            </div>
            <div class="xs:flex sm:block">
              <div class="xs:pr-3 sm:pr-0">
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> No less than 1600 pixels wide.
                </div>
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> High quality and in focus.
                </div>
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> No larger than 10MB in file size.
                </div>
              </div>
              <div class="xs:pl-3 sm:pl-0">
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> An unobstructed view
                </div>
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> Landscape format (wider than tall).
                </div>
                <div class="mb-2">
                  <i class="fa fa-check text-green-600 mr-2" /> Well centered on area of interest.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-white sm:w-1/3 md:w-2/5 lg:w-1/3 p-6 md:p-10 sm:overflow-y-auto scrolling-touch"
        >
          <div class="mb-3">
            <strong class="block text-lg">Your Home Images</strong>
          </div>
          <div class="mb-3">
            Enter a reference (my home, dads house, mams house)
            <input v-model="reference" class="bg-gray-200 p-2 h-10 rounded w-full mt-2" />
          </div>
          <vue-dropzone
            id="dropzone"
            ref="fileUploadDropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            class="text-center block flex-grow flex flex-col justify-around border-none text-black mb-2 px-0 bg-gray-200"
            @vdropzone-file-added="fileAdded"
            @vdropzone-success="fileUploaded"
          >
            <div class="text-lg mb-6">
              <strong>Drag and drop your images here</strong>
            </div>
            <div class="text-lg">OR</div>
            <div class="mt-6">
              <a class="btn-action btn-lg"> Click to upload </a>
            </div>
          </vue-dropzone>
          <div class="text-red-600 flex flex-col justify-around font-medium text-base mb-10">
            Max File Size: 10MB
          </div>
          <template v-if="imagesUploaded">
            <uploaded-image-tile
              v-for="(image, index) in images"
              :key="image.key"
              :image="image"
              :last_image="images.length - 1 === index"
              :first_image="index === 0"
            />
          </template>
        </div>
      </div>
    </transition>
  </touch-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UploadedImageTile from '@/components/branded/visualiser/UploadedImageTile.vue';
import vue2Dropzone from 'vue2-dropzone';

export default {
  components: {
    'uploaded-image-tile': UploadedImageTile,
    'vue-dropzone': vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: window.touch.visualiserUploadURL(),
        paramName: 'File',
        maxFilesize: 10,
        params: this.dropzoneParams,
        acceptedFiles: 'image/*',
        headers: {
          Authorization: `Bearer ${window.touch.token[window.touch.installation]}`,
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        loading: false,
      },
      bgImage: '',
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      images: 'visualiser/images',
    }),
    imagesUploaded() {
      return this.images.length > 0;
    },
    reference: {
      get() {
        return this.$store.state.visualiser.reference;
      },
      set(ref) {
        this.$store.commit('visualiser/setReference', ref);
      },
    },
  },
  async created() {
    this.loading = true;
    this.$store.dispatch('visualiser/loadImages');
    const customImage = (await this.getCompanyStyles())?.virtualHomePageStyling?.backgroundImageUri;
    const defaultImage = this.$t('/images/visualiser-start-background.jpg');
    this.bgImage = `url(${customImage ?? defaultImage})`;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getCompanyStyles: 'style/getCompanyStyles',
    }),
    fileAdded(file) {
      if (file.size > 10000000) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'File Size Limit Exceeded',
          text: 'The maximum file size is 10MB.',
          icon: 'error',
        });
      }
    },
    fileUploaded(file) {
      this.$refs.fileUploadDropzone.dropzone.removeFile(file);
      this.$store.dispatch('visualiser/loadImages');
    },
    dropzoneParams(file) {
      return {
        Reference: file[0].name.replace(/\.(jpe?g|png)/gi, ''),
        InternalView: false,
      };
    },
  },
};
</script>
