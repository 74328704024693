import Touch from './touch';

// eslint-disable-next-line class-methods-use-this
Touch.prototype.newOrders = function newOrders() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
};

// eslint-disable-next-line class-methods-use-this
Touch.prototype.newTickets = function newTickets() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
};
