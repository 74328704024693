<template>
  <touch-layout v-if="quote && quote.consumer">
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Enquiries', '/network-enquiry'], [quote.customerCounter]]"
      />
    </template>
    <template #header_page_title> #{{ quote.customerCounter }} </template>
    <template #header_page_actions>
      <div class="md:px-10 flex items-center">
        <div class="p-3 flex items-center">
          <span class="font-medium mr-2">Status</span>
          <span class="text-brand-primary"> Enquiry </span>
        </div>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div
        v-if="quote.locked"
        key="locked-banner"
        class="flex bg-red-200 py-5 px-10 rounded-lg mb-6 xs:mb-10"
        data-index="1"
      >
        <div class="mr-6 hidden xs:flex flex-col justify-around">
          <i class="fal fa-lock-alt"></i>
        </div>
        <div
          v-if="lockedByUser.id == user.id"
          class="flex-grow flex flex-col xs:flex-row justify-between text-center xs:text-left"
        >
          <div class="flex flex-col justify-around">This quote is locked by you.</div>
          <transition type="out-in" name="fade-in">
            <template v-if="quote_unlocking">
              <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-action">
                <i class="fal fa-unlock mr-2"></i> Unlocking Quote
              </a>
            </template>
            <template v-else>
              <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-danger" @click="unlockQuote()">
                <i class="fal fa-unlock-alt mr-2"></i> Unlock Quote
              </a>
            </template>
          </transition>
        </div>
        <div v-else>
          This Quote is locked by {{ lockedByUser.firstName }} {{ lockedByUser.lastName }} -
          {{ lockedByUser.email }}
        </div>
      </div>

      <div v-if="quote.consumer" class="bg-white p-3 border mb-6">
        <div class="flex flex-wrap relative">
          <div class="xs:flex flex-grow">
            <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
              <div class="font-bold">Name</div>
              {{ quote.consumer.firstName }} {{ quote.consumer.lastName }}
            </div>
            <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
              <div class="font-bold">Email</div>
              <a :href="'mailto:' + quote.consumer.email">{{ quote.consumer.email }}</a>
            </div>
            <div class="flex-1 mt-3 xs:mt-0 xs:mr-6">
              <div class="font-bold">Tel</div>
              <a
                v-if="quote.consumer.mobile"
                :href="`tel:${quote.consumer.mobile}`"
                class="pb-3 xs:pb-0"
              >
                {{ quote.consumer.mobile }}
              </a>
            </div>
          </div>
          <div class="w-full mt-3">
            <div class="font-bold">Address</div>
            <div v-html="formatted_address"></div>
          </div>
        </div>
      </div>
      <div key="2" data-index="2">
        <template v-if="quote.jobs">
          <animation-staggered-fade>
            <contract-job
              v-for="(job, index) in quote.jobs"
              :key="index"
              :data-index="index"
              :job="job"
              :job-number="job.jobKey"
              :total-jobs="quote.jobs.length"
              :show-price="false"
              @delete-job="deleteJob(job)"
              @duplicate-job="duplicateJob(job)"
              @edit-job="editJob(job)"
              @order-job="orderJob(job)"
              @show-pdf-for-job="showPdf(job)"
              @pdf-price-request="showQuoteRequestPdf(job)"
              @pdf-survey-request="showSurveyRequestPdf(job)"
            ></contract-job>
          </animation-staggered-fade>
        </template>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import dateHelper from '@/mixins/dateHelper';
import currencyHelper from '@/mixins/currencyHelper';
import EnquiryJob from '@/components/shared/job/EnquiryJob.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'contract-job': EnquiryJob,
  },
  mixins: [dateHelper, currencyHelper],
  data() {
    return {
      open_line_item_details: false,
      requoter_open: false,
      requoter_type: 'items',
      quote: [],
      line_items: [],
      loading: true,
      lockedByUser: false,
      quote_unlocking: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    quote_name() {
      if (!this.quote.consumer) {
        return '';
      }

      return [this.quote.consumer.FirstName, this.quote.consumer.LastName]
        .filter(Boolean)
        .join(' ');
    },
    formatted_address() {
      const address = [
        this._.escape(this.quote.consumer.addressLine1),
        this._.escape(this.quote.consumer.addressLine2),
        this._.escape(this.quote.consumer.addressLine3),
        this._.escape(this.quote.consumer.addressTown),
        this._.escape(this.quote.consumer.addressCounty),
        this._.escape(this.quote.consumer.addressCode),
        this._.escape(this.quote.consumer.addressIso),
      ]
        .filter(Boolean)
        .join(', ');

      if (!this.quote.consumer.addressCode) {
        return address;
      }

      return `<a target="_blank" title="click to find directions" href="https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}">${address} <i class="fal fa-map-marker"></i> </a>`;
    },
  },
  async mounted() {
    await this.loadQuote(this.$route.params.id);
  },
  methods: {
    async deleteJob(jobToDelete) {
      if (this.quote.jobs.length === 1) {
        this.alertBox().fire({
          title: 'Unable to delete',
          icon: 'error',
          html: 'Cannot delete last job in contract',
        });

        return;
      }

      await this.$store.dispatch('touch/deleteJob', {
        contractId: this.quote.id,
        jobId: jobToDelete.jobKey,
      });

      this.loadQuote(this.quote.id);
    },
    async duplicateJob(jobToCopy) {
      this.loading = true;
      const { value: newReference } = await this.alertBox().fire({
        title: 'Enter reference for new Job',
        input: 'text',
        inputPlaceholder: 'Enter Reference',
      });

      if (!newReference) {
        this.alertBox().fire('Cancelled');
        return;
      }

      await this.$store.dispatch('touch/copyJob', {
        contractId: this.quote.id,
        jobId: jobToCopy.jobKey,
        reference: newReference,
      });

      this.loadQuote(this.quote.id);
    },
    async editJob(jobToCopy) {
      this.loading = true;
      this.$store
        .dispatch('basket/fromQuote', {
          contractId: this.quote.id,
          jobKey: jobToCopy.jobKey,
        })
        .then(() => {
          this.$store.commit('basket/setReference', this.quote.reference);
          this.routerPush('/basket');
        })
        .catch((error) => {
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
          this.loading = false;
        });
    },
    async unlockQuote() {
      this.quote_unlocking = true;
      await this.$store.dispatch('touch/unlockQuote', {
        contractId: this.$route.params.id,
      });
      await this.loadQuote(this.$route.params.id);
      this.quote_unlocking = false;
    },
    async orderJob(jobToOrder) {
      this.loading = true;
      this.$store
        .dispatch('basket/fromQuote', {
          contractId: this.quote.id,
          jobKey: jobToOrder.jobKey,
          isEnquiry: true,
        })
        .then(() => {
          this.$store.commit('basket/setReference', this.quote.reference);
          this.routerPush('/basket');
        })
        .catch((error) => {
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
          this.loading = false;
        });
    },
    async loadQuote(id) {
      this.loading = true;
      this.quote = await this.$store.dispatch('touch/loadQuote', {
        quoteId: id,
      });

      if (this.quote.locked) {
        await this.loadLockedByUser(this.quote.lockedBy);
      }

      this.loading = false;
    },
    show_line_item_details(lineItem) {
      if (this.open_line_item_details === lineItem) {
        this.open_line_item_details = null;
        return;
      }

      this.open_line_item_details = lineItem;
    },
    async showPdf(job) {
      await window.touch.downloadQuoteReport(this.$route.params.id, job.jobKey);
    },
    async showQuoteRequestPdf(job) {
      await window.touch.downloadQuoteRequestReport(this.$route.params.id, job.jobKey);
    },
    async loadLockedByUser(id) {
      this.$store.dispatch('user/customerGet', this.$route.params.customerId).then((resp) => {
        // eslint-disable-next-line prefer-destructuring
        this.lockedByUser = resp.users.filter((usr) => usr.id === id)[0] || {
          id: undefined,
          firstName: "Staff can't show",
          lastName: '',
          email: '',
        };
      });
    },
  },
};
</script>
