<template>
  <div class="flex flex-col flex-grow">
    <div class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full overflow-y-auto md:overflow-y-auto" style="flex: 1 1 0">
      <div class="flex flex-col gap-2 bg-white p-5 border border-gray flex-shrink-0">
        <loading v-if="isLoadingCategories" :loading="true" class="h-full" style="min-height: 200px; flex: 1 1 0"></loading>
        <div v-else-if="categoriesForDisplay.length > 0" class="overflow-y-auto" style="min-height: 200px; flex: 1 1 0">
          <div v-for="category in categoriesForDisplay" :key="category.id" class="mb-5">
            <div class="mb-1">{{ category.description }}</div>
            <div
              v-for="subCategory in stockPartSubcategoriesWithParts.filter(
                (x) => x.partCategoryId === category.id && x.description,
              )"
              :key="subCategory.id"
              class="cursor-pointer mb-2 px-5 py-1 rounded bg-gray-200"
              :class="{
                'bg-green-300':
                  openStockPartSubcategory && openStockPartSubcategory.id === subCategory.id,
              }"
              @click="openStockPartSubcategory = subCategory"
            >
              {{ subCategory.description }}
            </div>
          </div>
        </div>
        <div v-else class="overflow-hidden flex flex-col flex-grow justify-around text-center px-5"> No Categories Found</div>
      </div>
      <div class="flex flex-col gap-2 bg-white p-5 w-full border border-gray">
        <loading v-if="isLoadingSubCategories" :loading="true" class="h-full" style="min-height: 200px; flex: 1 1 0"></loading>
        <div v-else class="md:overflow-y-auto" style="flex: 1 1 0">
          <div class="flex-grow bg-white">
            <table class="sm:table-unstacked _with-hover w-full">
              <thead class="top-0 bg-white z-10 text-left hidden sm:table-header-group sticky">
                <tr class="pt-5">
                  <th class="pb-4">Colour</th>
                  <th class="pb-4">Code</th>
                  <th v-if="!isInCustomerMode" class="pb-4">Cost</th>
                  <th class="pb-4">Price</th>
                  <th v-if="isInCustomerMode" class="pb-4">FOC</th>
                  <th v-if="!isInCustomerMode" class="pb-4">Weight</th>
                  <th v-if="!isInCustomerMode" class="pb-4 w-20">Per</th>
                  <th v-if="!isInCustomerMode" class="pb-4">Customer Prices</th>
                </tr>
              </thead>
              <tbody v-for="part in organizedPartItems" :key="part.id">
                <tr>
                  <td colspan="7">
                    <div class="my-2 w-full">
                      <div class="bg-gray-200 text-center rounded w-full p-3">
                        <b>{{ part[0].part }}</b>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-for="(item, index) in part" :key="item.id + '-' + index">
                  <td v-if="item.colour" class="pt-5 sm:pt-0">
                    <div class="td-title w-24"> Colour: </div>
                    <span class="w-full">{{ item.colour }}</span>
                  </td>
                  <td v-else><span class="hidden sm:block">-</span></td>
                  <td v-if="item.code" class="pt-2 sm:pt-0">
                    <div class="td-title w-24"> Code: </div>
                    <span class="w-full">{{ item.code }}</span>
                  </td>
                  <td v-else><span class="hidden sm:block">-</span></td>
                  <td v-if="!isInCustomerMode" class="flex relative py-1 w-full sm:w-auto pt-2 sm:pt-0">
                    <div class="td-title w-24"> Cost: </div>
                    <div class="relative w-full">
                      <div class="absolute py-2 px-3 border rounded-l-lg">£</div>
                      <input
                        v-model="item.cost"
                        type="number"
                        class="flex-grow bg-white p-2 rounded-lg pl-12 w-full sm:24 lg:w-32 border border-l-0"
                        @input="hasChangedItems = true"
                      />
                    </div>
                  </td>
                  <td class="flex flex-row relative py-1 w-full sm:w-auto pt-2 sm:pt-0">
                    <div class="td-title w-24"> Price: </div>
                    <div class="relative w-full flex items-center gap-2">
                      <div v-if="isInCustomerMode" class="italic text-xs">
                        (Global Price: {{ item.price }})
                      </div>
                      <div class="relative">
                        <div class="absolute py-2 px-3 border rounded-l-lg">£</div>
                        <input
                          v-if="isInCustomerMode"
                          v-model="item.customerPrices.nettPrice"
                          :class="{ 'cursor-not-allowed text-gray-400': item.customerPrices.foc }"
                          :disabled="item.customerPrices.foc"
                          type="number"
                          class="flex-grow bg-white p-2 rounded-lg pl-12 w-full sm:24 lg:w-32 border border-l-0"
                          @input="hasChangedItems = true"
                        />
                        <input
                          v-else
                          v-model="item.price"
                          type="number"
                          class="flex-grow bg-white p-2 rounded-lg pl-12 w-full sm:24 lg:w-32 border border-l-0"
                          @input="hasChangedItems = true"
                        />
                      </div>
                    </div>
                  </td>
                  <td v-if="isInCustomerMode" class="pt-2 sm:pt-0">
                    <div class="td-title w-24"> FOC: </div>
                    <div class="w-full">
                      <input
                        v-model="item.customerPrices.foc"
                        type="checkbox"
                        @input="hasChangedItems = true"
                      />
                    </div>
                  </td>
                  <td v-if="!isInCustomerMode" class="relative flex flex-row w-full sm:w-auto pt-2 sm:pt-0">
                    <div class="td-title w-24"> Weight: </div>
                    <div class="relative border rounded-lg w-full sm:24 lg:w-32">
                      <div class="absolute py-2 px-3 right-0 border-l">kg</div>
                      <input
                        v-model="item.weight"
                        type="number"
                        class="flex-grow bg-white p-2 rounded-lg w-full pr-12"
                        @input="hasChangedItems = true"
                      />
                    </div>
                  </td>
                  <td v-if="!isInCustomerMode && item.per" class="pt-2 sm:pt-0">
                    <div class="td-title w-24"> Per: </div>
                    <span class="w-full">{{ item.per }}</span>
                  </td>
                  <td v-else-if="!isInCustomerMode" class="text-center">-</td>
                  <td v-if="item.hasCustomerPrices && !isInCustomerMode">
                    <div
                      class="text-center cursoir-pointer"
                      @click="itemIdForModalEditing = item.id"
                    >
                      <i class="fa fa-person"></i>
                    </div>
                  </td>
                  <td v-else-if="!isInCustomerMode"><span class="hidden sm:block">-</span></td>
                  <td v-if="index !== part.length - 1" class="sm:hidden">
                    <div class="border-t w-full my-5"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isLoadingCategories"
      class="text-right w-full p-3 border-t" 
      :class="{'pointer-events-none opacity-50': !hasChangedItems || isLoadingSubCategories}"
    >
      <button class="btn-action btn-lg" @click="saveSettings">Save Changes</button>
    </div>
    <PriceOverrides
      v-if="itemIdForModalEditing !== -1"
      :item-id="itemIdForModalEditing"
      :global-price="getItemPrice(itemIdForModalEditing)"
      :item-code="getItemCode(itemIdForModalEditing)"
      :colour="getItemColour(itemIdForModalEditing)"
      :part-description="getPartDescription(itemIdForModalEditing)"
      @close="itemIdForModalEditing = -1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PriceOverrides from '@/components/shared/StockPartsPricingOverrides.vue';

export default {
  components: {
    PriceOverrides,
  },
  props: {
    query: String,
    minimumQueryLength: Number,
  },
  data() {
    return {
      isLoadingCategories: true,
      isLoadingSubCategories: true,
      customer: {},
      items: [],
      hasChangedItems: false,
      selectedCategory: null,
      selectedSubCategory: null,
      searchTerm: '',
      categoryExpanded: true,
      isInCustomerMode: false,
      modalOpen: false,
      itemIdForModalEditing: -1,
      newFilter: '',
      filter: '',
    };
  },
  computed: {
    ...mapGetters('stockParts', {
      categoriesForDisplay: 'stockPartCategoriesWithSubcategories',
      subCategories: 'stockPartSubcategoriesForOpenCategory',
      stockPartSubcategoriesWithParts: 'stockPartSubcategoriesWithParts',
    }),
    ...mapState('stockParts', {
      results: 'queriedStockParts',
      searchQuery: 'searchQuery',
    }),
    openStockPartSubcategory: {
      get() {
        return this.$store.state.stockParts.openStockPartSubcategory;
      },
      set(subcategory) {
        if (!this.hasChangedItems) {
          this.$store.commit('stockParts/setOpenStockPartSubcategory', subcategory);
          return;
        }

        this.alertBox()
          .fire({
            title: `You have unsaved changes in this "${this.openStockPartSubcategory.description}" category`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save These Prices',
            denyButtonText: 'Discard Changes',
            cancelButtonText: `Back To ${this.openStockPartSubcategory.description}`,
          })
          .then(async (res) => {
            if (res.isDismissed) {
              return;
            }

            if (res.isConfirmed) {
              await this.saveSettings();
            }

            this.$store.commit('stockParts/setOpenStockPartSubcategory', subcategory);
          });
      },
    },
    openSubcategoryId() {
      if (!this.openStockPartSubcategory) {
        return undefined;
      }

      return this.openStockPartSubcategory.id;
    },
    subCategoriesForDisplay() {
      return this.subCategories;
    },
    organizedPartItems() {
      return Object.values(window._.groupBy(this.items, (item) => item.partId));
    },
  },
  watch: {
    subCategories: {
      handler() {
        this.$store.dispatch('stockParts/selectValidSubcategory');
      },
      deep: true,
    },
    openSubcategoryId: {
      async handler() {
        if (this.openSubcategoryId) {
          this.hasChangedItems = false;
          this.isLoadingSubCategories = true
          this.items = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
            subCategoryId: this.openSubcategoryId,
            customerId: this.$route.params.id,
          });
          this.isLoadingSubCategories = false
        }
      },
    },
    async searchQuery(to) {
      if (to === '') {
        this.isLoadingSubCategories = true
        this.items = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
          subCategoryId: this.openSubcategoryId,
          customerId: this.$route.params.id,
        });
        this.isLoadingSubCategories = false
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.loadCustomer();
      this.isInCustomerMode = true;
    }
    this.$store.commit('stockParts/initialize');

    await Promise.all([
      this.$store.dispatch('stockParts/loadStockPartCategories'),
      this.$store.dispatch('stockParts/loadStockPartSubcategories'),
    ]);

    this.$store.dispatch('stockParts/selectValidCategory');
    this.isLoadingCategories = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    getItemPrice(id) {
      return this.items.find((x) => x.id === id)?.price;
    },
    getItemCode(id) {
      return this.items.find((x) => x.id === id)?.code;
    },
    getItemColour(id) {
      return this.items.find((x) => x.id === id)?.colour;
    },
    getPartDescription(itemId) {
      return this.items.find((x) => x.id === itemId)?.part;
    },
    async loadCustomer() {
      this.customer = await this.customerGet(this.$route.params.id);
    },
    async saveSettings() {
      if (this.hasChangedItems) {
        this.isLoadingSubCategories = true
        if (this.isInCustomerMode) {
          await window.touch.stockUpdateStockCustomerPrice(
            this.items
              .filter(
                (x) =>
                  x.customerPrices.nettPrice !== undefined || x.customerPrices.foc !== undefined,
              )
              .map((x) => ({
                foc: x.customerPrices.foc,
                nettPrice: x.customerPrices.nettPrice,
                customerId: this.$route.params.id,
                stockId: x.id,
                id: x.customerPrices.id,
              })),
          );
        } else {
          await window.touch.stockUpdateStockItems(this.items);
        }
        this.isLoadingSubCategories = false
        this.hasChangedItems = false
      }
    },
  },
};
</script>

<style scoped>

@media (min-width: 768px) {
  .xs\:table-unstacked td {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  td {
    padding-left: 0;
  }
}

</style>