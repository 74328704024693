<template>
  <div class="relative">
    <i
      class="cursor-pointer fa-regular fa-info-circle align-middle block"
      :class="[iconSize ? iconSize : 'text-xl']"
      @mouseenter="isToolTip = true" 
      @mouseleave="isToolTip = false"
    >
    </i>
    <div
      v-if="isToolTip"
      class="text-white p-3 rounded tooltip bg-black absolute transform -translate-y-1/2"
      :class="[isLeft ? 'mr-2' : 'ml-2']"
      :style="{
        'width': width ? width + 'px' : 'auto', 
        'z-index': '999', 
        'left': isLeft ? 'auto' : '100%', 
        'right': isLeft ? '100%' : 'auto', 
        'top': '50%', 
        'min-width': width ? 'unset' : 'max-content'}"
    >
      <slot></slot>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    isLeft: {
      type: Boolean,
      default: true
    },
    width: Number,
    iconSize: String
  },
  data () {
    return {
      isToolTip: false,
    }
  }
}

</script>
