<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/dealer-network-setup'],
          ['Option Control'],
        ]"
      />
    </template>
    <template #header_page_title> Option Control </template>
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
      <router-link
        to="/dealer-network-setup/products"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black border-white"
      >
        Products
      </router-link>
      <router-link
        to="/dealer-network-setup/option-control"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 border-black"
      >
        Option Control 
      </router-link>
    </div>
    <div class="flex flex-col flex-grow overflow-y-auto">
      <div class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full" style="flex: 1 1 0">
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white p-5 border border-gray">
            <span class="text-lg">Option Groups</span>
          </div>
          <loading v-if="isLoadingOptionGroups" :loading="true" class="bg-white p-5 border border-gray h-full" style="flex: 1 1 0"></loading>
          <div v-else id="processing-options-groups-scroll" class="bg-white p-5 border border-gray overflow-y-scroll" style="flex: 1 1 0; min-height: 200px;">
            <draggable v-model="groups" class="flex flex-col gap-2 w-full" :tag="'div'" handle=".handle">
              <div
                v-for="group in groups"
                :key="group.id"
                class="bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white flex gap-5 items-center"
                :class="{ 'bg-green-600 text-white': group.id === groupId }"
                @click="validateOptionItems('groupId', group.id)"
              >
                <i class="td-content cursor-move fa fa-align-justify handle"></i>
                <span>{{ group.name }}</span>
              </div>
            </draggable>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white p-5 border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Headings</span>
              <div class="ml-auto">
                <Tooltip v-if="headings.length > 0" :is-left="false" :width="200">
                  <span>Enable or disable entire headings for the Retail Designer.</span>
                </Tooltip>
              </div>
            </div>
          </div>
          <loading v-if="isLoadingOptionGroups || isLoadingOptionHeadings" :loading="true" class="bg-white p-5 border border-gray h-full" style="flex: 1 1 0"></loading>
          <div
            v-else
            id="processing-options-headings-scroll"
            class="bg-white p-5 border border-gray overflow-y-auto"
            style="flex: 1 1 0; min-height: 200px;"
          >
            <div v-if="headingId" class="mb-5 flex gap-3">
              <span class="text-sm opacity-50">Selected: </span>
              <strong>{{ headings.filter((x) => x.id === headingId)[0].name }}</strong>
            </div>
            <draggable v-model="headings" class="flex flex-col gap-2 w-full" :tag="'div'" handle=".handle">
              <div
                v-for="heading in headings"
                :key="heading.id"
                class="bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white flex gap-5 items-center"
                :class="{ 'bg-green-600 text-white': heading.id === headingId }"
                @click="validateOptionItems('headingId', heading.id)"
              >
                <i class="td-content cursor-move fa fa-align-justify handle"></i>
                <span>{{ heading.name }}</span>
                <div
                  class="ml-auto flex items-center gap-5"
                >
                  <span class="text-xs opacity-50">{{
                    heading.consumer ? 'Enabled' : 'Disabled'
                  }}</span>
                  <div class="relative cursor-pointer" @click.stop="heading.consumer = !heading.consumer">
                    <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                    <div class="switch-toggle" :class="{ _active: heading.consumer }"></div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white p-5 border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Items</span>
              <div class="ml-auto">
                <Tooltip v-if="items.length > 0" :width="200">
                  <span>Enable or Disable individual options for the Retail Designer.</span>
                </Tooltip>
              </div>
            </div>
          </div>
          <loading
            v-if="isLoadingOptionGroups || isLoadingOptionHeadings || isLoadingOptionItems"
            :loading="true"
            class="bg-white p-5 border border-gray h-full"
            style="flex: 1 1 0"
          ></loading>
          <div v-else class="bg-white p-5 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
            <div
              v-if="isHeadingDisabled"
              class="bg-yellow-500 p-5 rounded mb-5"
            >
              <strong>Enable Option Heading to toggle Option Items.</strong>
            </div>
            <draggable v-model="items" class="flex flex-col gap-2 w-full" :tag="'div'" handle=".handle">
              <div
                v-for="item in items"
                :key="item.id"
                class="flex items-center gap-5 bg-gray-200 rounded lg p-2"
              >
                <i class="td-content cursor-move fa fa-align-justify handle"></i>
                <span>{{ item.name }}</span>
                <div
                  class="ml-auto flex items-center gap-5"
                  :class="{'pointer-events-none opacity-50': isHeadingDisabled}"
                >
                  <span class="text-xs opacity-50">{{
                    item.consumer ? 'Enabled' : 'Disabled'
                  }}</span>
                  <div class="relative cursor-pointer" @click.stop="item.consumer = !item.consumer">
                    <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                    <div class="switch-toggle" :class="{ _active: item.consumer }"></div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 text-right p-3 bg-white border-gray-300 border-t">
        <button
          class="btn-action btn-lg"
          :class="{
            'opacity-50 pointer-events-none bg-gray-500 border-gray-500':
              JSON.stringify(items) === JSON.stringify(originalItems) && JSON.stringify(headings) === JSON.stringify(originalHeadings) && JSON.stringify(groups) === JSON.stringify(originalGroups),
          }"
          @click="save()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import Tooltip from '@/components/shared/Tooltip.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import draggable from 'vuedraggable';

export default {
  components: {
    Breadcrumbs,
    Tooltip,
    draggable
  },
  data() {
    return {
      customer: undefined,
      loading: true,
      groups: [],
      originalGroups: [],
      groupId: undefined,
      headings: [],
      originalHeadings: [],
      headingId: undefined,
      items: [],
      originalItems: [],
      isHeaderPopUp: false,
      isLoadingOptionGroups: false,
      isLoadingOptionHeadings: false,
      isLoadingOptionItems: false
    };
  },
  computed: {
    isHeadingDisabled () {
      return this.items.length > 0 && this.headings.some(({ id, consumer }) => id === this.items[0].optionHeadingId && consumer === false)
    }
  },
  watch: {
    groupId: {
      async handler() {
        if (this.groupId) {
          this.headings = await this.getOptionHeadingsByGroup(this.groupId);
          this.headingId = undefined;
          this.items = [];
          this.originalItems = [];
          this.originalHeadings = JSON.parse(JSON.stringify(this.headings));
          document.getElementById('processing-options-groups-scroll').scrollTo({ top: 0 });
          document.getElementById('processing-options-headings-scroll').scrollTo({ top: 0 });
        }
      },
    },
    headingId: {
      async handler() {
        if (this.headingId) {
          this.items = await this.getCustomerOptionItems(this.headingId);
          this.originalItems = JSON.parse(JSON.stringify(this.items));
          document.getElementById('processing-options-groups-scroll').scrollTo({ top: 0 });
          document.getElementById('processing-options-headings-scroll').scrollTo({ top: 0 });
        }
      },
    },
  },
  async created() {
    this.customer = this.$store.state.user.customer
    this.groups = await this.getOptionGroups();
    this.originalGroups = JSON.parse(JSON.stringify(this.groups));
    this.groupId = this.groups.length > 0 ? this.groups[0].id : undefined;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    async getOptionGroups() {
      this.isLoadingOptionGroups = true
      const { groups } = (await window.touch.staffGetFenestrationOptionGroups(this.customer.datasetIds[0]));
      groups.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionGroups = false
      return groups;
    },
    async getOptionHeadingsByGroup(groupId) {
      this.isLoadingOptionHeadings = true
      const { headings } = await window.touch.staffGetFenestrationOptionHeadingsForGroup(this.customer.datasetIds[0], groupId);
      headings.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionHeadings = false
      return headings;
    },
    async getCustomerOptionItems(headingId) {
      this.isLoadingOptionItems = true
      const { items } = await window.touch.staffGetFenestrationOptionItemsForHeading(
        this.customer.datasetIds[0],
        headingId,
        this.customer.customerId,
      );
      items.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionItems = false
      return items;
    },
    validateOptionItems(property, value) {
      if (
        JSON.stringify(this.items) !== JSON.stringify(this.originalItems) || 
        JSON.stringify(this.headings) !== JSON.stringify(this.originalHeadings) && property === 'groupId'
      ) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this[property] = value;
            }
          });
      } else {
        this[property] = value;
      }
    },
    getChanged () {
      let changedGroups = [];
      let changedHeadings = [];
      let changedItems = [];
      if (JSON.stringify(this.groups) !== JSON.stringify(this.originalGroups)) {
        for (let index = 0; index < this.groups.length; index += 1) {
          if (!this.originalGroups.some((x, originalIndex) => x.id === this.groups[index].id && index === originalIndex) || this.groups[index].listIndex !== index) {
            changedGroups.push({
              Id: this.groups[index].id,
              ListIndex: index,
              Name: this.groups[index].name,
            });
          }
        }
      }
      if (JSON.stringify(this.headings) !== JSON.stringify(this.originalHeadings)) {
        for (let index = 0; index < this.headings.length; index += 1) {
          if (!this.originalHeadings.some((x, originalIndex) => x.id === this.headings[index].id && index === originalIndex && x.consumer === this.headings[index].consumer) || this.headings[index].listIndex !== index) {
            changedHeadings.push({
              Id: this.headings[index].id,
              ListIndex: index,
              Consumer: this.headings[index].consumer,
              Name: this.headings[index].name,
            });
          }
        }
      }
      if (JSON.stringify(this.items) !== JSON.stringify(this.originalItems)) {
        for (let index = 0; index < this.items.length; index += 1) {
          if (!this.originalItems.some((x, originalIndex) => x.id === this.items[index].id && index === originalIndex && x.consumer === this.items[index].consumer) || this.items[index].listIndex !== index) {
            changedItems.push({
              Id: this.items[index].id,
              ListIndex: index,
              OptionHeadingId: this.items[index].optionHeadingId,
              Consumer: this.items[index].consumer,
              Name: this.items[index].name,
            });
          }
        }
      }
      return {
        changedGroups,
        changedHeadings,
        changedItems,
      }
    },
    async save() {
      const { changedItems, changedHeadings, changedGroups } = this.getChanged()
      this.alertBox()
        .fire({
          title: 'Save Changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading =  true
            await window.touch.UpdateOptionConfigurations(
              this.customer.datasetIds[0],
              changedGroups,
              changedHeadings,
              changedItems,
            );
            this.originalItems = JSON.parse(JSON.stringify(this.items));
            this.originalHeadings = JSON.parse(JSON.stringify(this.headings));
            this.originalGroups = JSON.parse(JSON.stringify(this.groups));
            this.loading =  false
          }
        });
    },
  },
};
</script>
