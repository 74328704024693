<template>
  <portal v-if="$route.query.hideIntroPopUp" to="portal_popup">
    <modal-window
      :is-form="false"
      :modal_open="true"
      :max-width="1250"
      title="Introduction"
      @close="
        $router.push({ query: { ...$route.query, hideIntroPopUp: undefined } }).catch(() => {})
      "
    >
      <div class="md:flex gap-10">
        <div class="lg:flex-1 bg-gray-200 rounded-lg flex flex-col text-center p-5">
          <div class="mb-5">
            <strong class="text-2xl">Welcome to {{ companyName }} Quotes & Orders</strong>
          </div>
          <div class="mb-5">
            <span>Here's a few videos to get you started.</span>
          </div>
          <div class="mb-5 px-10">
            <div class="relative rounded-full overflow-hidden inline-block">
              <img src="/images/knowledgebase.png" />
            </div>
          </div>
          <div class="mb-5">
            <span>Visit our Knowledge Base for a whole host of videos explaining every feature of TOUCH
              Portal.</span>
          </div>
          <div class="mb-5 mt-auto">
            <a
              class="flex rounded-lg bg-gradient-to-r from-orange-400 via-pink-500 to-red-500 py-3 px-5 items-center text-white cursor-pointer max-w-lg mx-auto"
              href="https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252285"
              target="_blank"
            >
              <div
                class="mr-5 bg-blue-400 rounded-lg p-1 border-solid border-2 border-white w-8 h-8 box-content"
              >
                <i class="text-md fa fa-video-camera" :style="{ 'line-height': '2rem' }"></i>
              </div>
              <div class="text-left">
                <div>
                  <span class="text-lg">Visit Knowledge Base</span>
                </div>
                <div>
                  <span class="text-sm">Free Help & Support</span>
                </div>
              </div>
              <div class="ml-auto">
                <i class="text-lg fa fa-chevron-right"></i>
              </div>
            </a>
          </div>
        </div>
        <div class="mt-5 md:mt-0" :style="{ flex: '2 1' }">
          <div class="mb-5 text-center">
            <strong class="text-2xl">Explore the Knowledge Base</strong>
          </div>
          <div
            class="grid xs:grid-cols-2 md:grid-cols-none lg:grid-cols-2 gap-5 mx-auto"
            :style="{ 'grid-auto-rows': '1fr' }"
          >
            <a
              v-for="item in articles"
              :key="item.id"
              :href="item.url"
              target="_blank"
              class="bg-gray-200 rounded-lg p-5 flex"
            >
              <div class="relative flex-1">
                <img :src="'/images/knowledge-base/' + item.imageURL" class="w-100 rounded-lg" />
              </div>
              <div class="flex-1 pl-5">
                <div v-if="item.duration">
                  <span class="text-xs">{{ item.duration }}</span>
                </div>
                <div class="mb-3">
                  <span class="text-lg">{{ item.title }}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="w-64 mt-5">
        <label class="flex items-center">
          <input v-model="hideIntroPopUp" type="checkbox" />
          <span class="ml-2 p-1">Don't show again</span>
        </label>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    companyName: String,
  },
  data() {
    return {
      hideIntroPopUp: false,
    };
  },
  computed: {
    articles () {
      if (this.isLeadGen) {
        return this.$store.state.knowledgeBase.articles.filter(x => x.id !== '5')
      }
      return this.$store.state.knowledgeBase.articles
    },
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
  watch: {
    hideIntroPopUp() {
      this.setHideIntroPopUp(this.hideIntroPopUp);
    },
  },
  mounted() {
    this.hideIntroPopUp = this.$store.state.branded.hideIntroPopUp;
    if (!this.$store.state.branded.hideIntroPopUp) {
      this.checkIsBefore30Days();
    }
  },
  methods: {
    ...mapMutations('branded', {
      setHideIntroPopUp: 'setHideIntroPopUp',
    }),
    checkIsBefore30Days() {
      const dateSinceStoreCleared = new Date(this.$store.state.branded.dateSinceStoreCleared);
      const ThirtyDaysTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      if (dateSinceStoreCleared < ThirtyDaysTime) {
        setTimeout(() => {
          this.$router
            .push({ query: { ...this.$route.query, hideIntroPopUp: true } })
            .catch(() => {});
        }, 3000);
      }
    },
  },
};
</script>
