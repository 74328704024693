<template>
  <table class="table-lined xs:table-unstacked _with-hover w-full">
    <thead>
      <tr>
        <th class="w-10">Image</th>
        <th>Title</th>
        <th class="w-1/2">Description</th>
        <th class="w-10">Order</th>
        <th class="w-10">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <show-product-group
        v-for="group in groups"
        :key="group.id"
        :group="group"
        @delete="$emit('delete', group.id)"
        @edit="$emit('edit', group.id)"
      ></show-product-group>
    </tbody>
  </table>
</template>

<script>
import ShowProductGroup from '@/components/business/productSetup/manageProductGroups/ShowProductGroup.vue';

export default {
  components: {
    'show-product-group': ShowProductGroup,
  },
  props: ['groups'],
};
</script>

<style scoped></style>
