<template>
  <img ref="img" :src="url" @error="checkForFallback()" @load="onloadMethod" />
</template>

<script>
export default {
  props: {
    src: {
      required: true,
    },
    fallback: {
      required: false,
      default: false,
    },
    loadMethod: {
      required: false,
    },
  },
  computed: {
    url() {
      if (this.src.indexOf('//') !== -1) {
        // if it's a full url don't append base.
        return this.src;
      }

      return window.touch.imageBaseURL + this.src;
    },
  },
  methods: {
    checkForFallback() {
      if (this.fallback) {
        this.$refs.img.src = this.fallback;
      }
    },
    onloadMethod(event) {
      if (this.loadMethod) {
        this.loadMethod(event);
      }
    },
  },
};
</script>
