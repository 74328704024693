<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Staff & Licenses'],
        ]"
      />
    </template>
    <template #header_page_title> Staff & Licenses </template>
    <template #header_page_actions>
      <div v-if="$refs.StaffAndLicenses && !$refs.StaffAndLicenses.loading">
        <div v-if="!demo_mode" class="px-3 md:px-10 flex items-center">
          <a
            class="btn-action"
            @click.stop.prevent="$refs.StaffAndLicenses.add_staff_popup_open = true"
          >
            Add Staff
          </a>
        </div>
      </div>
    </template>
    <Menu></Menu>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden">
      <StaffAndLicenses ref="StaffAndLicenses" :branches="branches"></StaffAndLicenses>
    </div>
  </touch-layout>
</template>

<script>
import StaffAndLicenses from '@/components/shared/StaffAndLicenses.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/business/customer/Menu.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    StaffAndLicenses,
    Breadcrumbs,
    Menu,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      branches: [],
    };
  },
  async mounted() {
    const response = await this.customerListBranches({
      limit: 20,
      offset: 0,
    });
    this.branches = response.branches;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
      customerUpdateUsers: 'user/customerUpdateUsers',
      customerGetProcessingLevels: 'user/customerGetProcessingLevels',
      customerListBranches: 'user/customerListBranches',
    }),
  },
};
</script>
