<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          [$t('link.My Branded Mode Account'), '/dealer-network-setup'],
          ['Products'],
        ]"
      />
    </template>
    <template #header_page_title> Products </template>
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
      <router-link
        to="/dealer-network-setup/products"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 border-black"
      >
        Products
      </router-link>
      <router-link
        v-if="false"
        to="/dealer-network-setup/option-control"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black border-white"
      >
        Option Control 
      </router-link>
    </div>  
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <ProductsList v-if="product_overrides" :product_overrides="product_overrides" setup="Dealer Network Mode" @enable="enable">
        <template #show_products="{ show_products_data }">
          <label class="mt-2 flex items-center">
            <input
              v-model="show_products_data.customerEnabledRetail"
              class="mr-2"
              :disabled="demo_mode"
              type="checkbox"
              :value="show_products_data.productId"
            />
            <span>Show Product To Consumers</span>
          </label>
        </template>
      </ProductsList>
    </div>
  </touch-layout>
</template>

<script>
import ProductsList from '@/components/shared/setup/products/ProductsList.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
    ProductsList,
  },
  data() {
    return {
      customerId: undefined,
      product_overrides: undefined,
    };
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
    this.product_overrides = await this.customerGetAllProductsWithOverrides(this.customerId);
  },
  methods: {
    ...mapActions({
      customerGetAllProductsWithOverrides: 'user/customerGetAllProductsWithOverrides',
    }),
    enable() {
      this.product_overrides.forEach((product) => {
        if (product.customerEnabledRetail) {
          // eslint-disable-next-line no-param-reassign
          product.enabledRetail = true;
          // eslint-disable-next-line no-param-reassign
          product.customerEnabled = true;
          // eslint-disable-next-line no-param-reassign
          product.enabled = true;
        }
      });
    }
  },
};
</script>
