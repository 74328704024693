export default {
  namespaced: true,
  actions: {
    async getProductSelectionGroups() {
      return (await window.touch.commonGetProductSelectionGroups()).data;
    },
    async getProductSelectionTypes() {
      return (await window.touch.commonGetProductSelectionTypes()).data;
    },
    async sendEnquiryToTouchStore(
      context,
      { companyName, contactFirstname, contactSurname, contactPhone, contactEmail },
    ) {
      return window.touch.sendEnquiryToTouchStore(
        companyName,
        contactFirstname,
        contactSurname,
        contactPhone,
        contactEmail,
      );
    },
  },
};
