<template>
  <div
    id="error"
    class="absolute top-0 left-0 right-0 bottom-0 bg-white flex flex-col justify-center text-center"
  >
    <div class="text-3xl">Something has gone wrong!</div>
    <div v-if="errorType !== enums.errorType.GENERIC">{{ getErrorMessage() }}</div>
  </div>
</template>
<script>
export default {
  props: {
    errorType: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getErrorMessage() {
      switch (this.errorType) {
        case this.enums.errorType.COOKIES_DISABLED:
          return 'In order to continue, please ensure you have cookies enabled.';
        case this.enums.errorType['404']:
          return '404: This page could not be found.';
        default:
          return '';
      }
    },
  },
};
</script>
