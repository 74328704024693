import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.customerUpdateCompanySettings = async function customerUpdateCompanySettings(
  showSizes,
  showDesignNames
) {
  return (
    await this.authenticatedPost('customer/UpdateCompanySettings', {
      ShowconsumerSizes: showSizes,
      ShowDesignNames: showDesignNames
    })
  ).data;
};

Touch.prototype.customerGetCompanySettings = async function customerGetCompanySettings() {
  return this.parseResponse(await this.authenticatedPost('customer/GetCompanySettings'));
};
Touch.prototype.customerGetProcessingLevels = async function customerGetProcessingLevels() {
  return this.parseResponse(await this.authenticatedPost('customer/GetProcessingLevels'));
};

Touch.prototype.customerUpdateProductDefault = async function customerUpdateProductDefault(
  id,
  productId,
  optionItemId,
  datasetId,
  customerId,
) {
  return this.authenticatedPost('customer/UpdateProductDefault', {
    Id: id,
    ProductId: productId,
    OptionItemId: optionItemId,
    DatasetId: datasetId,
    CustomerId: customerId,
  });
};

Touch.prototype.customerGetProductDefaultDetails = async function customerGetProductDefaultDetails(
  id,
  productId,
  datasetId,
  customerId,
) {
  return this.authenticatedPost('customer/GetProductDefaultDetails', {
    Id: id,
    ProductId: productId,
    DatasetId: datasetId,
    CustomerId: customerId,
  });
};

Touch.prototype.customerGetProductDefaults = async function customerGetProductDefaults(
  productId,
  datasetId,
  customerId,
) {
  return this.authenticatedPost('customer/GetProductDefaults', {
    ProductId: productId,
    DatasetId: datasetId,
    CustomerId: customerId,
  });
};

Touch.prototype.customerGetProductDefaultsWithDetails =
  async function customerGetProductDefaultsWithDetails(productId, datasetId, customerId) {
    return this.authenticatedPost('customer/GetProductDefaultsWithDetails', {
      ProductId: productId,
      DatasetId: datasetId,
      CustomerId: customerId,
    });
  };

Touch.prototype.customerGetProductsWithDefaults = async function customerGetProductsWithDefaults(
  datasetId,
) {
  return this.authenticatedPost('customer/GetProductsWithDefaults', {
    DatasetId: datasetId,
  });
};

Touch.prototype.customerGetExtraItemOptions = async function customerUpdateExtraItemOptions(
  itemId,
) {
  return this.parseResponse(
    await this.authenticatedPost('customer/GetExtraItemOptions', { itemId }),
  );
};

Touch.prototype.customerUpdateExtraItemOptions = async function customerUpdateExtraItemOptions( params ) {
  return this.parseResponse( 
    await this.authenticatedPost('customer/UpdateExtraItemOptions', {
      Options: params,
    }),
    errorCallback,
    (r) => successCallback('Save', r.data)
  )
};
Touch.prototype.customerUpdateExtraItems = async function customerUpdateExtraItems(params) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateExtraItems', {
      Items: params,
    }),
    errorCallback,
    (r) => successCallback(params.length === 1 && params[0].Delete ? 'Delete' : 'Save', r.data),
  );
};
Touch.prototype.customerUpdateExtraItemGroups = async function customerUpdateExtraItemGroups(
  params,
) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateExtraItemGroups', {
      Groups: params,
    }),
    errorCallback,
    (response) => successCallback(params[0].Delete ? 'Delete' : 'Save', response.data),
  );
};

Touch.prototype.customerGetAllExtraItems = async function customerGetAllExtraItems() {
  return this.parseResponse(await this.authenticatedPost('customer/GetAllExtraItems'));
};

Touch.prototype.customerGetExtraItemGroups = async function customerGetExtraItemGroups() {
  return this.parseResponse(await this.authenticatedPost('customer/GetExtraItemGroups'));
};

Touch.prototype.customerGetExtraItems = async function customerGetExtraItems(GroupId) {
  return this.parseResponse(await this.authenticatedPost('customer/GetExtraItems', { GroupId }));
};

Touch.prototype.customerCheckSeats = async function customerCheckSeats(customerId) {
  const options = customerId ? { CustomerId: parseInt(customerId, 10) } : {};

  return this.parseResponse(await this.authenticatedPost('Customer/CheckSeats', options), () => {
    window.alertBox.fire('Unable to check Seats');
    return 1;
  });
};

Touch.prototype.customerGet = async function customerGet(customerId, staffLogin) {
  let options = {};
  if (staffLogin) {
    options = {
      CustomerId: parseInt(customerId, 10),
    };
  }
  return this.parseResponse(await this.authenticatedPost('customer/Get', options), errorCallback)
};

Touch.prototype.customerGetGuest = async function customerGetGuest() {
  return this.parseResponse(await this.authenticatedPost('customer/GetForGuest'), errorCallback)
};

Touch.prototype.customerUpdate = async function customerUpdate(customer, staffLogin) {
  const Customer = {
    Company: {
      email: customer.company.email,
      phone: customer.company.phone,
      web: customer.company.web,
    },
    Styling: {
      logo: customer.newLogo,
      primaryColor: Number(customer.styling.primaryColor),
      primaryTextColor: Number(customer.styling.primaryTextColor),
    },
    DealerNetwork: customer.dealerNetwork,
    ECommerceIntegration: {
      Enabled: customer.eCommerceIntegration.enabled,
      Alias: customer.eCommerceIntegration.alias,
      WebAddress: customer.eCommerceIntegration.webAddress,
      Email: customer.eCommerceIntegration.email,
    },
    Financial: {
      vatEnabled: Boolean(customer.financial.vatEnabled),
      taxValue: Number(customer.financial.taxValue),
      requoteMarkup: Number(customer.financial.requoteMarkup),
      currencyId: Number(customer.financial.currencyId),
    },
  };

  if (staffLogin) {
    Customer.Customer = {
      salesSectorType: Number(customer.customer.salesSectorType),
      salesContactId: Number(customer.customer.salesContactId),
      generalLicenseStatus: Number(customer.customer.generalLicenseStatus),
      eCommerceIntegrationLicenseStatus: Number(
        customer.customer.eCommerceIntegrationLicenseStatus,
      ),
      dealerNetworkLicenseStatus: Number(customer.customer.dealerNetworkLicenseStatus),
    };
    Customer.CustomerId = customer.customerId;
    Customer.Financial.accountCode = customer.financial.accountCode;
    Customer.Financial.paymentWithOrder = customer.financial.paymentWithOrder;
    Customer.Financial.defaultTaxRateId = Number(customer.financial.defaultTaxRateId);
    Customer.Company.name = customer.company.name;
  }
  await this.authenticatedPost('customer/Update', Customer);
};

Touch.prototype.customerUpdateDeliveryDays = async function customerUpdateDeliveryDays(
  customer,
  staffLogin,
) {
  const Customer = {
    Delivery: customer.delivery,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }
  return this.parseResponse(
    await this.authenticatedPost('customer/Update', Customer),
    errorCallback,
    () => successCallback('Save'),
  );
};

// pass userIdsToUpdate or create new customer without id
Touch.prototype.customerUpdateUsers = async function customerUpdateUsers(
  customer,
  staffLogin,
  userIdsToUpdate,
) {
  let usersToUpdate = [];

  if (!userIdsToUpdate) usersToUpdate = customer.users.filter((x) => !x.id);
  else usersToUpdate = customer.users.filter((x) => userIdsToUpdate.find((y) => y === x.id));

  const Users = usersToUpdate.map((user) => ({
    ...user,
    licenseStatusType: Number(user.licenseStatusType),
    processingLevelId: Number(user.processingLevelId),
  }));
  const Customer = {
    Users,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }

  return this.parseResponse(
    await this.authenticatedPost('customer/Update', Customer),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.customerUpdateAddresses = async function customerUpdateAddresses(
  customer,
  staffLogin,
) {
  const Customer = {
    Addresses: customer.addresses,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }
  return this.parseResponse(
    await this.authenticatedPost('customer/Update', Customer),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.customerUpdateVendorAddress = async function customerUpdateVendorAddress(
  customer,
  staffLogin,
) {
  const Customer = {
    Addresses: customer.addresses,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }
  await this.parseResponse(
    await this.authenticatedPost('customer/Update', Customer),
    errorCallback,
    () => successCallback('Save'),
  );

  let params = {};

  if (staffLogin) {
    params = {
      CustomerId: customer.customerId,
    };
  }
  const { data } = await this.authenticatedPost('customer/Get', params);

  return data.addresses;
};

Touch.prototype.customerCreate = async function customerCreate(customer) {
  const Customer = {
    Name: customer.name,
    SalesSector: customer.salesSector,
    Email: customer.email,
    Phone: customer.phone,
    Web: customer.web,
    DefaultTaxId: customer.defaultTaxId,
    Branches: customer.branches,
  };
  const data = await this.parseResponse(
    await this.authenticatedPost('customer/Create', Customer),
    errorCallback,
  );
  return data.id;
};

Touch.prototype.customerListContacts = async function customerListContacts() {
  const { data } = await this.authenticatedPost('Customer/ListContacts');
  return data;
};

Touch.prototype.customerGetFinancialGroups = async function customerGetFinancialGroups(datasetId) {
  const { data } = await this.authenticatedPost('Customer/GetFinancialGroups', {
    DatasetId: datasetId,
  });
  return data;
};

Touch.prototype.customerGetContact = async function customerGetContact(ContactId) {
  const { data } = await this.authenticatedPost('Customer/GetContact', {
    ContactId: parseInt(ContactId, 10),
  });
  return data;
};

Touch.prototype.customerEditContact = async function customerEditContact(Contact) {
  const user = Contact;
  user.licenseStatusType = Number(Contact.licenseStatusType);
  return this.parseResponse(
    await this.authenticatedPost('Customer/EditContact', user),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.customerCreateContact = async function customerCreateContact(Contact) {
  return this.parseResponse(
    await this.authenticatedPost('Customer/CreateContact', Contact),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.customerUpdateCommerceIntegrationSettings =
  async function customerUpdateCommerceIntegrationSettings(
    eCommerceIntegrationSettings,
    customerId,
  ) {
    const Customer = {
      ECommerceIntegration: {
        Alias: eCommerceIntegrationSettings.alias,
        WebAddress: eCommerceIntegrationSettings.webAddress,
        Email: eCommerceIntegrationSettings.email,
        mobilePhone: eCommerceIntegrationSettings.mobilePhone,
        privacyPolicyDocumentId: eCommerceIntegrationSettings.privacyPolicyDocumentId,
        addressId: eCommerceIntegrationSettings.addressId,
        companyRegNumber: eCommerceIntegrationSettings.companyRegNumber,
        fensaRegistrationNumber: eCommerceIntegrationSettings.fensaRegistrationNumber,
        vatRegistrationNumber: eCommerceIntegrationSettings.vatRegistrationNumber,
        priceDisplayMode: Number(eCommerceIntegrationSettings.priceDisplayMode),
      },
      CustomerId: customerId,
    };
    return this.parseResponse (
      await this.authenticatedPost('customer/Update', Customer),
      errorCallback,
      () => successCallback('Save'),
    )
  };

Touch.prototype.customerUpdateFinancialSettings = async function customerUpdateFinancialSettings({
  vatEnabled,
  taxValue,
  fittingRate,
}) {
  const Customer = {
    Financial: {
      vatEnabled: Boolean(vatEnabled),
      taxValue: Number(taxValue),
      fittingRate: Number(fittingRate),
    },
  };
  await this.authenticatedPost('customer/Update', Customer);
};

Touch.prototype.customerGetProductOverrides = async function customerGetProductOverrides() {
  const { data } = await this.authenticatedPost('Customer/GetProductOverrides', {});
  return data;
};

Touch.prototype.customerGetAllProductsWithOverrides = async function customerGetProductOverrides(
  customerId,
) {
  const { data } = await this.authenticatedPost('Customer/GetAllProductsWithOverrides', {
    CustomerId: customerId !== undefined ? Number(customerId) : undefined,
  });

  return data;
};

Touch.prototype.customerUpdateProductOverrides = async function customerUpdateProductOverrides(
  overrides,
  customerId,
) {
  return this.parseResponse(
    await this.authenticatedPost('Customer/UpdateProductOverrides', {
    CustomerId: customerId,
    Overrides: overrides,
    }),
    errorCallback,
    () => successCallback('Save')
  )
};

Touch.prototype.customerUploadDocumentWithUrl = async function customerUploadDocumentWithUrl(
  document,
) {
  const resp = await this.authenticatedPost('customer/UploadDocumentWithUrl', {
    Id: document.id,
    Type: Number(document.type),
    CategoryId: document.categoryId,
    Name: document.name,
    OverwriteCurrentSingleDocument: true,
    Url: document.url,
    VideoType: document.videoType,
    DealerNetworkPartnerOnly: document.DealerNetworkPartnerOnly,
    ImageBlob: document.ImageBlob,
  });
  return resp.data.id;
};

Touch.prototype.UpdateCustomerOnlyGlass = async function UpdateCustomerOnlyGlass(
  CustomerId,
  UseCustomerOnlyGlass,
) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateCustomerOnlyGlass', {
      CustomerId,
      UseCustomerOnlyGlass,
    }),
  );
};

Touch.prototype.customerUpdateRaw = async function customerUpdateRaw(customer) {
  return this.parseResponse(
    await this.authenticatedPost('customer/Update', customer),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.updateCustomerRequoteDetails = async function updateCustomerRequoteDetails(
  customer,
) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateCustomerRequoteDetails', {
      CustomerId: customer.customerId,
      RequoteEmail: customer.requoteEmail,
      RequoteEmailSubject: customer.requoteEmailSubject,
      RequoteEmailBodyText: customer.requoteEmailBodyText,
    }),
    errorCallback,
    () => successCallback('Save')
  );
};

Touch.prototype.customerUpdateLogo = async function customerUpdateLogo(logo, customerId) {
  const Customer = {
    Styling: {
      logo,
    },
    CustomerId: customerId,
  };
  await this.authenticatedPost('customer/Update', Customer);
};

Touch.prototype.customerGetProcessingLevels = async function customerGetProcessingLevels() {
  const resp = await this.authenticatedPost('Customer/GetProcessingLevels');
  return resp.data;
};

Touch.prototype.listTicketContacts = async function listTicketContacts() {
  const resp = await this.authenticatedPost('Customer/ListTicketContacts');
  return resp.data;
};

Touch.prototype.organisationUpdate = async function organisationUpdate(
  organisationId,
  users,
  defaultTicketContact,
  workingDays,
) {
  const obj = {
    organisationId,
    users,
  };
  if (defaultTicketContact) {
    obj.company = {};
    obj.company.DefaultTicketContact = defaultTicketContact;
  }
  if (workingDays) {
    obj.company = {};
    obj.company.WorkingDays = workingDays;
  }
  return this.parseResponse(
    await this.authenticatedPost('organisation/Update', obj),
    errorCallback,
    () => successCallback('Save'),
  );
};
Touch.prototype.organisationGet = async function organisationGet() {
  const resp = await this.authenticatedPost('organisation/Get');
  return resp.data;
};

Touch.prototype.resendActivationEmail = async function resendActivationEmail(contactId) {
  const resp = await this.authenticatedPost('Customer/ResendActivationEmail', {
    ContactId: contactId,
  });
  return resp.data;
};

Touch.prototype.customerCreateBranch = async function customerCreateBranch(branch) {
  const Branch = {
    Name: branch.name,
    Email: branch.email,
    Phone: branch.phone,
    Address: {
      Town: branch.town,
      County: branch.county,
    },
  };
  return this.parseResponse(
    await this.authenticatedPost('customer/CreateBranch', Branch),
    errorCallback,
    (response) => successCallback('Save', response.data),
  );
};

Touch.prototype.customerListBranches = async function customerListBranches(
  limit,
  offset,
  searchName,
  contractId,
) {
  const response = await this.authenticatedPost('customer/ListBranchCompanies', {
    limit,
    offset,
    searchName,
    ContractIDForDistance: contractId,
  });
  const branches = response.data.availableBranches.map((branch) => ({
    ...branch,
    url: `/branch/${branch.id}/details`,
  }));
  return {
    branches,
  };
};

Touch.prototype.customerBranchUpdate = async function customerBranchUpdate(branch) {
  this.parseResponse(
    await this.authenticatedPost('customer/UpdateBranch', {
      Id: parseInt(branch.id, 10),
      Name: branch.name,
      Email: branch.email,
      Phone: branch.phone,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.customerGetCompany = async function customerGetCompany(companyId) {
  return this.authenticatedPost('customer/GetCompany', { CompanyId: parseInt(companyId, 10) });
};

Touch.prototype.branchUpdateAddress = async function branchUpdateAddress(address) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateBranch', {
      Id: parseInt(address.branchId, 10),
      Address: {
        Line1: address.line1,
        Line2: address.line2,
        Line3: address.line3,
        Town: address.town,
        County: address.county,
        Code: address.code,
        Iso: address.iso,
        IsPrimary: address.isPrimary,
      },
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.GetCustomerOnlyPrices = async function GetCustomerOnlyPrices(
  DatasetId,
  Limit,
  Offset,
) {
  return this.authenticatedPost('vendor/GetCustomerOnlyPrices', { DatasetId, Limit, Offset });
};

Touch.prototype.GetCustomQuoteStatuses = async function GetCustomQuoteStatuses(CompanyId) {
  const { stages } = this.parseResponse(
    await this.authenticatedPost('Customer/GetCustomQuoteStages', { CompanyId }),
  );
  return stages;
};

Touch.prototype.UpdateCustomQuoteStage = async function UpdateCustomQuoteStage(stages) {
  return this.parseResponse(
    await this.authenticatedPost(
      'customer/UpdateCustomQuoteStage',
      {
        Stages: stages,
      },
      errorCallback,
      () => successCallback('Save'),
    ),
  );
};

Touch.prototype.ListOrganisationContacts = async function ListOrganisationContacts(role) {
  return this.parseResponse(
    await this.authenticatedPost('customer/ListOrganisationContacts', { role }),
  );
};

Touch.prototype.GetExtraItemGroups = async function GetExtraItemGroups() {
  return this.parseResponse(await this.authenticatedPost('customer/GetExtraItemGroups'));
};

Touch.prototype.getAccountSettings = async function getAccountSettings() {
  return this.parseResponse(await this.authenticatedPost('customer/GetAccountSettings'));
};

Touch.prototype.updateBrandedFabricator = async function updateBrandedFabricator(CustomerId, Active, SupplyUntil, IndefiniteSupply) {
  return this.parseResponse(
    await this.authenticatedPost('customer/updateBrandedFabricator', { CustomerId, Active, SupplyUntil, IndefiniteSupply }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.ListTradeAccountApplications = async function ListTradeAccountApplications(query) {
  return this.parseResponse(await this.authenticatedPost('customer/ListTradeAccountApplications', query));
};

Touch.prototype.ProcessTradeAccountApplication = async function ProcessTradeAccountApplication(Id, Status) {
  return this.parseResponse(
    await this.authenticatedPost('customer/ProcessTradeAccountApplication', { Id, Status}),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.ResendVerificationEmail = async function ResendVerificationEmail(ApplicationId) {
  return this.parseResponse(await this.authenticatedPost('customer/ResendVerificationEmail', { ApplicationId }));
};

Touch.prototype.UpdateCompanyIssuePresent = async function UpdateCompanyIssuePresent({ ShowIssuePresentWarning, IssuePresentWarning }) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UpdateCompanyIssuePresent', { ShowIssuePresentWarning, IssuePresentWarning }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.GetCompanyIssuePresent = async function GetCompanyIssuePresent() {
  return this.parseResponse(await this.authenticatedPost('customer/GetCompanyIssuePresent'));
};
