<template>
  <div v-show="total_pages > 1" class="flex justify-end">
    <div class="hidden xs:flex flex-col justify-around p-1 mr-2">View more results</div>
    <div
      class="flex flex-col justify-around cursor-pointer hover:text-gray-600 mr-1 border bg-white p-1 w-6 text-center"
      :class="{ 'text-gray-400 pointer-events-none': current_page === 1 }"
      @click="current_page = 1"
    >
      <div class="flex justify-center">
        <i class="fa fa-caret-left"></i>
        <i class="fa fa-caret-left"></i>
      </div>
    </div>
    <div
      class="flex flex-col justify-around cursor-pointer hover:text-gray-600 border bg-white p-1 w-6 text-center"
      :class="{ 'text-gray-400 pointer-events-none': current_page === 1 }"
      @click="current_page--"
    >
      <i class="fa fa-caret-left"></i>
    </div>
    <div class="flex flex-col justify-around">
      <select v-model="current_page" class="h-7 w-18 bg-white mx-1 border p-1 text-xs">
        <option
          v-for="page in total_pages"
          :key="page"
          :selected="current_page === page"
          :value="page"
        >
          {{ page }} of {{ total_pages }}
        </option>
      </select>
    </div>
    <div
      class="flex flex-col justify-around cursor-pointer hover:text-gray-600 border bg-white p-1 w-6 text-center"
      :class="{ 'text-gray-400 pointer-events-none': current_page === total_pages }"
      @click="current_page++"
    >
      <i class="fa fa-caret-right"></i>
    </div>
    <div
      class="flex flex-col justify-around cursor-pointer hover:text-gray-600 ml-1 border bg-white p-1 w-6"
      :class="{ 'text-gray-400 pointer-events-none': current_page === total_pages }"
      @click="current_page = total_pages"
    >
      <div class="flex justify-center">
        <i class="fa fa-caret-right"></i>
        <i class="fa fa-caret-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total_items: {
      required: true,
    },
    limit: {
      required: true,
    },
  },
  data() {
    return {
      current_page: 1,
    };
  },
  computed: {
    total_pages() {
      return Math.ceil(this.total_items / this.limit);
    },
  },
  watch: {
    current_page(page) {
      const offset = this.limit * (page - 1);
      this.$emit('load-feed', offset);
    },
  },
};
</script>
