<template>
  <div class="bg-white p-5 xs:p-10 border sm:mt-0">
    <slot name="enquiries-overdue-limit"></slot>
    <div v-if="companySettings" class="pb-10 border-b">
      <div class="mb-3 text-base max-w-3xl">
        <strong>Request Measurements</strong> <span class="text-red-600 ml-1">*</span>
      </div>
      <div class="flex flex-col justify-around text-gray-600 text-base max-w-3xl">
        Would you like to request measurements from your customers via the {{ setup }} Designer? If
        Yes, this question will appear on the intro screen.
      </div>
      <div class="flex flex-col md:flex-row -mx-5">
        <div class="p-5">
          <label class="flex flex-col">
            <div>
              <div class="inline-flex border border-gray-600 rounded-lg">
                <div
                  class="text-white border border-white rounded-l-lg px-3"
                  :class="{
                    'bg-green-600': companySettings.showConsumerSizes,
                    'bg-gray-600': !companySettings.showConsumerSizes,
                  }"
                >
                  Yes
                </div>
                <div
                  class="bg-gray-600 text-white border border-white rounded-r-lg px-3"
                  :class="{
                    'bg-gray-600': companySettings.showConsumerSizes,
                    'bg-green-600': !companySettings.showConsumerSizes,
                  }"
                >
                  No
                </div>
              </div>
              <input v-model="companySettings.showConsumerSizes" type="checkbox" class="hidden" />
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row gap-10 mt-10 border-b pb-10">
      <div class="flex-1 flex flex-col">
        <div class="flex flex-col justify-around text-base">
          <strong>Show Design Names</strong>
        </div>
        <div class="text-gray-600 text-base my-3 max-w-md">
          <div>
            Choose between Design A or Design B when a customer chooses a product type.
          </div>
        </div>
        <div class="flex flex-col md:flex-row -mx-5">
          <div class="p-5">
            <label class="flex flex-col">
              <div>
                <div class="inline-flex border border-gray-600 rounded-lg cursor-pointer">
                  <div
                    class="text-white border border-white rounded-l-lg px-3"
                    :class="{
                      'bg-green-600': companySettings.showDesignNames,
                      'bg-gray-600': !companySettings.showDesignNames,
                    }"
                  >
                    Design A
                  </div>
                  <div
                    class="bg-gray-600 text-white border border-white rounded-r-lg px-3"
                    :class="{
                      'bg-gray-600': companySettings.showDesignNames,
                      'bg-green-600': !companySettings.showDesignNames,
                    }"
                  >
                    Design B
                  </div>
                </div>
                <input v-model="companySettings.showDesignNames" type="checkbox" class="hidden" />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="flex flex-1 flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12" style="min-height: 300px; max-width: 400px;">
        <img src="/images/guides/show-design-names.jpg" />
      </div>
    </div>
    <div class="mt-10 pb-10 w-full">
      <div class="flex flex-col justify-around text-base">
        <strong>Google Analytics</strong>
      </div>
      <div class="text-gray-600 text-base my-3">
        <div>
          Setup analytics to measure traffic & conversions in {{ setup }} Mode.
        </div>
        <div class="py-5 flex sm:grid grid-rows-2 grid-cols-2 grid-flow-col-dense flex-col gap-5 sm:gap-10" style="grid-template-rows: auto 1fr;">
          <div class="flex flex-col gap-2">
            <div>Step 1.</div>
            <div class="flex gap-5 items-center">
              <b>Add Google Tag</b>
              <span
                v-if="refreshRequired"
                class="bg-yellow-500 text-black rounded px-2 py-1 flex items-center gap-2 cursor-pointer"
                @click="refresh"
              >
                Refresh Required
                <i class="fa-light fa-arrows-rotate"></i>
              </span>
            </div>
            <div
              class="max-w-sm flex items-center gap-1 p-2 h-10 rounded w-full border border-black"
              :class="[companySettings.tagId ? 'text-black' : 'text-gray-600']"
            >
              <div>
                <span>G - </span>
              </div>
              <input v-model="companySettings.tagId" type="text" class="flex-grow" />
            </div>
            <div>
              Provide the gtag code embedded on your website.
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div>Step 2.</div>
            <div>
              <b>Setup Cross-domain Measurements</b>
              <div>Allows activity to be accurately attributed to a single user as they cross domains</div>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                1
              </div>
              <span>Go to the Google Analytics home page linked to the gtag above.</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                2
              </div>
              <span>In Admin, under Data collection and modification, click Data streams</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                3
              </div>
              <span>Click Web, then click a web data stream</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                4
              </div>
              <span>In the web stream details, click Configure tag settings (at the bottom)</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                5
              </div>
              <span>In the Settings section, click Configure your domains</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                6
              </div>
              <span>Click Add condition under Include domains and set match type to <u>Exactly matches</u></span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                7
              </div>
              <span>Enter the domain for your website, e.g. example.com (without https)</span> 
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                8
              </div>
              <span>Repeat step 6 and enter the domain for the designer which is <u>bm-touch.co.uk</u></span> 
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div>Step 3.</div>
            <b>Verify Cross-domain Measurements is Working</b>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                1
              </div>
              <span>Open a page of your site that contains a link to {{ setup }} Mode.</span>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                2
              </div>
              <span>Click the link and verify that the URL in the destination domain contains the linker parameter _gl</span>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div>Step 4.</div>
            <div>
              <b>Verify Conversion Data is Working</b>
              <div> A conversion event will be named <u>enquiry-conversion</u></div>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px"
              >
                1
              </div>
              <span>Submit an enquiry</span>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="flex-shrink-0 block rounded-full bg-gray-700 text-white text-center flex flex-col justify-center"
                style="width: 20px; height: 20px;"
              >
                2
              </div>
              <span>Go to Google Analytics Realtime report. You should see the event name with at least one conversion next to the name in the card</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    companySettings: Object,
    originalCompanySettings: Object,
    refreshRequired: Boolean,
    setup: String,
  },
  methods: {
    refresh () {
      window.location.reload()
    }
  }
}

</script>