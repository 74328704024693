var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'company-details',
          'border-white': _vm.$route.name !== 'company-details',
        },attrs:{"to":"/manage-touch-account/company-details"}},[_vm._v(" Company Details ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'documents-and-agreements',
          'border-white': _vm.$route.name !== 'documents-and-agreements',
        },attrs:{"to":"/manage-touch-account/documents-and-agreements"}},[_vm._v(" Documents & Agreements ")]),(_vm.isCompanyStylingEnabled)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'custom-styles',
          'border-white': _vm.$route.name !== 'custom-styles',
        },attrs:{"to":"/manage-touch-account/custom-styles"}},[_vm._v(" Custom Styles ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'delivery-options',
          'border-white': _vm.$route.name !== 'delivery-options',
        },attrs:{"to":"/manage-touch-account/delivery-options"}},[_vm._v(" Delivery Options ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'quote-stages',
          'border-white': _vm.$route.name !== 'quote-stages',
        },attrs:{"to":"/manage-touch-account/quote-stages"}},[_vm._v(" Quote Stages ")]):_vm._e(),(_vm.$store.state.basket.isPaymentGateway)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm",class:{
          'border-black': _vm.$route.name === 'payment-gateway',
          'border-white': _vm.$route.name !== 'payment-gateway',
        },attrs:{"to":"/manage-touch-account/payment-gateway"}},[_vm._v(" Payment Gateway ")]):_vm._e(),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }