<template>
  <div class="h-screen overflow-hidden bg-gray-200 sm:bg-transparent">
    <div
      ref="intro_popup"
      class="absolute top-0 left-0 right-0 bottom-0 bg-black_80 flex flex-col justify-around z-50"
    >
      <div
        class="p-10 m-auto text-center text-white rounded-lg bg-touch-purple"
        style="max-width: 500px"
      >
        <div>
          Welcome to the TOUCH Vendor Demo, click the green ‘login’ button to start the demo.
        </div>
        <div class="pt-10">
          <div
            class="border border-white p-3 hover:bg-white hover:text-touch-purple rounded cursor-pointer"
            @click="$refs.intro_popup.remove()"
          >
            Continue <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="text-brand-primary hidden sm:block">
      <div class="absolute top-0 right-0 -z-1 left-0">
        <img :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
      </div>
    </div>
    <header class="bg-white w-full relative sm:bg-transparent print:hidden">
      <div class="flex flex-grow">
        <logo
          class="border-0 bg-transparent"
          :src="style.fabricator_logo"
          :href="$t(store_url)"
        ></logo>
        <div class="flex flex-grow flex-col"></div>
        <div class="flex">
          <div v-if="style.back_to_site_link" class="flex flex-col justify-center">
            <a
              :href="style.back_to_site_link"
              class="bg-white rounded-full hover:bg-gray-200 my-auto mx-3 sm:py-2 sm:pr-6 sm:pl-5 sm:m-5 sm:font-medium"
            >
              <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
            </a>
          </div>
          <div class="flex flex-col justify-around sm:block">
            <a
              :href="store_url"
              target="_blank"
              class="whitespace-no-wrap block border border-brand-primary sm:border-white text-brand-primary sm:text-white m-1 xs:m-3 sm:m-6 rounded-full p-2 xs:py-3 xs:px-6 hover:bg-brand-primary hover:text-white sm:hover:text-brand-primary sm:hover:bg-white"
              v-text="'Touch Store'"
            >
            </a>
          </div>
        </div>
      </div>
    </header>
    <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
      <div class="mx-auto w-full flex justify-around" style="max-width: 1600px">
        <div class="hidden sm:flex flex-col justify-around text-center m-10 mr-0">
          <div class="flex flex-col justify-between font-medium">
            <div v-html="$t('Login-Main Title')"></div>
            <div class="text-lg font-normal mx-auto my-6" style="max-width: 500px">
              {{
                $t(
                  'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
                )
              }}
            </div>
            <div class="mx-auto">
              <img
                :src="$t('/images/login-image.png')"
                class="m-auto w-full"
                style="margin-left: -30px"
              />
            </div>
          </div>
        </div>
        <div class="login-form-wrapper">
          <transition name="fade-in" appear mode="out-in">
            <div v-if="!forgot_password_open" key="login" class="my-auto">
              <div class="text-center mb-4 bg-white py-4 sm:hidden font-medium">
                <div v-html="$t('Login-Main Title')"></div>
                <div class="text-base xs:text-lg font-normal mx-auto my-6" style="max-width: 500px">
                  {{
                    $t(
                      'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
                    )
                  }}
                </div>
                <div class="mx-auto">
                  <img
                    :src="$t('/images/login-image.png')"
                    class="m-auto"
                    style="max-height: 130px"
                  />
                </div>
              </div>

              <form class="login-form" @submit.prevent="login">
                <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                  <div class="text-4xl mb-2 font-medium">Welcome back!</div>
                  <div class="text-xl">Continue to your dashboard.</div>
                </div>

                <error v-if="login_error" :message="login_error"></error>

                <div class="mb-4">
                  <label for="email" class="block w-full">Email address</label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    readonly
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="email"
                    autofocus
                  />
                </div>

                <div class="mb-4">
                  <label for="password" class="block w-full">Password</label>
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    readonly
                    autocomplete="current-password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                  />
                </div>

                <div v-show="show_dev">
                  <div class="bg-red-300 p-4">
                    <div
                      class="border-b-4 border-red-800 mb-3 text-red-800 font-bold flex justify-between"
                    >
                      <div>DEVELOPMENT ONLY</div>
                      <div
                        title="hide"
                        class="flex items-center cursor-pointer"
                        @click="show_dev = false"
                      >
                        &times;
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="test-user" class="block w-full font-bold">Select Test User</label>
                      <select
                        id="test-user"
                        v-model="devUser"
                        class="border border-solid border-gray-400 rounded block w-full p-2 appearance-none"
                      >
                        <optgroup label="Consultancy">
                          <option
                            :value="{ username: 'bill@theconsultancy.co.uk', password: 'bill' }"
                          >
                            Bill Wilson
                          </option>
                          <option
                            :value="{ username: 'nick@theconsultancy.co.uk', password: 'nick' }"
                          >
                            Nick Strife
                          </option>
                        </optgroup>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label for="processingLevel" class="block w-full font-bold">
                        Set Processing Level
                      </label>
                      <select
                        id="processingLevel"
                        v-model="processingLevel"
                        class="border border-solid border-gray-400 rounded block w-full p-2 appearance-none"
                      >
                        <option :value="0">Level 0 - None</option>
                        <option :value="1">Level 1 - Consumer</option>
                        <option :value="2">Level 2 - Sales</option>
                        <option :value="3">Level 3 - Survey</option>
                        <option :value="4">Level 4 - Full</option>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label for="endpoint" class="block w-full font-bold">
                        Endpoint Base Address
                      </label>
                      <input
                        id="endpoint"
                        v-model="endpoint"
                        type="url"
                        autocomplete="endpointURL"
                        class="border border-solid border-gray-400 rounded block w-full p-2"
                      />
                    </div>

                    <div class="mb-4">
                      <label for="imageBaseURL" class="block w-full font-bold">
                        Image Base Address
                      </label>
                      <input
                        id="imageBaseURL"
                        v-model="imageBaseURL"
                        type="url"
                        autocomplete="imageBaseURL"
                        class="border border-solid border-gray-400 rounded block w-full p-2"
                      />
                    </div>

                    <div class="mb-4">
                      <label for="touch-server" class="block w-full font-bold">
                        Select Touch Server
                      </label>
                      <select
                        id="touch-server"
                        v-model="devTouchServer"
                        class="border border-solid border-gray-400 rounded block w-full p-2 appearance-none"
                      >
                        <optgroup label="Presentation Link (Stable)">
                          <option
                            :value="{
                              endpointUrl: 'https://touch-test-server.azurewebsites.net/api/',
                              imageUrl: 'https://touch-test-server.azurewebsites.net',
                            }"
                          >
                            touch-test-server.azurewebsites.net
                          </option>
                        </optgroup>
                        <optgroup label="EDM Test Link (Dev)">
                          <option
                            :value="{
                              endpointUrl: 'https://server.test.touch-server.bminfinity.com/api/',
                              imageUrl: 'https://server.test.touch-server.bminfinity.com',
                            }"
                          >
                            test.touch-server.bminfinity.com
                          </option>
                        </optgroup>
                        <optgroup label="BM Internal Link (Unstable)">
                          <option
                            :value="{
                              endpointUrl: 'https://server.risky.touch-server.bminfinity.com/api/',
                              imageUrl: 'https://server.risky.touch-server.bminfinity.com',
                            }"
                          >
                            risky.touch-server.bminfinity.com
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="mb-4 text-right">
                  <div
                    class="text-gray-600 text-xs hover:text-gray-800 cursor-pointer"
                    @click.prevent="forgot_password_open = true"
                  >
                    Forgot your password? Click here!
                  </div>
                </div>

                <button type="submit" class="btn-action btn-lg w-full">Login</button>

                <div class="text-center mt-10">
                  <a
                    :href="$t(store_url)"
                    target="_blank"
                    class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                  >
                    <div class="p-1">
                      <img src="../../assets/images/touch-logo.svg" width="75px" />
                    </div>
                    <div class="">
                      <template v-if="touch_portal_installation">
                        <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
                      </template>
                    </div>
                    <div class="p-1 text-xs">By BM Group</div>
                  </a>
                </div>
              </form>
            </div>
            <div v-if="forgot_password_open" key="forgot">
              <forgot-password-form @close="forgot_password_open = false"></forgot-password-form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Logo from '@/components/shared/nav/Logo.vue';
import ForgotPasswordForm from './ForgotPassword.vue';

export default {
  components: {
    'forgot-password-form': ForgotPasswordForm,
    Logo,
  },
  data() {
    const devMode = false; // process.env.NODE_ENV !== 'production';

    return {
      show_dev: devMode,
      email: 'demo@businessmicros.co.uk',
      password: 'escapeThick1',
      endpoint: process.env.VUE_APP_ENDPOINT_URL,
      imageBaseURL: process.env.VUE_APP_IMAGE_BASE_URL,
      processingLevel: 2,
      login_error: false,
      forgot_password_open: false,
      devUser: {
        username: 'bill@theconsultancy.co.uk',
        password: 'bill',
      },
      devTouchServer: {
        endpointUrl: 'https://server.risky.touch-server.bminfinity.com/api/',
        imageUrl: 'https://server.risky.touch-server.bminfinity.com',
      },
    };
  },
  watch: {
    devUser({ username, password }) {
      this.email = username;
      this.password = password;
    },
    devTouchServer({ endpointUrl, imageUrl }) {
      this.endpoint = endpointUrl;
      this.imageBaseURL = imageUrl;
    },
  },
  methods: {
    ...mapActions({
      setEndpoint: 'auth/setEndpoint',
      setImageBaseURL: 'auth/setImageBaseURL',
      attemptLogin: 'auth/attemptPortalLogin',
    }),
    ...mapMutations({
      setProcessingLevel: 'auth/setProcessingLevel',
    }),
    login() {
      const devMode = false; // process.env.NODE_ENV !== 'production';
      if (devMode) {
        this.setEndpoint(this.endpoint);

        this.setImageBaseURL(this.imageBaseURL);

        this.setProcessingLevel(this.processingLevel);
      }
      if (this.email !== '' && this.password !== '') {
        this.attemptLogin({
          username: this.email,
          password: this.password,
        }).then(async (loggedIn) => {
          if (loggedIn) {
            this.login_error = false;
            this.routerPush(this.$router.currentRoute.query.redirect ?? '/');
            return;
          }
          this.login_error = 'Unable to login, Please check your username / password and try again';
        });
      } else {
        this.login_error = 'Email address and password required';
      }
    },
  },
};
</script>
