<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Add Vehicle" @close="$emit('close')">
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Registration<span class="text-green-600">*</span></div>
          <input v-model="registration" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Description<span class="text-green-600">*</span></div>
          <input v-model="description" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Max Weight<span class="text-green-600">*</span></div>
          <input v-model="maxWeight" type="number" class="form-control" />
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="validateAndSave">Save</button>
      </div>
    </modal-window>
  </portal>
</template>
<script>
import validate from 'validate.js';

export default {
  props: {
    initialRegistration: {
      type: String,
      default: '',
    },
    initialMaxWeight: {
      type: Number,
      default: undefined,
    },
    initialDescription: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      registration: this.initialRegistration,
      maxWeight: this.initialMaxWeight,
      description: this.initialDescription,
    };
  },
  methods: {
    validateAndSave() {
      const validationErrors = validate(
        {
          registration: this.registration,
          maxWeight: this.maxWeight,
          description: this.description,
        },
        {
          registration: {
            presence: { allowEmpty: false },
            length: {
              maximum: 20,
            },
          },
          maxWeight: {
            presence: { allowEmpty: false },
          },
          description: {
            presence: { allowEmpty: false },
            length: {
              maximum: 200,
            },
          },
        },
      );
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      this.$emit('save', {
        registration: this.registration,
        maxWeight: this.maxWeight,
        description: this.description,
        id: this.id,
      });
    },
  },
};
</script>
