<template>
  <div>
    <div
      :class="['flex', 'cursor-pointer', 'bg-gray-300']"
      @click.stop="show_issues = !show_issues"
    >
      <div class="p-3 border-r border-white">
        <i
          :title="getTooltipText(mostSevereIssueId)"
          :class="['fal', 'fa-2xl', getIconForIssue(mostSevereIssueId)]"
        ></i>
      </div>
      <div class="flex-grow p-3 text-gray-800">
        {{ issues.length }} issues, click for details.
        <i class="fa fa-fw ml-6" :class="[{ 'fa-caret-right': !show_issues }, 'fa-caret-down']"></i>
      </div>
    </div>
    <div v-show="show_issues" class="relative">
      <div class="absolute w-full bg-gray-300 text-gray-800 border-t border-white">
        <div
          v-for="(issue, index) in issues"
          :key="index"
          class="p-3 flex flex-row justify-center items-center"
        >
          <template v-if="issue.type === 'InvalidatedOptions'">
            <i
              :title="getTooltipText(issue.severityTypeId)"
              :class="[getIconForIssue(issue.severityTypeId), 'mr-auto fa-2xl fal']"
              @click="$emit('show-invalidated-popup')"
            ></i>
            <div class="mr-auto pl-3 cursor-pointer" @click="$emit('show-invalidated-popup')">
              Last change caused some previous options to be invalidated. Click here for details
            </div>
          </template>
          <template v-else>
            <i
              :title="getTooltipText(issue.severityTypeId)"
              :class="[getIconForIssue(issue.severityTypeId), 'mr-auto fa-2xl fal']"
              @click="displayIssueDescriptionModal(issue.severityTypeId)"
            ></i>
            <div class="mr-auto pl-3">{{ issue.description }}</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['issues'],
  data() {
    return {
      show_issues: false,
    };
  },
  computed: {
    mostSevereIssueId() {
      return Math.max.apply(
        null,
        this.issues.map((i) => i.severityTypeId),
      );
    },
  },
  methods: {
    getIconForIssue(issueSeverityId) {
      switch (issueSeverityId) {
        case window.enum.IssueSeverityEnum.INFORM:
        default:
          return 'fa-circle-info text-issue-inform';
        case window.enum.IssueSeverityEnum.WARN:
          return 'fa-triangle-exclamation text-issue-warn';
        case window.enum.IssueSeverityEnum.RESTRICT:
          return 'fa-triangle-exclamation text-issue-restrict';
        case window.enum.IssueSeverityEnum.PROHIBIT:
          return 'fa-hexagon-exclamation text-issue-prohibit';
      }
    },
    getTooltipText(issueSeverityId) {
      switch (issueSeverityId) {
        case window.enum.IssueSeverityEnum.INFORM:
        default:
          return 'Information';
        case window.enum.IssueSeverityEnum.WARN:
          return 'Warning';
        case window.enum.IssueSeverityEnum.RESTRICT:
          return 'Restriction (fabricator authorisation required)';
        case window.enum.IssueSeverityEnum.PROHIBIT:
          return 'Prohibition';
      }
    },
    displayIssueDescriptionModal(issueSeverityId) {
      this.alertBox().fire({
        text: this.getTooltipText(issueSeverityId),
        icon: 'warning',
      });
    },
  },
};
</script>
