<template>
  <transition appear name="widget-fade-in-1">
    <div class="flex flex-col justify-between bg-white border p-5 w-full">
      <div class="flex justify-between">
        <div class="flex flex-col justify-around">
          <div class="flex text-touch-red">
            <span class="text-xl mr-3"><i class="fal fa-clipboard-list-check"></i></span>
            <span class="flex flex-col justify-around">Number of Orders</span>
          </div>
        </div>
        <div>
          <select v-model="range" class="text-xs bg-gray-200 px-2 py-1 rounded-lg">
            <option value="30">Last 30 Days</option>
            <option value="60">Last 60 Days</option>
            <option value="90">Last 90 Days</option>
          </select>
        </div>
      </div>
      <div class="text-6xl">
        {{ number_of_orders }}
      </div>
      <template v-if="percentage_change >= 0">
        <div class="flex">
          <span class="text-xl text-green-600 mr-3">
            <i class="fal fa-chart-line"></i>
          </span>
          <span class="flex flex-col justify-around"> {{ percentage_change }}% Increase </span>
        </div>
      </template>
      <template v-else-if="percentage_change < 0">
        <div class="flex">
          <span class="text-xl text-red-600 mr-3">
            <i class="fal fa-chart-line-down"></i>
          </span>
          <span class="flex flex-col justify-around"> {{ percentage_change }}% Decrease </span>
        </div>
      </template>
      <template v-else>
        <div class="flex">
          <span class="flex flex-col justify-around"> No Data Available for previous period </span>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      number_of_orders: 0,
      range: 30,
      loading: true,
      percentage_change: 0,
    };
  },
  watch: {
    range(val) {
      this.loadFeed(val);
    },
  },
  mounted() {
    this.loading = true;
    this.loadFeed(this.range);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      loadKPIDashboardOrders: 'kpi/loadKPIDashboardOrders',
    }),
    async loadFeed(range) {
      const resp = await this.getNumberOfOrders(range);
      this.number_of_orders = resp;
      this.loadPercentageChange();
    },
    async getNumberOfOrders(range) {
      const resp = await this.loadKPIDashboardOrders(range * 1440);
      return resp.total;
    },
    async loadPercentageChange() {
      const thisPeriod = this.number_of_orders;
      const previousPeriod = (await this.getNumberOfOrders(this.range * 2)) - thisPeriod;
      const percentageChange = (thisPeriod / previousPeriod) * 100 - 100;

      if (Number.isNaN(percentageChange)) {
        this.percentage_change = 0;
      } else {
        this.percentage_change = parseInt(percentageChange, 10);
      }
    },
  },
};
</script>
