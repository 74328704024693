<template>
  <div v-if="newPrices.length > 0">
    <table class="table-pricing table-fixed">
      <thead>
        <tr v-if="isWidthByHeight">
          <th class="text-left py-1 px-3">Min Height</th>
          <th class="text-left py-1 px-3">Max Height</th>
          <th class="text-left py-1 px-3">Min Width</th>
          <th class="text-left py-1 px-3">Max Width</th>
          <th class="p-1" style="width: 104px">Price</th>
          <th v-if="isCustomerPricing()" class="p-1" style="width: 78px">FOC</th>
        </tr>
        <tr v-else>
          <th class="text-left py-1 px-3">Value</th>
          <th class="p-1" style="width: 104px">Price</th>
          <th v-if="isCustomerPricing()" class="p-1" style="width: 78px">FOC</th>
        </tr>
      </thead>
      <tbody>
        <component
          :is="lineComponent[financialRateType]"
          v-for="(price, index) in newPrices"
          :key="price.id"
          :is-customer-pricing="isCustomerPricing()"
          :is-width-by-height="isWidthByHeight"
          :default-currency="defaultCurrency"
          :price="price"
          :value="valueFor(index)"
          @update="updateValueFor(index, $event)"
          @updatePrice="updatePrice(index, $event)"
          @updateFOC="updateFOC(index, $event)"
          @updateDimensions="updateDimensions(index, $event)"
        >
        </component>
      </tbody>
    </table>
  </div>
</template>

<script>
import LineEach from '@/components/shared/priceLines/List/Lines/LineEach.vue';
import LineMeterSquared from '@/components/shared/priceLines/List/Lines/LineMeterSquared.vue';
import LinePerMeter from '@/components/shared/priceLines/List/Lines/LinePerMeter.vue';
import LinePerKg from '@/components/shared/priceLines/List/Lines/LinePerKg.vue';
import LinePercent from '@/components/shared/priceLines/List/Lines/LinePercent.vue';
import LineNone from '@/components/shared/priceLines/List/Lines/LineNone.vue';

export default {
  components: {
    'line-each': LineEach,
    'line-meter-squared': LineMeterSquared,
    'line-per-meter': LinePerMeter,
    'line-per-kg': LinePerKg,
    'line-percent': LinePercent,
    'line-none': LineNone,
  },
  props: ['grid', 'financialRateType', 'defaultCurrency', 'isWidthByHeight'],
  data() {
    return {
      newPrices: [],
      lineComponent: {
        [window.enum.priceRateType.NONE]: 'line-none',
        [window.enum.priceRateType.EACH]: 'line-each',
        [window.enum.priceRateType.PER_KG]: 'line-per-kg',
        [window.enum.priceRateType.PERCENT]: 'line-percent',
        [window.enum.priceRateType.PER_METER]: 'line-per-meter',
        [window.enum.priceRateType.METER_SQUARED]: 'line-meter-squared',
      },
    };
  },
  computed: {
    originalPrices() {
      if (this.grid.overrideCells) {
        return this.grid.overrideCells;
      }

      return this.grid.cells;
    },
  },
  mounted() {
    this.newPrices = JSON.parse(JSON.stringify(this.originalPrices));
    this.$emit('new-prices', this.newPrices);
  },
  methods: {
    isCustomerPricing() {
      return this.$route.path !== '/manage-touch-account/pricing';
    },
    updateValueFor(index, value) {
      this.newPrices[index].value = Number(value);
      this.$emit('new-prices', this.newPrices);
    },
    updatePrice(index, { value, foc }) {
      this.newPrices[index].value = Number(value);
      this.newPrices[index].foc = foc;
      this.$emit('new-prices', this.newPrices);
    },
    updateDimensions(index, { property, value }) {
      this.newPrices[index][property] = Number(value);
      this.$emit('new-prices', this.newPrices);
    },
    valueFor(index) {
      if (this.newPrices.length === 0) {
        return 0;
      }

      return Number(this.newPrices[index].value);
    },
  },
};
</script>
