<template>
  <div v-if="isVisible" class="relative">
    <div v-show="isVisible" class="info-popup-message-wrapper">
      <div class="info-popup-close" @click.stop="isVisible = false">
        <i class="fal fa-times"></i>
      </div>
      <div class="info-popup-message mb-2">
        <slot></slot>
      </div>
      <div class="info-popup-button">
        <div @click.stop="isVisible = false"><slot name="button">OK</slot></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tipName: {
      required: true,
    },
    shownByDefault: {
      default: true,
    },
    closeIn: {
      default: 0,
    },
  },
  computed: {
    isVisible: {
      get() {
        return this.$store.state.infoPopup.isVisible[this.tipName];
      },
      set(value) {
        this.$store.commit('infoPopup/setIsVisible', {
          key: this.tipName,
          value,
        });
      },
    },
  },
  mounted() {
    if (this.isVisible === undefined) {
      this.isVisible = this.shownByDefault;
    }

    if (this.closeIn > 0) {
      setTimeout(() => {
        this.isVisible = false;
      }, this.closeIn * 1000);
    }
  },
};
</script>

<style scoped></style>
