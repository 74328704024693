<template>
  <div class="flex w-full">
    <div class="flex-1 py-1 px-2 flex items-center">
      <div class="text-xs md:text-sm flex-1">
        {{ description }}
      </div>
      <div v-if="subDescription" class="text-xs md:text-sm">
        {{ subDescription }}
      </div>
    </div>
    <div class="flex p-1 ml-2" style="width: 6.6rem">
      <button class="btn-action w-full" @click="editGridA">Edit</button>
      <portal v-if="showGridA" to="portal_popup">
        <select-grid-a
          :price="price"
          :dataset-id="datasetId"
          :customer-id="customerId"
          :is-new-base-price="isNewBasePrice"
          @close="showGridA = false"
        ></select-grid-a>
      </portal>
    </div>
  </div>
</template>

<script>
import SelectGrid from '@/components/shared/priceLines/Grid/SelectGrid.vue';

export default {
  components: {
    'select-grid-a': SelectGrid,
  },
  props: ['price', 'defaultCurrency', 'datasetId', 'customerId', 'isNewBasePrice'],
  data() {
    return {
      showGridA: false,
    };
  },
  computed: {
    description() {
      return this.price.description.replace('(WxH)', '');
    },
    subDescription() {
      if (this.price.description.includes('(WxH)')) {
        return '(W x H)';
      }

      return '';
    },
  },
  methods: {
    editGridA() {
      this.showGridA = true;
    },
  },
};
</script>
