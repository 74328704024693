<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Delivery Options'],
        ]"
      />
    </template>
    <template #header_page_title> Delivery Options </template>
    <Menu></Menu>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <DefaultDeliveryOptions />
      <DeliveryOptions />
    </loading>
  </touch-layout>
</template>

<script>
import DefaultDeliveryOptions from '@/components/business/manageTouchAccount/deliveryOptions/DefaultDeliveryOptions.vue';
import DeliveryOptions from '@/components/business/manageTouchAccount/deliveryOptions/DeliveryOptions.vue';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    DefaultDeliveryOptions,
    DeliveryOptions,
    Menu,
    Breadcrumbs,
  },
};
</script>
