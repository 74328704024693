<template>
  <Checkout
    ref="checkout"
    breakdown="Price"
    :customer="customer"
    :warnings_checked="true"
    @cancelOrder="cancelOrder"
    @redirectProcessPlaceOrder="redirectProcessPlaceOrder"
  ></Checkout>
</template>

<script>
import Checkout from '@/components/shared/checkout/Checkout.vue';

export default {
  components: {
    Checkout,
  },
  props: {
    customer: Object,
  },
  mounted() {
    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush(`/customer/${this.$store.state.basket.customerId}/sales-dashboard/new-quote`);
    }
    this.$refs.checkout.terms_checked = true;
  },
  methods: {
    cancelOrder() {
      this.$store.dispatch('basket/clearBasket');
      this.$router.go(-1);
    },
    redirectProcessPlaceOrder({ isSuccess }) {
      if (isSuccess) {
        this.routerPush(`/customer/${this.$store.state.basket.customerId}/sales-dashboard?type=` + this.$store.state.basket.buttonName.toLowerCase());
      } else {
        this.routerPush('/customer');
      }
    },
  },
};
</script>
