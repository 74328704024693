<template>
  <touch-layout page_override_class="retail-designer-basket">
    <transition name="fade-in" mode="out-in">
      <div v-if="loading || saving_quote" class="flex items-center justify-center" style="flex: 1 1 0">
        <div>
          <loading
            :key="1"
            style="display: block !important; flex: none !important"
            :loading="loading || saving_quote"
            :label="saving_quote ? 'Sending your enquiry' : 'Loading'"
          ></loading>
          <div v-if="saving_quote">
            <span>Please do not click back or refresh button.</span>
          </div>
        </div>
      </div>
      <div v-else :key="2" class="flex h-screen overflow-hidden relative">
        <div
          v-if="basketItems.length === 0 && !loading"
          class="bg-gray-200 w-full flex-grow overflow-y-auto scrolling-touch text-center"
        >
          <div class="p-10">No basket items to display</div>
          <router-link :to="chooseProductURL" class="btn-primary btn-lg">
            Design a Product
          </router-link>
        </div>
        <main
          v-if="basketItems.length > 0"
          class="vendor-basket-main bg-gray-200 w-full flex-grow sm:w-1/2 md:w-2/3 flex flex-col overflow-hidden"
        >
          <div class="px-5 md:px-10 mb-4 mt-4 md:mr-3">
            <div class="flex">
              <router-link
                key="add_another"
                :to="chooseProductURL"
                tag="button"
                class="btn-action text-xs py-3 px-3 md:px-10 w-full xs:mr-3"
                data-index="0"
              >
                <span class="pr-3"><i class="fal fa-plus"></i></span> Add Another Product
              </router-link>
              <router-link
                v-if="!hideVirtualHomeBanner"
                key="open-visualiser"
                :to="{ name: 'visualiser' }"
                tag="button"
                class="btn-action text-xs py-3 px-3 md:px-10 w-full ml-3 hidden xs:block"
                data-index="0"
              >
                <span class="pr-3"><i class="fal fa-house"></i></span> Open Virtual Home
              </router-link>
            </div>
          </div>
          <div
            class="overflow-y-auto overflow-x-hidden scrolling-touch flex-grow"
            :class="{ 'md:overflow-y-hidden': showImage || showSummary }"
          >
            <animation-staggered-slide
              class="flex md:flex-col flex-wrap p-2 md:p-10 pt-0 md:pt-0 relative"
            >
              <basket-tile
                v-for="(item, index) in basketItems"
                :key="item.key"
                :item="item"
                :data-index="index"
                :show-sizes="contractHasSizing"
                :style="getTileStyle(item.key)"
                @show-summary="showSummaryPanel(item.key)"
                @show-image="showImagePanel(item.key)"
                @delete-line-item="deleteLineItem(item.key)"
              />
              <router-link
                v-if="!hideVirtualHomeBanner"
                key="open-visualiser"
                tag="div"
                :to="{ name: 'visualiser' }"
                class="rounded-lg cursor-pointer mt-6 sm:mt-10 hidden xs:flex"
                style="background-color: #242424"
              >
                <div>
                  <img :src="$t('/images/visualiser-banner-1.png')" class="rounded-lg" />
                </div>
                <div class="hidden md:block">
                  <img :src="virtualHomeBannerUrl" class="rounded-lg" />
                </div>
                <HelpPoint
                  key="help"
                  class="absolute right-0 bottom-0 mr-2 mb-2 z-10"
                  freshdesk-article-id="101000456233"
                />
              </router-link>
            </animation-staggered-slide>
          </div>
        </main>

        <transition name="fade-in" mode="out-in" @after-enter="afterFadeInBasketSidebar">
          <basket-image
            v-if="showImage && loadedItem"
            ref="basket-image"
            key="basket_image"
            :item="loadedItem"
            :show-sizes="contractHasSizing"
            @close="hideImagePanel()"
          />
          <basket-summary
            v-else-if="showSummary && loadedItem"
            ref="basket-summary"
            key="basket_summary"
            :item="loadedItem"
            :show-sizes="contractHasSizing"
            @close="hideSummaryPanel()"
          />
          <basket-pricing
            v-else-if="basketItems.length > 0"
            key="basket_pricing"
            :show-save-product-button="false"
            :show-place-order-button="false"
            class="sm:block"
            :class="{ hidden: !showEnquiryForm }"
            @save-quote="saveQuote($event)"
            @close-form="showEnquiryForm = false"
          />
        </transition>
        <div
          class="sm:hidden absolute bottom-0 left-0 right-0 p-4 bg-white"
          :class="{ hidden: showEnquiryForm }"
        >
          <div class="btn-action btn-lg w-full" @click.stop.prevent="showEnquiryForm = true">
            Get a Quote
          </div>
        </div>
      </div>
    </transition>
  </touch-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Tile from '@/components/branded/basket/Tile.vue';
import BasketPricing from '@/components/branded/basket/BasketPricing.vue';
import BasketSummary from '@/components/branded/basket/BasketSummary.vue';
import BasketImage from '@/components/branded/basket/BasketImage.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';

export default {
  components: {
    'basket-tile': Tile,
    'basket-pricing': BasketPricing,
    'basket-summary': BasketSummary,
    'basket-image': BasketImage,
    HelpPoint,
  },
  data() {
    return {
      showSummary: false,
      showImage: false,
      showEnquiryForm: true,
      itemIndex: undefined,
      loadedItem: undefined,
      saving_quote: false,
      loading: true,
      virtualHomeBannerUrl: '/images/visualiser-banner-2.png',
    };
  },
  computed: {
    ...mapGetters({
      contractHasSizing: 'basket/contractHasSizing',
      chooseProductURL: 'branded/chooseProductURL',
      brandedThanksURL: 'branded/thanksURL',
      basketCustomerId: 'basket/customerId',
    }),
    ...mapState('basket', {
      basketItems: 'basket',
    }),
    hideVirtualHomeBanner() {
      return (
        this.basketItems.filter((x) => x.omitFromVisualiser).length === this.basketItems.length
      );
    },
  },
  async mounted() {
    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush(this.$store.getters['branded/launchURL']);
      return
    }

    // hidden behind a click on mobile mode
    if (window.innerWidth <= 1024) {
      this.showEnquiryForm = false;
    }
    await this.$store.dispatch('basket/refresh');
    this.virtualHomeBannerUrl = this.$store.state.style.companyStyles?.virtualHomePageStyling?.bannerImageUri ?? this.virtualHomeBannerUrl;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getCompanyStyles: 'style/getCompanyStyles',
      customerGet: 'user/customerGet',
    }),
    async saveQuote({
      firstName,
      lastName,
      email,
      phone,
      addressLine1,
      addressLine2,
      addressLine3,
      addressTown,
      addressCode,
      addressIso,
      notes,
      latitude,
      longitude,
    }) {
      this.saving_quote = true;
      await this.$store.dispatch('basket/saveBrandedEnquiry', {
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressIso,
        notes,
        latitude,
        longitude,
      });
      window.gtag('event', 'enquiry-conversion');
      this.routerPush(this.brandedThanksURL);
    },
    async showSummaryPanel(key) {
      if (this.itemIndex !== key) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = key;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: key,
        });
      }
      this.showSummary = true;
      this.showImage = false;
    },
    hideSummaryPanel() {
      this.showSummary = false;
    },
    async showImagePanel(key) {
      if (this.itemIndex !== key) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = key;
        this.loadedItem = await this.$store.dispatch('basket/loadItem', {
          itemKey: key,
        });
      }
      this.showImage = true;
      this.showSummary = false;
    },
    hideImagePanel() {
      this.showImage = false;
    },
    getTileStyle(itemKey) {
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey !== this.loadedItem.itemKey
      ) {
        return {
          opacity: 0.15,
          pointerEvents: 'none',
        };
      }
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey === this.loadedItem.itemKey
      ) {
        return {
          pointerEvents: 'none',
        };
      }
      return {};
    },
    afterFadeInBasketSidebar() {
      if (this.showImage && this.loadedItem) {
        this.$refs['basket-image'].$el.focus();
      }
      if (this.showSummary && this.loadedItem) {
        this.$refs['basket-summary'].$el.focus();
      }
    },
    deleteLineItem(lineItemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to remove this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('basket/deleteLineItem', {
                lineItemId,
              })
              .then(() => {
                this.alertBox().fire({
                  title: 'Item deleted',
                  icon: 'success',
                });
              });
          }
        });
    },
  },
};
</script>
