<template>
  <div>
    <div class="m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">
        <span class="hidden xs:inline">Your Touch Connect</span> Sources
      </div>
    </div>
    <div class="flex flex-col gap-2 bg-white border m-3 xs:m-10 p-5">
      <div class="flex items-center border-b pb-3">
        <b>Name</b>
      </div>
      <div class="flex items-center py-1">
        <span>Stock</span>
        <div class="ml-auto">
          <div v-if="isSourceTypeEnabled(enums.connectType.STOCK)">
            <i class="fal fa-check text-green-600"></i> Enabled
          </div>
          <label v-else class="btn-action" @click="enableSource(enums.connectType.STOCK)">
            Enable
          </label>   
        </div>
      </div>
      <!--
      <div class="flex items-center py-1">
        <span>Components</span>
        <div class="ml-auto">
          <div v-if="isSourceTypeEnabled(enums.connectType.COMPONENTS)">
            <i class="fal fa-check text-green-600"></i> Enabled
          </div>
          <label v-else class="btn-action" @click="comingSoon()">
            Enable
          </label>   
        </div>
      </div>
      <div class="flex items-center py-1">
        <span>Products</span>
        <div class="ml-auto">
          <div v-if="isSourceTypeEnabled(enums.connectType.PRODUCTS)">
            <i class="fal fa-check text-green-600"></i> Enabled
          </div>
          <label v-else class="btn-action" @click="comingSoon()">
            Enable
          </label>   
        </div>
      </div>
      -->
    </div>
    <hr class="" />
    <div class="m-3 xs:m-10 mb-0 flex">
      <div class="text-2xl font-medium">
        <span class="hidden xs:inline">Your Touch Connect</span> Subscriptions
      </div>
      <div class="ml-auto">
        <button class="btn-action" @click="isSubscribe = true">Subscribe</button>
      </div>
    </div>
    <portal v-if="isSubscribe" to="portal_popup">
      <modal-window
        :modal_open="isSubscribe"
        title="Subscribe to Touch Connect Source"
        @close="reset"
      >
        <form @submit.prevent="subscribe">
          <div class="flex flex-wrap">
            <div class="p-3 w-full">
              <div class="font-medium">Code</div>
              <div>
                <input v-model="code" class="form-control" />
              </div>
            </div>
            <div class="p-3 w-full 2xs:w-1/2">
              <div class="font-medium">Email</div>
              <div>
                <input v-model="email" type="email" class="form-control" />
              </div>
            </div>
            <div class="p-3 w-full 2xs:w-1/2">
              <div class="font-medium">Password</div>
              <div>
                <input v-model="password" type="password" class="form-control" />
              </div>
            </div>
            <div class="ml-auto mt-10">
              <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="reset()">
                Cancel
              </button>
              <button class="btn-action btn-lg" type="submit">Subscribe</button>
            </div>
          </div>
        </form>
      </modal-window>
    </portal>
    <div class="bg-white border m-3 xs:m-10 p-5">
      <div v-if="connectSubscriptions.length > 0">
        <div class="flex items-center border-b pb-3">
          <b>Name</b>
          <b class="ml-auto">Type</b>
        </div>
        <div v-for="connectSubscription in connectSubscriptions" :key="connectSubscription.id" class="flex items-center py-3">
          <span>{{ connectSubscription.name }}</span>
          <span
            v-if="connectSubscription.type == enums.connectType.COMPONENTS"
            class="ml-auto"
          >
            Components
          </span>
          <span
            v-else-if="connectSubscription.type == enums.connectType.PRODUCTS"
            class="ml-auto"
          >
            Products
          </span>
          <span
            v-else-if="connectSubscription.type == enums.connectType.STOCK"
            class="ml-auto"
          >
            Stock
          </span>
        </div>
      </div>
      <div v-else>
        <span>No Touch Connect Sources</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      connectSources: [],
      connectSubscriptions: [],
      isSubscribe: false,
      code: '',
      email: '',
      password: '',
    };
  },
  async created() {
    this.connectSources = await this.getConnectSources();
    this.connectSubscriptions = await this.getConnectSubscriptions();
  },
  methods: {
    ...mapActions({
      getConnectSources: 'touch/connectGetConnectSources',
      addConnectSource: 'touch/addConnectSource',
      getConnectSubscriptions: 'touch/connectGetConnectSubscriptions',
      authenticateConnectCustomer: 'touch/authAuthenticateConnectCustomer',
    }),
    async subscribe() {
      await window.touch.createStockSubscription(this.email, this.password, this.code);
      this.connectSubscriptions = await this.getConnectSubscriptions();
      this.reset();
    },
    reset() {
      this.isSubscribe = false;
      this.code = '';
      this.email = '';
      this.password = '';
    },
    isSourceTypeEnabled(type) {
      return this.connectSources.some((source) => source.type === type);
    },
    comingSoon() {
      this.alertBox().fire('Feature coming soon');
    },
    async enableSource(source) {
      await this.addConnectSource(source);
      this.connectSources = await this.getConnectSources();
    },
  },
};
</script>

<style scoped></style>
