<template>
  <div>
    <router-view>
      <Menu></Menu>
    </router-view>
  </div>
</template>

<script>
import Menu from '@/components/business/customers/Menu.vue';

export default {
  components: {
    Menu
  },
};
</script>