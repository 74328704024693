<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Enquiries', '/dealer-network'],
          ['Assign Enquiry'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div v-if="!loading && quote">
        <template v-if="quote.consumer">
          Assign Enquiry To Customer
          <span class="hidden xs:inline">
            ({{
              [
                quote.consumer.addressTown,
                quote.consumer.addressCounty,
                quote.consumer.addressCode,
                quote.consumer.addressIso,
              ]
                .filter(Boolean)
                .join(', ')
            }})
          </span>
        </template>
        <template v-else>Assign Enquiry To Customer (No Consumer Found on Enquiry...)</template>
      </div>
    </template>
    <loading v-if="loading || saving" :loading="loading || saving" :label="saving ? 'Saving' : 'Loading'" style="flex: 1 1 0"></loading>
    <div v-else class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10">
      <template v-if="quote">
        <portal v-if="edit_consumer_open" to="portal_popup">
          <consumer-popup
            v-show="edit_consumer_open"
            :contract="quote"
            @close="edit_consumer_open = false"
            @saved="savedConsumer()"
          ></consumer-popup>
        </portal>
        <div class="flex flex-col gap-3">
          <find-customer
            :contract-id="$route.params.id"
            :assignment="quote.dealerNetworkAssignment"
          ></find-customer>
          <div v-if="quote.consumer" class="bg-white p-3 border">
            <consumer-details
              :read-only="true"
              :consumer="quote.consumer"
              @edit="edit_consumer_open = true"
            ></consumer-details>
          </div>
          <div class="flex flex-col xs:flex-row gap-3">
            <div class="flex-1">
              <div class="bg-white border p-3 h-full w-full">
                <div class="border-b font-bold mb-3 pb-3">Enquiry Details</div>
                <table>
                  <tbody>
                    <tr>
                      <td class="pb-1 w-32">Enquiry Number:</td>
                      <td class="pb-1">{{ quote.customerCounter }}</td>
                    </tr>
                    <tr>
                      <td class="pb-1 w-32">Status:</td>
                      <td class="pb-1">Enquiry</td>
                    </tr>
                    <tr>
                      <td class="pb-1 w-32">Created at:</td>
                      <td class="pb-1">{{ formatDate(quote.date) }}</td>
                    </tr>
                    <tr v-if="notes">
                      <td class="pb-1 w-32">Notes:</td>
                      <td class="pb-1">{{ notes }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="showPrice" class="flex-1">
              <div class="bg-white border p-3 h-full">
                <div class="border-b font-bold mb-3 pb-3">Price</div>
                <table>
                  <tbody>
                    <tr>
                      <td class="pb-1 w-32">Enquiry Number:</td>
                      <td class="pb-1">{{ quote.customerCounter }}</td>
                    </tr>
                    <tr>
                      <td class="pb-1 w-32">Subtotal:</td>
                      <td
                        class="pb-1"
                        v-html="formatCurrency(touchJob.currency(), touchJob.itemCost())"
                      ></td>
                    </tr>
                    <tr>
                      <td class="pb-1 w-32">VAT:</td>
                      <td
                        class="pb-1"
                        v-html="formatCurrency(touchJob.currency(), touchJob.taxCost())"
                      ></td>
                    </tr>
                    <tr>
                      <td class="pb-1 w-32">Total:</td>
                      <td
                        class="pb-1"
                        v-html="formatCurrency(touchJob.currency(), touchJob.totalCost())"
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <template v-if="job">
            <contract-job-line-item
              v-for="(line_item, index) in sortedItems"
              :key="index"
              :show-decoration-overrides="false"
              :show-sizes="!quote.noSizes"
              :show-price="showPrice"
              :data-index="index"
              :item="line_item"
              :item-number="index + 1"
              :total-items="job.items.length"
              :job="touchJob"
              :is-enquiry-line-item="true"
              breakdown="Price"
            />
          </template>
        </div>
      </template>
    </div>
  </touch-layout>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';
import currencyHelper from '@/mixins/currencyHelper';
import LineItem from '@/components/shared/job/LineItem.vue';
import jobHelper from '@/mixins/jobHelper';
import ConsumerDetails from '@/components/shared/job/ConsumerDetails.vue';
import ConsumerPopup from '@/components/shared/ConsumerPopup.vue';
import FindCustomer from '@/components/business/DealerNetworkEnquiries/FindCustomer.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    'contract-job-line-item': LineItem,
    'consumer-details': ConsumerDetails,
    'consumer-popup': ConsumerPopup,
    'find-customer': FindCustomer,
    Breadcrumbs,
  },
  mixins: [dateHelper, currencyHelper, jobHelper],
  data() {
    return {
      jobId: parseInt(1, 10),
      quote: null,
      notes: '',
      loading: true,
      saving: false,
      edit_consumer_open: false,
      other_actions_dropdown_open: false,
    };
  },
  computed: {
    sortedItems() {
      return this.job.items.slice().sort((a, b) => a.key - b.key);
    },
    showPrice() {
      return false;
    },
    job() {
      return this.quote.jobs.filter((job) => job.jobKey === this.jobId)[0];
    },
    quote_name() {
      if (!this.quote.consumer) {
        return '';
      }

      return [this.quote.consumer.FirstName, this.quote.consumer.LastName]
        .filter(Boolean)
        .join(' ');
    },
  },
  async mounted() {
    await this.loadQuote(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    async savedConsumer() {
      this.loading = true;
      this.edit_consumer_open = false;
      await this.loadQuote(this.$route.params.id);
      this.loading = false;
    },
    async showPdf() {
      this.loading = true;
      await window.touch.downloadQuoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showSurveyPdf() {
      this.loading = true;
      await window.touch.downloadManualSurveyReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showRequotedPdf() {
      this.loading = true;
      await window.touch.downloadRequoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async loadQuote(id) {
      return Promise.all([
        this.$store.dispatch('touch/loadQuote', {
          quoteId: id,
          customerId: await window.touch.staffGetOrganisationCustomerId(),
          jobId: this.jobId,
        }),
        this.$store.dispatch('touch/loadEnquiryNotes', {
          contractId: id,
          jobId: this.jobId,
        }),
      ]).then(([quote, notes]) => {
        this.quote = quote;

        if (notes.length > 0) {
          this.notes = notes[0].text;
        }
      });
    },
    async deleteJob() {
      if (this.quote.jobs.length === 1) {
        this.alertBox().fire({
          title: 'Unable to delete',
          icon: 'error',
          html: 'Cannot delete last job in contract',
        });

        return;
      }
      const quoteId = this.$route.params.id;

      await this.$store.dispatch('touch/deleteJob', {
        contractId: quoteId,
        jobId: 1,
      });

      this.routerPush(`/quote/${quoteId}`);
    },
    async duplicateJob() {
      const quoteId = this.$route.params.id;

      const { value: newReference } = await this.alertBox().fire({
        title: 'Enter reference for new Job',
        input: 'text',
        inputPlaceholder: 'Enter Reference',
      });

      if (!newReference) {
        this.alertBox().fire('Cancelled');
        return;
      }

      await this.$store.dispatch('touch/copyJob', {
        contractId: quoteId,
        jobId: 1,
        reference: newReference,
      });

      this.routerPush(`/quote/${quoteId}`);
    },
    async orderJob() {
      this.loading = true;
      await this.$store
        .dispatch('basket/fromEnquiry', {
          contractId: this.$route.params.id,
          jobKey: 1,
          fromDealer: true,
        })
        .then(() => {
          this.routerPush('/basket');
        });
    },
    async editJob() {
      this.$store
        .dispatch('basket/fromQuote', {
          contractId: this.$route.params.id,
          jobKey: 1,
        })
        .then(() => {
          this.$store.commit('basket/setReference', this.quote.reference);
          this.routerPush('/basket');
        })
        .catch((error) => {
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
        });
    },
    async reload() {
      this.loading = true;
      await this.loadQuote(this.$route.params.id);

      await window.touch.downloadRequoteReport(this.$route.params.id, 1);
      this.saving = false;
      this.loading = false;
    },
  },
};
</script>
