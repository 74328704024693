import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    reference: '',
    selectedImageId: null,
    internalView: false,
    images: [],
    visualisations: {},
    productSourceImages: [],
  },
  mutations: {
    setProductSourceImages(state, images) {
      state.productSourceImages = images;
    },
    updatePosition(state, { id, payload, imageWidth, imageHeight }) {
      const imageId = state.images.filter((image) => image.id === state.selectedImageId)[0].id;

      Vue.set(state.visualisations[imageId].items, id, {
        position: {
          ...state.visualisations[imageId].items[id].position,
          ...payload,
        },
        product: state.visualisations[imageId].items[id].product,
      });

      state.visualisations[imageId].width = imageWidth;
      state.visualisations[imageId].height = imageHeight;
    },
    deactivateAllImages(state) {
      Object.values(state.visualisations).forEach((visualisation) => {
        visualisation.items.forEach((item) => {
          Vue.set(item.position, 'active', false);
        });
      });
    },
    addProduct(state, { product, image, imageWidth, imageHeight }) {
      if (state.visualisations[image.id] === undefined) {
        Vue.set(state.visualisations, image.id, {
          id: image.id,
          width: imageWidth,
          height: imageHeight,
          reference: 'reference',
          insideView: false,
          items: [],
        });
      }

      state.visualisations[image.id].width = imageWidth;
      state.visualisations[image.id].height = imageHeight;

      state.visualisations[image.id].items.push({
        position: {
          x: 100,
          y: 100,
          width: null,
          height: null,
          angle: 0,
          active: true,
          randomKey: Math.random().toString(36).substr(2, 9),
        },
        product: {
          id: product.itemKey,
          image: state.productSourceImages
            .filter((sourceProduct) => sourceProduct.itemKey === product.itemKey)[0]
            .images.filter((productImage) => productImage.type === (state.internalView ? 2 : 1))[0]
            .image,
        },
      });
    },
    selectImage(state, id) {
      state.selectedImageId = id;
    },
    setReference(state, ref) {
      state.reference = ref;
    },
    setView(state, view) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return;
      }

      Vue.set(state.visualisations[match[0].id], 'insideView', view);
    },
    setImages(state, images) {
      state.images = images;
    },
    syncVisualisations(state) {
      state.images.forEach((image) => {
        if (state.visualisations[image.id] === undefined) {
          Vue.set(state.visualisations, image.id, {
            id: image.id,
            width: 0,
            height: 0,
            reference: 'reference',
            insideView: false,
            items: [],
          });
        }
      });
    },
    setVisualisations(state, visualisations) {
      state.visualisations = visualisations;
    },
  },
  actions: {
    clear({ commit }) {
      commit('setReference', '');
      commit('selectImage', null);
      commit('setImages', []);
      commit('setVisualisations', {});
    },
    activateItem({ commit, getters }, key) {
      commit('deactivateAllImages');
      Vue.set(getters.placedImages[key].position, 'active', true);
    },
    async saveToServer({ rootState, state }) {
      const visualisations = Object.values(state.visualisations).map((visualisation) => ({
        Reference: state.reference ? state.reference : 'Your Home',
        VirtualHomeImageId: visualisation.id,
        Width: visualisation.width,
        Height: visualisation.height,
        Items: visualisation.items.map((item) => ({
          ItemKey: item.product.id,
          X: item.position.x,
          Y: item.position.y,
          X1: item.position.x1,
          Y1: item.position.y1,
          X2: item.position.x2,
          Y2: item.position.y2,
          X3: item.position.x3,
          Y3: item.position.y3,
          X4: item.position.x4,
          Y4: item.position.y4,
          Transform: item.position.transform,
          TransformOrigin: item.position['transform-origin'],
        })),
      }));

      // noinspection JSVoidFunctionReturnValueUsed
      await Promise.all(
        Object.values(state.visualisations).map(async (visualisation) => {
          if (visualisation.insideView) {
            return window.touch.visualisationUpdateImage(
              visualisation.id,
              undefined,
              visualisation.insideView,
            );
          }

          return Promise.resolve();
        }),
      );

      await window.touch.processingSetVisualisations(
        rootState.basket.contractIdentifier.contractId,
        rootState.basket.contractIdentifier.jobKey,
        visualisations.filter((visualisation) => visualisation.Width > 0),
      );
    },
    async deleteProduct({ state, commit }, { id }) {
      let deleted;

      do {
        deleted = false;
        Object.values(state.visualisations)
          // eslint-disable-next-line no-loop-func
          .forEach((visualisation) => {
            visualisation.items.forEach((item, index) => {
              if (item.product.id === id) {
                Vue.delete(visualisation.items, index);
                deleted = true;
              }
            });
          });
      } while (deleted);

      commit('setVisualisations', state.visualisations);
    },
    async deleteActiveProduct({ state, commit }) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return;
      }

      const index = state.visualisations[match[0].id].items.findIndex(
        (element) => element.position.active,
      );

      if (index !== -1) {
        Vue.delete(state.visualisations[match[0].id].items, index);
      }

      commit('setVisualisations', state.visualisations);
    },
    async addProduct({ commit, getters, dispatch }, { product, imageWidth, imageHeight }) {
      await dispatch('loadSourceImages');

      commit('deactivateAllImages');

      commit('addProduct', {
        image: getters.selectedImage,
        product,
        imageWidth,
        imageHeight,
      });
    },
    // async loadSourceImages() {
    async loadSourceImages({ rootState, commit, state }, force = false) {
      if (state.productSourceImages.length === 0 || force) {
        await commit(
          'setProductSourceImages',
          await Promise.all(
            rootState.basket.basket.map(async (item) => {
              const fullItem = await window.touch.jobLineItem(
                rootState.basket.customerId,
                rootState.basket.contractIdentifier.contractId,
                rootState.basket.contractIdentifier.jobKey,
                item.key,
                rootState.auth.processingLevel,
                false,
                true,
              );

              return {
                ...item,
                ...fullItem,
              };
            }),
          ),
        );
      }
    },
    async loadFromStorage({ commit, state }) {
      const id = state.selectedImageId;

      if (id) {
        commit('selectImage', id);
      }

      if (state.visualisations) {
        commit('setVisualisations', state.visualisations);
      }

      if (state.reference) {
        commit('setReference', state.reference);
      }
    },
    async updateReference(context, { id, reference, view }) {
      return window.touch.visualisationUpdateImage(id, reference, view);
    },
    async deleteImage(context, { id }) {
      return window.touch.visualisationDeleteImage(id);
    },
    async loadImages({ commit }) {
      const response = await window.touch.visualisationListImages();
      commit('setImages', response.data);
      commit('syncVisualisations');
    },
    async refreshProductImages({ dispatch, state, getters }, reloadProducts) {
      await dispatch('loadSourceImages', reloadProducts);

      const internalView = getters.selectedVisualisation
        ? getters.selectedVisualisation.insideView
        : false;

      Object.values(state.visualisations).forEach((visualisation) => {
        visualisation.items.forEach((item) => {
          Vue.set(
            item.product,
            'image',
            state.productSourceImages
              .filter((sourceProduct) => sourceProduct.itemKey === item.product.id)[0]
              .images.filter((productImage) => productImage.type === (internalView ? 2 : 1))[0]
              .image,
          );
        });
      });
    },
  },
  getters: {
    productIsSelected(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return false;
      }

      return (
        state.visualisations[match[0].id].items.find((element) => element.position.active) !==
        undefined
      );
    },
    placedImages(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return [];
      }

      return state.visualisations[match[0].id].items;
    },
    selectedVisualisation(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return null;
      }

      return state.visualisations[match[0].id];
    },
    selectedImage(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length === 1) {
        return match[0];
      }

      return null;
    },
    images(state) {
      return state.images;
    },
  },
};
