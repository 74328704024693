var render = function render(){var _vm=this,_c=_vm._self._c;return _c('touch-layout',{attrs:{"page_override_class":{ 'trade-designer-choose-product': true }},scopedSlots:_vm._u([{key:"header_page_breadcrumb",fn:function(){return [_c('Breadcrumbs',{attrs:{"routes":[
        ['Dashboard', '/'],
        ['Customer Management', '/customer'],
        ['Sales Dashboard', '/customer/' + _vm.customer.customerId + '/sales-dashboard'],
        ['Choose Product'],
        [_vm.selectedProductName],
      ]}})]},proxy:true},{key:"site_header_actions_wrapper",fn:function(){return [(_vm.selectedProductName)?_c('div',{staticClass:"flex border-b flex-1 h-full"},[(_vm.$refs.chooseProduct)?_c('div',{staticClass:"flex flex-row flex-wrap bg-white md:px-5 border-t-4 border-white h-full"},[_c('div',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-3 md:mx-5 hover:border-black mt-auto",class:{ 'border-black': !_vm.stock_parts_mode, 'border-white': _vm.stock_parts_mode },on:{"click":function($event){return _vm.$refs.chooseProduct.setMode('products')}}},[_vm._v(" Products ")]),_c('div',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-3 md:mx-5 hover:border-black mt-auto",class:{ 'border-black': _vm.stock_parts_mode, 'border-white': !_vm.stock_parts_mode },on:{"click":function($event){_vm.license?.status ? _vm.$refs.chooseProduct.setMode('stock-parts') : _vm.store()}}},[_vm._v(" Stock Parts ")])]):_vm._e()]):_vm._e()]},proxy:true}])},[_c('ChooseProduct',{ref:"chooseProduct",attrs:{"customer":_vm.customer,"url":_vm.url},on:{"selectedProductName":function($event){_vm.selectedProductName = $event},"setStockParts":function($event){_vm.stock_parts_mode = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }