import { render, staticRenderFns } from "./EditProductType.vue?vue&type=template&id=5517e3a2&scoped=true"
import script from "./EditProductType.vue?vue&type=script&lang=js"
export * from "./EditProductType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5517e3a2",
  null
  
)

export default component.exports