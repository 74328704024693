import { isValidDate, addMinutesToDate } from '@/helpers/date';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    processingLevels: [],
    pageProductStoredDesignsCache: {
      cachedPages: {},
      cacheExpiry: undefined,
      // cache lifetime in minutes
      cacheLifetimeMins: 30,
    },
    isDealerNetworkPartnerOnly: false,
  },
  getters: {
    contactDepartments() {
      return [
        window.enum.contactDepartments.MARKETING,
        window.enum.contactDepartments.SALES,
        window.enum.contactDepartments.SALES_ADMIN,
        window.enum.contactDepartments.PRODUCTION,
        window.enum.contactDepartments.DELIVERIES,
        window.enum.contactDepartments.INSTALLATIONS,
        window.enum.contactDepartments.ACCOUNTS,
        window.enum.contactDepartments.CUSTOMER_SERVICE,
        window.enum.contactDepartments.PURCHASING,
      ].map((department) => ({
        id: department,
        name: i18n.t(`contactDepartment.${department}`),
      }));
    },
    positionTypes() {
      return [
        window.enum.contactPositions.DIRECTOR,
        window.enum.contactPositions.MANAGER,
        window.enum.contactPositions.STAFF,
      ].map((positionType) => ({
        id: positionType,
        name: i18n.t(`positionType.${positionType}`),
      }));
    },
  },
  mutations: {
    setDealerNetworkPartnerOnly(state, isDealerNetworkPartnerOnly) {
      state.isDealerNetworkPartnerOnly = isDealerNetworkPartnerOnly;
    },
    SET_PROCESSING_LEVELS(state, processingLevels) {
      state.processingLevels = processingLevels;
    },
    ADD_TO_PAGE_STORED_DESIGNS_CACHE(state, { itemKey, item }) {
      if (!Object.keys(state.pageProductStoredDesignsCache.cachedPages).length) {
        // if there are no pages cached lets set a new cache expiry time
        state.pageProductStoredDesignsCache.cacheExpiry = addMinutesToDate(
          new Date(),
          state.pageProductStoredDesignsCache.cacheLifetimeMins,
        );
      }
      state.pageProductStoredDesignsCache.cachedPages[itemKey] = item;
    },
    CLEAR_STORED_DESIGNS_CACHE(state) {
      state.pageProductStoredDesignsCache.cachedPages = {};
    },
  },
  actions: {
    testAcceptChanges(context, sandboxId) {
      return window.touch.testAcceptChanges(sandboxId);
    },
    customerGetCompanySettings() {
      return window.touch.customerGetCompanySettings();
    },
    customerUpdateCompanySettings(context, { showSizes, showDesignNames }) {
      return window.touch.customerUpdateCompanySettings(showSizes, showDesignNames);
    },
    sendTradeCustomerQuoteReadyEmail(context, { contractId, jobKey }) {
      return window.touch.contractSendTradeCustomerQuoteReadyEmail(contractId, jobKey);
    },
    staffUpdateDiscountCategories(context, discount) {
      return window.touch.staffUpdateDiscountCategories([discount]);
    },
    staffListDiscountCategories() {
      return window.touch.staffListDiscountCategories();
    },
    async loadProcessingLevels(context) {
      if (context.state.processingLevels.length === 0) {
        context.commit('SET_PROCESSING_LEVELS', await window.touch.customerGetProcessingLevels());
      }
    },
    connectGetConnectSubscriptions() {
      return window.touch.connectGetConnectSubscriptions();
    },
    connectGetConnectSources() {
      return window.touch.connectGetConnectSources();
    },
    getDatasetIds() {
      return window.touch.commonGetDatasets();
    },
    async addConnectSource(context, type) {
      const datasetIds = await context.dispatch('getDatasetIds');
      return window.touch.connectUpdateConnectSource(undefined, type, datasetIds[0].id);
    },
    async UpdateConnectCustomerProductLinks(context, link) {
      return window.touch.connectUpdateConnectCustomerProductLinks(
        context.rootState.user.customer.datasetIds[0],
        link,
      );
    },
    authAuthenticateConnectCustomer(context, { username, password, accountId }) {
      return window.touch.authAuthenticateConnectCustomer(username, password, accountId);
    },
    customerUpdateProductDefault(context, { id, productId, optionItemId, datasetId, customerId }) {
      return window.touch.customerUpdateProductDefault(
        id,
        productId,
        optionItemId,
        datasetId,
        customerId,
      );
    },
    customerGetProductDefaultDetails(context, { id, productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaultDetails(id, productId, datasetId, customerId);
    },
    customerGetProductDefaultsWithDetails(context, { productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaultsWithDetails(productId, datasetId, customerId);
    },
    customerGetProductDefaults(context, { productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaults(productId, datasetId, customerId);
    },
    customerGetProductsWithDefaults(context, datasetId) {
      return window.touch.customerGetProductsWithDefaults(datasetId);
    },
    staffUpdateAlternativePriceData(context, { CustomerId, DatasetId, Anomalies, customerName }) {
      return window.touch.staffUpdateAlternativePriceData(
        CustomerId,
        DatasetId,
        Anomalies,
        customerName,
      );
    },
    staffGetFinancialGroups(context, { CustomerId, DatasetId }) {
      return window.touch.staffGetFinancialGroups(CustomerId, DatasetId);
    },
    staffGetAlternativePriceData(
      context,
      { CustomerId, DatasetId, GroupId, Description, Offset, Limit },
    ) {
      return window.touch.staffGetAlternativePriceData(
        CustomerId,
        DatasetId,
        GroupId,
        Description,
        Offset,
        Limit,
      );
    },
    async staffGetLatestExchangeRates() {
      return window.touch.staffGetLatestExchangeRates();
    },
    async staffUpdateCurrency(context, param) {
      return window.touch.staffUpdateCurrency(param);
    },
    async commonGetCurrencies() {
      return window.touch.commonGetCurrencies();
    },
    async processingGetAvailableProductsToSelect(context, { typeId, groupId }) {
      return window.touch.processingGetAvailableProductsToSelect(
        typeId,
        groupId,
        context.rootState.basket.customerId,
      );
    },
    async processingGetAvailableProductSelectionGroups(context, customerId) {
      return window.touch.processingGetAvailableProductSelectionGroups(customerId);
    },
    async processingGetAvailableProductSelectionTypes(context, { customerId, groupId }) {
      return window.touch.processingGetAvailableProductSelectionTypes(customerId, groupId);
    },
    async saveProductGroup(context, { title, description, ImageBlob }) {
      return window.touch.staffUpdateProductSelectionGroups([{ title, description, ImageBlob }]);
    },
    async updateProductGroup(context, { id, title, description, ImageBlob, listOrder }) {
      return window.touch.staffUpdateProductSelectionGroups([
        {
          id,
          title,
          description,
          ImageBlob,
          listOrder,
        },
      ]);
    },
    async updateProductType(context, { id, title, description, ImageBlob, listOrder }) {
      return window.touch.staffUpdateProductSelectionTypes([
        {
          id,
          title,
          description,
          ImageBlob,
          listOrder,
        },
      ]);
    },
    async saveProductType(context, { title, description, ImageBlob }) {
      return window.touch.staffUpdateProductSelectionTypes([{ title, description, ImageBlob }]);
    },
    async deleteProductType(context, groupId) {
      return window.touch.staffUpdateProductSelectionTypes([{ Id: groupId, Delete: true }]);
    },
    async setupUpdateProducts(context, params) {
      return window.touch.setupUpdateProducts([params]);
    },
    async deleteProductGroup(context, groupId) {
      return window.touch.staffUpdateProductSelectionGroups([{ Id: groupId, Delete: true }]);
    },
    async AddExtraItem(context, params) {
      return window.touch.customerUpdateExtraItems([
        {
          GroupId: params.category,
          Description: params.name,
          UnitOfMeasure: params.unit_of_measure,
          Price: params.price,
          FittingItem: params.isFittingCharge,
          UseSizesFromProduct: params.useProductMeasurements,
          ImageData: params.ImageData,
        },
      ]);
    },
    async AddExtraItemOption(context, params) {
      return window.touch.customerUpdateExtraItemOptions([
        {
          ItemId: params.itemId,
          Description: params.description,
          Value: params.value,
        },
      ]);
    },
    async UpdateExtraItemOptions(context, params) {
      return window.touch.customerUpdateExtraItemOptions(params);
    },
    async UpdateExtraItemOption(context, params) {
      return window.touch.customerUpdateExtraItemOptions([
        {
          id: params.id,
          ItemId: params.itemId,
          Description: params.description,
          Price: params.value,
          Delete: params.deleted,
        },
      ]);
    },
    async updateExtraItem(context, params) {
      return window.touch.customerUpdateExtraItems([
        {
          Id: params.id,
          GroupId: params.category,
          Description: params.name,
          UnitOfMeasure: params.unit_of_measure,
          Value: params.price,
          FittingItem: params.isFittingCharge,
          UseSizesFromProduct: params.useProductMeasurements,
          ImageData: params.ImageData,
        },
      ]);
    },
    async DeleteExtraItem(context, id) {
      return window.touch.customerUpdateExtraItems([
        {
          Id: id,
          Delete: true,
        },
      ]);
    },
    async AddExtraItemGroup(context, name) {
      return window.touch.customerUpdateExtraItemGroups([
        {
          Description: name,
        },
      ]);
    },
    async UpdateExtraItemGroups(context, params) {
      return window.touch.customerUpdateExtraItemGroups(params);
    },
    async deleteExtraItemGroup(context, groupId) {
      return window.touch.customerUpdateExtraItemGroups([
        {
          Id: groupId,
          Delete: true,
        },
      ]);
    },
    async customerGetAllExtraItems() {
      return window.touch.customerGetAllExtraItems();
    },
    async vendorUpdateThisGridAnomaly(context, params) {
      return window.touch.vendorUpdateThisGridAnomaly(
        context.rootState.user.customer.datasetIds[0],
        params.Id,
        params.FinancialGridConfigurationId,
        params.OverrideCells,
      );
    },
    async vendorGetThisGridAnomaly(context, params) {
      return window.touch.vendorGetThisGridAnomaly(
        params.id,
        params.customerId,
        params.datasetId ?? context.rootState.user.customer.datasetIds[0],
      );
    },
    async staffUpdateThisListAnomaly(context, params) {
      return window.touch.staffUpdateThisListAnomaly(
        params.DatasetId ?? context?.rootState?.user?.customer?.datasetIds[0],
        params.Id,
        params.FinancialGridConfigurationId,
        params.OverrideCells,
        params.customerId,
      );
    },
    async staffGetThisListAnomaly(context, { id, customerId, datasetId }) {
      return window.touch.staffGetThisListAnomaly(id, customerId, datasetId);
    },
    async customerGetFinancialGroups(context) {
      return window.touch.customerGetFinancialGroups(
        context.rootState.user.customer.datasetIds[0],
      );
    },
    async staffUpdateOrganisationDetails(context, { name, email, phone, web, file }) {
      return window.touch.staffUpdateOrganisationDetails(name, email, phone, web, file);
    },
    async assignLead(context, { contractId, customerIds }) {
      return window.touch.staffAssignEnquiry(contractId, customerIds);
    },
    async loadProductsForCustomer(context, customerId) {
      return window.touch.customerGetAllProductsWithOverrides(customerId);
    },
    async updateProductsForCustomer(context, { customerId, products }) {
      return window.touch.customerUpdateProductOverrides(products, customerId);
    },
    async getMyStoreLinkToken() {
      return window.touch.authGetMyStoreLinkToken();
    },
    async productSelectionStage(context, selectedStages = []) {
      return window.touch.processingProductSelectionStage(selectedStages);
    },
    async getProducts() {
      return window.touch.getProducts();
    },
    async saveJobTotalOverride(context, { contractId, jobId, overridePrice, fitPrice }) {
      return window.touch.contractUpdateJobRequote({
        contractId,
        jobKey: jobId,
        overridePrice,
        fitPrice,
        requotePriceBeforeTax: Number(overridePrice),
      });
    },
    async saveItemOverride({ rootState }, { contractId, jobId, overridePrices }) {
      const response = await window.touch.processingReloadContract(
        rootState.basket.customerId,
        contractId,
      );

      if (!response.status) {
        throw new Error(response.message);
      }

      return window.touch.processingUpdateJobLineItemRequote(
        contractId,
        jobId,
        rootState.auth.processingLevel,
        overridePrices.map((item) => ({
          itemKey: item.itemKey,
          price: item.price,
          fitPrice: item.fitPrice,
        })),
      );
    },
    async saveContract(context, { contractId }) {
      return window.touch.processingSaveContract(context.rootState.basket.customerId, contractId);
    },
    // async dashboardEnquiries({ rootState }, vendorMode = false) {
    async dashboardEnquiries(context, vendorMode = false) {
      let typeLabel = 'Branded Mode Enquiry';
      if (vendorMode) {
        typeLabel = 'Website Designer Enquiry';
      }

      const brandedModeEnquiries = (
        await window.touch.contractSummary(
          window.touch.CONTRACT_TYPE_ENQUIRY,
          5,
          0,
          undefined,
          this.enums.EnquiryStatus.UNASSIGNED,
          undefined,
          window.enum.enquirySource.BRANDED_MODE,
        )
      ).contracts.map((quote) => ({
        ...quote,
        // ...await window.touch.contractDetails(context.rootState.basket.customerId, quote.id, undefined, rootState.auth.enquiryProcessingLevel),
        url: `/enquiry/${quote.id}/1`,
        enquirySource: window.enum.enquirySource.BRANDED_MODE,
        type: typeLabel,
      }));

      return brandedModeEnquiries.sort((a, b) => b.id - a.id);
    },
    // async dashboardEnquiries({ rootState }, vendorMode = false) {
    async dashboardNetworkEnquiries() {
      const dealerNetworkEnquiries = (
        await window.touch.contractList(
          window.touch.CONTRACT_TYPE_ENQUIRY,
          5,
          0,
          undefined,
          this.enums.DealerStatus.AWAITINGACTION,
          undefined,
          window.enum.enquirySource.DEALER_NETWORK,
        )
      ).contracts.map((quote) => ({
        ...quote,
        // ...await window.touch.contractDetails(context.rootState.basket.customerId, quote.id, undefined, rootState.auth.enquiryProcessingLevel),
        url: `/enquiry/${quote.id}/1`,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        type: 'Dealer Network Enquiry',
      }));

      return dealerNetworkEnquiries.sort((a, b) => b.id - a.id);
    },
    async dashboardQuotes({ rootState }) {
      return Promise.all(
        (await window.touch.contractList(window.touch.CONTRACT_TYPE_QUOTE, 5, 0)).contracts.map(
          async (quote) => ({
            ...quote,
            jobs: (
              await window.touch.contractListFirstJobMinimal(
                quote.id,
                rootState.auth.processingLevel,
              )
            ).map((job) => ({
              ...job,
              currency: quote.currencyCode,
              customerCurrency: quote.customerCurrencyCode,
            })),
            url: quote.jobs.length === 1 ? `/quote/${quote.id}/1` : `/quote/${quote.id}`,
          }),
        ),
      );
    },
    async dashboardOrders() {
      return (
        await window.touch.contractList(window.touch.CONTRACT_TYPE_ORDER, 5, 0)
      ).contracts.map((order) => ({
        ...order,
        url: `/order/${order.id}`,
      }));
    },
    async loadQuotes(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_QUOTE,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.statusId,
        onlyShowLocked: options.onlyShowLocked,
        IncludeItems: true,
      });
      const quotes = response.contracts.map((quote) => ({
        ...quote,
        url: quote.jobs.length === 1 ? `/quote/${quote.id}/1` : `/quote/${quote.id}`,
      }));
      return {
        quotes,
        total_items: response.total,
      };
    },
    async loadActiveQuotes({ dispatch }, options) {
      return dispatch('loadQuotes', options);
    },
    async staffListCustomers(
      context,
      { limit, offset, query, contractId, dealerNetworkPartnerOnly, customerStatus },
    ) {
      return window.touch.staffListCustomers(
        limit,
        offset,
        query,
        contractId ? Number(contractId) : undefined,
        dealerNetworkPartnerOnly || false,
        customerStatus,
      );
    },
    async loadDealerNetworkList(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        statusId: options.statusId,
        fromDate: options.fromDate,
        toDate: options.toDate,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        isEnquiryOverdue:
          order.expired.charAt(2) !== '0'
            ? [...new Set(order.expired.split('/'))].length === 1
            : false,
        // the format of the order refused is x/y
        // if y is zero the contract hasn't been assigned yet so can't have been refused
        // if it is not 0 then we split on the /, change the resulting array into a set thus eliminating duplicates, and then back into an array.
        // if the length of the array is 1 then x and y were the same, thus the contract has been completely refused.
        // if the length is greater than one then x and y were different, thus we know at least one person has not yet refused the order.
        isEnquiryCompletelyRefused:
          order.refused.charAt(2) !== '0'
            ? [...new Set(order.refused.split('/'))].length === 1
            : false,
      }));
      return {
        orders,
        total_items: response.total,
      };
    },
    async loadDealerNetworkAssignedList(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        statusId: [2, 3],
        fromDate: options.fromDate,
        toDate: options.toDate,
      });
      return {
        orders: response.contracts,
        assignees: response.assignees,
        total_items: response.total,
      };
    },
    async loadOrders(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ORDER,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.orderStatus,
        onlyShowLocked: options.onlyShowLocked,
        customerId: options.customerId,
        branchCompanyId: options.branchCompanyId,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        url: `/order/${order.id}`,
      }));
      return {
        orders,
        total_items: response.total,
        assignees: response.assignees,
      };
    },
    async loadQuotesForAdmin(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_QUOTE,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.orderStatus,
        customerId: options.customerId,
        customQuoteStageId: options.customQuoteStageId,
        branchCompanyId: options.branchCompanyId,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        url: `/quote/${order.id}`,
      }));
      return {
        orders,
        total_items: response.total,
        assignees: response.assignees,
      };
    },
    async updateConsumer(context, params) {
      const { contractId, customerId, consumer } = params;
      await window.touch.contractUpdateConsumer({
        customerId: customerId || context.rootState.basket.customerId,
        contractId,
        firstName: consumer.firstName,
        lastName: consumer.lastName,
        email: consumer.email,
        mobile: consumer.mobile,
        addressLine1: consumer.addressLine1,
        addressLine2: consumer.addressLine2,
        addressLine3: consumer.addressLine3,
        addressTown: consumer.addressTown,
        addressCounty: consumer.addressCounty,
        addressCode: consumer.addressCode,
        addressIso: consumer.addressIso,
      });
    },
    async loadEnquiries(context, options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        enquirySource: options.enquirySource,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.statusId,
        onlyShowLocked: options.onlyShowLocked,
      });

      const enquiries = response.contracts.map((enquiry) => ({
        ...enquiry,
        url: `/enquiry/${enquiry.id}/1`,
      }));

      return {
        enquiries,
        total_items: response.total,
      };
    },
    async unlockQuote(context, { contractId }) {
      return window.touch.processingRemoveContract(contractId);
    },
    async get3DModel(context, { contractId, itemKey, jobKey, customerId }) {
      return window.touch.processingGet3DModel(contractId, itemKey, jobKey, customerId);
    },
    async loadEnquiryNotes(context, { contractId, jobId }) {
      return window.touch.contractGetNotes({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: window.enum.noteType.CUSTOMER,
      });
    },
    async loadNotes(context, { contractId, jobId }) {
      try {
        const notes = await window.touch.contractGetNotes({
          ContractId: contractId,
          JobKey: jobId,
        });

        if (notes.length === 0) {
          return null;
        }

        return notes;
      } catch (e) {
        return null;
      }
    },
    async loadNote(context, { contractId, jobId, noteType }) {
      try {
        const notes = (
          await window.touch.contractGetNotes({
            ContractId: contractId,
            JobKey: jobId,
          })
        ).filter((note) => note.noteType === noteType);

        if (notes.length === 0) {
          return null;
        }

        return notes[0];
      } catch (e) {
        return null;
      }
    },
    async updateNote(context, { contractId, jobId, noteType, noteId, text, deleteNote = false }) {
      return window.touch.contractUpdateNote({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: noteType,
        NoteId: noteId,
        Text: text,
        Delete: deleteNote,
      });
    },
    async createNote(context, { contractId, jobId, noteType, text }) {
      return window.touch.contractUpdateNote({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: noteType,
        Text: text,
      });
    },
    async loadQuote({}, { customerId, quoteId, jobId }) {
      return window.touch.contractDetails(
        customerId,
        quoteId,
        jobId
      );
    },
    async deleteJob(context, { contractId, jobId }) {
      await window.touch.processingReloadContract(context.rootState.basket.customerId, contractId);
      await window.touch.processingDeleteJob(contractId, jobId);
      await window.touch.processingSaveContract(context.rootState.basket.customerId, contractId);
    },
    async copyJob(context, { contractId, jobId, reference }) {
      await window.touch.processingReloadContract(context.rootState.basket.customerId, contractId);
      const newJobKey = await window.touch.processingCopyJob(contractId, jobId);

      await window.touch.processingUpdateJob(contractId, newJobKey, {
        Reference: reference,
      });

      await window.touch.processingSaveContract(context.rootState.basket.customerId, contractId);

      return newJobKey;
    },
    async loadOrder({}, { customerId, id }) {
      return window.touch.contractDetails(
        customerId,
        id
      );
    },
    async checkSeats(context, id = null) {
      return window.touch.customerCheckSeats(id);
    },
    async loadJobDelivery(context, { contractId, jobId }) {
      return window.touch.processingJobDelivery(
        context.rootState.basket.customerId,
        contractId,
        jobId,
      );
    },
    async loadMyAddresses() {
      const customer = await window.touch.customerGet();

      return customer.addresses;
    },
    async setBrandedContract(context, contractId) {
      context.commit(
        'basket/setContract',
        {
          contractId,
          jobKey: 1, // This is always 1 for branded mode enquiries
          isEnquiry: true,
        },
        { root: true },
      );
    },
    async startPublicSession(context, { slug, returnUrl }) {
      const auth = await window.touch.authVendorGuest(slug, returnUrl);

      if (auth.status === 400) {
        throw new Error('No Designer Found');
      }

      await context.dispatch(
        'auth/setVendorLogin',
        {
          isDemo: auth.data.isDemo,
          isDealerNetwork: auth.data.isDealerMode,
          property: 'public',
          token: auth.data.token,
          tokenExpiry: auth.data.expiresAt,
        },
        { root: true },
      );

      context.commit(
        'basket/setContract',
        {
          contractId: auth.data.contractId,
          jobKey: 1, // This is always 1 for branded mode enquiries
          isEnquiry: true,
        },
        { root: true },
      );

      context.commit('basket/setButtonName', 'Enquiry', { root: true });

      context.dispatch(
        'branded/setSlugAndKey',
        {
          slug,
        },
        { root: true },
      );

      return auth;
    },
    async getEnquiryStatuses() {
      return window.touch.getEnquiryStatuses();
    },
    async updateQuoteStatus(context, { contractId, status }) {
      await window.touch.processingReloadContract(context.rootState.basket.customerId, contractId);
      await window.touch.updateQuoteStatus(contractId, status);
      await window.touch.processingSaveContract(context.rootState.basket.customerId, contractId);
    },
    async updateEnquiryStatus(context, { contractId, status }) {
      await window.touch.processingReloadContract(context.rootState.basket.customerId, contractId);
      await window.touch.updateEnquiryStatus(contractId, status);
      await window.touch.processingSaveContract(context.rootState.basket.customerId, contractId);
    },
    async updateDealerEnquiryStatus(context, { dealerNetworkAssignmentId, status, refusalReason }) {
      await window.touch.updateDealerEnquiryStatus(
        dealerNetworkAssignmentId,
        status,
        refusalReason,
      );
    },
    async getDocsToAccept() {
      return window.touch.getDocsToAccept();
    },
    async updateJobRequote(context, { contractId, jobKey, emailSubject, emailBodyText }) {
      return window.touch.contractUpdateJobRequote({contractId, jobKey, emailSubject, emailBodyText});
    },
    async sendRequoteEmailToConsumer(context, { contractId, jobKey }) {
      await window.touch.contractSendRequoteEmailToConsumer(contractId, jobKey);
    },

    async getProductStoredDesignsForPage(
      context,
      { customerId, processingLevel, productId, page = 1, perPage = 50, squaresQty = undefined },
    ) {
      /*
       * this has some primitive caching functionality within it
       * if this approach is copied, I'd suggest we refactor and setup a store specific for caches
       * this would handle cache functionality (clearing, cache expiry, etc)
       */
      const cacheKey = `${customerId}${processingLevel}${productId}${undefined}${undefined}${false}${perPage}${(page - 1) * perPage}${squaresQty}`;

      if (
        isValidDate(context.state.pageProductStoredDesignsCache.cacheExpiry) &&
        context.state.pageProductStoredDesignsCache.cacheExpiry < Date.now()
      ) {
        // if the cache expiry is in the past then we need to clear out the cache
        context.commit('CLEAR_STORED_DESIGNS_CACHE');
      } else if (
        Object.keys(context.state.pageProductStoredDesignsCache.cachedPages).includes(cacheKey)
      ) {
        // we check to see if there is a product page in the cache that matches the page we're after
        return context.state.pageProductStoredDesignsCache.cachedPages[cacheKey];
      }

      return window.touch
        .productStoredDesigns(
          customerId,
          processingLevel,
          productId,
          undefined,
          undefined,
          false,
          perPage,
          (page - 1) * perPage,
          squaresQty,
        )
        .then((res) => {
          context.commit('ADD_TO_PAGE_STORED_DESIGNS_CACHE', { itemKey: cacheKey, item: res });
          return res;
        });
    },
  },
};
