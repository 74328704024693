import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    activePopup: '',
    isVisible: {},
  },
  mutations: {
    setIsVisible(state, { key, value }) {
      Vue.set(state.isVisible, key, value);
    },
    setActive(state, id) {
      state.activePopup = id;
    },
  },
};
