<script>
import BasicLine from '@/components/shared/priceLines/List/Lines/BasicLine.vue';

export default {
  mixins: [BasicLine],
  methods: {
    setupLayout() {
      this.subtitle = 'Per m<sup>2</sup>';
      this.icon = this.currencySymbol(
        this.touch_business_installation
          ? this.defaultCurrency
          : this.$store.state.user.customer.currency,
      );
    },
  },
};
</script>
