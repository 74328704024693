<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Discounts'],
        ]"
      />
    </template>
    <template #header_page_title> Discounts </template>
    <Menu></Menu>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <template v-if="discounts.length > 0">
        <div class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16">
          <div
            v-for="(discount, index) in discounts"
            :key="index"
            class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
          >
            <div class="font-bold">{{ discount.description }}</div>
            <div class="h-10 flex flex-col justify-around">{{ discount.value }}%</div>
          </div>
          <div class="absolute bottom-0 right-0 p-5">
            <div
              v-show="is_admin_user"
              class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
              @click="editing = true"
            >
              <i class="fa fa-pencil"></i>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="bg-white border m-3 xs:m-10 p-3 xs:p-10">No Discounts to Show</div>
      </template>
      <portal to="portal_popup">
        <modal-window
          :modal_open="editing"
          title="Edit Customer Discounts"
          @close="editing = false"
        >
          <div class="flex flex-wrap">
            <div
              v-for="(discount, index) in discounts"
              :key="index"
              class="w-full mt-4 xs:w-1/2"
              :class="{
                'xs:pr-5': index % 2 === 0,
                'xs:pl-5': index % 2 !== 0,
                'xs:mt-8': index > 1,
              }"
            >
              <div class="font-medium">
                {{ discount.description }}
              </div>
              <input v-model="discount.value" class="form-control" />
            </div>
          </div>
          <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
            <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="editing = false">
              Cancel
            </button>
            <button class="btn-action btn-lg" @click.prevent.stop="saveSettings">
              Save Changes
            </button>
          </div>
        </modal-window>
      </portal>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import validate from 'validate.js';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      discountList: {},
      customerDiscountList: {},
      discounts: {},
      original_discounts: {},
      loading: true,
      editing: false,
    };
  },
  async mounted() {
    await this.loadFeed();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
      staffGetDiscountList: 'user/staffGetDiscountList',
      staffGetCustomerDiscounts: 'user/staffGetCustomerDiscounts',
      staffUpdateDiscounts: 'user/staffUpdateDiscounts',
    }),
    sort_discounts() {
      return this.discountsList
        .map((discount) => {
          const customerDiscount = this.customerDiscountsList.filter(
            (custDiscount) => custDiscount.id === discount.id,
          )[0];
          return {
            id: discount.id,
            description: discount.description,
            value: customerDiscount.value,
            listIndex: customerDiscount.listIndex,
          };
        })
        .sort((a, b) => a.listIndex - b.listIndex);
    },
    async loadFeed() {
      this.discountsList = await this.staffGetDiscountList(this.customer.datasetIds[0]);
      this.customerDiscountsList = await this.staffGetCustomerDiscounts({
        customerId: this.customer.customerId,
        datasetId: this.customer.datasetIds[0],
      });
      this.original_discounts = this.sort_discounts();
      this.discounts = this.sort_discounts();
    },
    async saveSettings() {
      this.loading = true;
      if (this.validateInput()) {
        await this.staffUpdateDiscounts({
          customerId: this.customer.customerId,
          datasetId: this.customer.datasetIds[0],
          discounts: this.discounts,
        });
      }
      this.editing = false;
      this.loading = false;
    },
    validateInput() {
      let isValid = true;
      this.discounts.forEach((discount) => {
        if (!validate.isNumber(Number(discount.value))) {
          isValid = false;
        }
      });
      return isValid;
    },
  },
};
</script>
