<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['KPI', '/kpi'],
        ]"
      />
    </template>
    <template #header_page_title> KPI </template>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-5 xs:p-10">
      <div class="w-full flex flex-col gap-5">
        <div class="flex flex-col xs:flex-row gap-5">
          <widget-kpi-active-users></widget-kpi-active-users>
          <widget-kpi-quotes></widget-kpi-quotes>
          <widget-kpi-orders></widget-kpi-orders>
        </div>
        <div class="flex flex-col xs:flex-row gap-5">
          <widget-kpi-enquiries-to-quotes></widget-kpi-enquiries-to-quotes>
          <widget-kpi-quotes-to-orders></widget-kpi-quotes-to-orders>
        </div>
        <div class="flex flex-col xs:flex-row gap-5">
          <widget-kpi-top-users-for-quotes></widget-kpi-top-users-for-quotes>
          <widget-kpi-top-users-for-orders></widget-kpi-top-users-for-orders>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import WidgetKpiActiveUsers from '@/components/business/kpi/WidgetKpiActiveUsers.vue';
import WidgetKpiQuotes from '@/components/business/kpi/WidgetKpiQuotes.vue';
import WidgetKpiOrders from '@/components/business/kpi/WidgetKpiOrders.vue';
import WidgetKpiEnquiriesToQuotes from '@/components/business/kpi/WidgetKpiEnquiriesToQuotes.vue';
import WidgetKpiQuotesToOrders from '@/components/business/kpi/WidgetKpiQuotesToOrders.vue';
import WidgetKpiTopUsersForQuotes from '@/components/business/kpi/WidgetKpiTopUsersForQuotes.vue';
import WidgetKpiTopUsersForOrders from '@/components/business/kpi/WidgetKpiTopUsersForOrders.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'widget-kpi-active-users': WidgetKpiActiveUsers,
    'widget-kpi-quotes': WidgetKpiQuotes,
    'widget-kpi-orders': WidgetKpiOrders,
    'widget-kpi-enquiries-to-quotes': WidgetKpiEnquiriesToQuotes,
    'widget-kpi-quotes-to-orders': WidgetKpiQuotesToOrders,
    'widget-kpi-top-users-for-quotes': WidgetKpiTopUsersForQuotes,
    'widget-kpi-top-users-for-orders': WidgetKpiTopUsersForOrders,
  },
};
</script>
