<template>
  <div class="-m-6 sm:m-0 relative">
    <div class="flex mr-3">
      <div
        v-for="(group, groupName) in groups"
        :key="`tab_${groupName}`"
        class="px-4 py-2 border mr-1 text-xs 2xs:text-sm sm:text-lg relative top-px bg-white border-b-0 flex flex-col cursor-pointer"
        :class="{ 'bg-gray-200 border-b': open_parent_group !== groupName }"
        @click="toggleOpenParentGroup(groupName)"
      >
        <div class="flex flex-col justify-around flex-grow">
          {{ groupName }}
        </div>
        <div
          v-if="open_parent_group === groupName"
          class="border-b border-white absolute bottom-0 left-0 right-0"
        ></div>
      </div>
    </div>
    <div class="border rounded-b-lg">
      <div class="p-3 pb-0">For this category prices are split into:</div>
      <div v-if="open_parent_group === 'Colours'" class="flex flex-wrap">
        <div class="w-full md:w-1/4 flex">
          <div :key="`Colours-White`" class="mb-3 flex-1 flex flex-col m-3 w-1/2">
            <div
              class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
            >
              <div>White</div>
            </div>
            <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
              <div class="flex flex-wrap">
                <div v-for="(item, i) in groups.Colours.White" :key="i" class="w-full p-2">
                  <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                    <div class="flex flex-col justify-around">
                      <div class="relative inline">
                        <api-img
                          :src="item[1]"
                          style="max-height: 75px; width: auto; height: auto"
                          class="mx-auto"
                        />
                      </div>
                      <div
                        class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                        style="height: 50px"
                      >
                        {{ item[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :key="`Colours-Bespoke`" class="mb-3 flex-1 flex flex-col m-3 w-1/2">
            <div
              class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
            >
              <div>Bespoke</div>
            </div>
            <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
              <div class="flex flex-wrap">
                <div v-for="(item, i) in groups.Colours.Bespoke" :key="i" class="w-full p-2">
                  <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                    <div class="flex flex-col justify-around">
                      <div class="relative inline">
                        <api-img
                          :src="item[1]"
                          style="max-height: 75px; width: auto; height: auto"
                          class="mx-auto"
                        />
                      </div>
                      <div
                        class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                        style="height: 50px"
                      >
                        {{ item[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-3/4 flex">
          <div :key="`Colours-Foil-Grained`" class="mb-3 flex-1 flex flex-col m-3 w-1/2">
            <div
              class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
            >
              <div>Foil Grained</div>
            </div>
            <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, i) in groups.Colours['Foil Grained']"
                  :key="i"
                  class="w-full 2xs:w-1/2 xs:w-1/3 lg:w-1/4 p-2"
                >
                  <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                    <div class="flex flex-col justify-around">
                      <div class="relative inline">
                        <api-img
                          :src="item[1]"
                          style="max-height: 75px; width: auto; height: auto"
                          class="mx-auto"
                        />
                      </div>
                      <div
                        class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                        style="height: 50px"
                      >
                        {{ item[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :key="`Colours-Foil-Coloured`" class="mb-3 flex-1 flex flex-col m-3 w-1/2">
            <div
              class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
            >
              <div>Foil Coloured</div>
            </div>
            <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, i) in groups.Colours['Foil Coloured']"
                  :key="i"
                  class="w-full 2xs:w-1/2 xs:w-1/3 lg:w-1/4 p-2"
                >
                  <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                    <div class="flex flex-col justify-around">
                      <div class="relative inline">
                        <api-img
                          :src="item[1]"
                          style="max-height: 75px; width: auto; height: auto"
                          class="mx-auto"
                        />
                      </div>
                      <div
                        class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                        style="height: 50px"
                      >
                        {{ item[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="open_parent_group === 'Doors'" class="flex">
        <div
          v-for="(data, name) in groups.Doors"
          :key="`Doors-${name}`"
          class="mb-3 flex-1 flex flex-col m-3"
        >
          <div
            class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
          >
            <div>{{ name }}</div>
          </div>
          <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
            <div class="flex flex-wrap">
              <div v-for="(item, i) in data" :key="i" class="w-full xs:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                  <div class="flex flex-col justify-around">
                    <div class="relative inline">
                      <api-img
                        :src="item[1]"
                        style="max-height: 75px; width: auto; height: auto"
                        class="mx-auto"
                      />
                    </div>
                    <div
                      class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                      style="height: 50px"
                    >
                      {{ item[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="open_parent_group === 'Composite Door Colours'" class="flex">
        <div
          v-for="(data, name) in groups['Composite Door Colours']"
          :key="`Composite-Door-Colours-${name}`"
          class="mb-3 flex-1 flex flex-col m-3"
        >
          <div
            class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
          >
            <div>{{ name }}</div>
          </div>
          <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
            <div class="flex flex-wrap">
              <div v-for="(item, i) in data" :key="i" class="w-full xs:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                  <div class="flex flex-col justify-around">
                    <div class="relative inline">
                      <api-img
                        :src="item[1]"
                        style="max-height: 75px; width: auto; height: auto"
                        class="mx-auto"
                      />
                    </div>
                    <div
                      class="inline-block flex flex-col justify-around text-2xs sm:text-xs m-auto p-2 leading-tight"
                      style="height: 50px"
                    >
                      {{ item[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="open_parent_group === 'Composite Door Glass'" class="flex flex-wrap">
        <div
          v-for="(data, name) in groups['Composite Door Glass']"
          :key="`Composite-Door-Glass-${name}`"
          class="p-3 flex flex-col"
          :class="{
            'w-full order-3 pt-0': name === 'Bevel',
            'w-1/3': name === 'Standard',
            'w-2/3': name === 'Premium',
          }"
        >
          <div class="mb-3 flex-1 flex flex-col h-full">
            <div
              class="p-2 sm:text-base w-full flex justify-between border-b bg-green-600 text-white border-green-600 rounded-t"
            >
              <div>{{ name }}</div>
            </div>
            <div class="flex flex-col bg-gray-200 flex-grow p-1 border rounded-b">
              <div class="flex flex-wrap">
                <div v-for="(item, i) in data" :key="i" class="w-full p-2" style="width: 155px">
                  <div class="h-full shadow-md bg-white text-center rounded-lg p-4">
                    <div class="flex flex-col justify-around">
                      <div class="relative inline">
                        <api-img
                          :src="item[1]"
                          style="max-height: 75px; width: auto; height: auto"
                          class="mx-auto"
                        />
                      </div>
                      <div
                        class="inline-block flex flex-col justify-around text-2xs sm:text-xs p-2 mt-2 leading-tight"
                      >
                        {{ item[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingInfo',
  props: {
    start_on_section: {
      default: 'Doors',
    },
  },
  data() {
    return {
      open_parent_group: this.start_on_section,
      open_group: null,
      groups: {
        Colours: {
          White: [['White', '/api/image/asset/117/b0e4a5a2-5a17-4599-9ac1-35cb75b10c8d']],
          Bespoke: [
            ['Bespoke Colour', '/api/image/asset/117/43efe5c9-d6ba-48bf-9b9f-acf80560d5da'],
          ],
          'Foil Grained': [
            ['Antique Oak', '/api/image/asset/117/534a8719-de31-4b15-b597-08f1dfbae72d'],
            ['English Oak', '/api/image/asset/117/a065c403-777a-4ceb-baea-1e342e34141e'],
            ['Golden Oak', '/api/image/asset/117/c02f0728-b489-46e5-833c-9a21d780a5ca'],
            ['Irish Oak', '/api/image/asset/117/4bf694c3-adf1-4a22-aad9-10686b0d0875'],
            ['Mahogany', '/api/image/asset/117/a0e6b394-f1df-4f75-a0a9-ebe4a7a1a613'],
            ['Rosewood', '/api/image/asset/117/01c0392f-0a33-48aa-96c4-5b70228f0356'],
          ],
          'Foil Coloured': [
            ['Agate Grey', '/api/image/asset/117/fb085c22-82f3-4b3b-8d28-702480555431'],
            ['Anthracite Grey Smooth', '/api/image/asset/117/f79cf4ac-d414-4a7f-aa6f-8490706c31cf'],
            ['Anthracite Grey', '/api/image/asset/117/86231423-34f0-4566-8b40-e63bf7d5be75'],
            ['Black', '/api/image/asset/117/131678df-313b-49a2-8970-b8b913290d8b'],
            ['Blue', '/api/image/asset/117/e08a534f-9075-4d9f-877b-1fa4ff643890'],
            ['Chartwell Green', '/api/image/asset/117/811015ff-dda7-4589-a70c-651e572e9303'],
            ['Cream', '/api/image/asset/117/fa1fa878-dcee-4ea8-95f0-4dcd4f654f72'],
            ['Green', '/api/image/asset/117/62b9c32d-123e-4192-bc13-fae7bf6bcad1'],
            ['Red', '/api/image/asset/117/be46f861-2c04-4b32-a7b5-b619c49ebd3d'],
            ['White Foil', '/api/image/asset/117/4b6e11db-5f0b-4123-b61c-306388da5ad5'],
          ],
        },
        Doors: {
          Traditional: [
            ['1 Large Glass', '/api/image/asset/117/59a3b8a5-09fa-4afe-ae95-654d7c0ee32b'],
            [
              '2 Panel 1 Arched Glass With Grid',
              '/api/image/asset/117/59fead82-a7b1-4da2-8f13-d8fa0d565972',
            ],
            ['2 Panel 1 Arched Glass', '/api/image/asset/117/4dfd0a04-9d77-4e15-91ba-1f211515b512'],
            [
              '2 Panel 1 Glass with Grid',
              '/api/image/asset/117/6c03ac63-40ba-4c9b-b666-7d4f0fc593ae',
            ],
            ['2 Panel 1 Glass', '/api/image/asset/117/c81a4432-23d5-415b-bf43-69bd174342e2'],
            ['2 Panel 1 Large Glass', '/api/image/asset/117/6b163348-2930-4a17-96a5-72289cd3e2d7'],
            ['2 Panel 2 Angled Glass', '/api/image/asset/117/7d8d5d4d-3698-4783-9eb0-b47c77b720a0'],
            ['2 Panel 2 Arched Glass', '/api/image/asset/117/160af6a8-7715-4768-81df-d55b6b6b1e75'],
            [
              '2 Panel 2 Glass Circle Glass With Grid',
              '/api/image/asset/117/7638508d-422e-496a-982b-9df4f78b0a9e',
            ],
            [
              '2 Panel 2 Glass Circle Glass',
              '/api/image/asset/117/3b9ab63e-e220-4e92-8018-54aa522326f9',
            ],
            ['2 Panel 2 Glass', '/api/image/asset/117/70ea1a5f-3965-4bdb-b27a-c6ba249356dc'],
            ['2 Panel 4 Glass', '/api/image/asset/117/0f534772-98a0-4dcb-a54e-b9201f776a58'],
            [
              '3 Panel 1 Square Arched Glass',
              '/api/image/asset/117/c44937f8-fef9-4bbe-9106-ec54a30219c1',
            ],
            ['4 Panel 2 Square Glass', '/api/image/asset/117/eb3a87db-ab7f-4db9-9813-f107c3ef2876'],
            ['4 Panel 2 Square', '/api/image/asset/117/c297fe25-b355-4189-b184-239e79e1ebca'],
            ['4 Panel Blank Top', '/api/image/asset/117/68e430bf-4a90-45a4-8bae-51335973ca73'],
            [
              '4 Panel Half Circle Glass With Grid',
              '/api/image/asset/117/dd1ec4d7-b097-47b3-b110-51bcb7b20a40',
            ],
            [
              '4 Panel Half Circle Glass',
              '/api/image/asset/117/689e0224-ba8e-4cf7-aa30-60bcf23e004a',
            ],
            [
              'Cottage 1 Rectangle Glass',
              '/api/image/asset/117/7821817d-0b19-4378-8730-cc6678f3a673',
            ],
            [
              'Cottage 1 Small Square Glass',
              '/api/image/asset/117/c565a9ef-6c28-4814-9d97-40f6361e8158',
            ],
            ['Cottage 1 Square Glass', '/api/image/asset/117/4aed592f-5e47-4fe7-bd46-57c76f1711b0'],
            [
              'Cottage 2 Panel 1 Glass with Grid',
              '/api/image/asset/117/18ade09f-db22-4e6c-b112-5a06639bb4c6',
            ],
            [
              'Cottage 2 Panel 1 Glass',
              '/api/image/asset/117/1e5e6397-b9df-4850-a671-7e62c33602ce',
            ],
            [
              'Cottage 2 Panel 2 Glass',
              '/api/image/asset/117/0d73743b-76d7-40a1-acd0-bc24a8ce8332',
            ],
            [
              'Cottage 3 Diamond Glass Left',
              '/api/image/asset/117/7d944005-c147-407a-8746-ba42a503ffee',
            ],
            [
              'Cottage 3 Diamond Glass Right',
              '/api/image/asset/117/bfdf1c95-725a-4900-a701-ad8be0cdb167',
            ],
            [
              'Cottage 3 Diamond Glass',
              '/api/image/asset/117/f80a667c-0af3-46be-820d-5f912ef972e2',
            ],
            [
              'Cottage 3 Rectangle Glass Left',
              '/api/image/asset/117/f3d3d767-a0ad-4186-a997-03803b37ca0e',
            ],
            [
              'Cottage 3 Rectangle Glass Right',
              '/api/image/asset/117/332975cb-7da1-48ee-8800-63320135ce2e',
            ],
            [
              'Cottage 3 Rectangle Glass',
              '/api/image/asset/117/c692bfbd-1420-4eb9-a319-013eb76d6658',
            ],
            [
              'Cottage 4 Rectangle Glass Left',
              '/api/image/asset/117/eb001fcb-665e-4a83-9876-05d1855611fd',
            ],
            [
              'Cottage 4 Rectangle Glass Right',
              '/api/image/asset/117/93320037-10fc-42a5-a4a8-a16896e5ca1d',
            ],
            [
              'Cottage 4 Rectangle Glass',
              '/api/image/asset/117/22cafc08-ae8b-425a-a169-acc29d1bd908',
            ],
            [
              'Cottage 4 Rectangle Glass',
              '/api/image/asset/117/c5a31dac-bdbe-48ac-8d5a-0d3099072b43',
            ],
            [
              'Cottage 5 Rectangle Glass Left',
              '/api/image/asset/117/695fdb28-56fc-44bc-a900-83266bc5a914',
            ],
            [
              'Cottage 5 Rectangle Glass Right',
              '/api/image/asset/117/d5e9b282-3908-4d85-9171-cb635755e796',
            ],
            ['Cottage Centre', '/api/image/asset/117/817d963c-0082-4923-a1e8-bcb1b5499219'],
            ['Cottage Circle Glass', '/api/image/asset/117/9122fa3a-8abc-455f-bb8f-ff662e8fff1d'],
            ['Cottage Diamond Glass', '/api/image/asset/117/623e072e-f716-4a93-8d46-75cec5d50a4c'],
            [
              'Cottage Flush Small Rectangle Glass',
              '/api/image/asset/117/79193af4-0f73-471d-a986-525ade75179d',
            ],
            ['Cottage Glass Right', '/api/image/asset/117/5d67e4f5-8841-46fc-a631-5305f5ba4f81'],
            ['Cottage Left', '/api/image/asset/117/562326aa-27af-47fa-a551-e6291dd28958'],
            [
              'Cottage Long Glass Centre',
              '/api/image/asset/117/61d2a415-04c0-49a1-b70b-d500e8fe560a',
            ],
            [
              'Cottage Long Glass Left',
              '/api/image/asset/117/296beac8-ad57-4092-ba01-11cc7ff7fb12',
            ],
            [
              'Cottage Long Glass Right',
              '/api/image/asset/117/fa33645c-ba18-41bc-98b8-928f21df9dd6',
            ],
            ['Cottage Long Glass Two', '/api/image/asset/117/a7d0e4a9-76ca-438e-b3f0-5bd6ce5bb2e3'],
            [
              'Cottage Mid 3 Square Glass',
              '/api/image/asset/117/35ab5464-ad35-48b5-a65a-41bb75cb9311',
            ],
            ['Cottage Solid', '/api/image/asset/117/a33cbfee-2bad-4ca5-94b2-c0a7f797ef38'],
            [
              'Cottage Twin Glass Left',
              '/api/image/asset/117/3c552f1c-8acc-4333-839c-530b30a85bfa',
            ],
            [
              'Cottage Twin Glass Right',
              '/api/image/asset/117/77fe419a-d9c3-4636-a7cd-db69b6195bb8',
            ],
            ['Cottage Twin Glass', '/api/image/asset/117/4e63d833-1e7c-4f92-a487-3816dfe1fc72'],
            ['Farmhouse Solid', '/api/image/asset/117/dc023b0e-fdb9-45c5-a55a-dd3de72836b7'],
          ],
          Contemporary: [
            ['Box Farmhouse Solid', '/api/image/asset/117/86891b0e-ab94-4133-8158-988e5b3d679a'],
            [
              'Cottage 3 Square Glass Left',
              '/api/image/asset/117/409f3e23-9fff-4c05-af77-b102e5af9974',
            ],
            [
              'Cottage 3 Square Glass Right',
              '/api/image/asset/117/3015368c-226e-42b7-8e2e-adc51c2dc0dc',
            ],
            ['Cottage 3 Square Glass', '/api/image/asset/117/421cb36b-fef0-427b-8533-a9fea4fa5455'],
            [
              'Cottage 4 Rectangle Glass',
              '/api/image/asset/117/76d477e5-8c98-417e-83f1-ee02000e39a0',
            ],
            [
              'Cottage 4 Square Glass Left',
              '/api/image/asset/117/d5de6024-d6dc-457d-a5e0-0bb73ca83bbe',
            ],
            [
              'Cottage 4 Square Glass Right',
              '/api/image/asset/117/e0a281e8-fd63-4c66-b6d1-6cf902932e12',
            ],
            ['Cottage 4 Square Glass', '/api/image/asset/117/ec3a16d7-38f6-4f56-879f-7e04ce800dd6'],
            [
              'Cottage 5 Square Glass Left',
              '/api/image/asset/117/5fabb598-e56c-4e40-aa31-147b03b2c2d6',
            ],
            [
              'Cottage 5 Square Glass Right',
              '/api/image/asset/117/74145e1f-f842-427a-b616-281b33042168',
            ],
            ['Flush 2 Glass', '/api/image/asset/117/7c86d1dd-ef57-4004-91f4-797fa7bb1808'],
            ['Flush 3 Diamond Glass', '/api/image/asset/117/b971d173-ad70-4abf-9fb8-4a0fe4bbdd22'],
            [
              'Flush 3 Rectangle Glass Left',
              '/api/image/asset/117/9b73d35b-7654-4689-ba48-2b74045c7faa',
            ],
            [
              'Flush 3 Rectangle Glass Right',
              '/api/image/asset/117/9509b24b-0f52-4711-a48d-f4bfbabc1ea9',
            ],
            [
              'Flush 3 Rectangle Glass',
              '/api/image/asset/117/bf669d55-f500-47ef-95d8-dfad122aaaa2',
            ],
            [
              'Flush 4 Rectangle Glass Left',
              '/api/image/asset/117/967ae700-6a01-49bc-b23e-8231b7b2e273',
            ],
            [
              'Flush 4 Rectangle Glass Right',
              '/api/image/asset/117/f841afa0-d2ad-4547-84c3-e21b10e71988',
            ],
            [
              'Flush 4 Rectangle Glass',
              '/api/image/asset/117/410754ab-cad3-40ee-9d43-d0af94d61a2c',
            ],
            [
              'Flush 4 Rectangle Glass',
              '/api/image/asset/117/fcaaaabc-e491-4f36-865b-84579ba0cc63',
            ],
            [
              'Flush 5 Rectangle Glass Left',
              '/api/image/asset/117/37b3402d-68da-41f6-ba32-0bc41e3b3e7b',
            ],
            [
              'Flush 5 Rectangle Glass Right',
              '/api/image/asset/117/58e493e5-0c9a-4d51-8e0e-bd70c7969da9',
            ],
            ['Flush Centre', '/api/image/asset/117/6f262f9f-99cd-47e9-ab9b-11803b0339b7'],
            ['Flush Circle Glass', '/api/image/asset/117/20fe0edd-fa03-4e02-8429-0a8a55897a0f'],
            ['Flush Diamond Glass', '/api/image/asset/117/e746047c-e292-4ec8-a5c4-20a48131fdf1'],
            [
              'Flush Forest Hills Long Left',
              '/api/image/asset/117/0463d439-41fc-4e19-a778-3312aadc38be',
            ],
            [
              'Flush Forest Hills Long Right',
              '/api/image/asset/117/77077a95-6716-458d-bc56-612a6c69f73f',
            ],
            ['Flush Glass Left', '/api/image/asset/117/0ec8a5f7-fc9d-4c2e-94f7-b8991231347f'],
            ['Flush Glass Right', '/api/image/asset/117/eea15e4e-03b5-4c79-94e3-d84430bd42dc'],
            [
              'Flush Half Glass with Grid',
              '/api/image/asset/117/0a456aee-90fc-44f0-a37b-067a7a96df50',
            ],
            ['Flush Half Glass', '/api/image/asset/117/a8e18295-9d4b-4432-8131-bc04de4e0a0a'],
            [
              'Flush Long Glass Centre',
              '/api/image/asset/117/37e3451f-e5a8-4be3-8238-2f4154e4674e',
            ],
            ['Flush Long Glass Left', '/api/image/asset/117/d4614d32-06d7-45fa-926a-8029e05910ee'],
            ['Flush Long Glass Right', '/api/image/asset/117/a722f511-84ec-4b24-b79e-eee95c08fe85'],
            ['Flush Long Glass Two', '/api/image/asset/117/401d70f7-8bc8-41e3-8d7f-e6cfbe406fef'],
            ['Flush Mid 1 Glass Left', '/api/image/asset/117/4755cb58-bbb8-44d8-b652-5746e43e99f6'],
            [
              'Flush Mid 1 Glass Right',
              '/api/image/asset/117/7cc4b34c-574c-4bf8-b3d9-4370d4497147',
            ],
            ['Flush Mid 1 Glass', '/api/image/asset/117/2f42b334-d233-44b4-b116-69995459272e'],
            [
              'Flush Mid 3 Square Glass',
              '/api/image/asset/117/f23d5118-3d2a-436d-b473-42873795e2a5',
            ],
            ['Flush Mid Glass 1', '/api/image/asset/117/7cbe78eb-4579-45fa-8853-8fac2ba7fce3'],
            [
              'Flush Small Rectangle Glass',
              '/api/image/asset/117/15a668d2-aa87-464f-9fe2-1f14f796ad6d',
            ],
            ['Flush Solid', '/api/image/asset/117/c999b97a-047f-4efb-aaae-757b68bfd747'],
            ['Flush Square 1 Glass', '/api/image/asset/117/1c8f85f7-02e0-4069-9f0c-eb6b630daa3c'],
            ['Flush Twin Glass Left', '/api/image/asset/117/af0cb654-41aa-4b6c-87d4-72a7212a01a3'],
            ['Flush Twin Glass Right', '/api/image/asset/117/b592a860-4784-466c-ac74-e7d6d304ea3b'],
            ['Flush Twin Glass', '/api/image/asset/117/5b99a2a5-48e7-48d4-81dd-d568778584ed'],
            [
              'Monza Long Glass Centre',
              '/api/image/asset/117/83028d8c-c6b0-4f2a-a795-a898e57d6515',
            ],
            ['Monza Long Glass Left', '/api/image/asset/117/e49774bb-0678-43d2-8277-cdf2f16feb6a'],
            ['Monza Long Glass Right', '/api/image/asset/117/bb8941a5-14a3-4cb4-b4e2-a023bf2861f1'],
            ['Monza Mid Glass Left', '/api/image/asset/117/64e8b6fe-5d89-43ed-bd99-1e7f8fd3c64d'],
            ['Monza Mid Glass Right', '/api/image/asset/117/a7424062-1a50-4b93-b740-eff9db21a267'],
            ['Monza Mid Glass', '/api/image/asset/117/88875b78-de4f-404b-bbbd-35fdf04f8bf3'],
          ],
        },
        'Composite Door Colours': {
          Standard: [
            ['Anthracite Grey', '/api/image/asset/117/74ad794e-a88c-4ead-8205-21ec0a94c1c3'],
            ['Black', '/api/image/asset/117/6b5d226e-dea1-4fe2-879a-b6f21b9bcff9'],
            ['Blue', '/api/image/asset/117/d067f530-e289-4a6d-9249-f253cdd25b11'],
            ['Chartwell Green', '/api/image/asset/117/524d439c-076d-47aa-901e-2611c209355b'],
            ['Cream', '/api/image/asset/117/763c314a-08d1-4ff4-8942-7efe47974a64'],
            ['Golden Oak', '/api/image/asset/117/4e6fa974-f7ce-4f83-926f-69840a81c737'],
            ['Green', '/api/image/asset/117/ad01ba87-dd54-4809-88a0-65d59fce9d7a'],
            ['Oak', '/api/image/asset/117/a5118a46-c224-4c0f-9cc3-aebc602d902e'],
            ['Red', '/api/image/asset/117/5e03f625-5bd8-457d-804e-3a5218a9c410'],
            ['Rosewood', '/api/image/asset/117/16a4f5c5-dd2e-465f-908f-a1748347f2af'],
            ['White', '/api/image/asset/117/551a8e37-bf4e-4826-b71e-62dba271f9e0'],
          ],
          Premium: [
            ['Agate Grey', '/api/image/asset/117/aa757830-7c0a-4eba-806f-b3344d631e6c'],
            ['Aubergine *', '/api/image/asset/117/36519217-36d5-44d6-a9cb-0da119dcd6b7'],
            ['Bespoke Colour', '/api/image/asset/117/43efe5c9-d6ba-48bf-9b9f-acf80560d5da'],
            ['Cool Blue *', '/api/image/asset/117/abd53348-eb74-40c1-bdcf-2dfbae51d428'],
            ['Duck Egg Blue *', '/api/image/asset/117/0717caad-7358-4e14-9370-c527e700c2e6'],
            ['Olive *', '/api/image/asset/117/268be2b0-88c1-4103-ae8e-9308ce5c1a42'],
            ['Orange *', '/api/image/asset/117/cfca21c1-0026-4476-889c-8da15bf97187'],
            ['Yellow *', '/api/image/asset/117/e62990c2-57a7-4d14-9a45-7043ff7f5d69'],
          ],
        },
        'Composite Door Glass': {
          Standard: [
            ['Clear Tough Low E', '/api/image/asset/117/df7fe591-9d70-492e-ba3b-f63be566db8e'],
            ['Obs Tough Low E', '/api/image/asset/117/7e458328-412c-4169-9af5-14b9659f9857'],
          ],
          Bevel: [
            ['Aurora', '/api/image/asset/117/67b0c9f4-2043-420e-8339-6021e10e0bab'],
            ['Beaumont', '/api/image/asset/117/40c24674-f4e0-4afc-9810-e33645fec248'],
            ['Bullseye', '/api/image/asset/117/8ca065f5-a83e-4976-8a22-05a62852fb36'],
            ['Cascata', '/api/image/asset/117/2d071b53-cb0f-4727-9c27-ca8254c9c8e8'],
            ['Chelsea', '/api/image/asset/117/a1cd8140-d616-4235-9db9-ef3b2353da58'],
            ['Classic', '/api/image/asset/117/c0ede2de-790d-4170-a756-f80fb2e08f37'],
            ['Crystal Flowers', '/api/image/asset/117/cdf8662c-5f36-4272-8693-0b7842d18b37'],
            ['Deco', '/api/image/asset/117/23cacc03-8f50-4865-a9a3-7f02a171ef8b'],
            [
              'Diamonds (Stippolyte Backing)',
              '/api/image/asset/117/0a8c428c-9625-48a6-af08-dc8dc1f0b15f',
            ],
            ['Dorchester', '/api/image/asset/117/43ff8c60-5915-470e-825d-e1e657e77e45'],
            ['Dorchester', '/api/image/asset/117/6b3f97c4-e6f8-4914-8df6-cab1c5fd28db'],
            ['Eiger', '/api/image/asset/117/1df9e86e-20f1-4ba5-b0ef-68030612eb98'],
            ['Elegance', '/api/image/asset/117/741f90a1-490d-45d0-8358-dc6c9b1fc2eb'],
            ['Element', '/api/image/asset/117/ef21959b-02ba-440b-8263-b0dfaea44bb8'],
            ['Etch Squares', '/api/image/asset/117/45de0dbf-6938-4253-8dd7-4c4894b40ffd'],
            ['Finesse', '/api/image/asset/117/05f28efd-6ef4-41f2-94ec-c9754d8b5dfb'],
            ['Flair', '/api/image/asset/117/fa1dfea4-f7be-4662-9baf-9c9ec53026b2'],
            ['French', '/api/image/asset/117/b8c0a3a0-db6a-4d9b-8408-c73f3a69aaf7'],
            ['Georgian Grid', '/api/image/asset/117/2aa1b605-9394-4651-abc4-6c5703e555bc'],
            ['Greenwich', '/api/image/asset/117/96e436e9-ae61-478d-8e3f-b72f15da039d'],
            ['Havana', '/api/image/asset/117/d0d0e794-e880-4b16-b940-bad62263537a'],
            ['Horizon', '/api/image/asset/117/97f8020b-0e2b-4a36-ad71-5fa2ce481ad3'],
            ['Linear', '/api/image/asset/117/c25d50f8-0912-474f-8676-4fdc5824e24c'],
            ['Masterline', '/api/image/asset/117/2222bcad-88ec-47b5-a7b3-ab0ebc81bd39'],
            ['Matrix', '/api/image/asset/117/64968c59-3972-4bf8-93dc-b10529337d33'],
            ['Milan', '/api/image/asset/117/79e264ff-d18f-454d-b118-396536f4077c'],
            ['Millennium', '/api/image/asset/117/be083db3-61c9-47c0-8b6d-c36f4972f4e9'],
            ['Modena', '/api/image/asset/117/57a76896-212b-476a-ad0f-13df5df6a5e8'],
            ['Murano', '/api/image/asset/117/257c64c2-4e63-4a72-8acc-97228ea46c6b'],
            ['Murano', '/api/image/asset/117/90eadd99-e76d-4df7-8cf4-86aa3fc7cccb'],
            ['New Orleans', '/api/image/asset/117/ba2143cb-723a-49ea-825a-226bee595533'],
            ['Prairie', '/api/image/asset/117/1d163695-9bfb-4cfb-9e69-8fba0f26f413'],
            ['Quartz', '/api/image/asset/117/cc301f13-2809-4146-8b29-37b0a0ec2f2a'],
            ['Reflections', '/api/image/asset/117/fe7284d7-9af6-4b74-bb78-5821eeaf8d65'],
            ['Richmond', '/api/image/asset/117/0de05c95-58c7-45bc-9799-254a4ab4a317'],
            ['Roma', '/api/image/asset/117/32139ba7-7136-467f-84e3-d28c70d70d42'],
            ['Simplicity', '/api/image/asset/117/86092712-0e61-43b1-9bce-ccaa0395ad5e'],
            ['Simplicity', '/api/image/asset/117/ef93dd1d-4d93-4aec-957b-3fe123cc673a'],
            ['Standard Glass', '/api/image/asset/117/7abfc04a-208a-44ff-a638-b81673714a77'],
            ['Tacoma', '/api/image/asset/117/0210d97d-9ba3-4d11-afb7-e268cd640c35'],
            ['Tahoe', '/api/image/asset/117/f48a6a60-c9a0-485b-98d6-a01fe459309e'],
            ['Trio Diamond', '/api/image/asset/117/7c5a2f44-6556-4c08-bf67-624218f7aaf9'],
            ['Yukon', '/api/image/asset/117/d932ff27-7199-4258-9f95-d3ed2265a4a3'],
            ['Zinc Art Star', '/api/image/asset/117/062cbc6c-2cdd-475c-8dbe-af73b5d10937'],
          ],
          Premium: [
            [
              'Georgian Bar (Anth Grey)',
              '/api/image/asset/117/c401a584-f1dd-475b-a108-3702e96364e0',
            ],
            ['Georgian Bar (Black)', '/api/image/asset/117/94f22854-3091-465a-ba6d-c8b2d97fea91'],
            ['Georgian Bar (White)', '/api/image/asset/117/9248e4e2-9901-46a5-befe-4c3090297311'],
            ['Gridlite Etch', '/api/image/asset/117/ee25c063-0d8f-48a4-86fe-b5fc1cce4140'],
            ['Kensington', '/api/image/asset/117/761cd21f-3e3c-420d-917c-97a1722fe964'],
            ['Kensington', '/api/image/asset/117/86e17e56-23dc-4995-9404-f0f43fd6fd45'],
            ['Satin Tough Low E', '/api/image/asset/117/fb52691c-af3f-4e0b-ba41-a10a6c0f3b2c'],
            ['Venetian Blind (LH)', '/api/image/asset/117/33440b52-a587-45ad-b752-ab58ff219002'],
            ['Venetian Blind (RH)', '/api/image/asset/117/f2e57d08-ad44-4a64-ae49-e4d9ef945705'],
          ],
        },
      },
    };
  },
  methods: {
    toggleOpenGroup(group) {
      if (this.open_group === group) {
        this.open_group = null;
      } else {
        this.open_group = group;
      }
    },
    toggleOpenParentGroup(group) {
      if (this.open_parent_group === group) {
        this.open_parent_group = null;
      } else {
        this.open_parent_group = group;
      }
    },
    // sortedData(data) {
    //   return data.sort((a, b) => a[1].localeCompare(b[1], undefined, { numeric: true, sensitivity: 'base' }));
    // },
  },
};
</script>

<style scoped></style>
