<template>
  <div class="mb-3">
    <AddressForm
      :is-save-button="true"
      @address-updated="addressUpdated"
      @saveAddress="saveNewAddress"
    />
  </div>
</template>

<script>
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  data() {
    return {
      address1: '',
      address2: '',
      address3: '',
      town: '',
      county: '',
      postcode: '',
      latitude: '',
      longitude: '',
      iso: 'GB',
    };
  },
  computed: {
    formatted_address() {
      return [this.address1, this.address2, this.town, this.county, this.postcode, this.iso]
        .filter((val) => val)
        .join(', ');
    },
  },
  methods: {
    addressUpdated(newAddress) {
      this.address1 = newAddress.addressLine1;
      this.address2 = newAddress.addressLine2;
      this.address3 = newAddress.addressLine3;
      this.town = newAddress.addressTown;
      this.county = newAddress.addressCounty;
      this.postcode = newAddress.addressCode;
      this.latitude = newAddress.latitude;
      this.longitude = newAddress.longitude;
      this.iso = newAddress.addressIso;
    },
    saveNewAddress() {
      this.$store.dispatch('basket/addUserEnteredAddress', {
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        town: this.town,
        postcode: this.postcode,
        iso: this.iso,
        latitude: this.latitude,
        longitude: this.longitude,
      });

      this.$emit('dismiss');
    },
  },
};
</script>
