<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-col md:flex-row">
        <div
          class="border border-gray-300 rounded-lg mb-6 md:mr-6 flex-1"
          :class="{ 'border-b-0': !base_price_box_open }"
        >
          <div
            class="border-b border-gray-300 p-3 flex justify-between md:pointer-events-none"
            @click="base_price_box_open = !base_price_box_open"
          >
            <div>
              <strong>Set Base Prices</strong>
            </div>
            <div class="md:hidden">
              <i class="fal fa-caret-down"></i>
            </div>
          </div>
          <transition name="slide-in">
            <div v-show="base_price_box_open" class="xs:flex justify-center xs:p-3">
              <div>
                <table class="table-striped w-full xs:w-auto">
                  <tr>
                    <td class="p-1 xs:p-2 xs:whitespace-no-wrap text-xs xs:text-sm">
                      Base Price: {{ minWidth }} x {{ minHeight }}
                    </td>
                    <td class="p-1 xs:p-2 text-right">
                      <input
                        v-model="baseGrid"
                        type="text"
                        class="p-2 w-20 rounded border border-gray-300"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <table class="w-full xs:w-auto">
                  <tr>
                    <td class="p-1 xs:p-2 text-xs xs:text-sm">Price Increment</td>
                    <td class="p-1 xs:p-2 text-xs xs:text-sm text-right">Width</td>
                    <td class="p-1 xs:p-2 text-right">
                      <input
                        v-model="widthIncrement"
                        type="text"
                        class="p-2 w-20 rounded border border-gray-300"
                      />
                    </td>
                    <td class="p-1 xs:p-2 text-xs xs:text-sm text-right">Height</td>
                    <td class="p-1 xs:p-2 text-right">
                      <input
                        v-model="heightIncrement"
                        type="text"
                        class="p-2 w-20 rounded border border-gray-300"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div class="flex flex-col justify-around p-1 xs:p-0">
                <div class="text-right">
                  <button class="btn-action w-full xs:w-auto" @click="saveCorners">Save</button>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="flex flex-col justify-end mb-6 flex-1">
          <div
            class="border border-gray-300 rounded-lg mt-auto"
            :class="{ 'border-b-0': !modify_whole_grid_box_open }"
          >
            <div
              class="border-b border-gray-300 p-3 flex justify-between md:pointer-events-none"
              @click="modify_whole_grid_box_open = !modify_whole_grid_box_open"
            >
              <div>
                <strong>Modify Whole Grid</strong>
              </div>
              <div class="md:hidden">
                <i class="fal fa-caret-down"></i>
              </div>
            </div>
            <transition name="slide-in">
              <div v-show="modify_whole_grid_box_open" class="xs:p-3">
                <table class="table-striped m-auto">
                  <tbody>
                    <tr>
                      <td class="p-1 xs:p-2 text-xs xs:text-sm">Value:</td>
                      <td class="p-1 xs:p-2">
                        <input
                          v-model="modifyValue"
                          type="number"
                          class="p-2 w-20 rounded border border-gray-300"
                        />
                      </td>
                      <td class="p-1 xs:p-2">
                        <button class="btn" @click="addPercent(modifyValue)">
                          {{ modifyValue }}%
                        </button>
                      </td>
                      <td class="p-1 xs:p-2">
                        <button class="btn" @click="addValue(modifyValue)">
                          {{ formatCurrency($store?.state?.style?.customer?.currency, modifyValue) }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="p-4 bg-green-200 rounded-lg mb-2">
        All sizes entered in grid apply to all styles; even where limitations are exceeded
      </div>

      <div
        class="p-6 border border-gray-300 rounded-lg flex flex-col relative"
        style="max-height: 50vh"
      >
        <table class="scrollable-table">
          <tbody class="scrolling-touch">
            <tr>
              <th class="sticky top-0 left-0 z-50">&nbsp;</th>
              <th
                v-for="width in widthSteps"
                :key="width"
                class="sticky top-0 z-10"
                v-text="width"
              ></th>
            </tr>
            <tr v-for="height in heightSteps" :key="height">
              <th class="sticky left-0" v-text="height"></th>
              <td v-for="width in widthSteps" :key="width" class="border border-gray-300">
                <input
                  :ref="width + 'x' + height"
                  type="text"
                  class="p-0 w-12 focus:text-black focus:bg-blue-100 text-right"
                  @keyup="valueChanged(width, height, $event.target.value)"
                  @keyup.down="moveDownFrom(width, height)"
                  @keyup.up="moveUpFrom(width, height)"
                  @keyup.left="moveLeftFrom(width, height)"
                  @keyup.right="moveRightFrom(width, height)"
                  @focus="$event.target.select()"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import gridHelper from '@/mixins/gridHelper';
import currencyHelper from '@/mixins/currencyHelper';

export default {
  mixins: [gridHelper, currencyHelper],
  props: ['grid', 'limits', 'customerId'],
  data() {
    return {
      // Values for setting grid
      baseGrid: 0,
      widthIncrement: 0,
      heightIncrement: 0,

      // Value used for uplifting grid
      modifyValue: 0,

      // An array of the new prices; with a lookup index
      newPrices: [],
      priceIndex: {},
      base_price_box_open: true,
      modify_whole_grid_box_open: true,
    };
  },
  computed: {
    originalPrices() {
      return this.getMergedCellsFromGrid(this.grid);
    },
    minWidth() {
      return this.widthSteps[0];
    },
    minHeight() {
      return this.heightSteps[0];
    },
    maxWidth() {
      return this.widthSteps[this.widthSteps.length - 1];
    },
    maxHeight() {
      return this.heightSteps[this.heightSteps.length - 1];
    },
    tl() {
      return this.valueFor(this.minWidth, this.minHeight);
    },
    widthSteps() {
      let lastWidth = this.limits.width.min;

      const widths = [this.limits.width.min];

      while (lastWidth < this.limits.width.max) {
        lastWidth += this.limits.width.steps;
        widths.push(lastWidth);
      }

      return widths;
    },
    heightSteps() {
      let lastHeight = this.limits.height.min;

      const heights = [this.limits.height.min];

      while (lastHeight < this.limits.height.max) {
        lastHeight += this.limits.height.steps;
        heights.push(lastHeight);
      }

      return heights;
    },
  },
  mounted() {
    this.newPrices = JSON.parse(JSON.stringify(this.originalPrices));
    this.newPrices.forEach((price, index) => {
      this.priceIndex[`${price.width}x${price.height}`] = index;
      if (
        price.width < this.limits.width.min ||
        price.width > this.limits.width.max ||
        price.height < this.limits.height.min ||
        price.height > this.limits.height.max
      ) {
        return;
      }
      this.updateValueFor(price.width, price.height, price.value, false);
    });
  },
  methods: {
    moveDownFrom(width, height) {
      if (height === this.maxHeight) {
        return;
      }

      this.focusOn(width, height + this.limits.height.steps);
    },
    moveUpFrom(width, height) {
      if (height === this.minHeight) {
        return;
      }

      this.focusOn(width, height - this.limits.height.steps);
    },
    moveLeftFrom(width, height) {
      if (width === this.minWidth) {
        return;
      }

      this.focusOn(width - this.limits.width.steps, height);
    },
    moveRightFrom(width, height) {
      if (width === this.maxWidth) {
        return;
      }

      this.focusOn(width + this.limits.width.steps, height);
    },
    focusOn(width, height) {
      this.$refs[`${width}x${height}`][0].focus();
    },
    saveCorners() {
      this.widthSteps.forEach((width) => {
        this.heightSteps.forEach((height) => {
          const widthIncrementCount = (width - this.minWidth) / this.limits.width.steps;
          const heightIncrementCount = (height - this.minHeight) / this.limits.height.steps;
          const value =
            Number(this.baseGrid) +
            widthIncrementCount * this.widthIncrement +
            heightIncrementCount * this.heightIncrement;
          this.updateValueFor(width, height, value, false);
        });
      });

      this.$emit('new-prices', this.newPrices);
    },
    addValue(value) {
      this.widthSteps.forEach((width) => {
        this.heightSteps.forEach((height) => {
          this.updateValueFor(width, height, Number(value) + this.valueFor(width, height), false);
        });
      });

      this.$emit('new-prices', this.newPrices);
    },
    addPercent(value) {
      this.widthSteps.forEach((width) => {
        this.heightSteps.forEach((height) => {
          const currentValue = this.valueFor(width, height);
          this.updateValueFor(width, height, currentValue * (1 + Number(value) / 100), false);
        });
      });

      this.$emit('new-prices', this.newPrices);
    },
    valueChanged(width, height, value) {
      const newValue = Number(value);
      const oldValue = this.newPrices[this.priceIndex[`${width}x${height}`]].value;

      this.newPrices[this.priceIndex[`${width}x${height}`]].value = newValue;

      if (oldValue !== newValue) {
        this.$emit('new-prices', this.newPrices);
      }

      if (!newValue) {
        this.$refs[`${width}x${height}`][0].classList.add('text-gray-400');
      } else {
        this.$refs[`${width}x${height}`][0].classList.remove('text-gray-400');
      }
    },
    updateValueFor(width, height, value) {
      const newValue = Number(value);
      try {
        this.newPrices[this.priceIndex[`${width}x${height}`]].value = newValue;
        this.$refs[`${width}x${height}`][0].value = parseFloat(newValue).toFixed(2);
        if (!newValue) {
          this.$refs[`${width}x${height}`][0].classList.add('text-gray-400');
        } else {
          this.$refs[`${width}x${height}`][0].classList.remove('text-gray-400');
        }
      } catch (error) {
        this.alertBox().fire(`Failed trying to set ${width}x${height} box`);
        throw error;
      }
    },
    valueFor(width, height) {
      if (this.newPrices.length === 0) {
        return 0;
      }

      return Number(this.newPrices[this.priceIndex[`${width}x${height}`]].value);
    },
  },
};
</script>
