<template>
  <div class="bg-white">
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
      <router-link
        :to="{ query: { active_section: 'company-details' } }"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': $route.query.active_section === 'company-details',
          'border-white': $route.query.active_section !== 'company-details',
        }"
      >
        Company Details
      </router-link>
      <router-link
        :to="{ query: { active_section: 'settings' } }"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': $route.query.active_section === 'settings',
          'border-white': $route.query.active_section !== 'settings',
        }"
      >
        Settings
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
