<template>
  <div class="w-full h-full overflow-y-scroll">
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search marketing materials..." />
    </portal>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-5 md:p-10"
    >
      <div class="flex flex-col-reverse flex-col 2xs:flex-row items-center gap-5 mb-5">
        <div class="w-full 2xs:w-auto relative" tabindex="3" @focusout="category_dropdown_open = false">
          <div
            class="border flex justify-between cursor-pointer bg-white"
            @click="category_dropdown_open = !category_dropdown_open"
          >
            <div class="p-3 whitespace-no-wrap text-gray-600">
              <template v-if="selectedCategory">
                Category: <strong>{{ selectedCategory.name }}</strong>
              </template>
              <template v-else> Choose Category </template>
            </div>
            <div class="p-3">
              <i v-if="category_dropdown_open" class="fa fa-caret-up"></i>
              <i v-else class="fa fa-caret-down"></i>
            </div>
          </div>
          <div
            v-if="category_dropdown_open"
            class="absolute bg-gray-200 w-full md:w-64 border z-10 text-xs sm:text-sm left-0"
          >
            <div
              v-for="category in categories.filter((x) => !x.isEmpty)"
              :key="category.id"
              class="flex cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
              :class="{ 'bg-brand-primary text-white': selected_category_id === category.id }"
              @click="(selected_category_id = category.id), (category_dropdown_open = false)"
            >
              <div v-if="selected_category_id === category.id" class="p-3">
                <i class="fal fa-check"></i>
              </div>
              <div class="p-3">
                <label :title="category.name"> {{ category.name }} </label>
              </div>
            </div>
          </div>
        </div>
        <slot name="manage_marketing"></slot>
      </div>
      <div v-if="filteredMarketingItems.length > 0">
        <animation-staggered-fade appear class="grid 2xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <div
            v-for="(marketing_item, index) in filteredMarketingItems"
            :key="marketing_item.id"
            :data-index="index"
            class="w-full flex align-stretch"
          >
            <marketing-item
              v-if="marketing_item.type === enums.documentType.RESOURCE_FILE"
              :categories="categories"
              :marketing-item="marketing_item"
              @deleted="loadFeed"
              @updated="loadFeed"
              @download="downloadDocument(marketing_item.id)"
            ></marketing-item>
            <video-item
              v-if="marketing_item.type === enums.documentType.RESOURCE_VIDEO"
              :categories="categories"
              :video="marketing_item"
              @deleted="loadFeed"
              @updated="loadFeed"
            ></video-item>
          </div>
        </animation-staggered-fade>
        <transition appear name="fade-in">
          <div v-if="show_load_more" class="text-center">
            <a class="btn bg-white" @click="current_page += 1"> Load More </a>
          </div>
        </transition>
      </div>
      <div v-else key="none-found" class="bg-white p-10 border">
        <div v-if="$route.query.search" class="flex justify-between items-center">
          <div>No Documents match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click.stop="$router.push({ query: undefined })">
              Clear Search
            </a>
          </div>
        </div>
        <div v-else>This category is empty</div>
      </div>
    </loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MarketingItem from '@/components/shared/marketing/MarketingItem.vue';
import VideoItem from '@/components/shared/marketing/VideoItem.vue';
import videoHelper from '@/mixins/videoHelper';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    VideoItem,
    'marketing-item': MarketingItem,
    SearchBox,
  },
  mixins: [videoHelper],
  data() {
    return {
      loading: true,
      categories: [],
      selected_category_id: 0,
      allItems: [],
      limit: 24,
      current_page: 1,
      show_load_more: true,
      image_error: false,
      category_dropdown_open: false,
    };
  },
  computed: {
    filteredMarketingItems() {
      return this.allItems
        .filter((marketingItem) =>
          `${marketingItem.description} ${marketingItem.name}`.toLowerCase().includes(this.search),
        )
        .slice(0, this.limit * this.current_page);
    },
    selectedCategory() {
      return this.categories.filter((category) => category.id === this.selected_category_id)[0];
    },
    search() {
      return this.$route.query.search || '';
    },
  },
  watch: {
    selected_category_id() {
      this.current_page = 1;
      this.loadFeed();
    },
    current_page() {
      this.show_load_more = this.showLoadMore();
    },
    '$route.query': {
      handler() {
        this.show_load_more = this.showLoadMore;
      },
    },
  },
  async mounted() {
    await this.loadMarketingItemsByCategory()
  },
  methods: {
    ...mapActions({
      getMarketingItems: 'marketing/marketingItems',
      marketingCategories: 'marketing/marketingCategories',
      getDocumentThumbnail: 'marketing/getDocumentThumbnail',
      downloadDocument: 'marketing/downloadDocument',
      deleteDocument: 'marketing/deleteMarketingDocuments',
    }),
    async getThumbnailForItem(item) {
      if (item.type === this.enums.documentType.RESOURCE_FILE) {
        return this.getDocumentThumbnail(item.id);
      }

      if (item.type !== this.enums.documentType.RESOURCE_VIDEO) {
        throw new Error('Unknown Document Type');
      }

      switch (this.getVideoType(item.url)) {
        case this.enums.documentVideoType.YOUTUBE:
          return this.getYoutubeThumbnail(item.url);
        case this.enums.documentVideoType.VIMEO:
          return this.getVimeoThumbnail(item.url);
        default:
          throw new Error('Unknown Video Type');
      }
    },
    async loadFeed() {
      const allItems = await this.getMarketingItems({ categoryId: this.selected_category_id });
      const promiseItems = allItems.map(async (marketingItem) => ({
        ...marketingItem,
        thumbnail: await this.getThumbnailForItem(marketingItem),
      }));
      await Promise.all(promiseItems).then((marketingItem) => {
        this.allItems = marketingItem;
        this.allItems.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )
        this.show_load_more = this.allItems.length > this.allItems.slice(0, this.limit).length;
      });
    },
    async deleteMarketingDocument() {
      await this.deleteDocument([this.marketing_document_id_to_delete]).then(() => {
        this.delete_document_modal_open = false;
        this.marketing_document_id_to_delete = null;
        this.loadFeed();
      });
    },
    showLoadMore() {
      const filteredItems = this.allItems.filter((marketingItem) =>
        `${marketingItem.description} ${marketingItem.name}`.toLowerCase().includes(this.search),
      );
      return filteredItems.length !== this.filteredMarketingItems.length;
    },
    async loadMarketingItemsByCategory() {
      this.loading = true;
      this.categories = await this.marketingCategories();
      if (this.categories.length === 0) {
        this.$emit('redirectToURL');
      } else {
        this.selected_category_id = this.categories[0].id;
      }
      await this.loadFeed()
      this.loading = false;
    }
  },
};
</script>
