<template>
  <div id="app" class="text-sm">
    <debug-banner v-if="showDebugBanner" :vue-env="vueEnv" />
    <router-view />
    <FreshdeskWidget />
    <TalkativeWidget v-if="hasTalkative" />
  </div>
</template>

<script>
import DebugBanner from '@/components/shared/DebugBanner.vue';
import FreshdeskWidget from '@/components/shared/FreshdeskWidget.vue';
import TalkativeWidget from '@/components/shared/TalkativeWidget.vue';

export default {
  components: {
    DebugBanner,
    FreshdeskWidget,
    TalkativeWidget,
  },
  data() {
    return {
      vueEnv: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    hasTalkative() {
      return this.touch_portal_user && window.touch.urlIs('endurance') && this.$route.meta.portalRoute;
    },
    showDebugBanner() {
      return (this.vueEnv?.toLowerCase() !== 'prod') & (this.vueEnv?.toLowerCase() !== 'fitshow');
    },
  },
  watch: {
    '$store.state.auth.loggedIn': {
      async handler(to) {
        if (to) {
          try {
            const response = await window.touch.authGetCompanyWebSettings()
            if (response.data.companyWebSettings.some(x => x.key === 'tagId')) {
              const index = response.data.companyWebSettings.findIndex(x => x.key === 'tagId')
              window.gtag('config', 'G-' + response.data.companyWebSettings[index].value);
            }
          } catch (error) {
            this.$store.state.auth.loggedIn = false
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initialiseStoreData();
    this.$store.state.auth.login_url = ['staging', 'prod'].includes(this.vueEnv?.toLowerCase()) ? '/login' : '/login-admin'

    if (this.inIframe()) {
      const sendHeight = () => {
        window.parent.postMessage({ type: 'resize', height: document.documentElement.scrollHeight }, '*')
      }
      window.addEventListener('resize', sendHeight)
    }
  },

  methods: {
    initialiseStoreData() {
      this.$store.dispatch('style/refresh');
      this.$store.dispatch('feature/refreshPermissions');
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
};
</script>

<style src="./assets/css/styles.css"></style>
