<template>
  <div class="flex sm:block items-center">
    <span v-if="enums.DeliveryType.ViaBranch === deliveryType" class="block w-40 font-medium sm:font-normal flex-1 sm:flex-auto"> {{ label }} </span>
    <strong v-else class="flex-shrink-0 block sm:hidden w-40 flex-1"> Delivery Days: </strong>
    <div v-if="deliveryDays === 0" class="opacity-50 flex-1">
      <span>No Delivery Days Selected</span>
    </div>
    <div v-else class="flex gap-2 text-center flex-wrap flex-1">
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Monday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Monday,
          ),
        }"
      >
        Mo
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Tuesday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Tuesday,
          ),
        }"
      >
        Tu
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Wednesday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Wednesday,
          ),
        }"
      >
        We
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Thursday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Thursday,
          ),
        }"
      >
        Th
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Friday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Friday,
          ),
        }"
      >
        Fr
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 block': bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Saturday,
          ),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Saturday,
          ),
        }"
      >
        Sa
      </span>
      <span
        class="bg-gray-200 rounded-lg w-8 h-8"
        :style="{ 'line-height': '2rem' }"
        :class="{
          'bg-green-400 inline-block': bitsInRoles(
            deliveryDays,
          ).includes(enums.DeliveryDays.Sunday),
          hidden: !bitsInRoles(deliveryDays).includes(
            enums.DeliveryDays.Sunday,
          ),
        }"
      >
        Su
      </span>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label: String,
    deliveryDays: Number,
    deliveryType: Number,
    isOnwardDays: Boolean
  },
  methods: {
    bitsInRoles(deliveryDays) {
      let b = 1;
      const res = [];
      while (b <= deliveryDays) {
        if (b & deliveryDays) res.push(b);
        b <<= 1;
      }
      return res;
    },
  },
};
</script>
