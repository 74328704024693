<template>
  <div v-if="downloadHistory.length > 0" class="pb-5">
    <hr key="divider" class="-mx-3 md:-mx-10" />
    <div class="flex justify-between items-center my-6">
      <div class="text-2xl font-medium">Download History</div>
    </div>
    <div class="flex flex-col gap-2 bg-white border p-3">
      <div class="grid grid-cols-3 gap-5 mb-2">
        <strong>Contact Email</strong>
        <strong>Report Type</strong>
        <strong>Downloaded</strong>
      </div>
      <div v-for="item in downloadHistory" :key="item.id" class="grid grid-cols-3 gap-5">
        <span v-if="users.some(x => x => x.id === contractId)">
          {{ users.find(x => x => x.id === contractId).email }}
        </span>
        <span v-else>Email not found</span>
        <span>{{ reportTypeEnums[item.reportType][0] }}</span>
        <span>{{ formatHumanDate(new Date(item.timeDownloaded)) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';

export default {
  mixins: [dateHelper],
  props: {
    customer: Object
  },
  data() {
    return {
      downloadHistory: [],
      users: []
    };
  },
  computed: {
    reportTypeEnums() {
      return Object.keys(this.enums.ReportType).map((key) => [key, this.enums.ReportType[key]]);
    },
  },
  async mounted () {
    this.users = this.customer.users.concat(this.$store.state.user.customer.users)
    this.downloadHistory = await window.touch.GetDownloadHistory(this.$route.params.id)
    this.downloadHistory.sort((a,b) => new Date(b.timeDownloaded).getTime() - new Date(a.timeDownloaded).getTime());
  },
};
</script>
