<template>
  <div>
    <input
      v-model="search"
      class="p-2 h-10 w-full border w-full focus:outline-none border-0"
      type="text"
      :placeholder="placeholder"
      @input="setSearch($event.target.value)"
    />
    <div class="w-10 h-10 border border-l-0 text-center flex flex-col justify-around border-0">
      <i v-if="search" class="fal fa-times cursor-pointer" @click="setSearch('', 0)"></i>
      <i v-else class="fal fa-search"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
  },
  data() {
    return {
      timeout: undefined,
      search: '',
    };
  },
  watch: {
    '$route.query.search': function ($search) {
      // eslint-disable-line
      this.search = $search || '';
    },
  },
  created() {
    this.search = this.$route.query?.search;
  },
  methods: {
    setSearch(search = this.search, timeout = 300) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$router
          .push({ query: { ...this.$route.query, search: search || undefined } })
          .catch(() => {});
      }, timeout);
    },
  },
};
</script>
