<template>
  <div>
    <div class="bg-gray-200 p-3 mb-1" @click="$emit('place')">
      <show-svg
        v-if="image"
        :branded-mode="true"
        :show-issues="false"
        :thumbnail-mode="true"
        :svg="image"
        class="mx-auto visualiser-place-product-tile-img"
      />
    </div>
    <div class="mb-1" v-text="product.retailDescription" />
    <div v-if="product.location !== 'None'" v-text="product.location" />
  </div>
</template>

<script>
export default {
  props: ['product', 'view'],
  data() {
    return {
      image: false,
    };
  },
  watch: {
    async view() {
      this.image = await this.loadImage();
    },
  },
  async mounted() {
    this.image = await this.loadImage();
  },
  methods: {
    async loadImage() {
      return this.$store.dispatch('basket/getProcessingImage', {
        itemKey: this.product.key,
        imageType: this.view === 'inside' ? 2 : 1,
      });
    },
  },
};
</script>
