<template>
  <div>loading...</div>
</template>
<script>
export default {
  name: 'LoadContract',
  async mounted() {
    this.$store
      .dispatch('basket/fromQuote', {
        contractId: this.$route.params.contractId,
        jobKey: this.$route.params.jobId,
      })
      .then(() => {
        this.routerPush('/basket');
      })
      .catch((error) => {
        this.alertBox().fire({
          title: 'Error',
          text: error,
        });
      });
  },
};
</script>
