var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs:flex"},[(_vm.groups.length > 0)?_c('div',{staticClass:"relative m-3",attrs:{"tabindex":"1"},on:{"focusout":function($event){_vm.select_group_dropdown_open = false}}},[_c('div',{staticClass:"border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full",class:{ 'border-green-600': _vm.value.groupId !== undefined }}),_c('div',{staticClass:"border flex justify-between cursor-pointer",on:{"click":function($event){(_vm.select_group_dropdown_open = !_vm.select_group_dropdown_open),
        (_vm.select_type_dropdown_open = false),
        _vm.$emit('close-number-of-squares-dropdown')}}},[_c('div',{staticClass:"p-3 whitespace-no-wrap text-gray-600"},[_vm._v("Select Group")]),_c('div',{staticClass:"p-3"},[(_vm.select_group_dropdown_open)?_c('i',{staticClass:"fa fa-caret-up"}):_c('i',{staticClass:"fa fa-caret-down"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.select_group_dropdown_open),expression:"select_group_dropdown_open"}],staticClass:"absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"},_vm._l((_vm.groups),function(group){return _c('div',{key:group.productSelectionGroupId},[_c('label',{staticClass:"flex justify-between cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white",class:{
            'bg-brand-primary text-white': _vm.value.groupId == group.productSelectionGroupId,
          },attrs:{"title":group.description}},[_c('div',{staticClass:"p-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.groupId),expression:"value.groupId"}],staticClass:"hidden",attrs:{"type":"radio","name":"group"},domProps:{"value":group.productSelectionGroupId,"checked":_vm._q(_vm.value.groupId,group.productSelectionGroupId)},on:{"change":[function($event){return _vm.$set(_vm.value, "groupId", group.productSelectionGroupId)},_vm.selectGroup]}}),_vm._v(" "+_vm._s(group.title)+" ")]),(_vm.value.groupId == group.productSelectionGroupId)?_c('div',{staticClass:"p-3"},[_c('i',{staticClass:"fal fa-check"})]):_vm._e()])])}),0)]):_vm._e(),(_vm.types.length > 0)?_c('div',{staticClass:"relative m-3",attrs:{"tabindex":"2"},on:{"focusout":function($event){_vm.select_type_dropdown_open = false}}},[_c('div',{staticClass:"border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full",class:{ 'border-green-600': _vm.value.typeId !== undefined }}),_c('div',{staticClass:"border flex justify-between cursor-pointer",on:{"click":function($event){(_vm.select_type_dropdown_open = !_vm.select_type_dropdown_open),
        (_vm.select_group_dropdown_open = false),
        _vm.$emit('close-number-of-squares-dropdown')}}},[_c('div',{staticClass:"p-3 whitespace-no-wrap text-gray-600"},[_vm._v("Select Type")]),_c('div',{staticClass:"p-3"},[(_vm.select_type_dropdown_open)?_c('i',{staticClass:"fa fa-caret-up"}):_c('i',{staticClass:"fa fa-caret-down"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.select_type_dropdown_open),expression:"select_type_dropdown_open"}],staticClass:"absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"},_vm._l((_vm.types),function(type){return _c('div',{key:type.productSelectionTypeId},[_c('label',{staticClass:"flex justify-between cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white",class:{ 'bg-brand-primary text-white': _vm.value.typeId === type.productSelectionTypeId },attrs:{"title":type.description}},[_c('div',{staticClass:"p-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.typeId),expression:"value.typeId"}],staticClass:"hidden",attrs:{"type":"radio","name":"type"},domProps:{"value":type.productSelectionTypeId,"checked":_vm._q(_vm.value.typeId,type.productSelectionTypeId)},on:{"change":[function($event){return _vm.$set(_vm.value, "typeId", type.productSelectionTypeId)},_vm.selectType]}}),_vm._v(" "+_vm._s(type.title)+" ")]),(_vm.value.typeId === type.productSelectionTypeId)?_c('div',{staticClass:"p-3"},[_c('i',{staticClass:"fal fa-check"})]):_vm._e()])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }