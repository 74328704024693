<template>
  <transition appear name="widget-fade-in-1">
    <div class="flex flex-col bg-white border p-5 w-full">
      <div class="flex justify-between mb-5">
        <div class="flex flex-col justify-around">
          <div class="flex">
            <span class="text-xl mr-3"><i class="fal fa-users"></i></span>
            <span class="flex flex-col justify-around">Top Users - Quotes</span>
          </div>
        </div>
        <div>
          <select v-model="range" class="text-xs bg-gray-200 px-2 py-1 rounded-lg">
            <option value="30">Last 30 Days</option>
            <option value="60">Last 60 Days</option>
            <option value="90">Last 90 Days</option>
          </select>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="flex items-center opacity-50 font-thin text-sm mb-2">
          <span>User</span>
          <span class="ml-auto">Quotes</span>
        </div>
        <router-link
          v-for="(user, index) in top_users"
          :key="index"
          :to="'/customer/' + user.customerId + '/details'"
          class="flex items-center"
        >
          <div>
            {{ user.name }}
          </div>
          <div class="ml-auto">
            <strong>{{ user.contractCount }}</strong>
          </div>
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      current_user: {},
      top_users: [],
      range: 30,
      loading: true,
    };
  },
  watch: {
    range(val) {
      this.loadFeed(val);
    },
  },
  mounted() {
    this.loading = true;
    this.loadFeed(this.range);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      loadKPITopCustomersForQuotes: 'kpi/loadKPITopCustomersForQuotes',
    }),
    async loadFeed(range) {
      const resp = await this.loadKPITopCustomersForQuotes(range * 1440);
      this.top_users = resp;
    },
  },
};
</script>
