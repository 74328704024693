import Touch from './touch';

// Touch.prototype.newOrders = async function newOrders() {
//   return (await this.authenticatedPost('contract/List', {
//     ContractType: 3,
//   })).data;
// };

Touch.prototype.newOrders = function newOrders() {
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve([
          {
            id: 53,
            date: '2020-03-03T16:17:42.883',
            number: '4',
            reference: 'TODO',
            url: '/order/0',
            jobs: [
              {
                id: 62,
                reference: null,
                description: null,
                priceBeforeTax: 0.0,
              },
            ],
          },
          {
            id: 51,
            date: '2020-03-03T15:42:50.623',
            number: '3',
            reference: 'TODO',
            url: '/order/1',
            jobs: [
              {
                id: 57,
                reference: null,
                description: null,
                priceBeforeTax: 0.0,
              },
              {
                id: 58,
                reference: null,
                description: 'version-1',
                priceBeforeTax: 0.0,
              },
            ],
          },
        ]),
      250 + Math.random() * 1000,
    ),
  );
};

Touch.prototype.showOrder = async function showOrder(id) {
  return (await this.newOrders()).map((order) => ({
    id: order.id,
    reference: order.reference,
    price: order.price,
    created_at: order.date,
    // name: order.name,
    // email: order.email,
    // updated_at: order.updated_at,
    // company: order.company
    // telephone: order.telephone,
    // ref_number: order.ref_number,
    // subtotal: order.subtotal,
    // vat: order.vat,
    // total: order.total,
    // status: order.status,
    url: order.url,
  }))[id];
};
