<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Product Selections'],
        ]"
      />
    </template>
    <template #header_page_title> Product Selections </template>
    <Menu></Menu>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <ManageProductGroups></ManageProductGroups>
    </loading>
  </touch-layout>
</template>

<script>
import ManageProductGroups from '@/components/business/productSetup/ManageProductGroups.vue';
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    ManageProductGroups,
    Breadcrumbs,
  },
};
</script>
