<template>
  <div class="bg-white">
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-no-wrap">
      <router-link
        v-if="!touch_business_user"
        to="/my-account/details"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': active_section === 'details',
          'border-white': active_section !== 'details',
        }"
      >
        My Details
      </router-link>
      <router-link
        v-if="touch_business_user"
        :to="`/branch/${$route.params.id}/details`"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': active_section === 'details',
          'border-white': active_section !== 'details',
        }"
      >
        Branch Details
      </router-link>
      <router-link
        v-if="touch_business_user"
        :to="`/branch/${$route.params.id}/address`"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': active_section === 'address',
          'border-white': active_section !== 'address',
        }"
      >
        Address
      </router-link>
      <router-link
        v-if="!touch_business_user"
        to="/my-account/staff"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': active_section === 'staff',
          'border-white': active_section !== 'staff',
        }"
      >
        Staff & Licenses
      </router-link>
      <router-link
        v-if="touch_business_user"
        :to="`/branch/${$route.params.id}/staff`"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black"
        :class="{
          'border-black': active_section === 'staff',
          'border-white': active_section !== 'staff',
        }"
      >
        Staff & Licenses
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    active_section() {
      const substrSection = this.$route.path
        .substring(this.$route.path.lastIndexOf('/') + 1)
        .replace(this.$route.params.id, '');
      if (substrSection === '') {
        return 'details';
      }
      return substrSection;
    },
  },
};
</script>
