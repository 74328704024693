var render = function render(){var _vm=this,_c=_vm._self._c;return _c('touch-layout',{scopedSlots:_vm._u([{key:"header_page_breadcrumb",fn:function(){return [_c('Breadcrumbs',{attrs:{"routes":[
        ['Dashboard', '/'],
        ['Quotes', '/quotes'],
      ]}})]},proxy:true},{key:"header_page_title",fn:function(){return [_vm._v(" Search Quotes ")]},proxy:true},{key:"header_page_actions",fn:function(){return [_c('div',{staticClass:"pr-3 md:px-10 flex items-center"},[_c('router-link',{staticClass:"btn-action",attrs:{"to":"/new-quote"}},[_vm._v(" New Quote ")]),_c('info-popup',{staticClass:"_pop-out-left",attrs:{"id":'quotes:new_quote',"info_message":'Click here to create a new quote at any time.',"next_id":'quotes:view_quote'}})],1)]},proxy:true}])},[_c('portal',{attrs:{"to":"portal_search"}},[_c('SearchBox',{staticClass:"flex",attrs:{"placeholder":"Search for reference..."}})],1),_c('loading',{staticClass:"bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"flex flex-wrap-reverse 2xs:flex-no-wrap"},_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.tabType,staticClass:"py-2 px-4 border border-b-0 cursor-pointer",class:{
          'bg-white': _vm.selectedTab === tab.tabType,
          'border-gray-400 bg-gray-300 text-gray-600': _vm.selectedTab !== tab.tabType,
        },on:{"click":function($event){_vm.selectedTab = tab.tabType}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0),(_vm.quotes.length > 0)?_c('div',{key:"quotes_list",staticClass:"bg-white p-3 md:p-10 pb-6 border",attrs:{"data-index":"1"}},[_c('table',{staticClass:"table-lined xs:table-unstacked _with-hover w-full"},[_c('thead',[_c('tr',[_c('th',{staticClass:"w-10"},[_vm._v("#")]),_c('th',[_vm._v("Date Created")]),_c('th',[_vm._v("Reference")]),_c('th',[_vm._v(" Net Cost "),_c('info-popup',{attrs:{"id":'quotes:pricebook',"info_message":'This will be created using your initial pricing setup.',"next_id":'quotes:requoted_price'}})],1),_c('th',[_vm._v(" Requoted Price "),_c('info-popup',{attrs:{"id":'quotes:requoted_price',"info_message":'This price will show any uplift added using the quote my customer feature, showing the last price quoted to the customer.',"next_id":'quotes:new_quote'}})],1),_c('th',[_vm._v("Visible to Admin")]),_c('th',{staticClass:"w-16"},[_c('button',{staticClass:"btn-action",on:{"click":function($event){$event.stopPropagation();return _vm.loadFeed.apply(null, arguments)}}},[_vm._v("Reload")]),_c('info-popup',{staticClass:"_pop-out-left",attrs:{"id":'quotes:view_quote',"info_message":'As you create quotes using the sales and survey designer they will land here, click a quote for more details of the customers quote.',"next_id":'quotes:status'}})],1)])]),_c('tbody',_vm._l((_vm.quotes),function(quote,index){return _c('router-link',{key:index,staticClass:"cursor-pointer pointer-events-none",class:{
              'pointer-events-auto': [
                _vm.enums.orderStatusSaveStates.NOT_APPLICABLE,
                _vm.enums.orderStatusSaveStates.COMPLETED,
              ].includes(quote.saveState),
              'fadeInAndOut': quote.saveState === _vm.enums.orderStatusSaveStates.STARTED
            },attrs:{"tag":"tr","to":quote.url}},[_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Number:")]),_c('div',{staticClass:"td-content"},[_vm._v(_vm._s(quote.customerCounter))])]),_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Created:")]),_c('div',{staticClass:"td-content"},[_vm._v(_vm._s(_vm.formatDate(quote.date)))])]),_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Reference:")]),_c('div',{staticClass:"td-content truncate w-full"},[_vm._v(_vm._s(quote.reference))])]),(quote.saveState === _vm.enums.orderStatusSaveStates.FAILED)?_c('td',[_c('div',{staticClass:"td-content font-bold text-red-500"},[_vm._v(" Sorry, problem saving this job. Please re-input ")])]):(quote.jobs.length > 0)?_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Net Cost:")]),_c('div',{staticClass:"td-content",domProps:{"innerHTML":_vm._s(
                  _vm.formatCurrency(
                    quote.customerCurrencyCode,
                    quote.jobs[0].priceBeforeTax,
                  )
                )}})]):_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Net Cost:")]),_c('div',{staticClass:"td-content"},[_vm._v("-")])]),(quote.jobs.length > 0)?_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Requoted Price:")]),_c('div',{staticClass:"td-content",domProps:{"innerHTML":_vm._s(
                  _vm.formatCurrency(
                    quote.customerCurrencyCode,
                    quote.jobs[0].requotePriceIncludingTax,
                  )
                )}})]):_c('td',[_c('div',{staticClass:"td-title w-32"},[_vm._v("Requoted Price:")]),_c('div',{staticClass:"td-content"},[_vm._v("-")])]),_c('td',[(
                  _vm.showReleaseToAdminButton(quote) &&
                    quote.saveState !== _vm.enums.orderStatusSaveStates.FAILED
                )?[_c('div',{staticClass:"td-title w-32 my-auto"},[_vm._v("Visible To Admin?")]),(!quote.visibleToAdmin)?_c('button',{staticClass:"btn-action",on:{"click":function($event){$event.stopPropagation();return _vm.toggleAdminView(quote)}}},[_vm._v(" Show To Admin ")]):_c('i',{staticClass:"fa fa-check text-green-600"})]:_vm._e()],2),_c('td',{staticClass:"td-action-buttons"},[_c('div',{staticClass:"table-edit-btn"},[_c('i',{staticClass:"fa fa-pencil"})])])])}),1)])]):_c('div',{key:"none-found",staticClass:"bg-white p-3 md:p-10 pb-6 border"},[(_vm.$route.query.search)?_c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("No Quotes match \""+_vm._s(_vm.$route.query.search)+"\"")]),_c('div',[_c('a',{staticClass:"btn-action",on:{"click":function($event){return _vm.$router.push({ query: undefined })}}},[_vm._v(" Clear Search ")])])]):_c('div',[_vm._v("No Quotes to show")])]),(_vm.quotes.length > 0)?_c('div',{key:"pagination",staticClass:"mt-3"},[_c('pagination',{attrs:{"total_items":_vm.total_items,"limit":_vm.limit},on:{"load-feed":_vm.loadFeed}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }