<template>
  <component :is="login_component"></component>
</template>
<script>
import TouchBusinessLogin from './TouchBusinessLogin.vue';
import TouchPortalLogin from './TouchPortalLogin.vue';

export default {
  components: {
    'portal-login': TouchPortalLogin,
    'business-login': TouchBusinessLogin,
  },
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('reset', [
        'basket',
        'touch.isDealerNetworkPartnerOnly',
        'style',
        'feature',
        'designer.isQuickTip',
      ]); // eslint-disable-line
    });
  },
  computed: {
    login_component() {
      switch (true) {
        case this.touch_portal_installation:
          return 'portal-login';
        case this.touch_business_installation:
          return 'business-login';
        default:
          throw new Error('Unknown Installation Type, Please check VUE_APP_INSTALLATION_TYPE');
      }
    },
  },
};
</script>
