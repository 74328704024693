<template>
  <div class="p-2 mt-3 mb-3 text-center rounded border border-solid border-red-700 bg-red-200">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['message'],
};
</script>

<style scoped></style>
