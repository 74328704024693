<template>
  <div
    ref="svg"
    @design-selector-clicked="designSelectorClicked($event.detail)"
    @selector-clicked="selectorClicked($event.detail)"
    @input-blur="dimensionBlured($event.detail)"
    @input-focus="dimensionFocused($event.detail)"
    v-html="svg"
  />
</template>

<script>
// This function appends a suffix to IDs of referenced elements in the <defs> in order to  to avoid ID collision
// between multiple injected SVGs. The suffix has the form "--inject-X", where X is a running number which is
// incremented with each injection. References to the IDs are adjusted accordingly.
// We assume tha all IDs within the injected SVG are unique, therefore the same suffix can be used for all IDs of one
// injected SVG.
// If the onlyReferenced argument is set to true, only those IDs will be made unique that are referenced from within the SVG

import { mapGetters } from 'vuex';

export default {
  props: {
    dimensionHighlight: {
      default: null,
    },
    permissionHighlightAreas: {
      default: false,
    },
    interactionHighlight: {
      default: null,
    },
    preserveAspectRatio: {
      default: true,
    },
    svg: {
      required: true,
    },
    allowComponentClicking: {
      default: false,
    },
    brandedMode: {
      default: false,
    },
    showDimensions: {
      default: false,
    },
    showIssues: {
      default: true,
    },
    showChangeBayDesigns: {
      default: false,
    },
    thumbnailMode: {
      default: false,
    },
    showSizes: {
      default: true,
    },
    showDecorationOverrides: {
      default: true,
    },
    mobileShowImage: {
      default: false,
    },
  },
  computed: {
    ...mapGetters('designer', {
      interactiveHighlightState: 'interactiveHighlightState',
      showSelectionForHeadingState: 'selectionForHeadingState',
      activeSpecificationGroups: 'specificationGroups',
    }),

    activeMemberIds() {
      // return this.activeSpecificationGroups.map(item => item[1])
      //   .map(item => Object.keys(item).map(key => item[key]))
      //   .flat(2)
      //   .flatMap(item => item.members.map(itemMember => itemMember.id))
      //   .filter(this.onlyUnique);
      return this.interactiveHighlightState.selectedComponents.flatMap(
        (selectedComponent) => selectedComponent.memberIds,
      );
    },
    activeComponentIds() {
      // return this.activeSpecificationGroups.map(item => item[1])
      //   .map(item => Object.keys(item).map(key => item[key]))
      //   .flat(2)
      //   .flatMap(item => item.components.map(itemComponent => itemComponent.id))
      //   .filter(this.onlyUnique);
      return this.interactiveHighlightState.selectedComponents.map(
        (selectedComponent) => selectedComponent.id,
      );
    },
  },
  watch: {
    showSelectionForHeadingState(is) {
      this.drawHeadingSelection(is);
    },
    interactiveHighlightState(is) {
      this.drawHighlights(is);
    },
    interactionHighlight(is, was) {
      const el = this.$refs.svg.querySelector('svg');

      if (was !== null) {
        el.querySelectorAll(`[componentid='${was}'],[memberid='${was}']`).forEach((selector) => {
          selector.classList.remove('interactionHighlight');
        });
      }

      if (is !== null) {
        el.querySelectorAll(`[componentid='${is}'], [memberid='${is}']`).forEach((selector) => {
          selector.classList.add('interactionHighlight');
        });
      }
    },
    dimensionHighlight(is, was) {
      const el = this.$refs.svg.querySelector('svg');

      if (was !== null) {
        el.querySelectorAll(`[dimensionid='${was}']`).forEach((selector) => {
          selector.classList.remove('dimensionHighlight');
        });
      }

      if (is !== null) {
        el.querySelectorAll(`[dimensionid='${is}']`).forEach((selector) => {
          selector.classList.add('dimensionHighlight');
        });
      }
    },
    showChangeBayDesigns(show) {
      const el = this.$refs.svg.querySelector('svg');
      this.showLayer(el, 'DesignSelectors', show);
      this.showLayer(el, 'GlassKeys', !show);
    },
    mobileShowImage(show) {
      if (show) {
        this.resizeSVG();
      }
    },
    svg() {
      this.$nextTick(() => {
        this.resizeSVG();
        this.drawHighlights(this.interactiveHighlightState);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeSVG);
      this.diagramInteractionToggle(this.showDimensions);
      this.resizeSVG();
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeSVG);
  },
  methods: {
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    memberIsInActiveSpec(memberId) {
      if (this.interactiveHighlightState.selectedComponents.length === 0) {
        return true;
      }

      return this.activeMemberIds.includes(memberId);
    },
    componentIsInActiveSpec(componentId) {
      if (this.interactiveHighlightState.selectedComponents.length === 0) {
        return true;
      }

      return this.activeComponentIds.includes(componentId);
    },
    drawHeadingSelection(headingSpec) {
      const el = this.$refs.svg.querySelector('svg');

      el.querySelectorAll('[ishighlight="Y"]').forEach((selector) => {
        // eslint-disable-next-line no-param-reassign
        selector.style.fill = null;
        selector.style.opacity = null;
        selector.classList.remove('isSelected');
      });

      if (headingSpec.length === 0) {
        this.drawHighlights(this.interactiveHighlightState);
        return;
      }

      headingSpec.forEach((heading) => {
        if (heading.memberIds.length > 0) {
          heading.memberIds.filter(this.memberIsInActiveSpec).forEach((memberId) => {
            el.querySelectorAll(`[ishighlight="Y"][memberid="${memberId}"]`).forEach((selector) => {
              selector.classList.add('isSelected');
              // eslint-disable-next-line no-param-reassign
              selector.style.fill = heading.colour;
              selector.style.opacity = null;
            });
          });
        } else {
          heading.componentIds.filter(this.componentIsInActiveSpec).forEach((comonentId) => {
            el.querySelectorAll(`[ishighlight="Y"][componentid="${comonentId}"]`).forEach(
              (selector) => {
                selector.classList.add('isSelected');
                // eslint-disable-next-line no-param-reassign
                selector.style.fill = heading.colour;
                selector.style.opacity = null;
              },
            );
          });
        }
      });
    },
    drawHighlights(highlightSpec) {
      if (!this.permissionHighlightAreas) {
        return;
      }
      const el = this.$refs.svg.querySelector('svg');

      el.querySelectorAll('[ishighlight="Y"].isSelected').forEach((selector) => {
        selector.classList.remove('isSelected');
      });

      if (highlightSpec.currentSelectionType === 'member') {
        el.querySelectorAll(
          `[ishighlight="Y"][memberid="${highlightSpec.clickedMemberId}"]`,
        ).forEach((selector) => {
          selector.classList.add('isSelected');
        });
      }

      if (highlightSpec.currentSelectionType === 'component') {
        highlightSpec.selectedComponents.forEach((selectedComponent) => {
          if (selectedComponent.memberIds.length > 0) {
            selectedComponent.memberIds.forEach((highlightedMemberId) => {
              el.querySelectorAll(`[ishighlight="Y"][memberid="${highlightedMemberId}"]`).forEach(
                (selector) => {
                  selector.classList.add('isSelected');
                },
              );
            });
          } else {
            el.querySelectorAll(`[ishighlight="Y"][componentid="${selectedComponent.id}"]`).forEach(
              (selector) => {
                selector.classList.add('isSelected');
              },
            );
          }
        });
      }
    },
    hideLayer(svg, layername) {
      this.showLayer(svg, layername, false);
    },
    showLayer(svg, layername, show = true) {
      svg.querySelectorAll(`[layertype=${layername}]`).forEach((selector) => {
        if (show) {
          selector.classList.add('layerShow');
          selector.classList.remove('layerHide');
        } else {
          selector.classList.add('layerHide');
          selector.classList.remove('layerShow');
        }
      });
    },
    selectorClicked({ componentId, memberId }) {
      if (!this.permissionHighlightAreas) {
        return;
      }
      this.$emit('selector-clicked', { componentId, memberId });
    },
    designSelectorClicked({ componentId }) {
      this.$emit('design-selector-clicked', componentId);
    },
    dimensionChanged(e) {
      const input = e.target.value;
      const value = Number(e.target.value);
      const originalValue = e.target.getAttribute('original-value');
      const title = e.target.getAttribute('title');
      const valuemin = Number(e.target.getAttribute('valuemin'));
      const valuemax = Number(e.target.getAttribute('valuemax'));

      if (Number.isNaN(value)) {
        this.alertBox()
          .fire({
            title: `${input} not recognised as a number`,
            icon: 'error',
            html: 'Please try again.',
          })
          .then(() => {
            e.target.value = originalValue;
            e.target.select();
          });
        return;
      }

      if (valuemin > 0) {
        if (value < valuemin) {
          this.alertBox()
            .fire({
              title: `${title}`,
              icon: 'error',
              html: `${value}mm is ${valuemin - value}mm below the minimum`,
            })
            .then(() => {
              e.target.value = originalValue;
              e.target.select();
            });
          return;
        }
      }

      if (valuemax > 0) {
        if (value > valuemax) {
          this.alertBox()
            .fire({
              title: `${title}`,
              icon: 'error',
              html: `${value}mm is ${value - valuemax}mm above the maximum`,
            })
            .then(() => {
              e.target.value = originalValue;
              e.target.select();
            });
          return;
        }
      }

      this.$emit('change-dimension', {
        dimensionId: e.target.getAttribute('dimensionid'),
        value: e.target.value,
      });
    },
    dimensionBlured() {
      this.$emit('dimension-highlight', null);
    },
    dimensionFocused({ dimensionId, element }) {
      element.select();
      this.$emit('dimension-highlight', dimensionId);
    },
    diagramInteractionToggle(active) {
      let el;

      try {
        el = this.$refs.svg.querySelector('svg');
      } catch (e) {
        return false;
      }

      if (!el) {
        return false;
      }

      el.querySelectorAll('[id^=dimensionInput]').forEach((input) => {
        if (active === true) {
          input.parentElement.classList.add('active-input');
          input.setAttribute('original-value', input.value);
          input.addEventListener('change', this.dimensionChanged);
          // eslint-disable-next-line no-param-reassign
          input.style.display = 'inline';

          input.parentElement.querySelectorAll('span').forEach((spanTag) => {
            spanTag.remove();
          });
        } else {
          input.parentElement.classList.remove('active-input');
          input.setAttribute('original-value', input.value);
          input.removeEventListener('change', this.dimensionChanged);
          // eslint-disable-next-line no-param-reassign
          input.style.display = 'none';

          input.parentElement.querySelectorAll('span').forEach((spanTag) => {
            spanTag.remove();
          });

          const measurementSpan = document.createElement('span');
          measurementSpan.appendChild(document.createTextNode(input.value));
          input.parentElement.appendChild(measurementSpan);
        }
      });

      el.querySelectorAll('[id^=interaction]').forEach((interaction) => {
        if (active === true) {
          interaction.setAttribute('interactionactive', 'y');
          interaction.classList.add('highlightOnHover');
        } else {
          interaction.setAttribute('interactionactive', 'n');
          interaction.classList.remove('highlightOnHover');
        }
      });

      return true;
    },
    resizeSVG() {
      let svg;

      try {
        svg = this.$refs.svg.querySelector('svg');
      } catch (e) {
        return;
      }
      /* Redraw the dimensions to suit the current scale. This will attempt to keep the dimensions at a human readable level no matter what size the image has been resized to */
      if (!svg) {
        return;
      }

      if (!this.preserveAspectRatio) {
        svg.setAttribute('preserveAspectRatio', 'none');
      }

      svg.querySelectorAll('style').forEach((styleTag) => {
        styleTag.remove();
      });

      if (this.thumbnailMode || !this.showSizes) {
        this.hideLayer(svg, 'Dimensions');
        this.hideLayer(svg, 'DecorationOverrides');
        this.hideLayer(svg, 'GlassKeys');
      }

      if (this.brandedMode) {
        this.hideLayer(svg, 'GlassKeys');
        this.hideLayer(svg, 'IssuesComponent');

        if (this.thumbnailMode) {
          this.hideLayer(svg, 'HingePointers');
        }
      }

      if (!this.showDecorationOverrides) {
        this.hideLayer(svg, 'DecorationOverrides');
      }

      if (!this.showIssues) {
        this.hideLayer(svg, 'IssuesComponent');
      }

      const svgWidth = svg.clientWidth;
      const svgHeight = svg.clientHeight;

      const svgViewbox = svg.getAttribute('viewBox');
      const svgViewboxWidth = svgViewbox.split(' ')[2];
      const svgViewboxHeight = svgViewbox.split(' ')[3];

      const scaleH = svgViewboxHeight / svgHeight; /* Get the horizontal scale */
      const scaleW = svgViewboxWidth / svgWidth; /* Get the vertical scale */
      const scale = Math.max(scaleH, scaleW); /* Use the largest scale overall */

      if (scale === Infinity) {
        return;
      }

      // scale gets bigger when the image is smaller

      let fontSize = 12; // Set based on scale

      if (scale < 2) {
        fontSize = 18;
      }

      this.diagramInteractionToggle(this.showDimensions);

      const dimsHeight = scale * fontSize; /* Adjust font size by the current scale */
      const dimsWidth =
        dimsHeight * 2.5; /* Set the width of the dimensions box using an aspect ratio of 2.5 : 1 */

      let textFontSize = 10;

      if (scale < 2) {
        textFontSize = 14;
      }

      const textHeight = scale * textFontSize;

      svg.querySelectorAll('[id^=dimensionContainer]').forEach((dimension) => {
        let x = -((dimsWidth * 1.14) / 2); // Default to Center
        let y = -((dimsHeight * 1.2) / 2); // Default to Middle

        if (dimension.getAttribute('aligntypeid').includes('Left')) {
          x = 0;
        }
        if (dimension.getAttribute('aligntypeid').includes('Right')) {
          x = -dimsWidth;
        }
        if (dimension.getAttribute('aligntypeid').includes('Top')) {
          y = 0;
        }
        if (dimension.getAttribute('aligntypeid').includes('Bottom')) {
          y = -dimsHeight;
        }

        dimension.setAttribute('x', x);
        dimension.setAttribute('y', y);
        dimension.setAttribute('width', dimsWidth * 1.14 + 45);
        dimension.setAttribute('height', dimsHeight * 1.2 + 20); // 45 and 20 are for padding - 45 for the icon

        const inputWrapper = dimension.querySelector('.input-wrapper');

        if (inputWrapper) {
          inputWrapper.setAttribute('height', dimsHeight * 1.2);
          inputWrapper.setAttribute('width', dimsWidth * 1.14);
        }
      });

      svg.querySelectorAll('[id^=dimensionInput]').forEach((dimension) => {
        // eslint-disable-next-line no-param-reassign
        dimension.style.fontSize = `${dimsHeight}px`;
      });

      svg.querySelectorAll('[id^=dimensionContainer]').forEach((dimension) => {
        // eslint-disable-next-line no-param-reassign
        dimension.style.fontSize = `${dimsHeight}px`;
      });

      svg.querySelectorAll('[id^=dimensionIcon]').forEach((dimensionIcon) => {
        // eslint-disable-next-line no-param-reassign
        dimensionIcon.style.fontSize = `${dimsHeight}px`;
      });

      svg.querySelectorAll('[id^=issueWarningLine]').forEach((warningLine) => {
        // eslint-disable-next-line no-param-reassign
        warningLine.style.strokeWidth = scale * 3;
      });

      svg.querySelectorAll('[id^=textElement]').forEach((textElement) => {
        // eslint-disable-next-line no-param-reassign
        textElement.style.fontSize = `${textHeight}px`;

        if (textElement.getAttribute('aligntypeid').includes('Left')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.textAnchor = 'start';
        }
        if (textElement.getAttribute('aligntypeid').includes('Centre')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.textAnchor = 'middle';
        }
        if (textElement.getAttribute('aligntypeid').includes('Right')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.textAnchor = 'end';
        }
        if (textElement.getAttribute('aligntypeid').includes('Top')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.alignmentBaseline = 'hanging';
        }
        if (textElement.getAttribute('aligntypeid').includes('Middle')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.alignmentBaseline = 'middle';
        }
        if (textElement.getAttribute('aligntypeid').includes('Bottom')) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.alignmentBaseline = 'baseline';
        }

        const textBackground = svg.querySelector(
          `[id^=textBackground${textElement.getAttribute('textelementuniqueid')}]`,
        );
        const boundingBox = textElement.getBBox();

        textBackground.setAttribute('x', boundingBox.x);
        textBackground.setAttribute('y', boundingBox.y);
        textBackground.setAttribute('width', boundingBox.width);
        textBackground.setAttribute('height', boundingBox.height);
      });
    },
  },
};
</script>

<style>
svg {
  pointer-events: none;
}
.interact {
  pointer-events: all;
  cursor: pointer;
}

.isSelected {
  fill: #ffe119;
  opacity: 0.75;
}

.layerHide {
  display: none;
}
.layerShow {
  display: block;
}
.interactionHighlight {
  fill: yellow;
  opacity: 0.5;
}
.inputInvalid {
  background-color: red;
  color: white;
}
.dimensionHighlight {
  border: 3px solid red;
}

.input-wrapper {
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.input-wrapper.highlighted {
  animation: blink 0.5s;
  animation-iteration-count: 5;
  border: 5px solid red;
}

@keyframes blink {
  50% {
    border: 1px solid transparent;
  }
}

.input-wrapper.active-input {
  background-image: url(/images/pencil-background-for-inputs.png);
  background-size: auto 50%;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  padding: 0 10px 0 30px;
}

.input-wrapper input,
.input-wrapper span {
  background: transparent;
  color: #424242;
  display: block;
  text-align: center;
}

.input-icon {
  display: none;
}
</style>
