<template>
  <div>
    <create-discount
      v-if="add_discount_open"
      @close="$emit('close-add-discount-popup')"
      @saved="loadDiscounts"
    ></create-discount>

    <div class="bg-white border m-3 xs:m-10 p-3">
      <table v-if="discounts.length > 0" class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(discount, index) in discounts"
            :key="index"
            :class="{ _hovered: hovered === index }"
            @click.stop.prevent="editing_discount = discount.id"
            @mouseover="hovered = index"
            @mouseleave="hovered = null"
          >
            <td>
              <div class="td-title w-24">Description:</div>
              <div class="td-content">{{ discount.description }}</div>
            </td>
            <td class="td-action-buttons">
              <div v-if="touch_business_user || loggedInUser.isAdmin" class="table-edit-btn">
                <i class="fa fa-pencil"></i>
              </div>
              <edit-discount
                v-if="editing_discount === discount.id"
                :discount="discount"
                @close="(editing_discount = null), loadDiscounts()"
                @updated="loadDiscounts"
              ></edit-discount>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>No Discounts to Show</div>
    </div>
  </div>
</template>

<script>
import EditDiscount from '@/components/business/productSetup/discounts/Edit.vue';
import CreateDiscount from '@/components/business/productSetup/discounts/Create.vue';

export default {
  name: 'ManageDiscounts',
  components: {
    CreateDiscount,
    EditDiscount,
  },
  props: ['add_discount_open'],
  data() {
    return {
      discounts: [],
      editing_discount: null,
      hovered: null,
    };
  },
  mounted() {
    this.loadDiscounts();
  },
  methods: {
    async loadDiscounts() {
      this.discounts = (await this.$store.dispatch('touch/staffListDiscountCategories'))
        .slice()
        .sort((a, b) => a.listIndex - b.listIndex);
    },
  },
};
</script>

<style scoped></style>
