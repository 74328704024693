<template>
  <div v-if="demo_mode" :id="`info_popup_${id}`" class="info-popup-wrapper">
    <div class="info-popup-icon" @click.stop="setActivePopup(id)">
      <i class="fas fa-info-circle"></i>
    </div>
    <div v-show="message_open" class="info-popup-message-wrapper">
      <div class="info-popup-close" @click.stop="setActivePopup('')">
        <i class="fal fa-times"></i>
      </div>
      <div class="info-popup-message" :class="{ 'mb-2': next_id === undefined }">
        {{ info_message }}
      </div>
      <div v-if="next_id !== undefined" class="info-popup-button">
        <div @click.stop="setActivePopup(next_id)">Next <i class="fa fa-caret-right ml-3"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPopup',
  props: ['info_message', 'id', 'next_id'],
  data() {
    return {};
  },
  computed: {
    message_open() {
      return this.$store.state.infoPopup.activePopup === this.id;
    },
  },
  methods: {
    setActivePopup(id) {
      this.$store.commit('infoPopup/setActive', id);

      const el = document.getElementById(`info_popup_${id}`);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<!--how to use:-->

<!--<info-popup :info_message="'The message that appears in the popup'" :id="'unique_id_for_popup'" :next_id="'id_of_the_popup_that_opens_next(optional)'"></info-popup>-->
