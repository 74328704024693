<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Quotes', '/quotes'],
        ]"
      />
    </template>
    <template #header_page_title> Search Quotes </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for reference..." />
    </portal>
    <template #header_page_actions>
      <div class="pr-3 md:px-10 flex items-center">
        <router-link to="/new-quote" class="btn-action"> New Quote </router-link>
        <info-popup
          :id="'quotes:new_quote'"
          class="_pop-out-left"
          :info_message="'Click here to create a new quote at any time.'"
          :next_id="'quotes:view_quote'"
        ></info-popup>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div class="flex flex-wrap-reverse 2xs:flex-no-wrap">
        <div
          v-for="tab in tabs"
          :key="tab.tabType"
          class="py-2 px-4 border border-b-0 cursor-pointer"
          :class="{
            'bg-white': selectedTab === tab.tabType,
            'border-gray-400 bg-gray-300 text-gray-600': selectedTab !== tab.tabType,
          }"
          @click="selectedTab = tab.tabType"
        >
          {{ tab.name }}
        </div>
      </div>
      <div
        v-if="quotes.length > 0"
        key="quotes_list"
        class="bg-white p-3 md:p-10 pb-6 border"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th class="w-10">#</th>
              <th>Date Created</th>
              <th>Reference</th>
              <th>
                Net Cost
                <info-popup
                  :id="'quotes:pricebook'"
                  :info_message="'This will be created using your initial pricing setup.'"
                  :next_id="'quotes:requoted_price'"
                ></info-popup>
              </th>
              <th>
                Requoted Price
                <info-popup
                  :id="'quotes:requoted_price'"
                  :info_message="'This price will show any uplift added using the quote my customer feature, showing the last price quoted to the customer.'"
                  :next_id="'quotes:new_quote'"
                ></info-popup>
              </th>
              <th>Visible to Admin</th>
              <th class="w-16">
                <button class="btn-action" @click.stop="loadFeed">Reload</button>
                <info-popup
                  :id="'quotes:view_quote'"
                  class="_pop-out-left"
                  :info_message="'As you create quotes using the sales and survey designer they will land here, click a quote for more details of the customers quote.'"
                  :next_id="'quotes:status'"
                ></info-popup>
              </th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(quote, index) in quotes"
              :key="index"
              tag="tr"
              :to="quote.url"
              class="cursor-pointer pointer-events-none"
              :class="{
                'pointer-events-auto': [
                  enums.orderStatusSaveStates.NOT_APPLICABLE,
                  enums.orderStatusSaveStates.COMPLETED,
                ].includes(quote.saveState),
                'fadeInAndOut': quote.saveState === enums.orderStatusSaveStates.STARTED
              }"
            >
              <td>
                <div class="td-title w-32">Number:</div>
                <div class="td-content">{{ quote.customerCounter }}</div>
              </td>
              <td>
                <div class="td-title w-32">Created:</div>
                <div class="td-content">{{ formatDate(quote.date) }}</div>
              </td>
              <td>
                <div class="td-title w-32">Reference:</div>
                <div class="td-content truncate w-full">{{ quote.reference }}</div>
              </td>
              <td v-if="quote.saveState === enums.orderStatusSaveStates.FAILED">
                <div class="td-content font-bold text-red-500">
                  Sorry, problem saving this job. Please re-input
                </div>
              </td>
              <td v-else-if="quote.jobs.length > 0">
                <div class="td-title w-32">Net Cost:</div>
                <div
                  class="td-content"
                  v-html="
                    formatCurrency(
                      quote.customerCurrencyCode,
                      quote.jobs[0].priceBeforeTax,
                    )
                  "
                ></div>
              </td>
              <td v-else>
                <div class="td-title w-32">Net Cost:</div>
                <div class="td-content">-</div>
              </td>
              <td v-if="quote.jobs.length > 0">
                <div class="td-title w-32">Requoted Price:</div>
                <div
                  class="td-content"
                  v-html="
                    formatCurrency(
                      quote.customerCurrencyCode,
                      quote.jobs[0].requotePriceIncludingTax,
                    )
                  "
                ></div>
              </td>
              <td v-else>
                <div class="td-title w-32">Requoted Price:</div>
                <div class="td-content">-</div>
              </td>
              <td>
                <template
                  v-if="
                    showReleaseToAdminButton(quote) &&
                      quote.saveState !== enums.orderStatusSaveStates.FAILED
                  "
                >
                  <div class="td-title w-32 my-auto">Visible To Admin?</div>
                  <button
                    v-if="!quote.visibleToAdmin"
                    class="btn-action"
                    @click.stop="toggleAdminView(quote)"
                  >
                    Show To Admin
                  </button>
                  <i v-else class="fa fa-check text-green-600"></i>
                </template>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 pb-6 border">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Quotes match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click="$router.push({ query: undefined })"> Clear Search </a>
          </div>
        </div>
        <div v-else>No Quotes to show</div>
      </div>
      <div v-if="quotes.length > 0" key="pagination" class="mt-3">
        <pagination :total_items="total_items" :limit="limit" @load-feed="loadFeed"></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import TouchJob from '@/classes/TouchJob';
import dateHelper from '@/mixins/dateHelper';
import Pagination from '@/components/shared/Pagination.vue';
import currencyHelper from '@/mixins/currencyHelper';
import pollContractStates from '@/mixins/pollContractStates';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

class Tab {
  constructor(name, tabType) {
    this.name = name;
    this.tabType = tabType;
  }
}

const TabTypeEnum = {
  ALL_QUOTES: 1,
  LOCKED_QUOTES: 2,
};

export default {
  components: {
    Breadcrumbs,
    pagination: Pagination,
    SearchBox,
  },
  mixins: [dateHelper, currencyHelper, pollContractStates],
  data() {
    return {
      quotes: [],
      loading: true,
      limit: 10,
      total_items: 0,
      selectedTab: 1,
      tabs: [
        new Tab('All Quotes', TabTypeEnum.ALL_QUOTES),
        new Tab('Locked Quotes', TabTypeEnum.LOCKED_QUOTES),
      ],
      contractsBeingProcessed: [],
    };
  },
  watch: {
    async selectedTab() {
      this.loading = true;
      await this.loadFeed();
      this.loading = false;
    },
    '$route.query': {
      async handler() {
        await this.loadFeed();
        this.pollContractStates(window.touch.CONTRACT_TYPE_QUOTE, this.quotes);
      },
      immediate: true,
    },
  },
  methods: {
    showReleaseToAdminButton(quote) {
      return quote.saveState !== this.enums.orderStatusSaveStates.STARTED;
    },
    touchJob(job) {
      return new TouchJob(job);
    },
    jobOverridePriceWithTax(job) {
      const fittingPrice = job.overrideCalculatedFittingPrice
        ? job.overrideFittingPriceTaxable * (1 + job.taxRate) + job.overrideFittingPriceTaxExempt
        : job.calculatedFittingPriceTaxable * (1 + job.taxRate) +
          job.calculatedFittingPriceTaxExempt;
      const price = job.overrideCalculatedFittingPrice
        ? job.overridePriceTaxable * (1 + job.taxRate) + job.overridePriceTaxExempt
        : job.calculatedPriceTaxable * (1 + job.taxRate) + job.calculatedPriceTaxExempt;

      return fittingPrice + price;
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.$store.dispatch('touch/loadQuotes', {
        limit: this.limit,
        offset,
        onlyShowLocked: this.selectedTab === TabTypeEnum.LOCKED_QUOTES,
        search: this.$route.query.search,
      });
      this.quotes = response.quotes;
      this.total_items = response.total_items;
      this.loading = false;
    },
    async toggleAdminView(contract) {
      // we don't need to do anything if the contract is being processed.
      if (
        this.contractsBeingProcessed.length &&
        this.contractsBeingProcessed.find((x) => x === contract.id) !== undefined
      ) {
        return;
      }
      this.contractsBeingProcessed.push(contract.id);
      const res = await window.touch.contractUpdateVisibility(contract.id, {
        visibleToAdmin: !contract.visibleToAdmin,
      });
      if (res.status === 200) {
        const originalQuote = this.quotes.find((x) => x.id === contract.id);
        originalQuote.visibleToAdmin = !originalQuote.visibleToAdmin;
      } else if (res?.data?.message) {
        window.alertBox.fire(res.data.message);
      }
      // we can now safely remove the contract from the processing list
      this.contractsBeingProcessed = this.contractsBeingProcessed.filter((x) => x !== contract.id);
    },
  },
};
</script>

<style scoped>

.fadeInAndOut {
  opacity: 1;
  animation: fade 2s linear infinite;
}

@keyframes fade {
  0%,100% { opacity: 0.2 }
  50% { opacity: 1 }
}

</style>