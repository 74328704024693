<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Pricing'],
        ]"
      />
    </template>
    <template #header_page_title> Pricing </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for an item to edit a price..." />
    </portal>
    <Menu></Menu>
    <Pricing ref="pricing" class="flex flex-col flex-grow" :search="search"></Pricing>
  </touch-layout>
</template>

<script>
import Pricing from '@/components/business/productSetup/Pricing.vue';
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Menu,
    Pricing,
    Breadcrumbs,
    SearchBox,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    pricingComponent () {
      return this.$refs.pricing
    }
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => {});
    setTimeout(() => {
      this.$watch('$route.query', this.loadSettings);
    }, 0);
  },
  methods: {
    loadSettings() {
      this.search = this.$route.query.search || '';
      this.$nextTick(() => {
        this.$refs.pricing.loadSettings(false, true);
      });
    },
  },
};
</script>
