export default {
  props: {
    startDelay: {
      default: 0,
      type: Number,
    },
  },
  methods: {
    staggeredFadeBeforeEnter(el) {
      el.style.opacity = 0; // eslint-disable-line no-param-reassign
    },
    staggeredFadeEnter(el) {
      const delay = el.dataset.index * 0 + this.startDelay;
      setTimeout(() => {
        el.animate(
          {
            opacity: [0, 1],
          },
          {
            duration: 500,
            easing: 'ease-in-out',
            iterations: 1,
          },
        );
        el.style.opacity = 1; // eslint-disable-line no-param-reassign
      }, delay);
    },
    staggeredFadeLeave(el) {
      const delay = el.dataset.index * 0;
      setTimeout(() => {
        el.animate(
          {
            opacity: [1, 0],
          },
          {
            duration: 500,
            easing: 'ease-in-out',
            iterations: 1,
          },
        );
      }, delay);
    },
    staggeredSlideBeforeEnter(el) {
      el.style.opacity = 0; // eslint-disable-line no-param-reassign
    },
    staggeredSlideEnter(el) {
      const delay = el.dataset.index * 0 + this.startDelay;
      setTimeout(() => {
        el.animate(
          {
            opacity: [0, 1],
            transform: ['translateX(100px)', 'translateX(0px)'],
          },
          {
            duration: 500,
            easing: 'ease-in-out',
            iterations: 1,
          },
        );
        el.style.opacity = 1; // eslint-disable-line no-param-reassign
        el.style.transform = 'translateX(0px)'; // eslint-disable-line no-param-reassign
      }, delay);
    },
    staggeredSlideLeave(el) {
      const delay = el.dataset.index * 0;
      setTimeout(() => {
        el.animate(
          {
            opacity: [1, 0],
            transform: ['translateX(0px)', 'translateX(100px)'],
          },
          {
            duration: 500,
            easing: 'ease-in-out',
            iterations: 1,
          },
        );
      }, delay);
    },
  },
};
