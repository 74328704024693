<template>
  <div class="bg-gray-200 flex flex-col rounded w-full h-12">
    <div
      class="cursor-pointer hover-bg-brand-primary_light flex h-12 relative"
      :class="{ 'cursor-wait': loading }"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="handleClick"
    >
      <div
        class="cursor-pointer p-2 flex flex-1 justify-around"
        :class="{ 'cursor-wait': loading, 'mr-2': hasText }"
      >
        <div v-if="imageUrl">
          <api-img
            :src="imageUrl"
            class="inline-block mr-2"
            style="max-width: 30px; max-height: 30px; width: auto; height: auto"
          />
        </div>
        <span class="inline-block flex-1 flex flex-col justify-around text-xs">
          <template v-if="hasText">
            <div
              v-if="loading"
              class="bg-white flex-1 border px-2 w-full align-middle flex justify-center content-center flex-col"
            >
              <span>Processing...</span>
            </div>
            <input
              v-else-if="textValidation"
              ref="text-input"
              :maxlength="textValidation.maxLength > 0 ? textValidation.maxLength : 255"
              type="text"
              :placeholder="textPlaceholder"
              :value="text"
              class="flex-1 border px-2 w-full"
              @change="setText($event.target.value)"
              @click.stop
            />
            <input
              v-else
              ref="text-input"
              type="text"
              :placeholder="textPlaceholder"
              :value="text"
              class="flex-1 border px-2 w-full"
              @change="setText($event.target.value)"
              @click.stop
            />
          </template>
          <slot v-else></slot>
        </span>
      </div>

      <div v-if="!hasText" class="flex flex-col justify-around p-2 ml-4 mr-2">
        <i v-if="isOpen" class="fa fa-caret-up" />
        <i v-if="!isOpen" class="fa fa-caret-down" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imageUrl', 'loading', 'isOpen', 'text', 'textValidation', 'hasText', 'textPlaceholder'],
  data() {
    return {
      hover: false,
    };
  },
  watch: {
    hasText(hasText) {
      if (hasText) {
        this.$nextTick(() => {
          this.$refs['text-input'].focus();
        });
      }
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();

      if (this.loading) {
        return;
      }

      this.$emit(this.isOpen ? 'close' : 'open');
    },
    isValidText(text) {
      const errors = [];

      if (!this.textValidation) {
        return true;
      }

      if (text.length > this.textValidation.maxLength && this.textValidation.maxLength > 0) {
        errors.push(
          `${this.textPlaceholder} must be ${this.textValidation.maxLength} characters or less.`,
        );
      }

      const regex = new RegExp(this.textValidation.regex);

      if (!regex.test(text)) {
        errors.push(this.textValidation.regexError);
      }

      if (errors.length > 0) {
        this.alertBox()
          .fire({
            html: `<ul><li>${errors.join('</li><li>')}</li></ul>`,
          })
          .then(() => {
            this.$refs['text-input'].select();
          });
      }

      return errors.length === 0;
    },
    setText(text) {
      if (this.isValidText(text)) {
        this.$emit('set-text', text);
      }
    },
  },
};
</script>
