<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          :to="`/customer/${$route.params.id}/details`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'details',
            'border-white': active_section !== 'details',
          }"
        >
          Customer Details
        </router-link>
        <router-link
          v-if="!isLeadGen"
          :to="`/customer/${$route.params.id}/sales-dashboard`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'sales-dashboard',
            'border-white': active_section !== 'sales-dashboard',
          }"
        >
          Sales Dashboard
        </router-link>
        <router-link
          :to="`/customer/${$route.params.id}/addresses`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'addresses',
            'border-white': active_section !== 'addresses',
          }"
        >
          Delivery Addresses
        </router-link>
        <router-link
          :to="`/customer/${$route.params.id}/staff`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'staff',
            'border-white': active_section !== 'staff',
          }"
        >
          Staff & Licenses
        </router-link>
        <router-link
          v-if="is_admin_user && !isLeadGen"
          :to="`/customer/${$route.params.id}/vouchers`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'vouchers',
            'border-white': active_section !== 'vouchers',
          }"
        >
          Vouchers
        </router-link>
        <router-link
          v-if="is_admin_user && !isLeadGen"
          :to="`/customer/${$route.params.id}/discounts`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'discounts',
            'border-white': active_section !== 'discounts',
          }"
        >
          Discounts
        </router-link>
        <router-link
          v-if="is_admin_user && !isLeadGen"
          :to="`/customer/${$route.params.id}/pricing`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'pricing',
            'border-white': active_section !== 'pricing',
          }"
        >
          Pricing
        </router-link>
        <router-link
          v-if="is_admin_user && !isLeadGen"
          :to="`/customer/${$route.params.id}/stock-parts`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'stock-parts',
            'border-white': active_section !== 'stock-parts',
          }"
        >
          Stock Parts
        </router-link>
        <router-link
          :to="`/customer/${$route.params.id}/available-products`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'available-products',
            'border-white': active_section !== 'available-products',
          }"
        >
          Products
        </router-link>
        <router-link
          :to="`/customer/${$route.params.id}/processing-options`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': active_section === 'processing-options',
            'border-white': active_section !== 'processing-options',
          }"
        >
          Processing Options
        </router-link>
        <div class="pr-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style.fabricator.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    active_section() {
      const substrSection = this.$route.path
        .substring(this.$route.path.lastIndexOf('/') + 1)
        .replace(this.$route.params.id, '');
      if (substrSection === '') {
        return 'details';
      }
      return substrSection;
    },
  },
};
</script>
