<template>
  <div
    id="customer_index_scrolling_outer_wrapper"
    class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
  >
    <CreateVehicle
      v-if="createVehicleModalOpen"
      :id="vehicleModalModel.id"
      :initial-registration="vehicleModalModel.registration"
      :initial-max-weight="vehicleModalModel.maxWeight"
      :initial-description="vehicleModalModel.description"
      @close="createVehicleModalOpen = false"
      @save="createVehicle"
    />
    <div class="m-3 xs:m-10 mb-0 flex flex-row items-center justify-between">
      <div class="text-2xl font-medium">Manage Vehicles</div>
      <button class="btn-action" @click="openModal">Add Vehicle</button>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table v-if="vehicles.length" class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Registration</th>
            <th>Max Weight</th>
          </tr>
        </thead>
        <tr v-for="vehicle in vehicles" :key="vehicle.id">
          <td>
            <div class="td-title w-24"> #: </div>
            <div class="td-content">{{ vehicle.id }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Description: </div>
            <div class="td-content">{{ vehicle.description }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Registration: </div>
            <div class="td-content">{{ vehicle.registration }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Max Weight: </div>
            <div class="td-content">{{ vehicle.maxWeight }}</div>
          </td>
          <td class="flex xs:td-action-buttons border-b xs:border-b-0">
            <div class="ml-auto flex pb-3 xs:pb-0">
              <div class="ml-auto table-edit-btn" @click="openModal(vehicle.id)">
                <i class="fa fa-pencil"></i>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import CreateVehicle from '@/components/business/rackManager/CreateVehicle.vue';

export default {
  components: {
    CreateVehicle,
  },
  data() {
    return {
      vehicles: [],
      createVehicleModalOpen: false,
      vehicleModalModel: undefined,
    };
  },
  async created() {
    this.loadVehicles();
  },
  methods: {
    async loadVehicles() {
      this.vehicles = await window.touch.getAllVehicles();
    },
    async createVehicle(vehicle) {
      this.createVehicleModalOpen = false;
      if (vehicle?.id) {
        await window.touch.updateVehicle(
          vehicle.id,
          vehicle.description,
          vehicle.registration,
          vehicle.maxWeight,
        );
      } else {
        await window.touch.createVehicle(
          vehicle.description,
          vehicle.registration,
          vehicle.maxWeight,
        );
      }
      await this.loadVehicles();
    },
    openModal(id) {
      this.vehicleModalModel = this.vehicles.find((x) => x.id === id) ?? {};
      this.createVehicleModalOpen = true;
    },
  },
};
</script>
