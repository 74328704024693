export default {
  namespaced: true,
  actions: {
    async latestNews(context, query) {
      return window.touch.latestNews(query);
    },
    async newsItems(context, options) {
      return window.touch.newsItems(
        options.limit,
        options.offset,
        options.categoryId,
        options.search,
      );
    },
    async getNewsItem(context, id) {
      return window.touch.getNewsItem(id);
    },
    async addNewsItem(context, newsItem) {
      return window.touch.addNewsItem(newsItem);
    },
    async updateNewsItem(context, newsItem) {
      return window.touch.updateNewsItem(newsItem);
    },
    async deleteNewsItems(context, newsPostIds) {
      return window.touch.deleteNewsItems(newsPostIds);
    },
    async getNewsCategories() {
      return window.touch.getNewsCategories();
    },
    async addNewsCategory(context, categoryName) {
      return window.touch.addNewsCategory(categoryName);
    },
    async updateNewsCategory(context, category) {
      return window.touch.updateNewsCategory(category);
    },
    async deleteNewsCategories(context, categoryIds) {
      return window.touch.deleteNewsCategories(categoryIds);
    },
  },
};
