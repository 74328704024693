<template>
  <div
    class="h-full hover-bg-brand-primary_light shadow-md bg-white text-center rounded-lg p-4 cursor-pointer"
    :class="{ 'cursor-wait': loading, 'opacity-25': loading }"
    @click="$emit('change-value', { heading: spec.optionHeadingId, value: option.id })"
  >
    <label class="cursor-pointer" :class="{ 'cursor-wait': loading }">
      <div class="cursor-pointer flex" :class="{ 'cursor-wait': loading }">
        <div
          v-if="optionTicked"
          class="rounded-full text-white border p-1 flex flex-col justify-around"
          :class="{
            'bg-brand-primary': optionTicked,
            'bg-gray-200': !optionTicked,
          }"
          style="width: 25px; height: 25px"
        >
          <i v-if="optionTicked" class="fal fa-check"></i>
        </div>
        <div class="flex flex-col justify-around text-xs ml-6 w-full">
          <div class="flex">
            <div class="flex flex-col justify-around">{{ option.description }}</div>
            <div v-if="option.displayColor !== ''" class="flex flex-col justify-around">
              <div
                v-if="option.displayColor !== ''"
                :style="displayColorSwatchStyle(option.displayColor)"
              ></div>
            </div>
            <div
              v-if="inUseCount"
              class="rounded-full w-6 h-6 bg-black ml-auto text-white text-center flex flex-col justify-around text-base"
              :style="imageLegendBackgroundColourStyle(0)"
            >
              {{ inUseCount }}
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: ['loading', 'option', 'spec', 'hideTick', 'inUseCount'],
  data() {
    return {};
  },
  computed: {
    optionTicked() {
      return (
        this.option.isCurrentOption &&
        this.hideTick !== true &&
        !this.$store.getters['designer/itemHasUnansweredForcedQuestions']
      );
    },
  },
  methods: {
    imageLegendBackgroundColourStyle(i) {
      return {
        backgroundColor: this.$store.state.designer.backgroundColourList[i],
      };
    },
    displayColorSwatchStyle(colour) {
      return {
        backgroundColor: `rgb(${colour})`,
        width: '10px',
        height: '10px',
        marginLeft: '20px',
      };
    },
  },
};
</script>
