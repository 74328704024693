<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Registration Requests'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Registration Requests
      </div>
    </template>
    <slot></slot>
    <loading :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-5">
      <div class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full bg-white p-5">
          <thead>
            <tr>
              <th> # </th>
              <th> Company </th>
              <th> Email</th>
              <th> Website </th>
              <th> Phone </th>
              <th>
                <FilterDropdown
                  label="Verification"
                  query-label="verification"
                  :filters="[
                    {id: enums.applicationStatus.PENDING, name: 'Pending', dot: 'bg-blue-500'},
                    {id: enums.applicationStatus.VERIFIED, name: 'Verified', dot: 'bg-green-500'},
                    {id: enums.applicationStatus.ACCEPTED, name: 'Accepted', dot: 'bg-green-500'},
                    {id: enums.applicationStatus.DECLINED, name: 'Declined', dot: 'bg-red-500'},
                  ]"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in customers"
              :key="customer.id"
            >
              <td>
                <div class="td-title w-24"> #: </div>
                <div class="td-content">{{ customer.id }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Company: </div>
                <div class="td-content">{{ customer.companyName }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Email: </div>
                <div class="td-content">{{ customer.email }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Website: </div>
                <div class="td-content">{{ customer.website }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Phone: </div>
                <div class="td-content">{{ customer.phone }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Verification: </div>
                <div class="capitalize">{{ applicationStatus[customer.status][0].toLowerCase() }}</div>
              </td>
              <td>
                <div v-if="customer.status === enums.applicationStatus.VERIFIED" class="grid lg:grid-cols-2 gap-2">
                  <div
                    class="bg-red-500 rounded text-white px-10 py-1 cursor-pointer text-center"
                    @click="processTradeAccountApplication(customer.id, enums.applicationStatus.DECLINED)"
                  >
                    Decline
                  </div>
                  <div
                    class="bg-green-500 rounded text-white px-10 py-1 cursor-pointer text-center"
                    @click="processTradeAccountApplication(customer.id, enums.applicationStatus.ACCEPTED)"
                  >
                    Accept
                  </div>
                </div>
                <div
                  v-else-if="customer.status === enums.applicationStatus.PENDING"
                >
                  <div
                    v-if="resentLoadingId === customer.id"
                    class="bg-blue-500 rounded text-white px-10 py-1 text-center"
                  >
                    Sending
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                  </div>
                  <div
                    v-else-if="resentIds.includes(customer.id)"
                    class="bg-blue-300 rounded text-white px-10 py-1 text-center"
                  >
                    Email Sent
                  </div>
                  <div
                    v-else
                    class="bg-blue-500 rounded text-white px-10 py-1 cursor-pointer text-center w-full"
                    @click="resendVerificationEmail(customer.id)"
                  >
                    Resend Email
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
          <div class="flex justify-between">
            <div>
              No requests to show
            </div>
            <div v-if="$route.query.search || $route.query.verification">
              <a class="btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => {});">
                Clear Filters
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customers.length > 0" class="mt-3">
        <pagination
          :key="JSON.stringify($route.query)"
          :total_items="total_items"
          :limit="limit"
          @load-feed="loadFeed"
        ></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
    FilterDropdown
  },
  data() {
    return {
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      resentIds: [],
      resentLoadingId: undefined,
    };
  },
  computed: {
    applicationStatus() {
      return Object.keys(this.enums.applicationStatus).map((key) => [key, this.enums.applicationStatus[key]]);
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true
    },
  },
  methods: {
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await window.touch.ListTradeAccountApplications({
        Limit: this.limit,
        Offset: offset,
        Status: this.$route.query.verification ? parseInt(this.$route.query.verification) : undefined
      });
      this.customers = response.applications;
      this.total_items = response.totalApplications;
      this.loading = false;
    },
    async processTradeAccountApplication (id, status) {
      await window.touch.ProcessTradeAccountApplication(id, status)
      await this.loadFeed()
    },
    async resendVerificationEmail (id) {
      this.resentLoadingId = id
      await window.touch.ResendVerificationEmail(id)
      this.resentIds.push(id)
      this.resentLoadingId = undefined
    },
  }
};
</script>

<style scoped>

.dot {
  animation: dot linear 0.9s infinite;
}
.dot:nth-of-type(2) {
  animation-delay: 0.3s;
}
.dot:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0% { opacity: 0.25; }
  100% {opacity: 1; }
}

</style>