<template>
  <div>
    <div
      class="flex items-center px-4 py-1 bg-green-500 rounded-lg cursor-pointer"
      @click="showLiveChat()"
    >
      <div class="mr-10">
        <strong class="text-lg text-white">Live Chat</strong>
      </div>
      <div class="ml-auto">
        <i class="text-xl fa-regular fa-comment-dots text-white"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveChatButton',
  data() {
    return {
      liveChatState: 'loading',
    };
  },
  computed: {
    liveChatisOnline() {
      return this.liveChatState === 'online';
    },
  },
  destroyed() {
    window.LiveChatWidget.off('ready', this.readyCallback);
    window.LiveChatWidget.off('availability_changed', this.availabilityChangedCallback);
  },
  mounted() {
    /* eslint-disable */
    if (window.__lc === undefined) {
      window.__lc = window.__lc || {};
      window.__lc.license = 7630051;
      (function (n, t, c) {
        // eslint-disable-next-line no-shadow,no-underscore-dangle,no-use-before-define,no-var,vars-on-top
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on() {
            i(["on", c.call(arguments)]);
          },
          once() {
            i(["once", c.call(arguments)]);
          },
          off() {
            i(["off", c.call(arguments)]);
          },
          get() {
            if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(["get", c.call(arguments)]);
          },
          call() {
            i(["call", c.call(arguments)]);
          },
          init() {
            const n = t.createElement("script");
            (n.async = !0),
              (n.type = "text/javascript"),
              (n.src = "https://cdn.livechatinc.com/tracking.js"),
              t.head.appendChild(n);
          },
        };
        !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
      })(window, document, [].slice);
    }
    /* eslint-enable */

    window.LiveChatWidget.on('ready', this.readyCallback);
    window.LiveChatWidget.on('availability_changed', this.availabilityChangedCallback);
  },
  methods: {
    readyCallback(initialData) {
      this.liveChatState = initialData.state.availability;
    },
    availabilityChangedCallback(data) {
      this.liveChatState = data.availability;
    },
    showLiveChat() {
      window.LiveChatWidget.call('maximize');
    },
  },
};
</script>
