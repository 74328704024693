<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Edit Category" @close="$emit('close')">
      <div class="font-medium">Category Name</div>
      <div>
        <input v-model="category.name" class="form-control" />
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn-danger btn-lg mb-1 2xs:mb-0 2xs:mr-1 xs:w-48" @click="deleteCategory()">
          Delete
        </button>
        <button class="btn-action btn-lg xs:w-48" @click.prevent.stop="updateCategory()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['category'],
  methods: {
    ...mapActions({
      updateDocumentCategory: 'marketing/updateDocumentCategory',
      deleteDocumentCategories: 'marketing/deleteDocumentCategories',
    }),
    async updateCategory() {
      await this.updateDocumentCategory(this.category);
      this.$emit('category-updated');
      this.$emit('close');
    },
    async deleteCategory() {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this category?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteDocumentCategories([this.category.id]);
            if (resp && resp.status === 400) {
              this.alertBox().fire({
                title: 'Category could not be deleted',
                icon: 'error',
                html: 'This Category cannot be deleted as there are still documents assigned to it.',
              });
            } else {
              this.$emit('category-updated');
              this.$emit('close');
            }
          }
        });
    },
  },
};
</script>
