<template>
  <portal v-if="!loading" to="portal_popup">
    <modal-window :modal_open="!loading" title="Connect to Instagram" @close="$emit('close')">
      <div v-if="!instagram_connected" class="">
        Click "Connect to Instagram" and you will be taken to Instagram to log in and grant
        permission for us to display your feed. Once permission has been given your Instagram feed
        will appear on the dashboard. Please ensure both permission requests are ticked or your feed
        cannot be displayed.
      </div>
      <div v-else>
        Your Instagram account is already connected. To remove access, visit
        <a
          class="font-medium underline cursor-pointer"
          href="https://www.instagram.com/accounts/manage_access/"
          target="_blank"
        >https://www.instagram.com/accounts/manage_access/</a>
        and click "Remove" on the Touch App.
      </div>
      <div class="mt-4 xs:mt-8 flex justify-end">
        <div class="mr-1">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
        </div>
        <div v-if="!instagram_connected">
          <button class="btn-action btn-lg" @click.prevent.stop="connect">
            Connect to Instagram
          </button>
        </div>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      instagram_connected: false,
    };
  },
  async mounted() {
    this.checkInstagramAuth({
      fabricatorName: this.$store.getters['style/fabricator_name'],
    })
      .then(() => {
        this.instagram_connected = true;
        this.loading = false;
      })
      .catch(() => {
        this.instagram_connected = false;
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      getOrganisationWebSetting: 'auth/getOrganisationWebSetting',
      updateCompanyWebSettings: 'auth/updateCompanyWebSettings',
      connectToInstagram: 'socialFeeds/connectToInstagram',
      checkInstagramAuth: 'socialFeeds/checkInstagramAuth',
    }),
    connect() {
      this.connectToInstagram({
        fabricatorName: this.$store.getters['style/fabricator_name'],
      });
    },
  },
};
</script>
