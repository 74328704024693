import { render, staticRenderFns } from "./StockPartDetails.vue?vue&type=template&id=533f4d3d&scoped=true"
import script from "./StockPartDetails.vue?vue&type=script&lang=js"
export * from "./StockPartDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533f4d3d",
  null
  
)

export default component.exports