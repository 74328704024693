<template>
  <touch-layout> </touch-layout>
</template>

<script>
export default {
  async mounted() {
    this.$store.dispatch('visualiser/clear');

    await this.$store.dispatch('basket/setCustomerId', this.$route.params.customerId);
    await this.$store.dispatch('basket/createTest');
    await this.$store.dispatch('auth/setProcessingLevel');

    this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/choose-product`);
  },
};
</script>
