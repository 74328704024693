<template>
  <div v-if="newsItems.length > 0" class="bg-white rounded-lg p-5">
    <div>
      <span class="text-lg mr-1">Latest News</span>
      <span class="text-xs">from {{ companyName }}</span>
    </div>
    <div class="border-solid border-t border-gray-500 my-4"></div>
    <div v-for="newsItem in newsItems" :key="newsItem.id" class="mb-5" @click="newsItem.url">
      <router-link :to="newsItem.url">
        <div class="flex gap-5">
          <div :style="{ flex: '1 1' }">
            <api-img :src="newsItem.imageUrl" class="w-full" />
          </div>
          <div :style="{ flex: '2 1' }">
            <div>
              <span class="text-lg" v-html="newsItem.title"></span>
            </div>
            <div class="mt-3">
              <span class="font-thin text-gray-500">{{ formatDate(newsItem.publishAt) }}</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>   
    <div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <router-link to="/news" class="btn-action btn-md"> View All </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';

export default {
  mixins: [dateHelper],
  props: {
    companyName: String,
    newsItems: Array
  },
};
</script>
