function randomChoice(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export default {
  fake_title() {
    return randomChoice(['Mr', 'Mrs', 'Dr', 'Ms']);
  },
  fake_street() {
    return randomChoice([
      'Whitleigh Villas',
      'Lord Loan',
      'Ann Close',
      'Pavement Square',
      'Langton Park',
      'Garth Acres',
      'Chequers Row',
      'Downing Acres',
      'Dawson Mount',
      'Andover Grove',
    ]);
  },
  fake_name() {
    return this.fake_lastname();
  },
  fake_phonenumber() {
    return `01${Math.floor(Math.random() * 999)} ${Math.floor(Math.random() * 499999 + 99999)}`;
  },
  fake_notes() {
    return randomChoice([
      'Skip hire required.',
      'Delivery Charge To Be Added.',
      'Requires survey to finalise design.',
      'Need to colour match to existing bifold; not sure RAL code.',
      'Can only take delivery on weekends.',
      'Please contact on phone after 5pm on weekdays.',
      'Looking for most thermally efficient glass please...',
      'Door is unusually narrow',
      'Can the locks be matched to my existing key?',
    ]);
  },
  fake_lastname() {
    return randomChoice([
      'Trevino',
      'Hamilton',
      'Newman',
      'Henderson',
      'Erickson',
      'Roach',
      'Stuart',
      'Foster',
      'Barker',
      'Knowles',
      'Chambers',
      'Young',
      'Hart',
      'Bond',
      'Davison',
      'Beck',
      'Higgins',
    ]);
  },
  fake_firstname() {
    return randomChoice([
      'Jessica',
      'Brittany',
      'Ashley',
      'Jennifer',
      'Nicole',
      'Elizabeth',
      'Sarah',
      'Amanda',
      'Stephanie',
      'Samantha',
      'James',
      'Joshua',
      'David',
      'Andrew',
      'Daniel',
      'Christopher',
      'Michael',
      'Matthew',
      'Joseph',
      'Ryan',
    ]);
  },
  fake_town() {
    return randomChoice([
      'Eastwood',
      'Bilston',
      'Ilkeston',
      'Hitchin',
      'Cramlington',
      'Wallsend',
      'Camborne',
      'Alston',
      'Guildford',
      'Carterton',
    ]);
  },
  fake_county() {
    return randomChoice([
      'Avon',
      'Bedford',
      'Blackpool',
      'Buckinghamshire',
      'Cambridgeshire',
      'Greater London',
      'Isle of Wight',
      'Kent',
      'Kingston upon Hull',
      'Medway',
      'Merseyside',
      'Middlesbrough',
      'Oxfordshire',
      'Sussex',
      'Swindon',
      'York',
    ]);
  },
  fake_postcode() {
    return randomChoice([
      'SA61 1RZ',
      'PA24 8AP',
      'CH42 1PD',
      'BR4 0DU',
      'GU51 5ED',
      'EH49 7ET',
      'G68 0LS',
      'NG17 5AZ',
      'DL15 0LG',
      'LS9 6BP',
      'PL33 9ER',
    ]);
  },
  fake_address2() {
    return `${Math.floor(Math.random() * 150 + 10)} ${randomChoice([
      'Warwick',
      'Albert',
      'Fairfield',
      'College',
      'Old',
      'Swan',
      'Essex',
      'Castle',
      'Elizabeth',
      'Westfield',
    ])} ${randomChoice(['Street', 'Close', 'Grove', 'Lane', 'Avenue', 'Road'])}`;
  },
  fake_address1() {
    return randomChoice([
      'Ivylands',
      'Orchard View',
      'Brown Bank',
      'Breezy Lodge',
      'South Bank',
      'Ivy House',
      'Willow End',
      'Orchardways',
      'Willowlands',
      "Dog's Lodge",
      'The Old Bakery',
      'Houghton Bank',
    ]);
  },
  fake_reference() {
    return [`${this.fake_title()} ${this.fake_name()}`, this.fake_street(), this.fake_town()].join(
      ', ',
    );
  },
};
