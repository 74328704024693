<template>
  <div class="border border-gray-300 p-6 rounded-lg">
    <div class="xs:text-base mb-3">Product Selection</div>
    <div class="flex flex-col">
      <div
        v-for="stockPartCategory in stockPartCategories"
        :key="stockPartCategory.id"
        :style="`order: ${stockPartCategory.listIndex}`"
        :class="{ 'my-1': openStockPartCategoryId === stockPartCategory.id }"
        class="border mb-3 shadow rounded cursor-pointer"
        @click="openStockPartCategory = stockPartCategory"
      >
        <div class="p-3 text-base w-full">
          {{ stockPartCategory.description }}
        </div>
        <div v-if="openStockPartCategoryId === stockPartCategory.id">
          <div class="flex flex-col p-3 pt-0">
            <div
              v-for="stock_part_subcategory in stockPartSubcategoriesForOpenCategory"
              :key="stock_part_subcategory.id"
              :style="`order: ${stock_part_subcategory.listIndex}`"
              @click="openStockPartSubcategory = stock_part_subcategory"
            >
              <div
                class="mt-1 flex justify-between bg-gray-200 rounded-lg p-2 cursor-pointer group hover:bg-green-600 hover:text-white text-xs sm:text-sm"
                :class="{
                  'bg-green-600 text-white':
                    openStockPartSubcategoryId === stock_part_subcategory.id,
                }"
              >
                <div class="pr-6">{{ stock_part_subcategory.description }}</div>
                <div
                  v-if="openStockPartSubcategoryId === stock_part_subcategory.id"
                  class="flex flex-col justify-around pl-2"
                >
                  <i class="fa fa-caret-right opacity-0 group-hover:opacity-100 opacity-100"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      openStockPartSubcategoryId: 'stockParts/openStockPartSubcategoryId',
      stockPartSubcategoriesForOpenCategory: 'stockParts/stockPartSubcategoriesForOpenCategory',
      openStockPartCategoryId: 'stockParts/openStockPartCategoryId',
      stockPartCategories: 'stockParts/stockPartCategoriesWithSubcategories',
    }),
    openStockPartSubcategory: {
      get() {
        return this.$store.state.stockParts.openStockPartSubcategory;
      },
      set(subcategory) {
        this.$store.commit('stockParts/setOpenStockPartSubcategory', subcategory);
      },
    },
    openStockPartCategory: {
      get() {
        return this.$store.state.stockParts.openStockPartCategory;
      },
      set(category) {
        this.$store.commit('stockParts/setOpenStockPartCategory', category);
      },
    },
  },
  watch: {
    stockPartCategories: {
      handler() {
        this.$store.dispatch('stockParts/selectValidCategory');
      },
      deep: true,
      immediate: true,
    },
    stockPartSubcategoriesForOpenCategory: {
      handler() {
        this.$store.dispatch('stockParts/selectValidSubcategory');
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.selected::before {
  overflow: visible;
  content: "";
  width: 0;
  height: 0;
  right: -1.1em;
  top: -0.375em;
  position: absolute;
  border-top: 1.2rem solid transparent;
  border-bottom: 1.2rem solid transparent;
  border-left: 1.2rem solid #239658;
}

.selected {
  color: white;
  overflow: visible;
  margin-right: -0.3rem;
  position: relative;
  background-color: #239658;
}

.selected span {
  color: white;
}
</style>

<style scoped></style>
