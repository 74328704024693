var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed top-0 z-50 text-white text-center w-full pointer-events-none"},[(_vm.devMode && _vm.showDevPanel)?_c('div',{staticClass:"bg-green-400 cursor-pointer text-white z-50 rounded-b-lg mx-auto pt-0.5 pb-5 px-5 pointer-events-auto flex flex-col items-center text-xs sm:w-4/12 w-full",on:{"click":function($event){_vm.showDevPanel = false}}},[_c('div',{staticClass:"text-base text-xl uppercase"},[_vm._v(" "+_vm._s(_vm.nodeEnv)),(_vm.demo_mode)?_c('span',[_vm._v(" - DEMO MODE")]):_vm._e()]),_vm._m(0),_c('div',{staticClass:"w-full flex justify-between"},[_c('div',[_vm._v("Installation Type:")]),(_vm.touch_portal_installation)?_c('div',[_vm._v("Portal")]):_c('div',[_vm._v("Business")])]),_c('div',{staticClass:"w-full flex justify-between"},[_c('div',[_vm._v("Token Type:")]),(_vm.guest_mode_user)?_c('div',[_vm._v("Unauthenticated")]):(_vm.touch_portal_user && !_vm.branded_mode_user)?_c('div',[_vm._v("Portal")]):(_vm.touch_business_user)?_c('div',[_vm._v("Business")]):(_vm.branded_mode_user)?_c('div',[_vm._v("Branded Mode")]):_vm._e()]),_c('div',{staticClass:"w-full flex justify-between"},[_c('div',[_vm._v("Server URL:")]),_c('div',[_vm._v(_vm._s(_vm.server_url))])])]):_c('div',{staticClass:"px-5 py-0.5 inline-block rounded-b-lg cursor-pointer pointer-events-none",class:{
      'bg-green-400': _vm.vueEnv === 'Local',
      'bg-blue-400': _vm.vueEnv === 'dev',
      'bg-orange-400': _vm.vueEnv === 'test',
      'bg-red-400': _vm.vueEnv === 'staging',
      'pointer-events-auto': _vm.vueEnv === 'Local',
    },on:{"click":function($event){_vm.showDevPanel = true}}},[_c('span',{staticClass:"text-xl uppercase"},[_vm._v(_vm._s(_vm.vueEnv))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex justify-between"},[_c('div',[_vm._v("Screen:")]),_c('div',{staticClass:"3xs:hidden"},[_vm._v("<3XS")]),_c('div',{staticClass:"hidden 3xs:block 2xs:hidden"},[_vm._v("3XS")]),_c('div',{staticClass:"hidden 2xs:block xs:hidden"},[_vm._v("2XS")]),_c('div',{staticClass:"hidden xs:block sm:hidden"},[_vm._v("XS")]),_c('div',{staticClass:"hidden sm:block md:hidden"},[_vm._v("SM")]),_c('div',{staticClass:"hidden md:block lg:hidden"},[_vm._v("MD")]),_c('div',{staticClass:"hidden lg:block xl:hidden"},[_vm._v("LG")]),_c('div',{staticClass:"hidden xl:block"},[_vm._v("XL")])])
}]

export { render, staticRenderFns }