<template>
  <component
    :is="priceTypeComponent[price.type]"
    :customer-id="customerId"
    :dataset-id="datasetId"
    :default-currency="defaultCurrency"
    :price="price"
    :is-new-base-price="isNewBasePrice"
    :hide-customer-override-button="hideCustomerOverrideButton"
    :list-value="listValue"
    :is-price-selectable="isPriceSelectable"
    @price-updated="$emit('price-updated', $event)"
    @price-selected="$emit('price-selected')"
    @show-overrides="$emit('show-overrides', $event)"
  ></component>
</template>

<script>
import List from '@/components/shared/priceLines/List.vue';
import Grid from '@/components/shared/priceLines/Grid.vue';
import SimpleValue from '@/components/shared/priceLines/SimpleValue.vue';

export default {
  components: {
    'price-line-list': List,
    'price-line-grid': Grid,
    'price-line-simple-value': SimpleValue,
  },
  props: [
    'price',
    'listValue',
    'defaultCurrency',
    'datasetId',
    'customerId',
    'isNewBasePrice',
    'hideCustomerOverrideButton',
    'isPriceSelectable',
  ],
  data() {
    return {
      priceTypeComponent: {
        [window.enum.anomalyType.SIMPLE_VALUE]: 'price-line-simple-value',
        [window.enum.anomalyType.LIST]: 'price-line-list',
        [window.enum.anomalyType.GRID]: 'price-line-grid',
      },
    };
  },
};
</script>
