<template>
  <div class="flex justify-between w-full">
    <div class="py-1 px-2 flex flex-col justify-around">
      <div class="text-xs md:text-sm">
        {{ price.description }}
      </div>
    </div>
    <div class="flex p-1 ml-2" style="width: 6.6rem">
      <button class="btn-action w-full" @click="editGrid">Edit</button>
      <portal v-if="showGrid" to="portal_popup">
        <select-grid
          :default-currency="defaultCurrency"
          :customer-id="customerId"
          :dataset-id="datasetId"
          :price="price"
          :is-new-base-price="isNewBasePrice"
          @close="showGrid = false"
        ></select-grid>
      </portal>
    </div>
  </div>
</template>

<script>
import SelectGrid from '@/components/shared/priceLines/List/SelectGrid.vue';

export default {
  components: {
    'select-grid': SelectGrid,
  },
  props: ['price', 'defaultCurrency', 'datasetId', 'customerId', 'isNewBasePrice'],
  data() {
    return {
      showGrid: false,
    };
  },
  methods: {
    editGrid() {
      this.showGrid = true;
    },
  },
};
</script>
