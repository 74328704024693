import Touch from './touch';

Touch.prototype.listRackTypes = async function listRackTypes() {
  return (await this.authenticatedPost('rack/ListTypes')).data;
};

Touch.prototype.createRackType = async function createRackType(name) {
  await this.authenticatedPost('rack/CreateType', {
    Name: name,
  });
};

Touch.prototype.updateRackType = async function updateRackType(id, name) {
  await this.authenticatedPost('rack/UpdateType', {
    Id: id,
    Name: name,
  });
};

Touch.prototype.getRack = async function getRack(Barcode) {
  await this.authenticatedPost('rack/Get', {
    Barcode,
  });
};

Touch.prototype.getRackList = async function getRackList() {
  return this.parseResponse(await this.authenticatedPost('rack/List'));
};
