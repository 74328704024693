<template>
  <touch-layout />
</template>

<script>
import forceReload from '@/mixins/forceReload';

export default {
  mixins: [forceReload],
  computed: {
    url() {
      if (this.touch_business_installation) {
        return `/customer/${this.$route.params.customerId}/sales-dashboard`;
      }
      return '';
    },
  },
  created() {
    this.forceReload();
  },
  async mounted() {
    this.$store.dispatch('visualiser/clear');
    await this.$store.dispatch('basket/setCustomerId', this.$route.params.customerId);
    if (await this.$store.dispatch('basket/createOrder')) {
      await this.$store.dispatch('auth/setProcessingLevel');
      if (this.$store.state.basket.salesSectorType === this.enums.salesSectorType.RETAIL) {
        const orderId = await this.$store.dispatch('basket/saveOrder', false);
        this.routerReplace(`${this.url}/order/${orderId}`);
      } else {
        this.routerReplace(`${this.url}/choose-product`);
      }
    } else {
      this.routerPush('/');
    }
  },
};
</script>
