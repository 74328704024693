import Touch from './touch';

Touch.prototype.getAllVehicles = async function getAllVehicles() {
  return (await this.authenticatedPost('vehicle/List')).data;
};

Touch.prototype.createVehicle = async function createVehicle(description, registration, maxWeight) {
  await this.authenticatedPost('vehicle/Create', {
    Description: description,
    Registration: registration,
    MaxWeight: maxWeight,
  });
};

Touch.prototype.updateVehicle = async function createVehicle(
  id,
  description,
  registration,
  maxWeight,
) {
  await this.authenticatedPost('vehicle/Update', {
    Id: id,
    Registration: registration,
    Description: description,
    MaxWeight: maxWeight,
  });
};
