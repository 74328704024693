export default {
  methods: {
    forceReload() {
      const today = new Date();
      if (this.$store.state.user.lastUpdated) {
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        if (new Date(this.$store.state.user.lastUpdated) < new Date(yesterday)) {
          this.$store.dispatch('user/setLastUpdated', today);
          this.$router.go();
        }
      } else {
        this.$store.dispatch('user/setLastUpdated', today);
        this.$router.go();
      }
    },
  },
};
