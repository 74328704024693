<template>
  <div class="flex flex-col bg-red-200 py-5 px-10 rounded-lg my-3">
    <p>This enquiry was supplied without measurements.</p>
    <p class="mt-2">
      Once you have entered real dimensions for <span class="font-bold">all products</span>, please
      click the button below to remove this warning.
    </p>
    <button class="btn mt-4" @click="markAsMeasured()">Mark as measured</button>
  </div>
</template>

<script>
export default {
  props: ['contractId'],
  methods: {
    async markAsMeasured() {
      await this.$store.dispatch('basket/markAsMeasured');
      return this.$store.dispatch('basket/refresh');
    },
  },
};
</script>
