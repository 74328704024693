var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isWidthByHeight)?_c('tr',[_c('td',{staticClass:"p-1"},[_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",attrs:{"type":"text"},domProps:{"value":_vm.price.minimumHeight},on:{"keyup":function($event){return _vm.updateDimensions('minimumHeight', $event.target.value)}}})]),_c('td',{staticClass:"p-1"},[_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",attrs:{"type":"text"},domProps:{"value":_vm.price.maximumHeight},on:{"keyup":function($event){return _vm.updateDimensions('maximumHeight', $event.target.value)}}})]),_c('td',{staticClass:"p-1"},[_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",attrs:{"type":"text"},domProps:{"value":_vm.price.minimumWidth},on:{"keyup":function($event){return _vm.updateDimensions('minimumWidth', $event.target.value)}}})]),_c('td',{staticClass:"p-1"},[_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",attrs:{"type":"text"},domProps:{"value":_vm.price.maximumWidth},on:{"keyup":function($event){return _vm.updateDimensions('maximumWidth', $event.target.value)}}})]),_c('td',{staticClass:"p-1"},[_c('div',{staticClass:"relative"},[(_vm.position === 'prefix')?_c('div',{staticClass:"h-10 absolute border-r border-gray-300 px-3 flex flex-col justify-around",domProps:{"innerHTML":_vm._s(_vm.icon)}}):_vm._e(),_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",class:{
          'text-gray-400': _vm.value == 0 || _vm.isFOC,
          'pl-10': _vm.position === 'prefix',
          'pr-10': _vm.position === 'suffix',
        },attrs:{"type":"text"},domProps:{"value":_vm.value},on:{"focus":function($event){return $event.target.select()},"keyup":function($event){return _vm.updateValue($event.target.value)}}}),(_vm.position === 'suffix')?_c('div',{staticClass:"h-10 absolute top-0 right-0 border-l border-gray-300 px-3 flex flex-col justify-around",domProps:{"innerHTML":_vm._s(_vm.icon)}}):_vm._e()])]),(_vm.isCustomerPricing)?_c('td',{staticClass:"py-1 px-3"},[_c('div',{on:{"click":function($event){return _vm.updateFOC()}}},[_c('label',[_vm._v("FOC: "),_c('input',{staticClass:"pointer-events-none",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isFOC}})])])]):_vm._e()]):_c('tr',[_c('td',{staticClass:"py-1 px-3"},[_vm._v(" "+_vm._s(_vm.price.description)+" "),(_vm.subtitle)?_c('span',[_vm._v("("+_vm._s(_vm.subtitle)+")")]):_vm._e()]),_c('td',{staticClass:"p-1"},[_c('div',{staticClass:"relative"},[(_vm.position === 'prefix')?_c('div',{staticClass:"h-10 absolute border-r border-gray-300 px-3 flex flex-col justify-around",domProps:{"innerHTML":_vm._s(_vm.icon)}}):_vm._e(),_c('input',{staticClass:"h-10 w-24 p-2 rounded border border-gray-300 focus:text-black",class:{
          'text-gray-400': _vm.value == 0 || _vm.isFOC,
          'pl-10': _vm.position === 'prefix',
          'pr-10': _vm.position === 'suffix',
        },attrs:{"type":"text"},domProps:{"value":_vm.value},on:{"focus":function($event){return $event.target.select()},"keyup":function($event){return _vm.updateValue($event.target.value)}}}),(_vm.position === 'suffix')?_c('div',{staticClass:"h-10 absolute top-0 right-0 border-l border-gray-300 px-3 flex flex-col justify-around",domProps:{"innerHTML":_vm._s(_vm.icon)}}):_vm._e()])]),(_vm.isCustomerPricing)?_c('td',{staticClass:"py-1 px-3"},[_c('div',{on:{"click":function($event){return _vm.updateFOC()}}},[_c('label',[_vm._v("FOC: "),_c('input',{staticClass:"pointer-events-none",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isFOC}})])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }