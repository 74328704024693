<template>
  <div v-if="openStockPartSubcategory" class="border border-gray-300 p-6 rounded-lg">
    <div class="xs:text-base mb-3">
      Stock Part Details - {{ openStockPartSubcategory.description }}
    </div>
    <div v-for="stockPart in itemsGroupedByPart" :key="stockPart[0].partId" class="mb-1">
      <div class="text-center p-2 w-full bg-green-600 text-white rounded-t">
        {{ stockPart[0].part }}
      </div>
      <stock-part-items-for-part :items="stockPart"></stock-part-items-for-part>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StockPartItemsForPart from '@/components/shared/chooseProduct/StockPartItemsForPart.vue';

export default {
  components: {
    'stock-part-items-for-part': StockPartItemsForPart,
  },
  props: ['openSubcategoryId'],
  data() {
    return {
      stockItems: [],
    };
  },
  computed: {
    ...mapState('stockParts', {
      openStockPartSubcategory: 'openStockPartSubcategory',
      searchQuery: 'searchQuery',
    }),
    itemsGroupedByPart() {
      return Object.values(window._.groupBy(this.stockItems, (item) => item.partId));
    },
  },
  watch: {
    openSubcategoryId: {
      async handler() {
        this.stockItems = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
          subCategoryId: this.openSubcategoryId,
        });
      },
      immediate: true,
    },
    async searchQuery(to) {
      if (to === '') {
        this.stockItems = await this.$store.dispatch('stockParts/loadItemsForSubcategory', {
          subCategoryId: this.openSubcategoryId,
        });
      }
    },
  },
};
</script>

<style scoped></style>
