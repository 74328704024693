<template>
  <div>
    <div class="p-5 xs:p-10">
      <div class="bg-white p-5 xs:p-10 border">
        <div class="sm:flex items-center justify-between text-base mb-3">
          <span>
            Select which products you sell from the list below, these will then be shown on your
            {{ setup }} designer
          </span>
          <span class="block mt-2 sm:mt-0">
            <button v-if="!isSelectAll" class="btn whitespace-no-wrap w-full sm:w-auto" @click="selectAll()">Select All</button>
            <button
              v-else
              class="btn whitespace-no-wrap w-full sm:w-auto"
              @click="isSelectAll = false, product_overrides = original_product_overrides, original_product_overrides = JSON.parse(JSON.stringify(product_overrides))"
            >
              Reset
            </button>
            <info-popup
              :id="'vendor_settings_product_details:select_all'"
              class="_pop-out-left"
              :info_message="'If you wish to show all products to your customers click ‘select all’ to quickly activate all products.'"
              :next_id="'vendor_settings_product_details:product'"
            ></info-popup>
          </span>
        </div>
        <div class="flex flex-wrap -mx-3 xs:-mx-6">
          <div
            v-for="(product, index) in product_overrides"
            :key="index"
            class="p-3 xs:p-6 w-full xs:w-1/3 md:w-1/4"
          >
            <label class="flex flex-col items-center p-3 xs:p-5 border border-gray-300 rounded-lg">
              <api-img :src="'/api/image/product/' + product.productId" alt="" class="h-32" />
              <span>{{ product.productName }}</span>
              <div class="flex flex-col">
                <slot name="product_mode" :product_mode_data="product"></slot>
                <slot name="show_products" :show_products_data="product"></slot>
                <div class="flex flex-col justify-around">
                  <info-popup
                    v-show="index === 1"
                    :id="'vendor_settings_product_details:product'"
                    class=""
                    :info_message="'Simply select the check boxes to activate and de-activate a product. These can be changed at anytime to always stay up to date with your range. Remember to save after you make a change.'"
                    :next_id="'vendor_settings_product_details:select_all'"
                  ></info-popup>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="is_admin_user"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t flex items-center"
    >
      <div class="text-red-600 flex flex-col justify-around text-center xs:text-left">
        <span v-show="!isUnChanged"> You have unsaved changes. </span>
      </div>
      <div class="ml-auto">
        <div
          v-if="!demo_mode"
          class=" mt-2 xs:mt-0"
          :class="{'pointer-events-none opacity-50': loading || isUnChanged}"
        >
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            Save all Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['product_overrides', 'setup'],
  data() {
    return {
      original_product_overrides: [],
      loading: false,
      isSelectAll: false
    };
  },
  computed: {
    isUnChanged () {
      return JSON.stringify(this.product_overrides) === JSON.stringify(this.original_product_overrides)
    }
  },
  async mounted() {
    this.loadProductOverrides();
  },
  methods: {
    ...mapActions({
      customerUpdateProductOverrides: 'user/customerUpdateProductOverrides',
    }),
    updateProductMode(product, { customerEnabled, customerEnabledRetail }) {
      // eslint-disable-next-line no-param-reassign
      product.customerEnabled = customerEnabled;
      // eslint-disable-next-line no-param-reassign
      product.customerEnabledRetail = customerEnabledRetail;
    },
    async loadProductOverrides() {
      this.original_product_overrides = JSON.parse(JSON.stringify(this.product_overrides));
    },
    async saveSettings() {
      this.loading = true;
      this.$emit('enable');

      if (!this.validateInput()) {
        this.alertBox().fire({
          title: 'You must leave at least 1 product enabled',
          icon: 'error',
        });
        this.loading = false;
        return;
      }

      await this.customerUpdateProductOverrides(this.product_overrides);

      this.loadProductOverrides();

      this.loading = false;
    },
    validateInput() {
      const customerEnabledList = this.product_overrides.map(
        ({ customerEnabled }) => customerEnabled,
      );
      return customerEnabledList.includes(true);
    },
    selectAll() {
      this.isSelectAll = true
      this.product_overrides.forEach((productOverride) => {
        // eslint-disable-next-line no-param-reassign
        productOverride.customerEnabled = true;
        // eslint-disable-next-line no-param-reassign
        productOverride.customerEnabledRetail = true;
      });
    },
  },
};
</script>
