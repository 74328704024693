<template>
  <div class="bg-white border m-3 xs:m-10 p-3">
    <portal v-if="editing_terms_file" to="portal_popup">
      <modal-window
        :modal_open="editing_terms_file"
        :loading="loading || saving_file"
        title="Terms & Conditions"

        @close="(editing_terms_file = false), (terms_link = null)"
      >
        <div v-if="termsAndConditionsDocument !== undefined" class="mb-2">
          <a
            class="btn-action"
            target="_blank"
            :href="termsAndConditionsDocument.url"
          >View Uploaded File</a>
        </div>
        <div class="mb-2">Enter a new link for your terms and conditions here:</div>
        <input v-model="terms_link" type="text" class="form-control" />
        <label class="block mt-2 text-green-600 hover:text-green-800 cursor-pointer">
          ... or click here to upload a new file.
          <input
            id="terms_file"
            type="file"
            accept="application/pdf"
            class="form-control hidden"
            @change="newTermsUpload"
          />
        </label>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click="(editing_terms_file = false), (terms_link = null)"
          >
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveNewTermsLink">Save</button>
        </div>
      </modal-window>
    </portal>
    <portal v-if="editing_privacy_file" to="portal_popup">
      <modal-window
        :modal_open="editing_privacy_file"
        :loading="loading || saving_file"
        title="Privacy Policy"
        @close="(editing_privacy_file = false), (privacy_policy_link = null)"
      >
        <div v-if="privacyPolicyDocument !== undefined" class="mb-2">
          <a
            class="btn-action"
            target="_blank"
            :href="privacyPolicyDocument.url"
          >View Uploaded File</a>
        </div>
        <div class="mb-2">Enter a new link for your privacy policy here:</div>
        <input v-model="privacy_policy_link" type="text" class="form-control" />
        <label class="block mt-2 text-green-600 hover:text-green-800 cursor-pointer">
          ... or click here to upload a new file.
          <input
            id="privacy_policy_file"
            type="file"
            accept="application/pdf"
            class="form-control hidden"
            @change="newPrivacyPolicyUpload"
          />
        </label>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click="(editing_privacy_file = false), (privacy_policy_link = null)"
          >
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveNewPrivacyPolicyLink">
            Save
          </button>
        </div>
      </modal-window>
    </portal>
    <table class="table-lined xs:table-unstacked _with-hover w-full">
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ _hovered: hovered === 'terms_file' }"
          @click.stop.prevent="editing_terms_file = true"
          @mouseover="hovered = 'terms_file'"
          @mouseleave="hovered = null"
        >
          <td>
            <div class="td-title w-24">Name:</div>
            <div class="td-content">Terms and Conditions</div>
          </td>
          <td>
            <div class="td-title w-24">Status:</div>
            <div class="td-content">
              <template v-if="termsAndConditionsDocument !== undefined"> Uploaded </template>
              <template v-else> Please Upload </template>
            </div>
          </td>
          <td class="td-action-buttons">
            <div class="table-edit-btn">
              <i class="fa fa-pencil"></i>
            </div>
          </td>
        </tr>
        <tr
          :class="{ _hovered: hovered === 'privacy_file' }"
          @click.stop.prevent="editing_privacy_file = true"
          @mouseover="hovered = 'privacy_file'"
          @mouseleave="hovered = null"
        >
          <td>
            <div class="td-title w-24">Name:</div>
            <div class="td-content">Privacy Policy</div>
          </td>
          <td>
            <div class="td-title w-24">Status:</div>
            <div class="td-content">
              <template v-if="privacyPolicyDocument !== undefined"> Uploaded </template>
              <template v-else> Please Upload </template>
            </div>
          </td>
          <td class="td-action-buttons">
            <div class="table-edit-btn">
              <i class="fa fa-pencil"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['customer'],
  data() {
    return {
      privacyPolicyDocument: false,
      termsAndConditionsDocument: false,
      saving_file: false,
      editing_terms_file: false,
      editing_privacy_file: false,
      terms_link: null,
      privacy_policy_link: null,
      hovered: null,
    };
  },
  async mounted() {
    this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
    this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getTermsAndConditionsDocument: 'user/getTermsAndConditionsDocument',
      getPrivacyPolicyDocument: 'user/getPrivacyPolicyDocument',
      uploadDocumentForm: 'marketing/uploadDocumentForm',
      customerUploadDocumentWithUrl: 'user/customerUploadDocumentWithUrl',
    }),
    async newPrivacyPolicyUpload(event) {
      this.saving_file = true;
      if (event.target.files[0].name.split('.').pop().toLowerCase() !== 'pdf') {
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          html: 'Please choose a PDF',
        });
        document.getElementById('privacy_policy_file').value = '';
        this.saving_file = false;
        return;
      }
      const file = document.getElementById('privacy_policy_file').files[0]; // eslint-disable-line prefer-destructuring
      const formData = new FormData();
      formData.append('Type', '2');
      formData.append('Name', 'privacy_policy.pdf');
      formData.append('File', file);
      formData.append('OverwriteCurrentSingleDocument', JSON.stringify(true));
      await this.uploadDocumentForm(formData);
      this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
      this.editing_privacy_file = false;
      this.saving_file = false;
      this.privacy_policy_link = null;
      this.alertBox().fire('Privacy Policy file saved');
    },
    async newTermsUpload(event) {
      this.saving_file = true;
      if (event.target.files[0].name.split('.').pop().toLowerCase() !== 'pdf') {
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          html: 'Please choose a PDF',
        });
        document.getElementById('terms_file').value = '';
        this.saving_file = false;
        return;
      }
      const file = document.getElementById('terms_file').files[0]; // eslint-disable-line prefer-destructuring
      const formData = new FormData();
      formData.append('Type', '1');
      formData.append('Name', 'terms_and_conditions.pdf');
      formData.append('File', file);
      formData.append('OverwriteCurrentSingleDocument', JSON.stringify(true));
      await this.uploadDocumentForm(formData);
      this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
      this.editing_terms_file = false;
      this.terms_link = null;
      this.saving_file = false;
      this.alertBox().fire('Terms and Conditions document saved');
    },
    async saveNewTermsLink() {
      this.loading = true;

      if (!this.terms_link.includes('//')) {
        this.terms_link = `http://${this.terms_link}`;
      }

      const document = {
        name: 'Terms and Conditions',
        type: 1,
        url: this.terms_link,
      };
      await this.customerUploadDocumentWithUrl(document);
      this.termsAndConditionsDocument = await this.getTermsAndConditionsDocument();
      this.editing_terms_file = false;
      this.loading = false;
      this.alertBox().fire('Terms and Conditions link saved');
      this.terms_link = null;
    },
    async saveNewPrivacyPolicyLink() {
      this.loading = true;

      if (!this.privacy_policy_link.includes('//')) {
        this.privacy_policy_link = `http://${this.privacy_policy_link}`;
      }

      const document = {
        name: 'Privacy Policy',
        type: 2,
        url: this.privacy_policy_link,
      };
      await this.customerUploadDocumentWithUrl(document);
      this.privacyPolicyDocument = await this.getPrivacyPolicyDocument();
      this.editing_privacy_file = false;
      this.loading = false;
      this.alertBox().fire('Privacy Policy link saved');
      this.privacy_policy_link = null;
    },
  },
};
</script>
