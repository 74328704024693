<template>
  <canvas id="Canvas3D" class="canvas3D" @wheel.prevent></canvas>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Show3d',
  props: ['contractId', 'jobKey', 'itemKey', 'trigger'],
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ...mapState('basket', {
      imageType: 'imageType',
    }),
  },
  watch: {
    trigger() {
      this.loadModel();
    },
    imageType() {
      window.rotateScene(window.babylonScene, window.babylonCamera);
    },
    model(is) {
      // eslint-disable-next-line camelcase
      if (window.touchFenestrationThreeD_BabylonEngine != null) {
        window.touchFenestrationThreeD_BabylonEngine.dispose();
      }
      const data1 = this.imageType === this.enums.imageType.INTERNAL ? 'inside' : 'outside';
      // eslint-disable-next-line camelcase
      const touchFenestrationThreeDBabylonEngine = window.touchFenestrationThreeD_RenderScene(
        'Canvas3D',
        is,
        data1,
      );
      window.touchFenestrationThreeD_BabylonEngine =
        touchFenestrationThreeDBabylonEngine.babylonEngine;
      window.babylonScene = touchFenestrationThreeDBabylonEngine.babylonScene;
      window.babylonCamera = touchFenestrationThreeDBabylonEngine.babylonCamera;
    },
  },
  mounted() {
    this.loadModel();
  },
  destroyed() {
    window.touchFenestrationThreeD_cleanUp();
  },
  methods: {
    async loadModel() {
      this.model = await this.$store.dispatch('touch/get3DModel', {
        contractId: this.contractId,
        customerId: this.$store.state.basket.customerId,
        jobKey: this.jobKey,
        itemKey: this.itemKey,
      });
    },
  },
};
</script>

<style>
#canvas3D {
  width: 100%;
  height: 100%;
  background-color: #f6f6f4;
}
</style>
