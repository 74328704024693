export default (initialState, ignoreList) => {
  const persistedStates = [];
  Object.keys(initialState).forEach((key) => {
    persistedStates.push(key);
  });
  for (let i = 0; i < ignoreList.length; i += 1) {
    const module = ignoreList[i].split('.')[0];
    const state = ignoreList[i].split('.')[1];
    if (persistedStates.includes(module)) {
      const index = persistedStates.indexOf(module);
      persistedStates.splice(index, 1);
      Object.keys(initialState[module]).forEach((key) => {
        if (key !== state) {
          persistedStates.push(`${module}.${key}`);
        }
      });
    } else if (persistedStates.includes(ignoreList[i])) {
      const index = persistedStates.indexOf(ignoreList[i]);
      persistedStates.splice(index, 1);
    }
  }
  return persistedStates;
};
