import Touch from './touch';

Touch.prototype.connectToInstagram = async function connectToInstagram(fabricatorName) {
  const touchAdminUrl = window.location.origin;
  window.location.href = `https://feeds.bm-touch.co.uk/instagram-authorise?touch_id=${fabricatorName}&touch_admin_url=${touchAdminUrl}`;
};

Touch.prototype.getInstagramFeed = async function getInstagramFeed(fabricatorName) {
  return this.axios.post(
    'https://feeds.bm-touch.co.uk/instagram-feed',
    {
      touch_id: fabricatorName,
    },
    {
      validateStatus(status) {
        return status >= 200 && status < 500; // Resolve only if the status code is less than 500
      },
    },
  );
};

Touch.prototype.checkInstagramAuth = async function checkInstagramAuth(fabricatorName) {
  return this.axios.post(
    'https://feeds.bm-touch.co.uk/instagram-feed',
    {
      touch_id: fabricatorName,
    },
    {
      validateStatus(status) {
        return status >= 200 && status < 500; // Resolve only if the status code is less than 500
      },
    },
  );
};
