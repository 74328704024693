export default {
  data() {
    return {
      timeout: undefined,
      isTimeOut: true,
    };
  },
  methods: {
    async pollContractStates(contractType, contracts) {
      contracts.sort((a, b) => {
        if (!a.date && !b.date) {
          return 0;
        }
        return new Date(b.date) - new Date(a.date)
      });
      const contractsInSavingState = contracts.filter(
        (x) => x.saveState === this.enums.orderStatusSaveStates.STARTED,
      );
      if (contractsInSavingState.length > 0) {
        const contractIds = contractsInSavingState.map((x) => x.id);
        const { contracts: updatedContracts } = await window.touch.ListSaveStates({
          type: contractType,
          OnlyTheseContracts: contractIds,
        });
        const contractsInCompleteState = updatedContracts.filter(
          (x) => x.saveState === this.enums.orderStatusSaveStates.COMPLETED,
        );
        if (contractsInCompleteState.length > 0) {
          for (let i = 0; i < contractsInCompleteState.length; i++) {
            // eslint-disable-line
            const index = contracts.findIndex((x) => x.id === contractsInCompleteState[i].id);
            const contract = contracts[index];
            Object.keys(contract).forEach((key) => {
              if (key in contractsInCompleteState[i]) {
                contract[key] = contractsInCompleteState[i][key];
              }
            });
            this.$set(contracts, index, contract);
          }
        }
        if (
          updatedContracts.filter((x) => x.saveState === this.enums.orderStatusSaveStates.STARTED)
            .length > 0 &&
          this.isTimeOut
        ) {
          this.timeout = setTimeout(() => this.pollContractStates(contractType, contracts), 2000);
        } else {
          clearTimeout(this.timeout);
        }
      }
    },
  },
  beforeDestroy() {
    this.isTimeOut = false;
    clearTimeout(this.timeout);
  },
};
