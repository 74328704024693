<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Extras'],
        ]"
      />
    </template>
    <template #header_page_title> Extras </template>
    <Menu></Menu>
    <div class="flex flex-col flex-grow bg-gray-200 w-full h-full">
      <Extras />
    </div>
  </touch-layout>
</template>

<script>

import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Extras from '@/components/business/manageTouchAccount/Extras.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
    Extras,
  },
};
</script>
