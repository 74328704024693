var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('i',{staticClass:"cursor-pointer fa-regular fa-info-circle align-middle block",class:[_vm.iconSize ? _vm.iconSize : 'text-xl'],on:{"mouseenter":function($event){_vm.isToolTip = true},"mouseleave":function($event){_vm.isToolTip = false}}}),(_vm.isToolTip)?_c('div',{staticClass:"text-white p-3 rounded tooltip bg-black absolute transform -translate-y-1/2",class:[_vm.isLeft ? 'mr-2' : 'ml-2'],style:({
      'width': _vm.width ? _vm.width + 'px' : 'auto', 
      'z-index': '999', 
      'left': _vm.isLeft ? 'auto' : '100%', 
      'right': _vm.isLeft ? '100%' : 'auto', 
      'top': '50%', 
      'min-width': _vm.width ? 'unset' : 'max-content'})},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }