<template>
  <div>
    <label class="mt-2 flex items-center">
      <input
        v-model="customerEnabledRetailFlag"
        class="mr-2"
        :disabled="demo_mode"
        type="checkbox"
      />
      <span>Show on Branded Mode</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ProductMode',
  props: ['customerEnabled', 'customerEnabledRetail'],
  computed: {
    customerEnabledFlag: {
      get() {
        return this.customerEnabled;
      },
      set(mode) {
        this.$emit('update-customer-enabled', mode);
      },
    },
    customerEnabledRetailFlag: {
      get() {
        return this.customerEnabledRetail;
      },
      set(mode) {
        this.$emit('update-customer-enabled-retail', mode);
      },
    },
  },
};
</script>
