<template>
  <touch-layout class="h-full">
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Stock Parts'],
        ]"
      />
    </template>
    <template #header_page_title>
      Stock Parts
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for an code/description..." />
    </portal>
    <template #header_page_actions>
      <strong
        v-if="search.length < minimumQueryLength && search.length > 0"
        class="absolute text-red-400 mr-2"
        :style="{ right: '3.25rem' }"
      >
        Minimum {{ minimumQueryLength }} characters.
      </strong>
    </template>
    <Menu></Menu>
    <StockParts :query="search" :minimum-query-length="minimumQueryLength"></StockParts>
  </touch-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import StockParts from '@/components/shared/StockParts.vue';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    StockParts,
    Menu,
    Breadcrumbs,
    SearchBox,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('stockParts', {
      minimumQueryLength: 'minimumQueryLength',
    }),
    search() {
      return this.$route.query.search || '';
    },
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => {});
    setTimeout(() => {
      this.$watch('$route.query', this.setQuery);
    }, 0);
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    setQuery() {
      this.$store.dispatch('stockParts/runItemQuery', this.search);
      if (this.search.length < this.minimumQueryLength) {
        this.$store.commit('stockParts/setSearchQuery', '');
        this.$store.commit('stockParts/setQueriedStockParts', {
          query: this.search,
          results: [],
        });
      }
    },
  },
};
</script>
