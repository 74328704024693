<template>
  <div>Loading...</div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('reset', ['style', 'feature']);
    this.routerPush(this.$store.state.auth.login_url);
  },
};
</script>
