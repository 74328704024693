var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'Customers',
          'border-white': _vm.$route.name !== 'Customers',
        },attrs:{"to":`/customers/portal`}},[_vm._v(" Portal Customers ")]),(_vm.is_admin_user)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'Registration Requests',
          'border-white': _vm.$route.name !== 'Registration Requests',
        },attrs:{"to":`/customers/registration-requests`}},[_vm._v(" Registration Requests ")]):_vm._e(),(_vm.is_admin_user)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.name === 'Branded Mode Customers',
          'border-white': _vm.$route.name !== 'Branded Mode Customers',
        },attrs:{"to":`/customers/branded-mode`}},[_vm._v(" Branded Mode Subscriptions ")]):_vm._e(),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }