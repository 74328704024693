<template>
  <router-view></router-view>
</template>

<script>

export default {
   async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.auth.admin) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Unauthorised Access',
          html: 'Redirected user to home page.',
        });
        next('/');
      }
    });
  },
};

</script>
