export const errorCallback = (error) => {
  let alertBoxObject = {}
  if ([400, 403].includes(error.status)) {
    let message = '';
    if (typeof error.data === 'string') {
      message += `${error.data}<br>`;
    }
    if (error.data.title) {
      message += `${error.data.title}<br>`;
    }
    if (error.data.message) {
      message += `${error.data.message}<br>`;
    }
    if (error.data.issues) {
      message += error.data.issues.join('<br>');
    }
    if (message) {
      const title = message.split('<br>')[0];
      const html = message.split('<br>').slice(1).join('<br>');
      alertBoxObject = {
        title,
        html,
      };
    }
  } else if (error.status === 401) {
    alertBoxObject ={
      title: 'Unauthorised Access',
      html: 'Redirected user.',
    }
  }
  setTimeout(() => {
    if (window.alertBox.isVisible()) {
      window.alertBox.update(alertBoxObject)  
    } else {
      window.alertBox.fire(alertBoxObject)  
    } 
  }, 300) // How long sweetalert animations are
  return
};

export const successCallback = (operation, response) => {
  setTimeout(() => {
    if (!window.alertBox.isVisible()) {
      window.alertBox.fire({
        title: `${operation} Successful`,
        icon: 'success',
      });
    }
  }, 300) // How long sweetalert animations are
  return response
};
