import Touch from './touch';

Touch.prototype.loadKPIDashboardActiveUsers = async function loadKPIDashboardActiveUsers(range) {
  const { data } = await this.authenticatedPost('kpi/ActiveUsers', {
    Range: Number(range),
    GetTotal: true,
  });
  return data;
};

Touch.prototype.loadKPIDashboardQuotes = async function loadKPIDashboardQuotes(range) {
  const { data } = await this.authenticatedPost('kpi/NumberOfContracts', {
    Range: Number(range),
    Type: Number(window.touch.CONTRACT_TYPE_QUOTE),
  });
  return data;
};

Touch.prototype.loadKPIDashboardOrders = async function loadKPIDashboardOrders(range) {
  const { data } = await this.authenticatedPost('kpi/NumberOfContracts', {
    Range: Number(range),
    Type: Number(window.touch.CONTRACT_TYPE_ORDER),
  });
  return data;
};

Touch.prototype.loadKPITopCustomersForQuotes = async function loadKPITopCustomersForQuotes(range) {
  const { data } = await this.authenticatedPost('kpi/TopCustomersForContracts', {
    Range: Number(range),
    Type: Number(window.touch.CONTRACT_TYPE_QUOTE),
    TopN: 8,
  });
  return data;
};

Touch.prototype.loadKPITopCustomersForOrders = async function loadKPITopCustomersForOrders(range) {
  const { data } = await this.authenticatedPost('kpi/TopCustomersForContracts', {
    Range: Number(range),
    Type: Number(window.touch.CONTRACT_TYPE_ORDER),
    TopN: 8,
  });
  return data;
};

Touch.prototype.loadKPIConversionsFromEnquiriesToQuotes =
  async function loadKPIConversionsFromEnquiriesToQuotes(range) {
    const { data } = await this.authenticatedPost('kpi/NumberOfContractConversions', {
      Range: Number(range),
      Type: Number(1),
    });
    return data;
  };

Touch.prototype.loadKPIConversionsFromQuotesToOrders =
  async function loadKPIConversionsFromQuotesToOrders(range) {
    const { data } = await this.authenticatedPost('kpi/NumberOfContractConversions', {
      Range: Number(range),
      Type: Number(2),
    });
    return data;
  };
