import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

const splitHostname = window.location.hostname.split('.');
const subdomain =
  splitHostname[0] === 'local' ? process.env.VUE_APP_OVERRIDE_SLUG : splitHostname[0];
let product = splitHostname[1] || process.env.VUE_APP_INSTALLATION_TYPE;

if (product === 'admin') {
  product = 'business';
}

export default new VueI18n({
  locale: `${product}-${subdomain}`,
  fallbackLocale: [subdomain, product, 'en'],
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
});
