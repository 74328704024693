<template>
  <div class="h-full">
    <create-staff v-if="add_staff_popup_open" :customer-id="customer.customerId" @user-saved="loadCustomer" @close="add_staff_popup_open = false"></create-staff>
    <loading id="customer_index_scrolling_outer_wrapper" :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch min-h-full">
      <div class="bg-white border m-3 md:m-10 p-3">
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th colspan="2"> Name </th>
              <th> Email </th>
              <th> Mobile </th>
              <th> Admin </th>
              <th>
                <FilterDropdown
                  label="License"
                  query-label="licenseFilter"
                  :filters="[
                    {id: enums.licenseStatusType.DISABLED, name: 'Disabled', dot: 'bg-red-500'},
                    {id: enums.licenseStatusType.ENABLED, name: 'Enabled', dot: 'bg-green-500'},
                  ]"
                />
              </th>
              <th>
                <FilterDropdown
                  label="Status"
                  query-label="statusFilter"
                  :filters="[
                    {id: enums.customerStatus.LIVE, name: 'Live', dot: 'bg-green-500'},
                    {id: enums.customerStatus.ARCHIVED, name: 'Archived', dot: 'bg-red-500'},
                  ]"
                />
              </th>
              <th v-if="loggedInUser.isAdmin"> </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in filteredUsers"
              :key="index"
              :class="{ '_hovered' : hovered === index }"
              @click.stop.prevent="editStaff(user.id)"
              @mouseover="hovered = index"
              @mouseleave="hovered = null"
            >
              <td style="width: 60px;">
                <div class="rounded-full border flex flex-col justify-around overflow-hidden relative" style="width: 35px; height: 35px;">
                  <img :id="'userImg_'+user.id" :src="staffImageUrl(user.image)" class="m-auto" @error="imgError" @load="positionAvatar" />
                </div>
              </td>
              <td>
                <div class="td-title w-24"> Name: </div>
                <div class="td-content">{{ user.firstName }} {{ user.lastName }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Email: </div>
                <div class="td-content break-all">{{ user.email }}</div>
              </td>
              <td>
                <div v-if="user.mobile" class="td-title w-24"> Mobile: </div>
                <div class="td-content">{{ user.mobile }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Admin: </div>
                <div class="td-content">
                  <template v-if="user.isAdmin">
                    Yes
                  </template>
                  <template v-else>
                    No
                  </template>
                </div>
              </td>
              <td>
                <div class="td-title w-24"> License: </div>
                <div class="td-content text-center">
                  <div v-if="user.licenseStatusType === enums.licenseStatusType.ENABLED" class="bg-green-500 rounded text-white px-2 py-1">
                    Enabled
                  </div>
                  <div v-else class="bg-red-500 rounded text-white px-2 py-1">
                    Disabled
                  </div>
                </div>
              </td>
              <td>
                <div class="td-title w-24"> Status: </div>
                <div class="td-content text-center">
                  <div v-if="user.archived" class="bg-red-500 rounded text-white px-2 py-1">
                    Archived
                  </div>
                  <div v-else class="bg-green-500 rounded text-white px-2 py-1">
                    Live
                  </div>
                </div>
              </td>
              <td class="td-action-buttons">
                <div v-if="loggedInUser.isAdmin" class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
                <edit-staff
                  v-if="editing_user === user.id"
                  :branches="branches"
                  :user-id="user.id"
                  :customer-id="customer.customerId"
                  :logged-in-user="loggedInUser"
                  @close="editing_user = null"
                  @user-saved="loadCustomer"
                >
                </edit-staff>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="filteredUsers.length === 0" key="none-found" class="xs:m-10 p-3">
          <div class="flex justify-between">
            <div>
              No Staff to show
            </div>
            <a v-if="$route.query.statusFilter || $route.query.licenseFilter" class="ml-auto btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => {});">
              Clear Filters
            </a>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditStaff from '@/components/shared/staffAndLicenses/Edit.vue';
import CreateStaff from '@/components/shared/staffAndLicenses/Create.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    EditStaff,
    CreateStaff,
    FilterDropdown
  },
  props: {
    branches: Array,
  },
  data() {
    return {
      loggedInUser: {
        id: 0,
        isAdmin: false,
      },
      customer: {
        users: [],
        company: {},
        customer: {},
        financial: {},
      },
      loading: true,
      hovered: null,
      editing_user: null,
      add_staff_popup_open: false,
    };
  },
  computed: {
    filteredUsers() {
      const isLive = this.enums.customerStatus.LIVE === Number(this.$route.query.statusFilter);
      const filterArchived = (archived, statusFilter) => !statusFilter || (archived && !isLive || !archived && isLive)
      const filterLicenseStatus = (licenseStatusType, licenseFilter) => !licenseFilter || licenseStatusType === Number(licenseFilter)
      return this.customer.users.filter(user => 
        filterArchived(user.archived, this.$route.query.statusFilter) && 
        filterLicenseStatus(user.licenseStatusType, this.$route.query.licenseFilter)
      );
    },
  },
  async mounted() {
    await this.loadCustomer();
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
      customerUpdate: 'user/customerUpdate',
    }),
    ...mapGetters({
      user: 'auth/user',
    }),
    async loadCustomer() {
      this.loading = true;
      this.customer = await this.customerGet(this.$route.params.id);
      this.customer.users = this.customer.users.map(x => ({
        ...x,
        full_name: x.firstName + ' ' +  x.lastName
      }))
      this.customer.users.sort((a, b) =>
        a.full_name.localeCompare(b.full_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      )
      this.customer.newLogo = null;
      if (this.$store.state.auth.loggedIn) {
        this.loggedInUser.id = this.$store.state.auth.id;
        this.loggedInUser.isAdmin = this.$store.state.auth.admin;
        this.$emit('setIsAdmin', this.loggedInUser.isAdmin);
      }
      this.loading = false;
    },
    staffImageUrl(image) {
      return window.touch.imageBaseURL + image;
    },
    imgError(event) {
      const element = document.getElementById(event.target.id);
      element.src = '/images/blank-user.png';
    },
    editStaff(userId) {
      if (this.loggedInUser.isAdmin) {
        this.editing_user = userId;
      }
    },
  },
};
</script>