<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Account Settings" @close="$emit('close')">
      <div class="flex flex-col container">
        <div class="flex gap-5 pb-10">
          <div class="flex-1">
            <div class="flex items-center font-medium gap-2">
              Approve Orders with Warnings
              <Tooltip class="hidden xs:inline" :is-left="false" :width="200">
                Users need to approve orders containing items with warning-level issues
              </Tooltip>
            </div>
            <div class="flex items-center cursor-pointer" @click="showIssuePresentWarning = !showIssuePresentWarning">
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ '_active': showIssuePresentWarning }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
                <span v-text="showIssuePresentWarning ? 'On' : 'Off'"></span>
              </div>
            </div>
          </div>
          <div v-if="showIssuePresentWarning" class="flex-1">
            <textarea
              v-model="issuePresentWarning"
              placeholder="Text to be displayed to obtain approval of orders with items having warning-level issues present"
              class="form-control resize-none"
              rows="15"
            ></textarea>
          </div>
          <!-- <div class="flex-1">
            <div class="flex items-center cursor-pointer mt-2" @click.prevent="accountSettings.emailOff = !accountSettings.emailOff">
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ '_active': !$store.state.auth.accountSettings.emailOff }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
                Email <span v-text="$store.state.auth.accountSettings.emailOff ? 'Off' : 'On'"></span>
              </div>
            </div>
            <span>Setting to enable emails to be sent.</span>
          </div>
          <div class="flex-1">
            <div class="flex items-center cursor-pointer mt-2" @click.prevent="accountSettings.showFitting = !accountSettings.showFitting">
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ '_active': $store.state.auth.accountSettings.showFitting }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
                Extras <span v-text="$store.state.auth.accountSettings.showFitting ? 'On' : 'Off'"></span>
              </div>
            </div>
            <span>Setting to display extras.</span>
          </div> -->
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="save">
            Save Changes
          </button>
        </div>
      </div>
    </modal-window>
  </portal>
</template>

<script>

import Tooltip from '@/components/shared/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    warningSettings: Object
  },
  data() {
    return {
      accountSettings: this.$store.state.auth.accountSettings,
      showIssuePresentWarning: this.warningSettings.showIssuePresentWarning,
      issuePresentWarning: this.warningSettings.issuePresentWarning
    };
  },
  methods: {
    async save() {
      if (this.showIssuePresentWarning && !this.issuePresentWarning) {
        this.$emit('isLoading', false);
        this.alertBox().fire({
          title: 'Validation Errors',
          text: 'Disclaimer Text Required.',
          icon: 'error',
        });
        return;
      }
      await window.touch.UpdateCompanyIssuePresent({ ShowIssuePresentWarning: this.showIssuePresentWarning, IssuePresentWarning: this.issuePresentWarning });

      // await window.touch.updateAccountSettings(this.accountSettings);
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 650px;
}
