<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Edit File" :loading="saving" @close="$emit('close')">
      <div class="flex -mx-3">
        <div class="p-3 w-1/2">
          <div class="font-medium">Title</div>
          <div>
            <input v-model="document.name" class="form-control" />
          </div>
        </div>
        <div class="p-3 w-1/2">
          <div class="font-medium">Category</div>
          <div>
            <select v-model="document.categoryId" class="form-control">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <vue-dropzone
        v-if="!document.isUrl"
        id="dropzone"
        ref="fileUploadDropzone"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        class="p-0 hover:bg-white"
        @vdropzone-file-added="fileAddedToDropZone"
      >
        <div
          class="text-center block border border-black rounded p-6 flex flex-col justify-around text-black hover:bg-gray-200 cursor-pointer"
        >
          <div class="mb-2">Drag and drop a file</div>
          <div class="mb-2">or</div>
          <div>
            <a class="btn-action w-32"> Select PDF, JPG or PNG </a>
          </div>
        </div>
      </vue-dropzone>
      <div v-else class="flex flex-wrap -mx-3 mb-3">
        <div class="p-3 w-full">
          <div class="font-medium">File URL</div>
          <div>
            <input v-model="document.url" class="form-control" />
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button v-if="!saving" class="btn-action btn-lg" @click.prevent.stop="save()">Save</button>
        <button v-else class="btn-action btn-lg btn-disabled">Saving</button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  props: ['categories', 'document'],
  data() {
    return {
      saving: false,
      errors: [],
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
      },
    };
  },
  methods: {
    ...mapActions({
      uploadDocumentForm: 'marketing/uploadDocumentForm',
      uploadDocumentWithUrl: 'user/customerUploadDocumentWithUrl',
    }),
    async fileAddedToDropZone(uploadedFile) {
      if (!uploadedFile) {
        return;
      }

      const allowedExtensions = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF'];

      if (!allowedExtensions.includes(uploadedFile.name.split('.').pop().toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          text: 'Please choose a PDF, PNG or JPG.',
          icon: 'error',
        });
        return;
      }

      this.document.File = uploadedFile;
    },
    async save() {
      if (!this.saving) {
        this.saving = true;

        if (this.document.CategoryId === null) {
          this.alertBox().fire({
            title: 'No Category Chosen',
            text: 'Please choose a Document Category.',
            icon: 'error',
          });
          this.saving = false;
          return;
        }

        if (this.file_url === null) {
          const formData = new FormData();
          formData.append('Id', this.document.id);
          formData.append('Name', this.document.name);
          formData.append('CategoryId', this.document.categoryId);
          formData.append('Type', this.enums.documentType.RESOURCE_FILE.toString());

          if (this.document.File) {
            formData.append('File', this.document.File);
          }

          await this.uploadDocumentForm(formData).then(() => {
            this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
            this.saving = false;
            this.$emit('close');
          });
        } else {
          if (!this.document.url.includes('//')) {
            this.document.url = `http://${this.document.url}`;
          }
          const document = {
            id: this.document.id,
            name: this.document.Name,
            type: this.enums.documentType.RESOURCE_FILE.toString(),
            categoryId: this.document.categoryId,
            url: this.document.url,
          };
          await this.uploadDocumentWithUrl(document).then(() => {
            this.saving = false;
            this.$emit('added');
            this.$emit('close');
          });
        }
      }
    },
  },
};
</script>
