export default {
  namespaced: true,
  state: {
    isCreateQuoteEnabled: true,
    isCreateOrderEnabled: true,
    isViewQuotesEnabled: true,
    isViewOrdersEnabled: true,
    isQuoteEmailDetailEditingEnabled: true,
    isRequotePriceChangeEnabled: true,
  },
  getters: {
    getIsCreateQuoteEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsCreateOrderEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsViewQuotesEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsViewOrdersEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsQuoteEmailDetailEditingEnabled(state) {
      return state.isQuoteEmailDetailEditingEnabled;
    },
    getIsRequotePriceChangeEnabled(state) {
      return state.isRequotePriceChangeEnabled;
    },
    isRackManagerEnabled(state, getters, rootState) {
      return rootState?.style?.fabricator?.name === 'Epwin Window Systems';
    },
  },
  mutations: {
    setIsCreateQuoteEnabled(state, isEnabled) {
      state.isCreateQuoteEnabled = isEnabled;
    },
    setIsCreateOrderEnabled(state, isEnabled) {
      state.isCreateOrderEnabled = isEnabled;
    },
    setIsViewQuotesEnabled(state, isEnabled) {
      state.isViewQuotesEnabled = isEnabled;
    },
    setIsViewOrdersEnabled(state, isEnabled) {
      state.isViewOrdersEnabled = isEnabled;
    },
    setIsQuoteEmailDetailEditingEnabled(state, isEnabled) {
      state.isQuoteEmailDetailEditingEnabled = isEnabled;
    },
    setIsRequotePriceChangeEnabled(state, isEnabled) {
      state.isRequotePriceChangeEnabled = isEnabled;
    },
  },
  actions: {
    async isRequotePriceChangeEnabled({ rootState }) {
      const productStatus =
        rootState.style?.fabricator?.accountProductStatus ??
        (await window.touch.commonOrganisationDetails())?.accountProductStatus;
      if (productStatus !== window.enum.ProductStatus.LEAD_GEN_ONLY) {
        // if it is a business installation and the user is logged in, we can show this.
        if (window.VUE_APP_INSTALLATION_TYPE === 'business' && rootState.auth.loggedIn) {
          return true;
        }
        // if is not a vendor override and the user is an admin, we can show it.
        if (window.VUE_APP_OVERRIDE !== 'vendor' && rootState.auth.admin) {
          return true;
        }
      }
      return false;
    },

    async refreshPermissions({ commit, rootState }) {
      const productStatus =
        rootState.style?.fabricator?.accountProductStatus ??
        (await window.touch.commonOrganisationDetails())?.accountProductStatus;
      commit(
        'setIsQuoteEmailDetailEditingEnabled',
        window.VUE_APP_INSTALLATION_TYPE !== 'business' &&
          productStatus !== window.enum.ProductStatus.LEAD_GEN_ONLY,
      );

      if (
        productStatus === window.enum.ProductStatus.LEAD_GEN_ONLY &&
        window.VUE_APP_INSTALLATION_TYPE === 'portal'
      ) {
        commit('setIsCreateQuoteEnabled', false);
        commit('setIsCreateOrderEnabled', false);
        commit('setIsViewQuotesEnabled', false);
        commit('setIsViewOrdersEnabled', false);
      } else {
        commit('setIsCreateQuoteEnabled', true);
        commit('setIsCreateOrderEnabled', true);
        commit('setIsViewQuotesEnabled', true);
        commit('setIsViewOrdersEnabled', true);
      }
    },
  },
};
