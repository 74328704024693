<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['My Prices']]" />
    </template>
    <template #header_page_title>
      My Prices
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for an item to edit a cost..." />
    </portal>
    <template #header_page_actions>
      <div class="ml-auto flex flex-col justify-around">
        <label class="px-3 md:px-10 flex items-center">
          <div class="flex items-center border rounded">
            <div class="p-1 mx-1"><span class="hidden xs:inline">Show </span>FOC<span class="hidden xs:inline"> items only</span></div>
            <div class="p-1 mx-1">
              <input v-model="focFilterActive" type="checkbox" class="w-4 h-4 mt-1" />
            </div>
          </div>
        </label>
      </div>  
    </template>
    <Menu></Menu>
    <MyCosts class="h-full overflow-y-auto" :foc-filter-active="focFilterActive"></MyCosts>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';
import MyCosts from '@/components/portal/myAccount/MyPrices.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    MyCosts,
    Breadcrumbs,
    Menu,
    SearchBox,
  },
  data() {
    return {
      focFilterActive: false,
    };
  },
};
</script>
