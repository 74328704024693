<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Branded Mode Account', '/setup'], ['Prices']]"
      />
    </template>
    <template #header_page_title> Prices </template>
    <div
      id="pricing_index_scrolling_outer_wrapper"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch xs:p-10"
    >
      <div class="mx-auto relative" style="max-width: 1550px">
        <div class="flex flex-col-reverse xs:flex-row">
          <div class="flex-grow">
            <keep-alive>
              <router-view v-if="groups.length > 0" :groups="groups" :anomalies="anomalies" :original_anomalies="original_anomalies"></router-view>
            </keep-alive>
          </div>
          <div
            class="md:block md:ml-10 md:mb-0 md:relative mt-10 md:mt-0 bg-white md:bg-transparent"
            :class="{
              'absolute z-20 block top-0 right-0': test_help_box_open,
              hidden: !test_help_box_open,
            }"
          >
            <how-to-setup-pricing
              class="vendor-setup-help-sidebar"
              @set-filter="$router.push({ query: { search: $event } }).catch(() => {})"
            ></how-to-setup-pricing>
            <div class="bg-green-200 p-6 lg:p-10 mt-6 text-center vendor-setup-help-sidebar">
              <div class="text-xl mb-3">
                <strong>Test your pricing</strong>
              </div>
              <div>
                Process a a quote using the quote and survey designer. Select the Price Calculation
                Breakdown icon next to the price as shown below.
              </div>
              <div class="my-6">
                <img
                  src="/images/vendor-pricing-setup-guide.png"
                  class="mx-auto"
                  style="max-height: 150px"
                />
              </div>
              <div>
                <router-link
                  to="/new-quote"
                  class="rounded-full bg-white border border-black text-center p-3 w-full"
                  target="_blank"
                >
                  <strong>Process a Test Quote</strong>
                </router-link>
              </div>
            </div>
            <div class="flex justify-center">
              <info-popup
                :id="'vendor_settings_pricing:test_your_pricing'"
                class=""
                :info_message="'Once you have added your pricing you can then use the price breakdown calculator within the sales and survey designer by clicking the magnifying glass, as pictured in the test your pricing example.'"
                :next_id="'vendor_settings_pricing:how_to_setup_pricing'"
              ></info-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && is_admin_user"
      class="bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between">
        <div v-if="!demo_mode" class="ml-auto mt-2 xs:mt-0">
          <button
            class="btn-action btn-lg w-full xs:w-auto"
            :class="{'pointer-events-none opacity-50': JSON.stringify(anomalies) === JSON.stringify(original_anomalies) }"
            @click.prevent.stop="saveSettings()"
          >
            Save all Changes 
          </button>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HowToSetupPricing from '@/components/portal/setup/pricing/HowToSetupPricing.vue';
import validate from 'validate.js';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
    'how-to-setup-pricing': HowToSetupPricing,
  },
  data() {
    return {
      loading: true,
      test_help_box_open: false,
      anomalies: [],
      original_anomalies: [],
      groups: []
    };
  },
  async mounted () {
    await this.loadSettings();
  },
  methods: {
    ...mapActions({
      getAnomalies: 'branded/getAnomalies',
      updateAnomalies: 'branded/updateAnomalies',
    }),
    async loadSettings() {
      this.loading = true
      // As per Paul we're removing the W+H options
      const idForWidthPlusHeightGrid = '1bbf9186-0ad5-42aa-bf70-0f969ac93023';

      this.groups = (await this.$store.dispatch('touch/customerGetFinancialGroups')).filter(
        (group) => group.id !== idForWidthPlusHeightGrid,
      );

      const anomalies = (await this.getAnomalies()).anomalies
        .filter((anomaly) => anomaly.groupId !== idForWidthPlusHeightGrid)
        .map((anomaly) => {
          if (
            anomaly.overrideValue == null &&
            anomaly.type === window.enum.anomalyType.SIMPLE_VALUE
          ) {
            return {
              ...anomaly,
              overrideValue: 0,
            };
          }

          return anomaly;
        })
        .sort((a, b) => a.listIndex - b.listIndex)
        .map((anomoly) => ({
          ...anomoly,
          groupText: this.groups.find((group) => group.id === anomoly.groupId).description,
        }));
      this.original_anomalies = JSON.parse(JSON.stringify(anomalies));
      this.anomalies = anomalies;
      this.loading = false
    },
    async saveSettings() {
      this.loading = true;

      if (this.validateInput()) {
        await this.updateAnomalies(this.modifiedAnomalies());
        this.loadSettings();
      }

      this.loading = false;
    },
    modifiedAnomalies() {
      return this.anomalies.filter(
        (anomaly) =>
          this.original_anomalies.findIndex(
            (originalAnomaly) =>
              originalAnomaly.id === anomaly.id &&
              originalAnomaly.overrideValue !== anomaly.overrideValue,
          ) !== -1,
      );
    },
    validateInput() {
      let isValid = true;
      this.anomalies.forEach((anomaly) => {
        if (!validate.isNumber(Number(anomaly.overrideValue))) {
          isValid = false;
        }
      });
      return isValid;
    },
  }
};
</script>
