<template>
  <div
    class="absolute pointer-events-none"
    :style="`left:${x}px; top: ${y}px`"
    @click.stop="$store.dispatch('visualiser/activateItem', index)"
  >
    <div ref="handle" class="mx-auto pointer-events-auto">
      <slot></slot>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  props: [
    "index",
    "transform",
    "transformOrigin",
    "x",
    "y",
    "x1",
    "x2",
    "x3",
    "x4",
    "y1",
    "y2",
    "y3",
    "y4",
    "active",
  ],
  data() {
    return {
      makeTransformable: undefined,
      handleElements: [],
      handleWidth: 9,
      handleHeight: 9,
    };
  },
  watch: {
    active(isActive) {
      if (isActive) {
        this.makeTransformable(this.$refs.handle);
        window.$(this.$refs.handle).css({
          transform: this.transform,
          "transform-origin": this.transformOrigin,
          opacity: 1,
        });
      } else {
        this.handleElements.forEach((handleElement) => {
          window.$(handleElement).remove();
        });
        this.handleElements = [];
      }
    },
  },
  methods: {
    applyTransform(props) {
      this.$emit("update", props);
    },
  },
  destroyed() {
    this.handleElements.forEach((handleElement) => {
      window.$(handleElement).remove();
    });
    this.handleElements = [];
  },
  mounted() {
    const self = this;

    window.$(this.$el).draggable({
      start(event, ui) {
        self.$store.commit("visualiser/deactivateAllImages");
        self.handleElements.forEach((handleElement) => {
          window.$(handleElement).remove();
        });
        self.handleElements = [];
      },
      stop(event, ui) {
        self.$emit("update", {
          x: ui.position.left,
          y: ui.position.top,
        });

        window.$(self.$refs.handle).css({
          left: ui.position.left,
          top: ui.position.top,
        });

        self.makeTransformable(self.$refs.handle);

        window.$(self.$refs.handle).css({
          transform: self.transform,
          "transform-origin": self.transformOrigin,
        });

        self.handleElements.forEach((handleElement, key) => {
          window.$(handleElement).css({
            left:
              window.$(handleElement).position().left +
              (ui.position.left - ui.originalPosition.left),
            top:
              window.$(handleElement).position().top + (ui.position.top - ui.originalPosition.top),
          });
          const props1 = {};
          props1[`x${key + 1}`] = window.$(handleElement).position().left + self.handleWidth;
          props1[`y${key + 1}`] = window.$(handleElement).position().top + self.handleHeight;
          self.applyTransform(props1);
        });

        self.$store.dispatch("visualiser/activateItem", self.index);
      },
    });

    var selector = this.$refs.handle; // Replace this with the selector for the element you want to make transformable

    window.jQuery.getScript(
      "//cdnjs.cloudflare.com/ajax/libs/numeric/1.2.6/numeric.min.js",
      function () {
        (function () {
          var $, applyTransform, getTransform, makeTransformable;

          $ = window.jQuery;

          getTransform = function (from, to) {
            var A, H, b, h, i, k_i, lhs, rhs, _i, _j, _k, _ref;
            console.assert(from.length === (_ref = to.length) && _ref === 4);
            A = [];
            for (i = _i = 0; _i < 4; i = ++_i) {
              A.push([
                from[i].x,
                from[i].y,
                1,
                0,
                0,
                0,
                -from[i].x * to[i].x,
                -from[i].y * to[i].x,
              ]);
              A.push([
                0,
                0,
                0,
                from[i].x,
                from[i].y,
                1,
                -from[i].x * to[i].y,
                -from[i].y * to[i].y,
              ]);
            }
            b = [];
            for (i = _j = 0; _j < 4; i = ++_j) {
              b.push(to[i].x);
              b.push(to[i].y);
            }
            h = numeric.solve(A, b);
            H = [
              [h[0], h[1], 0, h[2]],
              [h[3], h[4], 0, h[5]],
              [0, 0, 1, 0],
              [h[6], h[7], 0, 1],
            ];
            for (i = _k = 0; _k < 4; i = ++_k) {
              lhs = numeric.dot(H, [from[i].x, from[i].y, 0, 1]);
              k_i = lhs[3];
              rhs = numeric.dot(k_i, [to[i].x, to[i].y, 0, 1]);
              console.assert(numeric.norm2(numeric.sub(lhs, rhs)) < 1e-9, "Not equal:", lhs, rhs);
            }
            return H;
          };

          applyTransform = function (element, originalPos, targetPos, callback) {
            var H, from, i, j, p, to;
            from = (function () {
              var _i, _len, _results;
              _results = [];
              for (_i = 0, _len = originalPos.length; _i < _len; _i++) {
                p = originalPos[_i];
                _results.push({
                  x: p[0] - originalPos[0][0],
                  y: p[1] - originalPos[0][1],
                });
              }
              return _results;
            })();
            to = (function () {
              var _i, _len, _results;
              _results = [];
              for (_i = 0, _len = targetPos.length; _i < _len; _i++) {
                p = targetPos[_i];
                _results.push({
                  x: p[0] - originalPos[0][0],
                  y: p[1] - originalPos[0][1],
                });
              }
              return _results;
            })();
            H = getTransform(from, to);
            const newCss = {
              transform:
                "matrix3d(" +
                (function () {
                  var _i, _results;
                  _results = [];
                  for (i = _i = 0; _i < 4; i = ++_i) {
                    _results.push(
                      (function () {
                        var _j, _results1;
                        _results1 = [];
                        for (j = _j = 0; _j < 4; j = ++_j) {
                          _results1.push(H[j][i].toFixed(20));
                        }
                        return _results1;
                      })(),
                    );
                  }
                  return _results;
                })().join(",") +
                ")",
              "transform-origin": "0 0",
            };
            self.applyTransform(newCss);
            $(element).css(newCss);
            var topElement = element;
            return typeof callback === "function" ? callback(element, H) : void 0;
          };

          makeTransformable = function (selector, callback) {
            $(selector).css("transform", "");
            return $(selector).each(function (i, element) {
              var controlPoints,
                originalPos = [],
                p,
                position;
              controlPoints = (function () {
                var _i, _len, _ref, _results;
                _ref = ["left top", "left bottom", "right top", "right bottom"];
                _results = [];
                self.handleElements.forEach((handleElement) => {
                  window.$(handleElement).remove();
                });
                self.handleElements = [];
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                  position = _ref[_i];
                  const newHandle = $("<div>")
                    .css({
                      border: "9px solid black",
                      borderRadius: "9px",
                      cursor: "move",
                      position: "absolute",
                      zIndex: 100000,
                    })
                    .appendTo("#image-holder")
                    .position({
                      at: position,
                      of: element,
                      collision: "none",
                    });

                  self.handleElements.push(newHandle);

                  switch (position) {
                    case "left top":
                      originalPos[0] = [newHandle.position().left, newHandle.position().top];
                      if (self.x1 && self.y1) {
                        newHandle.css({
                          left: self.x1 - self.handleWidth,
                          top: self.y1 - self.handleHeight,
                        });
                      } else {
                        self.applyTransform({
                          x1: newHandle.position().left + self.handleWidth,
                          y1: newHandle.position().top + self.handleHeight,
                        });
                      }
                      break;
                    case "left bottom":
                      originalPos[1] = [newHandle.position().left, newHandle.position().top];
                      if (self.x2 && self.y2) {
                        newHandle.css({
                          left: self.x2 - self.handleWidth,
                          top: self.y2 - self.handleHeight,
                        });
                      } else {
                        self.applyTransform({
                          x2: newHandle.position().left + self.handleWidth,
                          y2: newHandle.position().top + self.handleHeight,
                        });
                      }
                      break;
                    case "right top":
                      originalPos[2] = [newHandle.position().left, newHandle.position().top];
                      if (self.x3 && self.y3) {
                        newHandle.css({
                          left: self.x3 - self.handleWidth,
                          top: self.y3 - self.handleHeight,
                        });
                      } else {
                        self.applyTransform({
                          x3: newHandle.position().left + self.handleWidth,
                          y3: newHandle.position().top + self.handleHeight,
                        });
                      }
                      break;
                    case "right bottom":
                      originalPos[3] = [newHandle.position().left, newHandle.position().top];
                      if (self.x4 && self.y4) {
                        newHandle.css({
                          left: self.x4 - self.handleWidth,
                          top: self.y4 - self.handleHeight,
                        });
                      } else {
                        self.applyTransform({
                          x4: newHandle.position().left + self.handleWidth,
                          y4: newHandle.position().top + self.handleHeight,
                        });
                      }
                      break;
                  }

                  _results.push(newHandle);
                }
                return _results;
              })();
              // originalPos = (function () {
              //   var _i,
              //     _len,
              //     _results;
              //   _results = [];
              //   for (_i = 0, _len = controlPoints.length; _i < _len; _i++) {
              //     p = controlPoints[_i];
              //     _results.push([p.position().left, p.position().top]);
              //   }
              //   return _results;
              // })();
              $(controlPoints).draggable({
                start: (function (_this) {
                  return function () {
                    return $(element).css("pointer-events", "none");
                  };
                })(this),
                drag: (function (_this) {
                  return function () {
                    return applyTransform(
                      element,
                      originalPos,
                      (function () {
                        var _i, _len, _results;
                        _results = [];
                        for (_i = 0, _len = controlPoints.length; _i < _len; _i++) {
                          p = controlPoints[_i];
                          _results.push([p.position().left, p.position().top]);
                        }
                        return _results;
                      })(),
                      callback,
                    );
                  };
                })(this),
                stop: (function (_this) {
                  return function () {
                    applyTransform(
                      element,
                      originalPos,
                      (function () {
                        var _i, _len, _results;
                        _results = [];
                        for (_i = 0, _len = controlPoints.length; _i < _len; _i++) {
                          p = controlPoints[_i];
                          _results.push([p.position().left, p.position().top]);
                          const coords = {};
                          coords[`x${_i + 1}`] = p.position().left + self.handleWidth;
                          coords[`y${_i + 1}`] = p.position().top + self.handleHeight;
                          self.applyTransform(coords);
                        }
                        return _results;
                      })(),
                      callback,
                    );
                    return $(element).css("pointer-events", "auto");
                  };
                })(this),
              });

              return element;
            });
          };

          self.makeTransformable = makeTransformable;
        }).call(this);

        if (self.active) {
          self.makeTransformable(selector);
        }

        window.$(selector).css({
          transform: self.transform,
          "transform-origin": self.transformOrigin,
          opacity: 1,
        });
      },
    );
  },
};
</script>
