import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.setupUpdateProducts = async function setupUpdateProducts(products) {
  return (
    await this.authenticatedPost('setup/UpdateProducts', {
      Products: products,
    })
  ).data;
};

Touch.prototype.setupOrderBusinessProducts = async function setupOrderBusinessProducts(products) {
  return this.parseResponse(
    await this.authenticatedPost('setup/OrderBusinessProducts', {
      Products: products,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};
