export default {
  namespaced: true,
  actions: {
    async loadKPIDashboardActiveUsers(context, range) {
      return window.touch.loadKPIDashboardActiveUsers(range);
    },
    async loadKPIDashboardQuotes(context, range) {
      return window.touch.loadKPIDashboardQuotes(range);
    },
    async loadKPIDashboardOrders(context, range) {
      return window.touch.loadKPIDashboardOrders(range);
    },
    async loadKPITopCustomersForQuotes(context, range) {
      return window.touch.loadKPITopCustomersForQuotes(range);
    },
    async loadKPITopCustomersForOrders(context, range) {
      return window.touch.loadKPITopCustomersForOrders(range);
    },
    async loadKPIConversionsFromEnquiriesToQuotes(context, range) {
      return window.touch.loadKPIConversionsFromEnquiriesToQuotes(range);
    },
    async loadKPIConversionsFromQuotesToOrders(context, range) {
      return window.touch.loadKPIConversionsFromQuotesToOrders(range);
    },
  },
};
