<script>
import { mapActions } from 'vuex';
import validate from 'validate.js';

export default {
  props: ['addresses', 'customerId', 'selectedAddress'],
  methods: {
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
    ...mapActions({
      customerUpdateVendorAddress: 'user/customerUpdateVendorAddress',
    }),
    close() {
      this.$emit('close');
    },
    selectAddress(event) {
      this.$emit('select-address', parseInt(event.target.value, 10));
      this.$emit('close');
    },
    async saveAddress() {
      const validationErrors = validate(
        {
          line1: this.newAddress.line1,
          town: this.newAddress.town,
          country: this.newAddress.iso,
          postcode: this.newAddress.code,
        },
        {
          line1: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          town: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          country: {
            presence: { allowEmpty: false },
            length: {
              maximum: 3,
            },
          },
          postcode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 16,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      const newAddressList = [...this.addresses, this.newAddress];

      const customer = {
        addresses: newAddressList,
      };
      if (this.touch_business_installation) {
        customer.customerId = this.customerId;
      }

      const savedNewAddresses = await this.customerUpdateVendorAddress(customer);

      this.$emit('new-address', {
        addresses: savedNewAddresses,
        addressId: savedNewAddresses[0].id,
      });

      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
