var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"flex bg-white px-5 border-t-4 border-white whitespace-no-wrap"},[_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.$route.query.active_section === 'company-details',
        'border-white': _vm.$route.query.active_section !== 'company-details',
      },attrs:{"to":{ query: { active_section: 'company-details' } }}},[_vm._v(" Company Details ")]),_c('router-link',{staticClass:"cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black",class:{
        'border-black': _vm.$route.query.active_section === 'settings',
        'border-white': _vm.$route.query.active_section !== 'settings',
      },attrs:{"to":{ query: { active_section: 'settings' } }}},[_vm._v(" Settings ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }