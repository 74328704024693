<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Branded Mode Subscriptions'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Branded Mode Subscriptions
      </div>
    </template>
    <portal v-if="licenses" to="portal_search">
      <SearchBox class="flex" placeholder="Search for customer..." />
    </portal>
    <slot></slot>
    <div class="flex flex-col flex-grow bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-5">
      <loading v-if="loading" :loading="true" class="w-full h-full" style="flex: 1 1 0"></loading>
      <div v-else-if="licenses">
        <div class="bg-white p-3 xs:p-5 border mb-3 flex">
          <div class="ml-auto">
            <strong>{{ licenses.used }}</strong> out of <strong>{{ licenses.max }}</strong> Branded Mode licenses allocated.
          </div>
        </div>
        <div class="bg-white p-3 xs:p-10 border">
          <table class="table-lined xs:table-unstacked _with-hover w-full bg-white p-5">
            <thead>
              <tr>
                <th> Company </th>
                <th> Designer Url </th>
                <th>
                  <FilterDropdown
                    label="Branded Mode"
                    query-label="brandedMode"
                    :filters="[
                      { id: enums.brandedMode.ENABLED, name: 'Enabled', dot: 'bg-green-500' },
                      { id: enums.brandedMode.FABRICATOR_ENABLED, name: 'Fabricator Enabled', dot: 'bg-blue-500' },
                      { id: enums.brandedMode.CUSTOMER_ENABLED, name: 'Customer Enabled', dot: 'bg-red-500' },
                    ]"
                  />
                </th>
                <th> Expiry Date </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="customer in customers"
                :key="customer.id"
              >
                <td>
                  <div class="td-title w-24"> Company: </div>
                  <div class="td-content">{{ customer.name }}</div>
                </td>
                <td>
                  <div>
                    <a v-if="customer.brandedAlias" :href="baseURL + customer.brandedAlias" target="_blank" class="text-blue-600 underline">
                      <div class="td-title w-24"> Designer Url: </div>
                      <div class="td-content">{{ baseURL + customer.brandedAlias }}</div>
                    </a>
                  </div>
                </td>
                <td>
                  <div class="flex items-center gap-3">
                    <span class="text-xs opacity-50">{{ customer.brandedEnabled ? 'Enabled' : 'Disabled' }}</span>
                    <div
                      class="relative cursor-pointer" 
                      :class="{ 'pointer-events-none opacity-50': customer.brandedEnabled && !customer.fabricatorBrandedEnabled }"
                      @click="validateBrandedMode(customer.customerId, customer.name, !customer.brandedEnabled, customer.supplyUntil)"
                    >
                      <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                      <div class="switch-toggle" :class="{ _active: customer.brandedEnabled }"></div>
                    </div>
                    <div v-if="customer.brandedEnabled && !customer.fabricatorBrandedEnabled">
                      <Tooltip :width="200">
                        <span>
                          Activated by customer. The customer would need to cancel branded mode for the fabricator to enable it.
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="customer.fabricatorBrandedEnabled">
                    <datepicker
                      ref="datepicker"
                      v-model="customer.supplyUntil"
                      type="date"
                      value-type="format"
                      :format="'DD/MM/YYYY'"
                      placeholder="Set Expiry Date"
                      clearable
                      :editable="false"
                      :disabled-date="(date) => date < new Date()"
                      @change="validateSupplyUntil(customer.customerId, customer.name, customer.brandedEnabled, customer.supplyUntil)"
                    ></datepicker>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
            <div class="flex justify-between">
              <div>
                No Customers to show
              </div>
              <div v-if="$route.query.search || $route.query.brandedMode">
                <a class="btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => { });">
                  Clear Search
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-show="customers.length > 0" class="mt-3">
          <pagination
            :total_items="total_items"
            :limit="limit"
            @load-feed="loadFeed"
          ></pagination>
        </div>
      </div>
      <div v-else class="bg-white p-3 xs:p-10 border" style="flex: 1 1 0">
        <div class="bg-gray-200">
          <div class="flex flex-col-reverse md:flex-row gap-10 items-center p-10 rounded mx-auto" style="max-width: 1500px">
            <div class="flex flex-col gap-5 items-center w-full flex-1 text-center">
              <span class="text-2xl">Looking to give your customers an advantage?</span>
              <span class="text-lg">
                Supporting their Branded Mode subscriptions is the ideal solution! Activate their subscription for a
                set period to boost their home enquiries - helping them, helps you!
              </span>
              <span>To discuss, call our sales team on <a class="text-blue-600" href="tel:08000599705">0800 059 9705</a></span>
              <a class="btn-action btn-lg w-64" :href="store_url + 'branded-mode/subscriptions'" target="_blank">Discover More</a>
            </div>
            <div class="flex-1">
              <div class="max-w-lg mx-auto">
                <img class="rounded-lg w-full" src="/images/branded-mode-no-subs.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import Datepicker from 'vue2-datepicker';
import moment from 'moment';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
    SearchBox,
    Tooltip,
    Datepicker,
    FilterDropdown
  },
  data() {
    return {
      isDate: undefined,
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      licenses: undefined
    };
  },
  computed: {
    baseURL() {
      return window.touch.endpoint.replace('server', 'portal').replace('api', 'launch')
    }
  },
  watch: {
    '$route.query': {
      handler(queryNew, queryOld) {
        if (queryNew?.search !== queryOld?.search) {
          this.$router.push({ query: { ...this.$route.query, brandedMode: undefined } }).catch(() => { });
        }
        this.loadFeed();
      },
      immediate: true
    },
  },
  async created() {
    this.loading = true;
    await this.getBrandedFabricatorLimit()
  },
  methods: {
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await window.touch.ListCustomerBrandedDetails({
        Limit: this.limit,
        Offset: offset,
        SearchName: this.$route.query.search,
        BrandedEnabled: this.$route.query.brandedMode ? true : undefined,
        FabricatorBrandedEnabled: this.$route.query.brandedMode == this.enums.brandedMode.FABRICATOR_ENABLED
          ? true
          : (this.$route.query.brandedMode == this.enums.brandedMode.CUSTOMER_ENABLED ? false : undefined),
        GetTotal: true
      });
      this.customers = response.customerBrandedSummaries;
      this.customers = this.customers.map(x => ({
        ...x,
        supplyUntil: x.supplyUntil ? moment(new Date(x.supplyUntil)).format('DD/MM/YYYY') : undefined
      }))
      this.total_items = response.total;
      this.loading = false;
    },
    async getBrandedFabricatorLimit() {
      const response = await window.touch.GetBrandedFabricatorLimit() || {}
      if (response.status === 200) {
        this.licenses = { used: response?.data?.used, max: response?.data?.max }
      }
    },
    async updateBrandedFabricator(customerId, brandedMode, supplyUntil, html) {
      this.alertBox()
        .fire({
          html,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            await window.touch.updateBrandedFabricator(customerId, brandedMode, supplyUntil, brandedMode ? !supplyUntil : false)
            await this.getBrandedFabricatorLimit()
            await this.loadFeed()
            this.loading = false;
          } else {
            this.loading = true;
            await this.getBrandedFabricatorLimit()
            await this.loadFeed()
            this.loading = false;
          }
        })
    },
    async validateBrandedMode(customerId, customerName, brandedMode, supplyUntil) {
      const state = brandedMode ? 'enable' : 'disable'
      const html = '<h2 class="text-l text-black">Are you sure you want to <strong><u>' + state + '</u></strong> branded mode for ' + customerName.trim() + '?</h2>'
      if (supplyUntil) {
        supplyUntil = moment(supplyUntil, 'DD/MM/YYYY')
        supplyUntil.add({ hours: 23, minutes: 59, seconds: 59 });
        supplyUntil = supplyUntil.format()
      }
      await this.updateBrandedFabricator(customerId, brandedMode, supplyUntil, html)
    },
    async validateSupplyUntil(customerId, customerName, brandedMode, supplyUntil) {
      let html = ''
      if (supplyUntil) {
        html = '<h2 class="text-l text-black">Are you sure you want to update the expiry date to  <strong><u>' + supplyUntil + '</u></strong> for ' + customerName.trim() + '?</h2>'
        supplyUntil = moment(supplyUntil, 'DD/MM/YYYY')
        supplyUntil.add({ hours: 23, minutes: 59, seconds: 59 });
        supplyUntil = supplyUntil.format()
      } else {
        html = '<h2 class="text-l text-black">Are you sure you want to remove the expiry date for ' + customerName.trim() + '?</h2>'
      }
      await this.updateBrandedFabricator(customerId, brandedMode, supplyUntil, html)
    },
  }
};
</script>