export default {
  methods: {
    getMergedCellsFromGrid(grid) {
      if (grid.overrideCells && grid.overrideCells.length === grid.cells.length) {
        return grid.overrideCells;
      }

      return grid.cells;
    },
  },
};
