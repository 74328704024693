var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'details',
          'border-white': _vm.active_section !== 'details',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/details`}},[_vm._v(" Customer Details ")]),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'sales-dashboard',
          'border-white': _vm.active_section !== 'sales-dashboard',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/sales-dashboard`}},[_vm._v(" Sales Dashboard ")]):_vm._e(),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'addresses',
          'border-white': _vm.active_section !== 'addresses',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/addresses`}},[_vm._v(" Delivery Addresses ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'staff',
          'border-white': _vm.active_section !== 'staff',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/staff`}},[_vm._v(" Staff & Licenses ")]),(_vm.is_admin_user && !_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'vouchers',
          'border-white': _vm.active_section !== 'vouchers',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/vouchers`}},[_vm._v(" Vouchers ")]):_vm._e(),(_vm.is_admin_user && !_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'discounts',
          'border-white': _vm.active_section !== 'discounts',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/discounts`}},[_vm._v(" Discounts ")]):_vm._e(),(_vm.is_admin_user && !_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'pricing',
          'border-white': _vm.active_section !== 'pricing',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/pricing`}},[_vm._v(" Pricing ")]):_vm._e(),(_vm.is_admin_user && !_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'stock-parts',
          'border-white': _vm.active_section !== 'stock-parts',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/stock-parts`}},[_vm._v(" Stock Parts ")]):_vm._e(),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'available-products',
          'border-white': _vm.active_section !== 'available-products',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/available-products`}},[_vm._v(" Products ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'processing-options',
          'border-white': _vm.active_section !== 'processing-options',
        },attrs:{"to":`/customer/${_vm.$route.params.id}/processing-options`}},[_vm._v(" Processing Options ")]),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }