<template>
  <aside
    class="hidden sm:flex border-r relative flex-col justify-around print:hidden bg-blue-900"
    style="width: 250px"
  >
    <router-link
      to="/"
      class="bg-white flex flex-col justify-around"
      style="height: 122px; width: 250px"
    >
      <img id="fabricator_logo" :src="style.fabricator_logo" class="m-auto max-h-full" />
    </router-link>
    <div class="overflow-y-auto overflow-x-hidden flex-shrink-0 flex-grow h-px scrolling-touch">
      <PortalMenuItems></PortalMenuItems>
    </div>
    <div class="mb-4">
      <div class="text-center">
        <a
          :href="$t(store_url)"
          target="_blank"
          class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
        >
          <div class="p-1">
            <img src="/images/touch-logo.svg" width="50px" />
          </div>
          <div class="p-1 text-2xs">By BM Group</div>
        </a>
      </div>
    </div>
  </aside>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';
import PortalMenuItems from '@/components/shared/nav/PortalMenuItems.vue';

export default {
  name: 'SidebarMenu',
  components: {
    PortalMenuItems,
  },
  mixins: [dateHelper],
  data() {
    return {
      show_company_name_as_logo: false,
    };
  },
  computed: {
    on_dashboard() {
      return this.$route.name === 'Portal Dashboard';
    },
    on_designer_settings() {
      return this.$route.name === 'Setup';
    },
  },
  methods: {
    vendorLogoMissing() {
      this.show_company_name_as_logo = true;
    },
  },
};
</script>
