<template>
  <touch-layout>
    <SwitchDesign @goToURL="goToURL"></SwitchDesign>
  </touch-layout>
</template>

<script>
import SwitchDesign from '@/components/shared/SwitchDesign.vue';

export default {
  components: {
    SwitchDesign,
  },
  methods: {
    goToURL() {
      this.routerPush(`/design/${this.$route.params.id}`);
    },
  },
};
</script>
